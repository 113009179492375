/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Stati di un offerta formativa. Valori previsti: *PUBBLICA*, *NON_PUBBLICA*
 */
export type OffertaFormativaStatus = 'PUBBLICA' | 'NON_PUBBLICA';

export const OffertaFormativaStatus = {
    PUBBLICA: 'PUBBLICA' as OffertaFormativaStatus,
    NONPUBBLICA: 'NON_PUBBLICA' as OffertaFormativaStatus
};