<ng-container *transloco="let t">
    <div class="flex flex-col w-full items-center justify-center justify-items-center">
        <app-image-header style="padding: 20px"
                          *ngIf="data?.showImage"
                          [image]="data?.imageSafeUrl"
                          [iniziali]="data.imageIniziali"
                          [urlFoto]="ctrlUrlFoto"
                          [enable]="false"></app-image-header>

        <div *ngIf="data.subTextImg">
            {{data.subTextImg}}
        </div>

        <div style="margin-top: 10px" class="w-full mb-2 flex flex-wrap rounded-2xl bg-gray-50">
            <div class="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 m-4 mb-4"
                 *ngIf="info?.length > 0">
                <ng-container *ngFor="let inf of info">
                    <ng-container *ngIf="!inf?.hide">

                    <app-input-value-key [label]="t(inf.key)"
                                         [value]=" inf.value"
                                         [pipe]="inf.pipe"
                                         [subValue]="inf?.subValue"
                                         [subLabel]="inf.subLabel"
                                         [showVisualizzaButton]="!!inf.showVisualizzaButton && !!inf.buttonAction"
                                         (onDownloadButtonPressed)="inf.buttonAction()">
                    </app-input-value-key>

                    </ng-container>
                </ng-container>
            </div>

            <div class="p-4 flex flex-col gap-4 w-full" *ngIf="this.data.bottomComponent">
                <div class="text-lg text-center lg:text-start font-medium break-words" *ngIf="this.data.bottomComponentLabel">{{t(this.data.bottomComponentLabel)}}</div>
                <ng-template [cdkPortalOutlet]="portal"></ng-template>
            </div>

        </div>
    </div>
</ng-container>

