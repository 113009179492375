<ng-container *transloco="let t">
    <div class="w-full flex flex-col flex-auto w-full studenti-topbar-filter-div" *ngIf="!!getMainFormGroup()"
         #mainContainer>
        <!-- FORMS -->
        <form [formGroup]="filterManagerService.mainFormGroup"
              (ngSubmit)="applyFilters()"
              (keydown.tab)="$event.stopPropagation()"
              class="flex flex-col gap-4">


        <!-- TITLE -->
        <div style="background-color: #ffffff; z-index: 1000;" #titleDiv
             class="flex flex-col sm:flex-row gap-3 sm:justify-between">
            <div class="mt-2 text-2xl font-medium ml-2">
                <strong>{{t('menu_filter.advanced_search')}}</strong>
            </div>

            <div class="pt-2 pb-2 grid grid-cols-1 sm:grid-cols-2 gap-3">

                <!-- BUTTONS-->
                <button mat-flat-button
                        (click)="reset()"
                        type="button"
                        [color]="'accent'">
                    <mat-icon class="mr-2">delete</mat-icon>
                    <span>{{t('menu_filter.reset')}}</span>
                </button>

                <button mat-flat-button
                        type="submit"
                        [color]="'primary'">
                    <mat-icon class="mr-2">search</mat-icon>
                    <span>{{t('home.apply')}}</span>
                </button>
            </div>
            <mat-divider class="sm:hidden"></mat-divider>
        </div>

        <div style="overflow: auto" class="pt-1">
           <div class="grid grid-cols-1 grid-rows-none sm:grid-cols-2 md:grid-cols-4 gap-3">
                <ng-container *ngFor="let field of currentFields">
                    <ng-container
                            *ngIf="(field !== _codiceFiscaleCosupervisore)
                            && (field !== _codiceFiscaleSupervisore)
                            && (field !== _codiceFiscaleDocenteCorso)
                            && (field !== _statoSpesaStudente)
                            && (field !== _statoUltimoAggiornamentoPianoDiStudi)
                            && (field !== _codiceTipoBorsaPerCiclo)
                            && (field !== _mobilitaDaApprovare)
                            && (field !== _hasNotDeliberaInizioAttivita)
                            && (field !== _hasNotSupervisore)
                            && (field !== _hasNotCosupervisore)
                            && (field !== _statoCarriera)
                            && (field !== _statoCarrieraMotivazione)
                            && (field !== _hasNumGiorniMobilitaInferioreAlMinimoPrevisto)
                            && (field !== _hasNumGiorniMobilitaSuperioreAlPrevisto)
                            && (field !== _hasMissioniDaApprovare)">
                        <mat-form-field class="w-full mat-form-field-topbar-filter" appearance="outline">
                            <mat-label>{{t(fieldsLabelMap.get(field))}}</mat-label>
                            <input matInput
                                   [formControl]="getFormControlByPath(field)"
                                   (blur)="getFormControlByPath(field).setValue(getFormControlByPath(field)?.value?.trim())">
                        </mat-form-field>
                    </ng-container>

                    <ng-container *ngIf="field === _statoCarriera">
                        <mat-form-field appearance="outline" class="mat-form-field-topbar-filter">
                            <mat-label>{{ t(fieldsLabelMap.get(field)) }}</mat-label>
                            <mat-select [formControl]="getFormControlByPath(field)" multiple (selectionChange)="statoCarrieraFilterChange()">
                                <ng-container *ngFor="let statoCarriera of Object.values(StudenteCicloStatus)">
                                    <mat-option [value]="statoCarriera" *ngIf="statoCarriera !== StudenteCicloStatus.IPOTESI">
                                        {{t(statoCarriera | statusEnumToLanguagePath: StudenteCicloStatus) | titlecase}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>

                    <ng-container *ngIf="field === _statoCarrieraMotivazione && getFormControlByPath(_statoCarriera)?.value?.length > 0">
                        <mat-form-field appearance="outline" class="mat-form-field-topbar-filter">
                            <mat-label>{{ t(fieldsLabelMap.get(field)) }}</mat-label>
                            <mat-select [formControl]="getFormControlByPath(field)" multiple>
                                <ng-container *ngFor="let statoCarrieraMotivo of getStudenteMotiviForStatus(getFormControlByPath(_statoCarriera)?.value)">
                                    <mat-option [value]="statoCarrieraMotivo">
                                        {{t(statoCarrieraMotivo | statusEnumToLanguagePath: StudenteCicloMotivoStatus) | titlecase}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>

                    <ng-container *ngIf="field === _codiceFiscaleSupervisore">
                        <mat-form-field appearance="outline" class="mat-form-field-topbar-filter">
                            <mat-label>{{t(fieldsLabelMap.get(field))}}</mat-label>
                            <mat-select [formControl]="getFormControlByPath(field)"
                                        [compareWith]="objectComparisonFunctionByCodiceFiscale">
                                <mat-option [value]="null">{{t('common.all')}}</mat-option>
                                <mat-option *ngFor="let codiceSupervisore of supervisoreList"
                                            [value]="codiceSupervisore">
                                    {{codiceSupervisore.cognome + ' ' + codiceSupervisore.nome | titlecase}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>

                    <ng-container *ngIf="field === _codiceFiscaleCosupervisore">
                        <mat-form-field appearance="outline" class="mat-form-field-topbar-filter">
                            <mat-label>{{t(fieldsLabelMap.get(field))}}</mat-label>
                            <mat-select [formControl]="getFormControlByPath(field)"
                                        [compareWith]="objectComparisonFunctionByCodiceFiscale">
                                <mat-option [value]="null">{{t('common.all')}}</mat-option>
                                <mat-option *ngFor="let codiceCosupervisore of cosupervisoreList"
                                            [value]="codiceCosupervisore">
                                    {{codiceCosupervisore.cognome + ' ' + codiceCosupervisore.nome | titlecase}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>

                    <ng-container *ngIf="field === _codiceFiscaleDocenteCorso">
                        <mat-form-field appearance="outline" *ngIf="!!docenteList && docenteList?.length > 0" class="mat-form-field-topbar-filter">
                            <mat-label>{{t(fieldsLabelMap.get(field))}}</mat-label>
                            <mat-select [formControl]="getFormControlByPath(field)"
                                        [compareWith]="objectComparisonFunctionByCodiceFiscale">
                                <mat-option [value]="null">{{t('common.all')}}</mat-option>
                                <mat-option *ngFor="let codiceDocente of docenteList"
                                            [value]="codiceDocente">
                                    {{codiceDocente.cognome + ' ' + codiceDocente.nome | titlecase}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>

                    <ng-container
                        *ngIf="(field === _codiceTipoBorsaPerCiclo)">
                        <mat-form-field appearance="outline" class="w-full mat-form-field-topbar-filter">
                            <mat-label>{{t(fieldsLabelMap.get(field))}}</mat-label>
                            <input matInput
                                   [formControl]="getFormControlByPath(field)"
                                   (blur)="getFormControlByPath(field).setValue(getFormControlByPath(field)?.value?.trim())">
                        </mat-form-field>
                    </ng-container>

                </ng-container>
            </div>

        </div>

            <mat-divider *ngIf="!!currentFields && (
            currentFields?.includes(_hasNotDeliberaInizioAttivita)
            || currentFields?.includes(_hasNotSupervisore)
            || currentFields?.includes(_hasNotCosupervisore)
            || currentFields?.includes(_hasNumGiorniMobilitaInferioreAlMinimoPrevisto)
            || currentFields?.includes(_hasNumGiorniMobilitaSuperioreAlPrevisto)
            || currentFields?.includes(_hasMissioniDaApprovare))"></mat-divider>

            <div style="overflow: hidden">
                <div class="grid grid-cols-1 grid-rows-none sm:grid-cols-2 gap-x-3 gap-y-5 items-center justify-center px-2">
                    <ng-container *ngFor="let field of currentFields">

                        <ng-container *ngIf="field === _hasNotDeliberaInizioAttivita">
                            <div class="flex flex-row items-center">
                                <mat-slide-toggle class="m-1" color="primary" [formControl]="getFormControlByPath(field)">
                                </mat-slide-toggle>
                                <span>{{ t(fieldsLabelMap.get(field)) }}</span>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="field === _hasNotSupervisore">
                            <div class="flex flex-row items-center">
                                <mat-slide-toggle class="m-1" color="primary" [formControl]="getFormControlByPath(field)">
                                </mat-slide-toggle>
                                <span>{{ t(fieldsLabelMap.get(field)) }}</span>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="field === _hasNotCosupervisore">
                            <div class="flex flex-row items-center">
                                <mat-slide-toggle class="m-1" color="primary" [formControl]="getFormControlByPath(field)">
                                </mat-slide-toggle>
                                <span>{{ t(fieldsLabelMap.get(field)) }}</span>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="field === _hasNumGiorniMobilitaInferioreAlMinimoPrevisto">
                            <div class="flex flex-row items-center">
                                <mat-slide-toggle class="m-1" color="primary" [formControl]="getFormControlByPath(field)">
                                </mat-slide-toggle>
                                <span>{{ t(fieldsLabelMap.get(field)) }}</span>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="field === _hasNumGiorniMobilitaSuperioreAlPrevisto">
                            <div class="flex flex-row items-center">
                                <mat-slide-toggle class="m-1" color="primary" [formControl]="getFormControlByPath(field)">
                                </mat-slide-toggle>
                                <span>{{ t(fieldsLabelMap.get(field)) }}</span>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="field === _hasMissioniDaApprovare">
                            <div class="flex flex-row items-center">
                                <mat-slide-toggle class="m-1" color="primary" [formControl]="getFormControlByPath(field)">
                                </mat-slide-toggle>
                                <span>{{ t(fieldsLabelMap.get(field)) }}</span>
                            </div>
                        </ng-container>

                    </ng-container>
                </div>

            </div>

        </form>
    </div>
</ng-container>
