import {Injectable} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {BehaviorSubject, Subject} from 'rxjs';
import {AggiornamentoBudgetStudenteStatus, AnnoRiferimentoValues, AuthorityType} from 'api-clients/generated/services';
import {LocalStorageService} from "../../../../../shared/service/local-storage.service";
import {ApplyFiltersQuicklyStudentsI} from "../../../students-list/students-list.component";

const DEFAULT_PAGE_SIZE = 10;


export interface BudgetEditFilters {
    nominativoStudente?: string;
    cfStudente?: string;
    matricolaStudente?: string;
    motivazioneModifica?: string;
    status?: AggiornamentoBudgetStudenteStatus[];
    annoRiferimento?: AnnoRiferimentoValues;
}

@Injectable({
    providedIn: "root"
})
export class ModificaBudgetFilterService {

    mainFormGroup: FormGroup | undefined;
    reset$ = new BehaviorSubject(false);
    checkApplyFilterClick$ = new BehaviorSubject(false);
    currentSottoRuolo: AuthorityType | undefined;
    filterApplied$ = new BehaviorSubject(undefined);
    filterApplied = undefined;
    pageSize = 10;
    page = 0;
    defaultFormPathList = [
        'nominativoStudente',
        'cfStudente',
        'matricolaStudente',
        'motivazioneModifica',
        'status',
        'annoRiferimento',
    ];
    fieldsLabelMap = new Map<string, string>([
        ['nominativoStudente', 'common.nominative'],
        ['cfStudente', 'common.fiscal_code'],
        ['matricolaStudente', 'common.enrollment_number'],
        ['motivazioneModifica', 'budget.motivation_edit'],
        ['status', 'common.status'],
        ['annoRiferimento', 'common.year'],
    ]);

    openDrawer = new Subject();
    applyFiltersQuickly: ApplyFiltersQuicklyStudentsI;


    constructor(public localStorageService: LocalStorageService,
                public formBuilder: FormBuilder) {
    }


    createDefaultFormGroup(): FormGroup {
        return this.formBuilder.group({
            nominativoStudente: null,
            cfStudente: null,
            matricolaStudente: null,
            motivazioneModifica: null,
            status: null,
            annoRiferimento: null,
        });
    }


    getFieldsByRole(): string[] {
        return this.defaultFormPathList;
    }

    getCtrlByPath(path: string): FormControl<any> {
        return this.mainFormGroup?.get(path) as FormControl;
    }

    init(force?: boolean): void {
        const sottoruolo = this.localStorageService.dipartimentoRuoloCiclo?.sottoruolo;
        if ((this.currentSottoRuolo !== sottoruolo) || !this.currentSottoRuolo) {
            this.currentSottoRuolo = sottoruolo;
            this.buildForm();
        } else if(force){
            this.buildForm();
            this.filterApplied = this.mainFormGroup.getRawValue();
        }
        if(this.applyFiltersQuickly?.statiModificaBudget) {
            this.mainFormGroup.get('status').setValue(this.applyFiltersQuickly?.statiModificaBudget);
            this.filterApplied = this.mainFormGroup.getRawValue();
        }
    }

    private buildForm() {
        if (!!this.mainFormGroup) {
            this.mainFormGroup.reset();
        } else {
            this.mainFormGroup = this.createDefaultFormGroup();
        }
    }






    reset(): void {
        this.page = 0;
        this.pageSize = DEFAULT_PAGE_SIZE;
        this.mainFormGroup.reset();
        this.filterApplied = this.mainFormGroup.getRawValue();
        this.checkApplyFilterClick$.next(true);
    }


    restore(): void {
        this.page = 0;
        this.pageSize = DEFAULT_PAGE_SIZE;
        this.mainFormGroup.reset();
        this.buildForm();
        this.filterApplied = this.mainFormGroup.getRawValue();
        this.checkApplyFilterClick$.next(true);
    }
}
