/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Tipologie di periodo di mobilità. Tipologie: *SOGGIORNO_ESTERO*, *RICERCA*
 */
export type TipoPeriodoEnum = 'SOGGIORNO_ESTERO' | 'RICERCA';

export const TipoPeriodoEnum = {
    SOGGIORNOESTERO: 'SOGGIORNO_ESTERO' as TipoPeriodoEnum,
    RICERCA: 'RICERCA' as TipoPeriodoEnum
};