/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Stati di una richiesta di budget di uno studente. Valori previsti: *DA_APPROVARE*, *APPROVATO_PTA*, *RIFIUTATO*
 */
export type AggiornamentoBudgetStudenteStatus = 'DA_APPROVARE' | 'APPROVATO_PTA' | 'RIFIUTATO' | 'IMPOSTATO_SUPERADMIN';

export const AggiornamentoBudgetStudenteStatus = {
    DAAPPROVARE: 'DA_APPROVARE' as AggiornamentoBudgetStudenteStatus,
    APPROVATOPTA: 'APPROVATO_PTA' as AggiornamentoBudgetStudenteStatus,
    RIFIUTATO: 'RIFIUTATO' as AggiornamentoBudgetStudenteStatus,
    IMPOSTATOSUPERADMIN: 'IMPOSTATO_SUPERADMIN' as AggiornamentoBudgetStudenteStatus
};