<ng-container *transloco="let t">


    <div class="flex flex-col w-full min-h-[20rem] items-center justify-center"
         *ngIf="loading; else stepTemplate">
        <app-loading-screen class="w-full h-full">
        </app-loading-screen>
    </div>


    <ng-template #stepTemplate>

        <div class="mt-4 mb-4">
            <fuse-alert class="w-full"
                        [showIcon]="true"
                        [appearance]="'outline'"
            >{{t('massive_operations.approvazione_registro_attivita.hint_compile_info')}}</fuse-alert>
        </div>

        <div class="w-full flex flex-col items-center">

            <!-- TABELLA STUDENTI -->
            <div class="lg:w-[95%] flex flex-col items-center gap-5 bg-gray-50 rounded-xl p-3"
                 *ngIf="!!periodiCompileInfos">
                <div class="flex flex-col gap-0 w-full items-center">
                    <div class="w-full rounded-lg bg-slate-200 text-slate-600 px-4 py-2 grid grid-cols-7 font-semibold items-center justify-center text-center">
                        <div>{{t('mobility.request_date')}}</div>
                        <div>{{t('common.start_date')}}</div>
                        <div>{{t('common.end_date')}}</div>
                        <div class="col-span-2">{{t('mobility.student')}}</div>
                        <div class="col-span-2">{{t(currentProfile === AuthorityType.COORDINATORE ? 'activity_log.activity_log_coordinator' : 'activity_log.activity_log_supervisor' )}}</div>
                    </div>
                    <div class="w-full grid grid-cols-7 px-4 py-2 min-h-18 text-center items-center gap-2"
                         *ngFor="let compileInfo of periodiCompileInfos">
                        <div>{{compileInfo.dataRichiestaFormatted}}</div>
                        <div>{{t(compileInfo.dataInizioFormatted)}}</div>
                        <div>{{t(compileInfo.dataFineFormatted)}}</div>
                        <div class="col-span-2">{{t(compileInfo.studenteUIMapped?.utenteCognome)}} {{t(compileInfo.studenteUIMapped?.utenteNome)}}</div>
                        <div class="col-span-2" *ngIf="formControls.get(compileInfo.id) as formControl">
                            <app-input-upload class="w-full"
                                              [labelNoTranslate]="''"
                                              [form]="formControl"
                                              (downloadEmitter)="downloadFile(formControl.value, compileInfo.id)"
                                              (uploadImgEmitter)="uploadFile($event, compileInfo.id)"
                                              (deleteEmitter)="deleteFile(compileInfo.id)"
                                              [forceAcceptedFiles]="true"
                                              [sizeMB]="optionFile.sizeMb"
                                              [customFileLabel]="t('common.file_selected')"
                                              [customNotSupportedFileMessageLabel]="'common.no_file_selected'">
                            </app-input-upload>
                        </div>
                    </div>
                </div>
            </div>

        </div>



    </ng-template>



</ng-container>
