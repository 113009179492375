import {Injectable} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {LocalStorageService} from './local-storage.service';
import {BehaviorSubject, map, Observable} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {
    AuthorityType, CodiceRispostaEnum, MobilitaRequestSelectFormValuesDTO, PeriodoDiMobilitaStudenteStatus,
    SottoruoloUtenteUserView, StatoTemporaleEnum, TipoPeriodoEnum,
    UserSottoruoloView
} from '../../../api-clients/generated/services';
import * as moment from "moment/moment";


export interface AuditFilters {
    dataDa: string,
    dataA: string,
    codiceRisposta?: CodiceRispostaEnum,
    codiceRispostaNonMode?: boolean,
    nominativo?: string,
    uri?: string,
    operazione?: string
}

@Injectable({
    providedIn: 'root'
})
export class AuditFilterManagerService {

    mainFormGroup: FormGroup | undefined;
    selectValues: MobilitaRequestSelectFormValuesDTO;
    checkAggiornamentoSelectValues$ = new BehaviorSubject(undefined);
    checkApplyFilterClick$ = new BehaviorSubject(false);
    currentSottoRuolo: AuthorityType | undefined;
    filterApplied$= new BehaviorSubject(undefined);
    defaultFormPathList = [
        'codiceRisposta',
        'nominativo',
        'uri',
        'operazione',
    ];
    fieldsLabelMap = new Map<string, string>([
        ['codiceRisposta', 'audit.response_code'],
        ['nominativo', 'audit.nominative'],
        ['uri', 'audit.uri'],
        ['operazione', 'audit.operation'],
    ]);



    constructor(private localStorageService: LocalStorageService,
                private formBuilder: FormBuilder) {
    }

    init(): void {
        this.mainFormGroup = this.createDefaultFormGroup();
    }

    createDefaultFormGroup(): FormGroup {
        return this.formBuilder.group({
            codiceRisposta: null,
            nominativo: null,
            uri: null,
            operazione: null,
            // non selectors,
            codiceRispostaNonMode: false,
        });
    }

    resetFormGroup(){
        this.mainFormGroup.patchValue({
            codiceRisposta: null,
            nominativo: null,
            uri: null,
            operazione: null,
            // non selectors,
            codiceRispostaNonMode: false,
        });
    }

    getCtrlByPath(path: string): FormControl<any> {
        return this.mainFormGroup?.get(path) as FormControl;
    }


    getFieldsByRole(ruolo?: AuthorityType, sottoruolo?: AuthorityType): string[] {
        switch(ruolo) {
            case AuthorityType.SUPERADMIN:
                return this.defaultFormPathList;
            default:
        }
    }

    setSelectValues(selectValues?: MobilitaRequestSelectFormValuesDTO): void {
        this.selectValues = selectValues;
        this.checkAggiornamentoSelectValues$.next(this.selectValues);
    }

    appliedFiltersObsevable$(): Observable<AuditFilters> {
        return this.checkApplyFilterClick$.asObservable().pipe(
            filter(Boolean),
            map(() => this.mainFormGroup?.value)
        );
    }

}
