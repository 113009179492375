import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {GenericTableComponent} from './generic-table.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatSortModule} from '@angular/material/sort';
import {SharedModule} from '../../../shared.module';
import {MatMenuModule} from "@angular/material/menu";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatChipsModule} from "@angular/material/chips";
import {TranslocoModule} from "@ngneat/transloco";
import {MatRadioModule} from "@angular/material/radio";
import { ExpandedGenericTableComponent } from './expanded-generic-table/expanded-generic-table.component';


@NgModule({
    declarations: [
        GenericTableComponent,
        ExpandedGenericTableComponent
    ],
    exports: [
        GenericTableComponent
    ],
    imports: [
        CommonModule,
        MatTableModule,
        MatPaginatorModule,
        MatIconModule,
        MatTooltipModule,
        MatButtonModule,
        SharedModule,
        FlexLayoutModule,
        MatSortModule,
        MatMenuModule,
        MatCheckboxModule,
        TranslocoModule,
        MatChipsModule,
        MatRadioModule
    ]
})
export class GenericTableModule { }
