/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Stati di un ciclo di dottorato. Valori previsti: *ATTIVO*, *DISABILITATO*
 */
export type CicloDottoratoStatus = 'ATTIVO' | 'DISABILITATO';

export const CicloDottoratoStatus = {
    ATTIVO: 'ATTIVO' as CicloDottoratoStatus,
    DISABILITATO: 'DISABILITATO' as CicloDottoratoStatus
};