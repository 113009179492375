import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {finalize, Observable, takeUntil} from 'rxjs';

import {
    AbstractDefaultComponent
} from '../../../../shared/abstracts/abstract-default-component/abstract-default-component';
import {TranslocoService} from "@ngneat/transloco";
import {TimeService} from "../../../../shared/service/time.service";
import moment from "moment";

@Component({
    selector: 'app-audit-topbar-filter-container',
    templateUrl: './audit-topbar-filter-container.component.html',
    styleUrls: ['./audit-topbar-filter-container.component.scss']
})
export class AuditTopbarFilterContainerComponent extends AbstractDefaultComponent implements OnInit {

    @Output() onDatesApplied = new EventEmitter<{dataDa: string, dataA: string}>();
    currentLanguage: Observable<string>;
    topbarFilterForm: FormGroup;
    currentFields = [
        'dataDa',
        'dataA'
    ];
    fieldsLabelMap = new Map<string, string>([
        ['dataDa', 'audit.request_date_from'],
        ['dataA', 'audit.request_date_to'],
    ]);
    prevDataDa: string;
    prevDataA: string;

    constructor(private translocoService: TranslocoService,
                private formBuilder: FormBuilder,
                private timeService: TimeService,
        ) {
        super();
        this.currentLanguage = this.translocoService.langChanges$;

    }

    ngOnInit(): void {
        this.initFiltersForm();
    }

    getFormControlByPath(path: string): FormControl<any> {
        return this.topbarFilterForm.get(path) as FormControl;
    }


    resetLast24h(): void {
        this.timeService.updateCurrentTime().pipe(
            takeUntil(this.destroy$),
            finalize(() => {
                this.topbarFilterForm.patchValue({
                    dataDa: this.timeService.currentDateTime.subtract(1, 'day').format('YYYY-MM-DDTHH:mm'),
                    dataA: this.timeService.currentDateTime.format('YYYY-MM-DDTHH:mm'),
                });
                this.apply();
            })
        ).subscribe();
    }

    private initFiltersForm() {
        this.topbarFilterForm = this.formBuilder.group({
            dataDa: moment().subtract(1, 'day').format('YYYY-MM-DDTHH:mm'),
            dataA: moment().format('YYYY-MM-DDTHH:mm'),
        });
        this.prevDataDa = this.topbarFilterForm?.get('dataDa').value;
        this.prevDataA = this.topbarFilterForm?.get('dataA').value;
        /*this.topbarFilterForm.valueChanges.pipe(startWith(null), pairwise()).subscribe(([prev, next]: [any, any])=> {
            prev = prev || {
                dataDa: this.timeService.currentDateTime.subtract(1, 'day').format('YYYY-MM-DDTHH:mm'),
                dataA: this.timeService.currentDateTime.format('YYYY-MM-DDTHH:mm'),
            };
            if (!next.dataDa) {
                this.topbarFilterForm.patchValue({
                    dataDa: prev.dataDa,
                });
            }
            if (!next.dataA) {
                this.topbarFilterForm.patchValue({
                    dataA: prev.dataA,
                });
            }
            // if something changed
            if(next.dataDa && next.dataA) {
                this.onDatesApplied.emit(this.topbarFilterForm.value);
            }
        });*/
    }

    apply() {
        this.onDatesApplied.emit(this.topbarFilterForm.value);
        this.prevDataDa = this.topbarFilterForm?.get('dataDa').value;
        this.prevDataA = this.topbarFilterForm?.get('dataA').value;
    }

    datesChanged() {
        return !moment(this.prevDataA)?.isSame(this.topbarFilterForm?.get('dataA').value)
            || !moment(this.prevDataDa)?.isSame(this.topbarFilterForm?.get('dataDa').value);
    }
}
