/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Tipologie di Job.
 */
export type JobType = 'DOCENTI' | 'DOTTORANDI' | 'PANIERE_DOTTORANDI' | 'REFERENTI' | 'PERSONALE_PTA' | 'PERCORSI' | 'BORSE' | 'INFO_MOBILITA' | 'PERIODI_DI_MOBILITA_EXPORT_PER_ANS' | 'UTENTI_ESTERNI_DA_SINCRONIZZARE_EXPORT' | 'REMINDER_REGISTRO_ATTIVITA';

export const JobType = {
    DOCENTI: 'DOCENTI' as JobType,
    DOTTORANDI: 'DOTTORANDI' as JobType,
    PANIEREDOTTORANDI: 'PANIERE_DOTTORANDI' as JobType,
    REFERENTI: 'REFERENTI' as JobType,
    PERSONALEPTA: 'PERSONALE_PTA' as JobType,
    PERCORSI: 'PERCORSI' as JobType,
    BORSE: 'BORSE' as JobType,
    INFOMOBILITA: 'INFO_MOBILITA' as JobType,
    PERIODIDIMOBILITAEXPORTPERANS: 'PERIODI_DI_MOBILITA_EXPORT_PER_ANS' as JobType,
    UTENTIESTERNIDASINCRONIZZAREEXPORT: 'UTENTI_ESTERNI_DA_SINCRONIZZARE_EXPORT' as JobType,
    REMINDERREGISTROATTIVITA: 'REMINDER_REGISTRO_ATTIVITA' as JobType
};