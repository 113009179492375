/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Motivi Stato Carriera dello studente di un ciclo.Valori previsti:      IMM(\"Immatricolazione\"),     IMMSI(\"Immatr. per Scambio Internazionale\"),     MAT(\"Maternità\"),     STU(\"Conclusione Studi Pregressi\"),     MAL(\"Malattia\"),     ALT(\"Altro\"),     TIT(\"Cons. Titolo\"),     ERRIM(\"Errata Immatricolazione\"),     RINC(\"Rinuncia corso\"),     DEC(\"Decadenza\"),     RIN(\"Rinuncia\"),     RINI(\"Rinuncia all'iscrizione\"),     DCS(\"Decesso\");
 */
export type StudenteCicloMotivoStatus = 'IMM' | 'IMMSI' | 'MAT' | 'STU' | 'MAL' | 'ALT' | 'TIT' | 'ERRIM' | 'RINC' | 'DEC' | 'RIN' | 'RINI' | 'DCS';

export const StudenteCicloMotivoStatus = {
    IMM: 'IMM' as StudenteCicloMotivoStatus,
    IMMSI: 'IMMSI' as StudenteCicloMotivoStatus,
    MAT: 'MAT' as StudenteCicloMotivoStatus,
    STU: 'STU' as StudenteCicloMotivoStatus,
    MAL: 'MAL' as StudenteCicloMotivoStatus,
    ALT: 'ALT' as StudenteCicloMotivoStatus,
    TIT: 'TIT' as StudenteCicloMotivoStatus,
    ERRIM: 'ERRIM' as StudenteCicloMotivoStatus,
    RINC: 'RINC' as StudenteCicloMotivoStatus,
    DEC: 'DEC' as StudenteCicloMotivoStatus,
    RIN: 'RIN' as StudenteCicloMotivoStatus,
    RINI: 'RINI' as StudenteCicloMotivoStatus,
    DCS: 'DCS' as StudenteCicloMotivoStatus
};