export const versionInfo = (() => {
  try {

    // @ts-ignore
    return require('./git-version.json');
  } catch {
    // In dev the file might not exist:
    return { tag: 'local', hash: 'dev' };
  }
})();

export const licensesInfo = (() => {
    try {
        // @ts-ignore
        return require('./src/assets/common/license-3rd-party/license-3rd-party.json');
    } catch {
        return undefined;
    }
})();
