import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams, HttpUrlEncodingCodec} from '@angular/common/http';
import {LocalStorageService} from './local-storage.service';
import {CustomHttpUrlEncodingCodec} from './custom-http-url-encoding.codec';
import {environment} from '../../../environments/environment';
import {ActivatedRoute} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class SamlAuthService {

    constructor(protected httpClient: HttpClient,
                private localStorageService: LocalStorageService,
                private activatedRoute: ActivatedRoute) {
    }

    samlSignIn(): void {
        const redirectUrlQueryParam = this.activatedRoute.snapshot.queryParams.redirectURL ?
            '?redirectURL=' + this.activatedRoute.snapshot.queryParams.redirectURL?.replace('#','@') : '';
        const loginPath = environment.servicesKeycloak?.basePath +'auth/realms/' + environment.servicesKeycloak?.realmName + '/protocol/openid-connect/auth?' +
            'response_type=code' +
            '&scope=openid' +
            '&client_id=' + environment.servicesKeycloak?.client_id +
            '&client_secret=' + environment.servicesKeycloak?.client_secret +
            '&redirect_uri=' + environment.servicesKeycloak?.redirectUri + redirectUrlQueryParam;
        window.open(loginPath, '_self');
    }

    canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    postRequest(inputCode: string, redirectURL?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];
        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        const redirectURLQueryParam = redirectURL ? ('?redirectURL=' + redirectURL.replace('#','@')) : '';
        const redirectUri = environment.servicesKeycloak?.redirectUri + redirectURLQueryParam;

        formParams = formParams.append('client_id', environment.servicesKeycloak?.client_id);
        formParams = formParams.append('grant_type', 'authorization_code');
        formParams = formParams.append('client_secret',  environment.servicesKeycloak?.client_secret);
        formParams = formParams.append('code', inputCode);
        formParams = formParams.append('redirect_uri', redirectUri);

        return this.httpClient.request<any>('post', environment.servicesKeycloak?.basePath +'auth/realms/'
            + environment.servicesKeycloak?.realmName + '/protocol/openid-connect/token',
            {
                body: formParams,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    postRequestRefresh(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        formParams = formParams.append('client_id', environment.servicesKeycloak?.client_id);
        formParams = formParams.append('grant_type', 'refresh_token');
        formParams = formParams.append('client_secret',  environment.servicesKeycloak?.client_secret);
        formParams = formParams.append('refresh_token', this.localStorageService.getRefreshToken());

        return this.httpClient.request<any>('post', environment.servicesKeycloak?.basePath +'auth/realms/'
            + environment.servicesKeycloak?.realmName + '/protocol/openid-connect/token',
            {
                body: formParams,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
}

export interface TokenResponse {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    access_token: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    expires_in: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    id_token: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'not-before-policy': number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    refresh_expires_in: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    refresh_token: string;
    scope: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    session_state: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    token_type: string;
}

export interface UserData {
    created: string;
    email: string;
    enabled: boolean;
    firstName: string;
    grants: any;
    id: string;
    lastName: string;
    username: string;
}
