/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Tipologie di nazionalità supportate. Tipologie: *ITALIANA*, *EUROPEA*, *EXTRACOMUNITARIA*
 */
export type NazionalitaType = 'ITALIANA' | 'EUROPEA' | 'EXTRACOMUNITARIA';

export const NazionalitaType = {
    ITALIANA: 'ITALIANA' as NazionalitaType,
    EUROPEA: 'EUROPEA' as NazionalitaType,
    EXTRACOMUNITARIA: 'EXTRACOMUNITARIA' as NazionalitaType
};