import {Component, Inject, OnInit} from '@angular/core';
import {
    ClickEvent,
    GenericTableConfigurationModel,
    TableData,
    TipoClickEnum
} from "../../../../../shared/components/table/model/generic-table-model";
import {
    AttivitaOffertaFormativaOfCurrentDocenteCorsoCountDTO,
    OfferteFormativeService,
    SpesaStudenteStatus, StudenteCicloPianoDiStudiViewImpl
} from "../../../../../../api-clients/generated/services";
import {filter, switchMap} from "rxjs/operators";
import {BehaviorSubject, take, takeUntil} from "rxjs";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {TranslocoService} from "@ngneat/transloco";
import {isEmpty} from "lodash";
import {StudentDetailManagerService} from "../../../../../shared/service/student-detail-manager.service";
import {LogoutService} from "../../../../../shared/service/logout.service";
import {SuperamentoCorsoMassivoDataI} from "../superamento-corso-steps-interface";
import {CAN_GO_AHEAD$, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {createTableConfigurationForCourses} from "../../../requests/requests-utils";
import {
    CorsiFilter,
    CorsiFilterService
} from "../../../requests/superamento-corsi/corsi-sidebar-filter-container/corsi-filter.service";
import {cloneDeep} from "lodash-es";
import {
    chipsSupermanetoCorsi,
    filtriSuperamentoCorsi
} from "../../../requests/superamento-corsi/table-superamento-corsi/superamento-corsi-table.component";
import {PathEnum} from "../../../../../app.routing";
import {Router} from "@angular/router";

@Component({
    selector: 'app-scegli-corso-stepper',
    templateUrl: './scegli-corso.component.html',
    styleUrls: ['./scegli-corso.component.scss']
})
export class ScegliCorsoComponent extends AbstractDefaultComponent implements OnInit {

    courseTableConfiguration: GenericTableConfigurationModel;
    loading: boolean;
    currentFilterListChipLabels: Array<string>;
    fieldsLabelMap: Map<string, string>;
    attivitaOffertaFormativa: AttivitaOffertaFormativaOfCurrentDocenteCorsoCountDTO[];
    firstLoading: boolean;

    constructor(private appInitService: AppInitService,
                private fuseConfirmationService: FuseConfirmationService,
                private offertaFormativaService: OfferteFormativeService,
                private translocoService: TranslocoService,
                private logoutService: LogoutService,
                protected corsiFilterService: CorsiFilterService,
                protected router: Router,
                @Inject(CAN_GO_AHEAD$) private canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) private operazioneMassivaData$: BehaviorSubject<SuperamentoCorsoMassivoDataI>,
                private studentDetailManagerService: StudentDetailManagerService) {
        super();
        this.fieldsLabelMap = this.corsiFilterService.fieldsLabelMap;
        this.corsiFilterService.applyFilterClick$.subscribe(value => {
            this.applyFilter();
        });
    }

    ngOnInit(): void {
        this.firstLoading = true;
        this.getCorsiRequest();
    }


    tableClickAction($event: ClickEvent) {
        switch ($event.tipoClick) {
            case TipoClickEnum.SHOW:
                this.studentDetailManagerService.openDescriptionDialog($event?.value?.descrizione);
                break;
        }
    }


    onCourseDataTableChanged($event: TableData) {
        this.operazioneMassivaData$.next({
            ...this.operazioneMassivaData$?.getValue(),
            corsoSelected: $event.selectedRows.length > 0 ? $event.selectedRows[0].data : undefined
        })
        this.canGoNext$.next($event.selectedRows.length > 0);
    }

    private getCorsiRequest() {
        this.loading = true;
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.offertaFormativaService.getOffertaFormativaOfCurrentDocenteCorso()),
            takeUntil(this.destroy$),
        ).subscribe({
            next: (attivitaOffertaFormativa: AttivitaOffertaFormativaOfCurrentDocenteCorsoCountDTO[]) => {
                this.attivitaOffertaFormativa = attivitaOffertaFormativa;
                this.applyFilter();
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => {
                        this.logoutService.goToHome()
                    },
                    () => this.getCorsiRequest(),
                    'common.go_to_home',
                    err?.error?.message);
            }
        });
    }

    openFilters() {
        this.corsiFilterService.openDrawer.next(true);
    }

    resetFilters() {
        this.corsiFilterService.mainFormGroup.reset();
        this.applyFilter();
    }

    protected readonly SpesaStudenteStatus = SpesaStudenteStatus;

    applyFilter(): void {
        this.corsiFilterService.nonEsguitaCtrl.setValue(true);
        const filters: CorsiFilter = this.corsiFilterService.mainFormGroup.getRawValue();
        const attivitaOffertaFormativa = cloneDeep(this.attivitaOffertaFormativa).filter(value => {
            return filtriSuperamentoCorsi(filters, value);
        });
        if (!this.firstLoading) {
            this.fuseConfirmationService.showLoader();
        }
        setTimeout(() => {
            this.courseTableConfiguration = createTableConfigurationForCourses(attivitaOffertaFormativa, true);
            this.fuseConfirmationService.hideLoader();
            this.currentFilterListChipLabels = this.getListChipsLabel();
            this.loading = false;
            this.firstLoading = false;
        }, this.firstLoading  ? 0 : 500);


    }

    reset(): void {
        this.corsiFilterService?.mainFormGroup?.reset();
        this.applyFilter();
    }

    getListChipsLabel(): Array<string> {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        if (!isEmpty(this.corsiFilterService?.mainFormGroup.getRawValue())) {
            const entries: [string, any][] = Object.entries(this.corsiFilterService.mainFormGroup?.value)?.filter(item => item[0] !== 'nonEseguita');
            return chipsSupermanetoCorsi(entries, this.fieldsLabelMap, translation);
        } else {
            return [];
        }
    }

}

