/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Tipologie di membri nello storico studente. Tipologie: *SUPERVISORE*, *COSUPERVISORE*, *RELATORE*
 */
export type TipoMembroStudente = 'SUPERVISORE' | 'COSUPERVISORE' | 'RELATORE';

export const TipoMembroStudente = {
    SUPERVISORE: 'SUPERVISORE' as TipoMembroStudente,
    COSUPERVISORE: 'COSUPERVISORE' as TipoMembroStudente,
    RELATORE: 'RELATORE' as TipoMembroStudente
};