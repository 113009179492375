/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Lingue supportate per i testi negli extra fields. Lingue previste: *IT*, *EN*, *FR*
 */
export type LingueEnum = 'IT' | 'FR' | 'EN';

export const LingueEnum = {
    IT: 'IT' as LingueEnum,
    FR: 'FR' as LingueEnum,
    EN: 'EN' as LingueEnum
};