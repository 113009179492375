import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ImageHeaderComponent} from "./image-header.component";
import {FlexModule} from "@angular/flex-layout";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {TranslocoModule} from "@ngneat/transloco";


@NgModule({
    declarations: [ImageHeaderComponent],
    imports: [
        CommonModule,
        FlexModule,
        MatIconModule,
        MatButtonModule,
        TranslocoModule
    ],
    exports: [ImageHeaderComponent],
})
export class ImageHeaderModule {
}
