import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TestoTerminiECondizioniComponent} from "./testo-termini-e-condizioni.component";


@NgModule({
    declarations: [TestoTerminiECondizioniComponent],
    exports: [
        TestoTerminiECondizioniComponent
    ],
    imports: [
        CommonModule
    ]
})
export class TestoTerminiECondizioniModule {
}
