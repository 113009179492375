import {Injectable} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {BehaviorSubject, Subject} from 'rxjs';
import {
    AnnoRiferimentoValues,
    AuthorityType,
    ItaliaEsteroEnum,
    MissioneStatus,
    MobilitaRequestSelectFormValuesDTO
} from 'api-clients/generated/services';
import {LocalStorageService} from "../../../../../shared/service/local-storage.service";
import {ApplyFiltersQuicklyStudentsI} from "../../../students-list/students-list.component";

export const DEFAULT_PAGE_SIZE = 10;


export interface MissioniFilters {
    nominativoStudente?: string;
    codiceFiscaleStudente?: string;
    matricolaStudente?: string;
    statiMissione?: MissioneStatus[];
    italiaEstero?: ItaliaEsteroEnum;
    descrizione?: string;
    luogo?: string;
    upb?: string;
    annoRiferimento?: AnnoRiferimentoValues;
    showOnlyApprovableRequests?: boolean;
}

@Injectable({
    providedIn: "root"
})
export class MissioniFilterService {

    mainFormGroup: FormGroup | undefined;
    selectValues: MobilitaRequestSelectFormValuesDTO;
    checkAggiornamentoSelectValues$ = new BehaviorSubject(undefined);
    reset$ = new BehaviorSubject(false);
    checkApplyFilterClick$ = new BehaviorSubject(false);
    currentSottoRuolo: AuthorityType | undefined;
    filterApplied$ = new BehaviorSubject(undefined);
    filterApplied = undefined;
    pageSize = 10;
    page = 0;
    defaultFormPathList = [
        'nominativoStudente',
        'codiceFiscaleStudente',
        'matricolaStudente',
        'statiMissione',
        'italiaEstero',
        'descrizione',
        'luogo',
        'upb',
        'annoRiferimento',
        'showOnlyApprovableRequests',
    ];
    fieldsLabelMap = new Map<string, string>([
        ['statiMissione', 'budget.state'],
        ['nominativoStudente', 'common.nominative'],
        ['codiceFiscaleStudente', 'common.fiscal_code'],
        ['matricolaStudente', 'common.enrollment_number'],
        ['italiaEstero', 'missions.nation'],
        ['descrizione', 'common.description'],
        ['luogo', 'missions.place'],
        ['upb', 'missions.upb'],
        ['annoRiferimento', 'common.year'],
        ['showOnlyApprovableRequests', 'menu_filter.only_approvable_by_me'],

    ]);

    openDrawer = new Subject();
    applyFiltersQuickly: ApplyFiltersQuicklyStudentsI;


    constructor(public localStorageService: LocalStorageService,
                public formBuilder: FormBuilder) {
    }


    createDefaultFormGroup(): FormGroup {
        return this.formBuilder.group({
            nominativoStudente: null,
            codiceFiscaleStudente: null,
            matricolaStudente:  null,
            statiMissione: null,
            italiaEstero: null,
            descrizione: null,
            luogo: null,
            upb: null,
            annoRiferimento: null,
            showOnlyApprovableRequests: null,
        });
    }


    getFieldsByRole(): string[] {
        return this.defaultFormPathList;
    }

    getCtrlByPath(path: string): FormControl<any> {
        return this.mainFormGroup?.get(path) as FormControl;
    }

    init(force?: boolean): void {
        const sottoruolo = this.localStorageService.dipartimentoRuoloCiclo?.sottoruolo;
        if ((this.currentSottoRuolo !== sottoruolo) || !this.currentSottoRuolo) {
            this.currentSottoRuolo = sottoruolo;
            this.buildForm();
        } else if(force){
            this.buildForm();
            this.filterApplied = this.mainFormGroup.getRawValue();
        }
        if(this.applyFiltersQuickly?.statiMissione) {
            this.mainFormGroup.get('statiMissione').setValue(this.applyFiltersQuickly?.statiMissione);
            this.filterApplied = this.mainFormGroup.getRawValue();
        }
        if(this.applyFiltersQuickly?.showOnlyApprovableRequests) {
            this.mainFormGroup.get('showOnlyApprovableRequests').setValue(this.applyFiltersQuickly?.showOnlyApprovableRequests);
            this.filterApplied = this.mainFormGroup.getRawValue();
        }
    }

    private buildForm() {
        if (!!this.mainFormGroup) {
            this.mainFormGroup.reset();
        } else {
            this.mainFormGroup = this.createDefaultFormGroup();
        }
    }






    reset(): void {
        this.page = 0;
        this.pageSize = DEFAULT_PAGE_SIZE;
        this.mainFormGroup.reset();
        this.filterApplied = this.mainFormGroup.getRawValue();
        this.checkApplyFilterClick$.next(true);
    }


    restore(): void {
        this.page = 0;
        this.pageSize = DEFAULT_PAGE_SIZE;
        this.mainFormGroup.reset();
        this.buildForm();
        this.filterApplied = this.mainFormGroup.getRawValue();
        this.checkApplyFilterClick$.next(true);
    }
}
