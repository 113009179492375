import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {GenericTableConfigurationModel} from "../../../../../shared/components/table/model/generic-table-model";
import {
    AddDeliberaDTO,
    AuthorityType,
    DeliberaInfoView,
    DeliberaInfoViewImpl,
    DelibereService,
    PageDeliberaInfoViewImpl,
    StudenteCicloUtenteViewImpl
} from "../../../../../../api-clients/generated/services";
import {BehaviorSubject, finalize, switchMap, take, takeUntil} from "rxjs";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {TranslocoService} from "@ngneat/transloco";
import * as moment from "moment/moment";
import {
    formGroupConfigInterface,
    GenericComponentDialogConfig
} from "../../../../../layout/common/generic-components/generic-components.interface";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {
    ApprovazionePianiMassivaDataI,
    CompileInfoApprovazionePianiMassiva
} from '../approvazione-piani-steps-interface';
import {CAN_GO_AHEAD$, CURRENT_PROFILE, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {get, orderBy} from "lodash";
import {
    GenericDialogComponent
} from "../../../../../layout/common/generic-components/generic-dialog/generic-dialog.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {filter} from "rxjs/operators";
import {SnackbarTypes} from "../../../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {buildFormConfigForAddNewDelibera} from "../../../gestione-documenti/gestione-documenti-utils";
import {
    StudyPlanWithDeliberaMode
} from "../../common-steps/show-with-delibera-for-plan-step/show-with-delibera-for-plan-step.component";


export interface StudenteWithExtraCustom {
    formGroup: FormGroup;
    tableConfiguration: GenericTableConfigurationModel;
    studente: StudenteCicloUtenteViewImpl;
    planId: string;
    aggiornamentoId: string;
}

@Component({
    selector: 'app-info-approvazione-piani-stepper',
    templateUrl: './compila-info-approvazione-piani.component.html',
    styleUrls: ['./compila-info-approvazione-piani.component.scss']
})
export class CompilaInfoApprovazionePianiComponent extends AbstractDefaultComponent implements OnInit, AfterViewInit {

    loading: boolean;
    formConfig: formGroupConfigInterface[];
    valueForm: any;
    form: FormGroup;
    studentiSelected: StudenteCicloUtenteViewImpl[];
    delibere: DeliberaInfoViewImpl[];
    outputFormatData = 'DD/MM/YYYY';
    loadingDelibere: boolean;
    deliberaMode: StudyPlanWithDeliberaMode;
    compileInfo: CompileInfoApprovazionePianiMassiva[];

    constructor(private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                private appInitService: AppInitService,
                private delibereService: DelibereService,
                private fb: FormBuilder,
                private dialog: MatDialog,
                @Inject(CURRENT_PROFILE) protected currentProfile: AuthorityType,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<ApprovazionePianiMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.initForm();
        this.operazioneMassivaData$.subscribe((inputOutputData: ApprovazionePianiMassivaDataI) => {
            this.deliberaMode = inputOutputData?.deliberaMode;
            this.initForm(inputOutputData?.deliberaCollegio?.id);
            // if confirm step force refresh set as not completed the step
            if(inputOutputData.refreshData){
                this.canGoNext$.next(false);
            }
            // if studenti selected changes reset delibera form and get plans for selected students
            if(inputOutputData.compileInfo !== this.compileInfo){
                this.compileInfo = inputOutputData?.compileInfo;
                this.form.get('deliberaCollegio').setValue(inputOutputData.deliberaCollegio?.id, {emitEvent: false});
            }
        });
    }

    ngAfterViewInit() {
        if(this.currentProfile !== AuthorityType.SUPERVISORE && this.currentProfile !== AuthorityType.COSUPERVISORE){
            this.getDelibereRequest();
        }
    }


    formatDay(date: string) {
        return moment(new Date(date)).format('DD/MM/YYYY');
    }

    private initForm(deliberaCollegio?: string) {
        this.form = this.fb.group({
            deliberaCollegio: [deliberaCollegio, (this.deliberaMode === StudyPlanWithDeliberaMode.FIRST_NO_DELIBERA && this.currentProfile !== AuthorityType.COSUPERVISORE && this.currentProfile !== AuthorityType.SUPERVISORE) ? Validators.required : undefined]
        });
        this.canGoNext$.next(this.form.valid);
        this.form.get('deliberaCollegio').valueChanges.subscribe(deliberaId => {
            this.canGoNext$.next(!!deliberaId || this.currentProfile === AuthorityType.COSUPERVISORE || this.currentProfile === AuthorityType.SUPERVISORE);
            this.operazioneMassivaData$.next({
                ...this.operazioneMassivaData$?.getValue(),
                deliberaCollegio: this.delibere?.find(d => d.id === deliberaId)
            });
        })
    }


    openAddNewDeliberaDialog() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        let confirmLabel = get(translation, 'dialog.confirm', null);
        let cancelLabel = get(translation, 'dialog.cancel', null);
        const data: GenericComponentDialogConfig = {
            title: 'common.add_delibera',
            //message: get(translation, 'mobility.approve_message', null),
            icon: {
                show: true,
                name: 'mat_outline:add',
                color: 'basic'
            },
            actions: {
                confirm: {
                    show: true,
                    label: confirmLabel,
                    color: 'primary',
                    function: (form, dialogRef) => this.addNewDeliberaRequest(form, form.getRawValue(), dialogRef)
                },
                cancel: {
                    show: true,
                    label: cancelLabel,
                }
            },
            dismissible: true,
            formConfig: buildFormConfigForAddNewDelibera(),
            valueForm: {
                fileDelibera: undefined,
                dataDelibera: undefined,
                numeroDelibera: undefined,
                fileNotaTrasmissione: undefined,
            }
        };
        this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: data.dismissible,
            disableClose: true,
        });
    }


    private addNewDeliberaRequest(form: FormGroup, rawValue: any, dialogRef: MatDialogRef<GenericDialogComponent>) {
        console.log(rawValue)
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        const requestBody: AddDeliberaDTO = {
            numero: rawValue.numeroDelibera,
            data: rawValue.dataDelibera
        }
        this.fuseConfirmationService.showLoader();
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.delibereService.addDeliberaForm(requestBody, rawValue.fileDelibera, rawValue.fileNotaTrasmissione)),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (addedDelibera: DeliberaInfoView) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation,'common.operation_success_delibera_added', null),
                    type: SnackbarTypes.Success,
                    duration: 5000,
                });
                this.delibere.push(addedDelibera);
                this.delibere = orderBy(this.delibere, [delibera => moment(delibera.data)], ['desc']);
                this.form?.get('deliberaCollegio')?.patchValue(addedDelibera.id);
                dialogRef.close();
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => {},() => this.addNewDeliberaRequest(form, rawValue, dialogRef), 'dialog.cancel',
                    err?.error?.message);
            }
        });

    }

    private getDelibereRequest() {
        this.loadingDelibere = true;
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.delibereService.searchDelibereForm()),
            takeUntil(this.destroy$),
            finalize(() => {
                this.loadingDelibere = false;
            })
        ).subscribe({
            next: (delibere: PageDeliberaInfoViewImpl) => {
                this.delibere = delibere?.content || [];
                this.delibere = orderBy(this.delibere, [delibera => moment(delibera.data)], ['desc']);
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => {},() => this.getDelibereRequest(),
                    'dialog.cancel',
                    err?.error?.message);
            }
        });
    }

    formatDeliberaDate(dateFromBE: string): string {
        return moment(dateFromBE).format(this.outputFormatData);
    }

    protected readonly StudyPlanWithDeliberaMode = StudyPlanWithDeliberaMode;
    protected readonly AuthorityType = AuthorityType;
}
