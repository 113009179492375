<ng-container *transloco="let t">


    <div class="flex flex-col w-full h-full overflow-auto px-2 gap-0">
        <app-loading-screen *ngIf="loading; else mobilityTemplate" class="w-full h-full" style="min-height: 40vh"></app-loading-screen>
        <ng-template #mobilityTemplate>
            <div class="flex flex-col md:flex-row gap-4 justify-end w-full items-center"
                 *ngIf="!!tableConfiguration">
                <button
                    *ngIf="!currentFilterListChipLabels?.length"
                    mat-flat-button
                    (click)="restore()"
                    [color]="'accent'">
                    <mat-icon class="mr-2">restore</mat-icon>
                    <span>{{ t('menu_filter.restore_filters_default') }}</span>
                </button>
                <div class="flex flex-col md:flex-row gap-4 justify-end w-full items-center">
                    <button mat-flat-button
                            class="max-w-60"
                            color="primary"
                            [disabled]="showMiniLoader"
                            (click)="getDataForFilters()">
                        <mat-spinner *ngIf="showMiniLoader" [diameter]="20" color="accent" class="mr-2"></mat-spinner>
                        <mat-icon class="mr-2" *ngIf="!showMiniLoader">manage_search</mat-icon>
                        {{ t('menu_filter.advanced_search') }}
                    </button>
                    <button mat-flat-button
                            class="multi-line-button"
                            type="submit"
                            (click)="openExportExcelDialog()"
                            [color]="'primary'">
                        <mat-icon class="mr-2">download</mat-icon>
                        <span>{{ t('mobility.export_excel_dialog') }}</span>
                    </button>
                </div>

            </div>

            <div class="flex flex-col flex-auto w-full h-full overflow-auto p-2 gap-4"
                 *ngIf="!!tableConfiguration">

                        <div class="flex flex-row justify-between w-full" *ngIf="currentFilterListChipLabels?.length > 0">

                            <div class="mt-0.5 px-2 py-4 flex flex-row gap-4">
                                <!-- TESTO FILTRI APPLICATI -->
                                <div class=" text-2xl font-semibold mt-[0.35rem]">{{t('students_list.applied_filters')}}</div>
                                <!--RESET BUTTON -->
                                <button mat-flat-button
                                        (click)="reset()"
                                        [color]="'accent'">
                                    <mat-icon class="mr-2">delete</mat-icon>
                                    <span>{{t('menu_filter.reset')}}</span>
                                </button>
                            </div>


                        </div>

                        <div class="w-full flex flex-row justify-center items-center" *ngIf="currentFilterListChipLabels?.length > 0">
                            <!-- CHIPS -->
                            <div class="w-full flex items-center">

                                <div class="flex flex-col gap-1.5">

                                    <mat-chip-list class="w-full">
                                        <div class="w-full flex flex-row gap-1.5 p-2 flex-wrap">
                                            <ng-container *ngFor="let chip of currentFilterListChipLabels">
                                                <mat-chip color="primary" class="justify-between">
                                                    {{chip | titlecase | removeUnderscores}}
                                                </mat-chip>
                                            </ng-container>
                                        </div>
                                    </mat-chip-list>
                                </div>
                            </div>

                        </div>

                <div class="w-full p-y-2.5" *ngIf="tableConfiguration">
                    <app-generic-table *ngIf="!!tableConfiguration"
                                       [configuration]="tableConfiguration"
                                       (clickAction)="tableClickAction($event)"
                                       (pageAction)="pageAction($event)">
                    </app-generic-table>
                </div>
            </div>
        </ng-template>
    </div>


</ng-container>
