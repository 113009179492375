import { CommonModule } from '@angular/common';
import { DropZoneDirective } from './directives/drop-zone.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputFileWithPreviewComponent } from './input-file-with-preview.component';
import { InputFileConfig } from './interfaces/input-file-config';
import { InputFileService } from './services/input-file.service';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ModuleWithProviders, NgModule } from '@angular/core';
import {MatTooltipModule} from "@angular/material/tooltip";

@NgModule({
    declarations: [
        DropZoneDirective,
        InputFileWithPreviewComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        ReactiveFormsModule,
        MatTooltipModule
    ],
    exports : [
        InputFileWithPreviewComponent
    ],
    providers: [
        InputFileService
    ],
    entryComponents: [ InputFileWithPreviewComponent ]
})

export class InputFileWithPreviewModule {
    public static forRoot(config: InputFileConfig): ModuleWithProviders<InputFileWithPreviewModule> {
        return {
            ngModule: InputFileWithPreviewModule,
            providers: [
                InputFileService,
                { provide: 'config', useValue: config }
            ]
        };
    }
}
