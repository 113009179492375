import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {
    ClickEvent,
    GenericTableConfigurationModel,
    TableData,
    TipoClickEnum,
    TipoColonnaEnum
} from "../../../../../shared/components/table/model/generic-table-model";
import {
    AuthorityType,
    BudgetStudenteInfoView, CorsoPianoDiStudiPerExportViewImpl,
    EsitoRichiestaApprovazioneByGruppoPTA, MediaService,
    PageSpesaStudentePerApprovMassivaInfoView,
    PeriodoDiMobilitaStudenteStatus,
    SpesaStudenteInfoViewImpl,
    SpesaStudentePerApprovMassivaInfoView,
    SpesaStudenteStatus,
    StudentiCicloService
} from "../../../../../../api-clients/generated/services";
import {filter, switchMap, tap} from "rxjs/operators";
import {
    BehaviorSubject,
    catchError,
    finalize,
    map,
    Observable,
    ObservedValueOf,
    of,
    OperatorFunction,
    take,
    takeUntil
} from "rxjs";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {TranslocoService} from "@ngneat/transloco";
import {get, isEmpty} from "lodash";
import * as moment from "moment/moment";
import {PageEvent} from "@angular/material/paginator";
import {FormGroup} from "@angular/forms";
import {LogoutService} from "../../../../../shared/service/logout.service";
import {CAN_GO_AHEAD$, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {annoRiferimentoToRomanNumeral, makeFilenameFromFE, removeUnderscore} from "../../../../../shared/utils/utils";
import {ApprovazioneSpesaPTAMassivaDataI} from "../approvazione-spesa-pta-steps-interface";
import {
    MobilitaFilterManagerRequestService
} from "../../../requests/approvazione-periodi-di-mobilita-ricerca/mobilita-sidebar-filter-container/mobilita-filter-manager-request.service";
import {
    GenericComponentDialogConfig
} from "../../../../../layout/common/generic-components/generic-components.interface";
import {TypeDialogFormEnum} from "../../../../../layout/common/generic-components/generic-components-enum";
import {
    GenericDialogComponent
} from "../../../../../layout/common/generic-components/generic-dialog/generic-dialog.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {SnackbarTypes} from "../../../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {PathEnum} from "../../../../../app.routing";
import {Router} from "@angular/router";
import {
    BudgetFilters
} from "../../../requests/approvazione-budget/budget-sidebar-filter-container-request/budget-filter.service";
import {
    BudgetPtaFilterService
} from "../../../pta-requests/approvazione-budget/budget-pta-sidebar-filter-container-request/budget-pta-filter.service";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {DialogInfoComponent, DialogInfoI} from "../../../../../shared/components/dialog-info/dialog-info.component";
import {UpperCasePipe} from "@angular/common";
import {HttpResponse} from "@angular/common/http";
import {CicloCorsoRuoloInterface} from "../../../../../shared/interface/CicloCorsoRuoloInterface";
import fs from "file-saver";
import {AppInitService} from "../../../../../shared/service/app-init.service";


@Component({
    selector: 'app-scegli-spesa-pta-stepper',
    templateUrl: './scegli-spesa-pta.component.html',
    styleUrls: ['./scegli-spesa-pta.component.scss']
})
export class ScegliSpesaPtaComponent extends AbstractDefaultComponent implements OnInit, OnDestroy {

    loading: boolean;
    speseListConfiguration: GenericTableConfigurationModel;
    private pageSize: number;
    private fieldsLabelMap: Map<string, string>;
    currentFilterListChipLabels: Array<string>;
    isFirstTime: boolean;
    showMiniLoader: boolean = false;
    showTopbar: boolean;
    defStatus: SpesaStudenteStatus = SpesaStudenteStatus.APPROVATO;
    idRigheSolaLettura: string[] = [];

    constructor(private fuseConfirmationService: FuseConfirmationService,
                private translocoService: TranslocoService,
                private filterManagerService: BudgetPtaFilterService,
                private logOutService: LogoutService,
                private studentiCicloService: StudentiCicloService,
                private dialog: MatDialog,
                private router: Router,
                protected mediaService: MediaService,
                protected sanitizer: DomSanitizer,
                protected appInitService: AppInitService,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<ApprovazioneSpesaPTAMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.isFirstTime = true;
        this.fieldsLabelMap = this.filterManagerService.fieldsLabelMap;
        this.getDataAndFilterSubscribe();
        this.operazioneMassivaData$?.subscribe(inputOutputData => {
            // when confirm step forces refresh get the data and subscribe to filters
            if (inputOutputData.refreshSpese) {
                console.log('REFRESHING SPESE IN SCEGLI SPESE')
                this.speseListConfiguration = undefined;
                this.idRigheSolaLettura = [];
                this.resetRefreshSpese();
                this.canGoNext$.next(false);
                this.getSpeseRequest$(false, this.filterManagerService?.filterApplied);
            }
        });
    }


    getSpeseRequest$(isFirstTime?: boolean, filters?: any, page: number = 0, size: number = 10): void {
        this.getSpeseFormObs$(isFirstTime, filters, page, size).subscribe({
            next: (value) => {},
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => {this.logOutService.goToHome()},
                    () => this.getSpeseRequest$(isFirstTime, filters, page, size),
                    'common.go_to_home',
                    err?.error?.message);
            }
        });
    }

    getSpeseFormObs$(isFirstTime?: boolean, filters?: BudgetFilters, page: number = 0, size: number = 10): Observable<PageSpesaStudentePerApprovMassivaInfoView> {
        if (isFirstTime) {
            this.loading = true;
        } else {
            this.fuseConfirmationService.showLoader();
        }
        return this.studentiCicloService.searchSpeseStudentiCicloForm(
            !!filters?.codiceSpesa ? filters?.codiceSpesa : undefined,
            !!filters?.tipologiaSpesa ? filters?.tipologiaSpesa : undefined,
            [SpesaStudenteStatus.APPROVATO],
            !!filters?.descrizioneNoteSpesa ? filters?.descrizioneNoteSpesa : undefined,
            !!filters?.nominativoStudente ? filters?.nominativoStudente : undefined,
            !!filters?.emailStudente ? filters?.emailStudente : undefined,
            !!filters?.cfStudente ? filters?.cfStudente : undefined,
            !!filters?.matricolaStudente ? filters?.matricolaStudente : undefined,
            undefined,
            page, size, undefined).pipe(
            tap((pageSpese: PageSpesaStudentePerApprovMassivaInfoView) => {
                this.speseListConfiguration = this.buildSpeseConfiguration(pageSpese, size, page);
            }),
            takeUntil(this.destroy$),
            finalize(() => {
                    if (isFirstTime) {
                        this.isFirstTime = false;
                        this.loading = false;
                    } else {
                        this.fuseConfirmationService.hideLoader();
                    }
                }
            )
        );


    }

    formatDay(date: string) {
        return moment(new Date(date)).format('DD/MM/YYYY');
    }

    buildSpeseConfiguration(pageSpese: PageSpesaStudentePerApprovMassivaInfoView, pageSize: number, page: number): GenericTableConfigurationModel {
        return {
            configuration: {
                data: pageSpese?.content?.map(spesa => ({
                    ...spesa,
                    studente: spesa.budgetStudente?.studenteCiclo?.utente?.cognome + ' ' + spesa.budgetStudente?.studenteCiclo?.utente?.nome,
                    studenteUIMapped: [{
                        utenteNome:  spesa?.budgetStudente?.studenteCiclo?.utente?.nome,
                        utenteCognome:  spesa?.budgetStudente?.studenteCiclo?.utente?.cognome,
                        utenteMail: spesa?.budgetStudente?.studenteCiclo?.utente?.mail,
                        nome: spesa?.budgetStudente?.studenteCiclo?.utente?.nome,
                        cognome: spesa?.budgetStudente?.studenteCiclo?.utente?.cognome,
                        studenteCodiceFiscale: spesa?.budgetStudente?.studenteCiclo?.utente?.codiceFiscale,
                        codiceFiscale: spesa?.budgetStudente?.studenteCiclo?.utente?.codiceFiscale,
                        studenteMatricola: spesa?.budgetStudente?.studenteCiclo?.matricola,
                        urlImmagineProfilo: spesa?.budgetStudente?.studenteCiclo?.utente?.urlImmagineProfilo,
                        utenteTelefono: spesa?.budgetStudente?.studenteCiclo?.utente?.telefono
                    }],
                    studenteAndSpesa: spesa.codiceSpesa + ' (' + spesa.budgetStudente?.studenteCiclo?.utente?.cognome + ' ' + spesa.budgetStudente?.studenteCiclo?.utente?.nome + ')',
                    annoRiferimento: annoRiferimentoToRomanNumeral(spesa.annoRiferimento),
                })),
                sticky: true,
                pageSize: pageSize,
                pageBE: page,
                pageSizeOptions: [10, 20, 30, 40, 50],
                totalElements: pageSpese.totalElements,
                isPaginatedBE: true,
                messaggioDatiAssenti: 'custom_table.no_data_budget',
                hidePaginator: false,
                selection: {
                    isSelectable: true,
                    selectedColumnKeyToShow: 'studenteAndSpesa',
                    selectedColumnKeyPrimaryKey: 'codiceSpesa',
                },
                disabledCssClass: 'generic-table-disabled-row',
                highlightRowWhen: (element) => this.idRigheSolaLettura?.includes(element.codiceSpesa) ? 'generic-table-removed-row' : undefined,
                configurazioneTabella: [
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'budget.description',
                        colonnaKey: 'descrizione',
                        flex: 30,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'budget.codice_spesa',
                        colonnaKey: 'codiceSpesa',
                        flex: 20,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'budget.type',
                        colonnaKey: 'tipologia',
                        flex: 13,
                    },
                    {
                        tipo: TipoColonnaEnum.CHIP_USER,
                        nomeColonna: 'common.dottorando',
                        colonnaKey: 'studenteUIMapped',
                        flex: 20,
                    },
                    /*{
                        nomeColonna: 'budget.note',
                        colonnaKey: 'nota',
                        flex: 7,
                        tipo: TipoColonnaEnum.ACTION,
                        button: [
                            {
                                nameIconButton: 'description',
                                click: TipoClickEnum.SHOW_NOTES,
                                tooltip: 'budget.note',
                                color: 'accent',
                                show: (nota?: string) => (!!nota && nota !== ''),
                                valueShow: 'nota'
                            }
                        ]
                    },*/
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'common.year',
                        colonnaKey: 'annoRiferimento',
                        flex: 4,
                    },
                    {
                        tipo: TipoColonnaEnum.AMOUNT,
                        nomeColonna: 'budget.appr_amount',
                        colonnaKey: 'importoApprovato',
                        flex: 8,
                    },
                    {
                        tipo: TipoColonnaEnum.AMOUNT,
                        nomeColonna: 'budget.req_amount',
                        colonnaKey: 'importoRichiesto',
                        flex: 8,
                    },
                    {
                        nomeColonna: ' ',
                        colonnaKey: 'action',
                        flex: 9,
                        tipo: TipoColonnaEnum.ACTION_MENU,
                        valueCheck: 'l',
                        show: (_, spesa: SpesaStudenteInfoViewImpl) =>
                            (!!spesa.nota && spesa.nota !== '')
                            || spesa.urlAllegatiRichiesta?.length > 0
                            || true,
                        button: [
                            {
                                nameIconButton: 'account_balance_wallet',
                                click: TipoClickEnum.GO_TO,
                                tooltip: 'common.go_to_budget',
                                color: 'accent',
                                valueShow: 'stato'
                            },
                            {
                                nameIconButton: 'description',
                                click: TipoClickEnum.SHOW_NOTES,
                                tooltip: 'budget.show_notes',
                                color: 'accent',
                                show: (nota?: string) => (!!nota && nota !== ''),
                                valueShow: 'nota'
                            },
                            {
                                nameIconButton: 'download',
                                click: TipoClickEnum.SHOW_ALLEGATO_BUDGET_REQUEST,
                                tooltip: 'budget.show_attachment_file',
                                color: 'accent',
                                show: (urlAllegatiRichiesta, spesa: SpesaStudenteInfoViewImpl) => spesa.urlAllegatiRichiesta?.length > 0,
                                valueShow: 'urlAllegatiRichiesta'
                            },
                            {
                                nameIconButton: 'close',
                                click: TipoClickEnum.REFUSE_COORDINATOR,
                                tooltip: 'budget.refuse',
                                valueShow: 'stato',
                                color: 'accent'
                            },
                        ]
                    }
                ],
            }
        };
    }


    tableClickAction($event: ClickEvent) {
        switch ($event?.tipoClick) {
            case TipoClickEnum.REFUSE_COORDINATOR:
                this.tryOpenCoordinatorRefuseDialog($event);
                break;
            case TipoClickEnum.CHIP_DETAIL:
                this.openInfoStudent($event.value);
                break;
            case TipoClickEnum.SHOW_NOTES:
                this.openNotesDialog($event.value);
                break;
            case TipoClickEnum.SHOW_ALLEGATO_BUDGET_REQUEST:
                this.downloadAllegatiRequest($event.value);
                break;
            case TipoClickEnum.GO_TO:
                const spesa: SpesaStudentePerApprovMassivaInfoView = $event?.value;
                const url = this.router.serializeUrl(this.router.createUrlTree([PathEnum.STUDENTS, spesa.budgetStudente?.studenteCiclo?.utente?.id, PathEnum.BUDGET], {
                    fragment: annoRiferimentoToRomanNumeral(spesa?.annoRiferimento)+'-anno'
                }));
                window.open(url, '_blank');
                break;
        }
    }

    private openNotesDialog(spesa: SpesaStudenteInfoViewImpl) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        let closeLabel = get(translation, 'dialog.close', null);
        const data: GenericComponentDialogConfig = {
            title: 'budget.note',
            message: spesa.nota,
            icon: {
                show: true,
                name: 'description',
                color: 'accent',
            },
            actions: {
                cancel: {
                    show: true,
                    label: closeLabel,
                },
                confirm: {
                    show: false,
                    function: form => {}
                }
            },
            dismissible: true,
        };
        this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: data.dismissible,
            disableClose: true,
        });
    }


    private downloadAllegatiRequest(spesa: SpesaStudentePerApprovMassivaInfoView) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.showLoader();
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.studentiCicloService.getZipAllegatiRichiestaDiSpesaStudente(spesa.budgetStudente?.studenteCiclo?.utente?.id, spesa?.codiceSpesa, 'response')),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (fileResponse: HttpResponse<Blob>) => {
                //const fileName = fileResponse.headers?.get('Content-Disposition')?.split('=').pop();
                const fileTypeForName = 'ALLEGATI_RICHIESTA_DI_SPESA_' + spesa?.codiceSpesa;
                const studentCicloCorsoRuolo: CicloCorsoRuoloInterface = {
                    codiceCorsoStudi: spesa?.budgetStudente?.studenteCiclo?.codiceCorsoDiStudiEsse3,
                    ciclo:  spesa?.budgetStudente?.studenteCiclo?.numeroCicloDottorato + '',
                    ruolo: AuthorityType.STUDENTE,
                    denominazioneCorsoStudi: ''
                }
                const outputFileName = makeFilenameFromFE(studentCicloCorsoRuolo, '.zip', fileTypeForName);
                fs.saveAs(fileResponse?.body, outputFileName);
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'student.file_download_success', null),
                    type: SnackbarTypes.Success,
                });
            },
            error: (err) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'budget.file_download_error', null),
                    error: err, type: SnackbarTypes.Error,
                });
                console.log(err)
            }
        });
    }


    private openInfoStudent(studente: CorsoPianoDiStudiPerExportViewImpl) {
        if (!!studente.urlImmagineProfilo && !!studente.studenteCodiceFiscale) {
            this.fuseConfirmationService.showLoader();
            this.mediaService.getImmagineProfiloForm(studente.urlImmagineProfilo, studente.studenteCodiceFiscale)
                .pipe(
                    catchError(err => of(undefined)),
                    map((image) => {
                        if (image) {
                            const objectURL = URL.createObjectURL(image);
                            return this.sanitizer.bypassSecurityTrustUrl(objectURL);
                        } else {
                            return undefined;
                        }
                    }),
                    takeUntil(this.destroy$),
                    finalize(() => this.fuseConfirmationService.hideLoader())
                ).subscribe({
                next: (image: SafeUrl) => {
                    this.openStudenteInfoDialog(studente, image);
                },
                error: (error) => {
                    this.fuseConfirmationService.openErrorDialog({error: error},
                        this.translocoService,
                        () => {
                        },
                        () => this.openInfoStudent(studente),
                        'dialog.close',
                        error?.error?.message);
                }
            });
        } else {
            this.openStudenteInfoDialog(studente);
        }
    }

    private openStudenteInfoDialog(studente: CorsoPianoDiStudiPerExportViewImpl, profileImage?: SafeUrl) {
        const data: DialogInfoI = {
            imageSafeUrl: profileImage,
            showImage: true,
            title: (studente.utenteCognome || '') + ' ' + (studente.utenteNome || ''),
            icon: {
                name: 'mat_outline:info',
                color: 'primary'
            },
            imageIniziali: (studente?.utenteCognome?.charAt(0) || '') + (studente?.utenteNome.charAt(0) || ''),
            readonlyData: [
                {
                    key: 'user_list.surname',
                    value: studente?.utenteCognome,
                    pipe: UpperCasePipe
                },
                {
                    key: 'user_list.name',
                    value: studente?.utenteNome,
                    pipe: UpperCasePipe
                },
                {
                    key: 'sign-up.email',
                    value: studente?.utenteMail?.toLowerCase()
                },
                { //telefono
                    key: 'sign-up.phone_number',
                    value: studente?.utenteTelefono
                },
                {
                    key: 'sign-up.fiscal_code',
                    value: studente?.studenteCodiceFiscale
                },
                {
                    key: 'students_list.enrollment_number',
                    value: studente?.studenteMatricola
                },
            ]
        };
        this.dialog.open(DialogInfoComponent, {
            data,
            panelClass: 'dialog-responsive-full-screen',
        });
    }


    private tryOpenCoordinatorRefuseDialog(event: ClickEvent) {
        if(this.operazioneMassivaData$.getValue().speseSelected?.find(
            s => s.codiceSpesa === event.value.codiceSpesa)){
            this.openDeselectFirstDialog();
        } else {
            this.openPTARefuseDialog(event)
        }
    }

    private openDeselectFirstDialog() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
            title: get(translation, 'dialog.attention', null),
            message: get(translation, 'common.deselect_first', null),
            icon: {
                name: 'mat_solid:error_outline',
                color: 'error'
            },
            onBackdrop: {
                show: true,
                backdrop: false
            },
            actions: [
                {
                    color: 'accent',
                    label: get(translation, 'common.close', null), icon: 'close',
                },
            ]
        });
    }

    private openPTARefuseDialog(event: ClickEvent) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        let confirmLabel = get(translation, 'dialog.confirm', null);
        let cancelLabel = get(translation, 'dialog.cancel', null);
        const data: GenericComponentDialogConfig = {
            title: 'budget.refuse',
            message: get(translation, 'budget.refuse_message', null),
            icon: {
                show: true,
                name: 'close',
                color: 'warn'
            },
            actions: {
                confirm: {
                    show: true,
                    label: confirmLabel,
                    color: 'primary',
                    function: (form, dialogRef) => this.ptaRefuseRequest(form, event.value, form.getRawValue().motivo, dialogRef)
                },
                cancel: {
                    show: true,
                    label: cancelLabel,
                }
            },
            dismissible: true,
            formConfig: [
                {
                    show: true,
                    name: 'motivo',
                    transloco: 'budget.refuse_reason',
                    required: true,
                    type: TypeDialogFormEnum.TEXT
                },
            ],
            valueForm: {
                motivo: undefined,
            }
        };
        this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: data.dismissible,
            disableClose: true,
        });
    }

    private ptaRefuseRequest(form, spesa: SpesaStudentePerApprovMassivaInfoView, motivo: string, dialogRef: MatDialogRef<GenericDialogComponent>) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        let esito: EsitoRichiestaApprovazioneByGruppoPTA = {
            approvato: false,
            motivazione_rifiuto: motivo,
        }
        form.disable();
        this.studentiCicloService.accettaRifiutaSpesaStudenteRequestByGruppoPTA(esito, spesa?.budgetStudente?.studenteCiclo?.utente?.id, spesa?.codiceSpesa).pipe(
            takeUntil(this.destroy$),
            finalize(() => {
                form.enable();
            })
        ).subscribe({
            next: (budget: BudgetStudenteInfoView) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'budget.saved', null),
                    type: SnackbarTypes.Success,
                });
                this.idRigheSolaLettura = [...(this.idRigheSolaLettura ?? []), spesa.codiceSpesa];
                dialogRef.close()
            },
            error: (err) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'budget.error_saving', null),
                    error: err, type: SnackbarTypes.Error,
                });
            }
        });
    }


    onSpeseDataTableChanged($event: TableData) {
        console.log('SELECTION CHANGED')
        this.operazioneMassivaData$.next({
            ...this.operazioneMassivaData$?.getValue(),
            speseSelected: $event.selectedRows.length > 0 ? $event.selectedRows.map(row => row.data) as SpesaStudentePerApprovMassivaInfoView[] : undefined,
            compileInfo: undefined
        });
        this.canGoNext$.next($event.selectedRows.length > 0);
    }

    pageAction($event: PageEvent): void {
        console.log('PageEvent', $event);
        const page = $event.pageIndex;
        this.pageSize = $event.pageSize;
        this.getSpeseRequest$(false, this.getMainFormGroup()?.value, page, this.pageSize);
    }

    getMainFormGroup(): FormGroup<any> {
        return this.filterManagerService?.mainFormGroup;
    }

    catchErrorInSpeseList$(inputObs: Observable<any>): OperatorFunction<unknown, ObservedValueOf<Observable<any>> | unknown> {
        return this.fuseConfirmationService.catchErrorCustom$(inputObs, {openModalError: {goToHome: true}}, true, true, true, false);
    }

    getListChipsLabel(): Array<string> {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        if (!isEmpty(this.getMainFormGroup()?.value)) {
            const entries: [string, any][] = Object.entries(this.getMainFormGroup()?.value)?.filter(item => item[0] !== 'statiSpesa');
            const filteredEntries = entries?.filter(item => item.every(value => !!value));
            let labels = filteredEntries?.map(filteredItem => filteredItem?.map((element) => {
                    if (typeof element === 'string') {
                        if (!!this.fieldsLabelMap.get(element)) {

                            return get(translation, this.fieldsLabelMap.get(element), element);
                        }else {
                            return removeUnderscore(element);
                        }
                    }
                    if (typeof element === 'boolean') {
                        return '';
                    }
                    else if (!!element?.codiceFiscale) {
                        const completeName = (element?.cognome || '') + ' ' + (element?.nome || '');
                        return (!!element?.cognome ? completeName : '')?.toUpperCase();
                    }
                    else if (!!element?.id && !!element?.denominazione) {
                        return (!!element?.denominazione ? element.denominazione : '');
                    }
                    else {
                        return element;
                    }
                }
            ).join(': '));
            console.log('Lables chips', labels);
            labels = labels.map(label => label?.endsWith(': ')? label.slice(0, -2) : label);
            return labels;
        } else {
            return [];
        }
    }


    private resetRefreshSpese() {
        console.log(this.operazioneMassivaData$.getValue())
        this.operazioneMassivaData$.next({
            ...this.operazioneMassivaData$.getValue(),
            refreshSpese: false
        })
    }

    private getDataAndFilterSubscribe() {
        if(this.isFirstTime){
            this.getSpeseRequest$(this.isFirstTime, this.filterManagerService?.filterApplied);
        }

        this.filterManagerService.checkApplyFilterClick$.asObservable().pipe(
            filter(Boolean),
            switchMap(value => {
                if(!this.isFirstTime){
                    return this.getSpeseFormObs$(this.isFirstTime,  this.filterManagerService?.filterApplied, 0, this.pageSize).pipe(
                        this.catchErrorInSpeseList$(this.getSpeseFormObs$(this.isFirstTime,  this.filterManagerService?.filterApplied, 0, this.pageSize))
                    );
                }
                return of(null);
            }),
            takeUntil(this.destroy$)
        ).subscribe(
            {
                next: (value) => {
                    this.isFirstTime = false;
                    this.filterManagerService.filterApplied$.next( this.getMainFormGroup().getRawValue());
                    this.currentFilterListChipLabels = this.getListChipsLabel();
                }
            }
        );
    }


    ngOnDestroy(): void {
        super.ngOnDestroy();
    }


    openFilters() {
        this.filterManagerService.openDrawer.next(true)
    }

    resetFilters(): void {
        this.getMainFormGroup()?.reset();
        this.filterManagerService.filterApplied = undefined;
        this.filterManagerService?.checkApplyFilterClick$.next(true);
    }

    protected readonly PeriodoDiMobilitaStudenteStatus = PeriodoDiMobilitaStudenteStatus;
    protected readonly SpesaStudenteStatus = SpesaStudenteStatus;
}
