import {Component, Inject, OnInit} from '@angular/core';
import {
    AuthorityType,
    DeliberaApprovaPeriodiDiRicercaResponse,
    DeliberaApprovaPeriodiDiSoggiornoEsteroRequest,
    EsitoOperazione,
    EsitoSingolaOperazioneInOperazioneMassiva,
    MediaService,
    OperazioniMassiveService,
    PeriodoDiMobilitaStudenteInfoView,
    StudenteCicloIdAndUtenteInfoView
} from "../../../../../../api-clients/generated/services";
import {BehaviorSubject, catchError, finalize, map, of, switchMap, take, takeUntil, tap} from "rxjs";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {ApprovazioneMobilitaMassivaDataI} from "../approvazione-mobilita-steps-interface";
import {CAN_GO_AHEAD$, CURRENT_PROFILE, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {filter} from "rxjs/operators";
import {cloneDeep} from "lodash-es";
import {get} from "lodash";
import {TranslocoService} from "@ngneat/transloco";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {DialogInfoComponent, DialogInfoI} from "../../../../../shared/components/dialog-info/dialog-info.component";
import {UpperCasePipe} from "@angular/common";
import {LogoutService} from "../../../../../shared/service/logout.service";
import {MatDialog} from "@angular/material/dialog";


@Component({
    selector: 'app-confirm-approvazione-mobilita-stepper',
    templateUrl: './confirm-approvazione-mobilita.component.html',
    styleUrls: ['./confirm-approvazione-mobilita.component.scss']
})
export class ConfirmApprovazioneMobilitaComponent extends AbstractDefaultComponent implements OnInit {

    approvazioneMobilitaData: ApprovazioneMobilitaMassivaDataI;
    operationSucceed: boolean;
    operationError: boolean;
    operationEsiti: Array<EsitoSingolaOperazioneInOperazioneMassiva> | undefined;
    resultsPeriodiSelected: PeriodoDiMobilitaStudenteInfoView[];

    constructor(private appInitService: AppInitService,
                private operazioniMassiveService: OperazioniMassiveService,
                private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                private mediaService: MediaService,
                private sanitizer: DomSanitizer,
                private logoutService: LogoutService,
                private dialog: MatDialog,
                @Inject(CURRENT_PROFILE) protected currentProfile: AuthorityType,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<ApprovazioneMobilitaMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.operazioneMassivaData$.subscribe((inputOutputData: ApprovazioneMobilitaMassivaDataI) => {
            if(inputOutputData?.compileInfo?.length > 0){
                this.approvazioneMobilitaData = inputOutputData;
            }
            // when there are studenti selected reset succeed and error flags
            if(this.approvazioneMobilitaData?.periodiSelected?.length > 0) {
                this.operationSucceed = false;
                this.operationError = false;
            }
        })
    }


    confirmOperation() {
        this.operationError = undefined;
        this.operationSucceed = undefined;
        this.fuseConfirmationService.showLoader();
        console.log('confirm compile info', this.approvazioneMobilitaData?.compileInfo)
        const requestBody: DeliberaApprovaPeriodiDiSoggiornoEsteroRequest = {
            id_delibera: this.approvazioneMobilitaData?.deliberaCollegio?.id,
            specifiche: this.approvazioneMobilitaData?.compileInfo?.map(ci => ({
                id_utente: ci.idUtente,
                codice_mobilita: ci.codiceMobilitaPeriodo,
                codice_tipologia_mobilita: ci.esito?.codice_tipologia_mobilita,
                doctor_europeaus: ci.esito?.doctor_europeaus,
                sede_doctor_europeaus: ci.esito?.sede_doctor_europeaus,
                erasmus: ci.esito?.erasmus,
                data_inizio: ci.esito?.data_inizio,
                data_fine: ci.esito?.data_fine,
                nome_referente_estero: ci.esito?.nome_referente_estero,
                cognome_referente_estero: ci.esito?.cognome_referente_estero,
                email_referente_estero: ci.esito?.email_referente_estero,
            }))
        }
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.operazioniMassiveService.deliberaApprovaPeriodiDiSoggiornoEstero(requestBody)),
            takeUntil(this.destroy$),
            tap((approvaRicercaResponse: DeliberaApprovaPeriodiDiRicercaResponse) => {
                this.resultsPeriodiSelected  = cloneDeep(this.approvazioneMobilitaData?.periodiSelected);
                // forcing the refresh of studenti
                this.operazioneMassivaData$.next({
                    periodiSelected: undefined,
                    refreshPeriodi: true,
                    deliberaCollegio: undefined,
                });
            }),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe(
            {
                next: (approvaRicercaResponse: DeliberaApprovaPeriodiDiRicercaResponse) => {
                    this.operationSucceed = true;
                    this.operationEsiti = approvaRicercaResponse?.esiti_operazioni;
                },
                error: (err) => {
                    this.operationError = true;
                    console.log(err)
                }
            }
        );

    }

    protected readonly EsitoOperazione = EsitoOperazione;

    getStudenteById(id_utente: string) {
        const studente = this.resultsPeriodiSelected?.find(s => s.studenteCiclo?.utente?.id === id_utente);
        return studente?.studenteCiclo?.utente?.cognome + ' ' + studente?.studenteCiclo?.utente?.nome;
    }

    openConfirmOperationDialog() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
                title: get(translation, 'dialog.attention', null),
                message: get(translation, 'massive_operations.confirm_operation_attention_message', null),
                icon: {
                    name: 'mat_outline:warning',
                    color: 'warning'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    },
                    {
                        color: 'primary',
                        label: get(translation, 'common.confirm', null), icon: 'check',
                        function: () => this.confirmOperation()
                    }]
            }
        );
    }

    private openInfoStudent(studente: StudenteCicloIdAndUtenteInfoView) {
        if (!!studente?.utente?.urlImmagineProfilo && !!studente?.utente?.codiceFiscale) {
            this.fuseConfirmationService.showLoader();
            this.mediaService.getImmagineProfiloForm(studente?.utente?.urlImmagineProfilo, studente?.utente?.codiceFiscale)
                .pipe(
                    catchError(err => of(undefined)),
                    map((image) => {
                        if (image) {
                            const objectURL = URL.createObjectURL(image);
                            return this.sanitizer.bypassSecurityTrustUrl(objectURL);
                        } else {
                            return undefined;
                        }
                    }),
                    takeUntil(this.destroy$),
                    finalize(() => this.fuseConfirmationService.hideLoader())
                ).subscribe({
                next: (image: SafeUrl) => {
                    this.openStudenteInfoDialog(studente, image);
                },
                error: (error) => {
                    this.fuseConfirmationService.openErrorDialog({error: error},
                        this.translocoService,
                        () => {
                            this.logoutService.goToHome();
                        },
                        () => this.openInfoStudent(studente),
                        'dialog.close',
                        error?.error?.message);
                }
            });
        } else {
            this.openStudenteInfoDialog(studente);
        }
    }

    private openStudenteInfoDialog(studente: StudenteCicloIdAndUtenteInfoView, profileImage?: SafeUrl) {
        const data: DialogInfoI = {
            imageSafeUrl: profileImage,
            showImage: true,
            title: (studente?.utente?.cognome || '') + ' ' + (studente?.utente?.nome || ''),
            icon: {
                name: 'mat_outline:info',
                color: 'primary'
            },
            imageIniziali: (studente?.utente?.cognome?.charAt(0) || '') + (studente?.utente?.nome?.charAt(0) || ''),
            readonlyData: [
                {
                    key: 'user_list.surname',
                    value: studente?.utente?.cognome,
                    pipe: UpperCasePipe
                },
                {
                    key: 'user_list.name',
                    value: studente?.utente?.nome,
                    pipe: UpperCasePipe
                },
                {
                    key: 'sign-up.email',
                    value: studente?.emailAteneo?.toLowerCase(),
                },
                { //telefono
                    key: 'sign-up.phone_number',
                    value: studente?.utente?.telefono
                },
                {
                    key: 'sign-up.fiscal_code',
                    value: studente?.utente?.codiceFiscale
                },
                {
                    key: 'students_list.enrollment_number',
                    value: studente?.matricola
                },
            ]
        };
        this.dialog.open(DialogInfoComponent, {
            data,
            panelClass: 'dialog-responsive-full-screen',
        });
    }




}
