import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'buildNomeCompletoPipe'})
export class BuildNomeCompletoPipe implements PipeTransform {

    transform(user: any): string {
        return ((user?.cognome || '') + ' ' + (user?.nome || '')).trim();
        //return trim((user?.cognome || '') + ' ' + ((user?.nome?.[0] ? (user?.nome?.[0] + '.') : undefined ) || ''));

    }
}
