import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate, CanActivateChild, Route,
    Router,
    RouterStateSnapshot, UrlSegment, UrlTree,
} from '@angular/router';
import {AuthorityType, UserSottoruoliInfoDTO, UsersService} from "../../../api-clients/generated/services";
import {LocalStorageService} from "../../shared/service/local-storage.service";
import {PathEnum} from "../../app.routing";
import {map, Observable, of, switchMap} from "rxjs";
import {AppInitService} from "../../shared/service/app-init.service";
import {filter, first, skip} from "rxjs/operators";
import {CicloCorsoRuoloInterface} from "../../shared/interface/CicloCorsoRuoloInterface";


@Injectable({
    providedIn: 'root'
})
export class AdministrationGuard implements CanActivate, CanActivateChild {
    /**
     * Constructor
     */
    constructor(
        private _router: Router,
        private localStorageService: LocalStorageService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this._check(route, state);
    }

    /**
     * Can activate child
     *
     * @param childRoute
     * @param state
     */
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        return this._check(childRoute, state);
    }


    _check(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const cicloAmm = this.localStorageService.getCicloAmm(true);
        if (cicloAmm) {
            return of(true);
        } else {
            this._router.navigate([PathEnum.HOME]);
            return of(false);
        }
    }

}
