import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output, QueryList,
    ViewChild, ViewChildren
} from '@angular/core';
import {MatSort, Sort} from '@angular/material/sort';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {Router} from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';
import {
    ButtonInterface,
    ClickEvent, ConfigurazioneColonna,
    ConfigurazioneTabella, ExpandedGenericTable, GenericTableConfigurationModel, IconInterface,
    TableData, TableDataRows,
    TipoClickEnum,
    TipoColonnaEnum
} from '../../model/generic-table-model';
import {cloneDeep} from 'lodash-es';
import {get, isArray, isEmpty, isNil, isString, sortBy, startCase, trim} from 'lodash';
import {UserCodiceFiscaleNomeCognomeView} from '../../../../../../api-clients/generated/services';
import {SelectedRowI} from "../generic-table.component";
import {filter} from "rxjs/operators";
import {BehaviorSubject, takeUntil} from "rxjs";
import {AbstractDefaultComponent} from "../../../../abstracts/abstract-default-component/abstract-default-component";
import {MatMenu, MatMenuTrigger} from "@angular/material/menu";

@Component({
    selector: 'app-expanded-generic-table',
    templateUrl: './expanded-generic-table.component.html',
    styleUrls: ['./expanded-generic-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandedGenericTableComponent extends AbstractDefaultComponent {

    @ViewChildren(MatMenuTrigger) trigger: QueryList<MatMenuTrigger>;

    @Input() _parentId: string;
    @Input() parentElement: any;
    @Input() set righeSelezionate(selezione: Array<SelectedRowI>) {
        this._righeSelezionate = cloneDeep(selezione);
        if(this._configuration.singleChoiceSelection) {
            this.singleChoiceSelectedKey = this.righeSelezionate?.find(value => this._parentId === value.parentId)?.key;
        }
    }
    @Input() set righeOriginali(selezione: Array<SelectedRowI>) {
        this._righeOriginali = cloneDeep(selezione);
    }
    _righeOriginali: Array<SelectedRowI> = [];
    _righeSelezionate: Array<SelectedRowI> = [];

    public dataSource$: BehaviorSubject<any[] | undefined> = new  BehaviorSubject<any[] | undefined>(undefined);

    private sort: MatSort;
    private paginator: MatPaginator;
    private disableAllCheck: boolean;
    private singleChoiceSelectedKey: any;

    constructor(private router: Router,
                private changeDetectorRef: ChangeDetectorRef) {
        super();
    }

    @ViewChild(MatSort) set matSort(ms: MatSort) {
        this.sort = ms;
        this.setDataSourceAttributes();
    }

    @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
        this.paginator = mp;
        this.setDataSourceAttributes();
    }

    totalElements = 25;
    displayedColumns: string[] = [];
    dataSourcePaginated: MatTableDataSource<any> = new MatTableDataSource<any>();
    _configuration: ExpandedGenericTable;
    tipoColonna = TipoColonnaEnum;
    messaggioDatiAssenti: string;
    isSelectable: boolean = false;
    selectedColumnKeyChildToShow: string = undefined;
    selectedColumnKeyPrimaryKey: string = 'id';
    chipClickType = TipoClickEnum.CHIP_DETAIL;

    @Output() clickAction: EventEmitter<ClickEvent> = new EventEmitter<ClickEvent>();
    @Output() pageAction: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
    @Output() sortAction: EventEmitter<Sort> = new EventEmitter<Sort>();
    @Output() toggleSelectedRowDataChild$: EventEmitter<SelectedRowI> = new EventEmitter<SelectedRowI>();
    @Output() outputTableData: EventEmitter<Sort> = new EventEmitter<Sort>();
    @Input() infoRigheInSolaLettura: { iconName: string; tooltip: string };

    @Input() righeSelezionateCustom = [];


    @Input() idRigheSolaLettura: string[] = [];
    @Input() hideColumns: string[] = [];

    get righeSelezionate() {
        return this._righeSelezionate;
    }

    get righeOriginali() {
        return this._righeOriginali;
    }

    @Input() set configuration(data: { configuration: ExpandedGenericTable; value: any }) {
        if (!!data) {
            data.configuration.data = data.value;
            this._configuration = data.configuration;
            this._configuration.configurazioneTabella.forEach((colonna) => {
                colonna.css = this.combineStyles(this._configuration.css, colonna?.flex, colonna.css);
            });
            // Gestione della selezione se opportunamente configurata. Se non configurata rimuovo elementi di selezione.
            this.isSelectable = this._configuration.selection !== undefined ? this._configuration.selection.isSelectable : false;
            this._configuration.configurazioneTabella = this._configuration.configurazioneTabella.filter(value => value.tipo !== TipoColonnaEnum.SELECTION);
            if (this.isSelectable) {
                this.selectedColumnKeyChildToShow = this._configuration.selection.selectedColumnKeyToShow;
                this.selectedColumnKeyPrimaryKey = this._configuration.selection.selectedColumnKeyPrimaryKey;
                this._configuration.configurazioneTabella.splice(0, 0, {
                    tipo: TipoColonnaEnum.SELECTION,
                    nomeColonna: 'common.selection',
                    colonnaKey: 'selezione',
                    flex: 5,
                });
            }
            this.displayedColumns = this._configuration.configurazioneTabella.filter(value => !value.hideColonna && !this.hideColumns.includes(value.colonnaKey)).map(value => value.colonnaKey);

            this.dataSource$.next(this._configuration.data || []);

            this.totalElements = this._configuration?.data?.length || this.totalElements;
            if (this._configuration.data.length > 0) {
                this.messaggioDatiAssenti = 'Nessun dato corrispondente al filtro';
            } else {
                this.messaggioDatiAssenti = 'Non ci sono dati da visualizzare nella pagina corrente';
            }
        }
    }

    @Input() readOnly: boolean;

    setDataSourceAttributes() {
        this.dataSourcePaginated.paginator = this.paginator;
        this.dataSourcePaginated.sort = this.sort;
        this.dataSourcePaginated.sortingDataAccessor = (item, property) => {
            if (this._configuration.configurazioneTabella?.find(v => v?.colonnaKey === property)?.sortByNumber) {
                const onlyNumber = item[property]?.replace(/\D/g, '');
                return onlyNumber ? Number(item[property].replace(/\D/g, '')) : this.castSort(item[property]);
            } else {
                return this.castSort(item[property]);
            }
        };

        this.setDataSource();
    }


    show(button: ButtonInterface, element, value: string | string []): boolean {
        return !!button.showEvery ?
            this.showEvery(button, element, value) : !!button.show ? !!value ? isArray(value)
                ? value.some(v => button.show(get(element, v, element[v]), element)) : button.show(get(element, value, element[value]), element) : true : true;
    }

    showEvery(button: ButtonInterface, element, value: string | string []): boolean {
        return !!button.showEvery ? !!value ? isArray(value) ? value.every(v => button.showEvery(element[v])) : button.showEvery(element[value]) : true : true;
    }


    getValue(colonna: ConfigurazioneColonna, element): string {
        return !!colonna.convertiValoreBoolean ? colonna.convertiValoreBoolean(element) : '';
    }

    convertiValoreInBo(colonna: ConfigurazioneColonna, elementElement): IconInterface | null {
        return !!colonna.convertiValoreBooleanInIcon ? colonna.convertiValoreBooleanInIcon(elementElement) : null;
    }

    getValueShow(colonna: ConfigurazioneColonna, element, colonnaKey: string): string {
        if (colonnaKey.includes(' ')) {
            return colonnaKey.split(' ').map(aElem => element[aElem]).join(' ');
        }
        return !!colonna.valueCheck ? !!colonna.getValue(element[colonna.valueCheck]) ? colonna.getValue(element[colonna.valueCheck]) : element[colonnaKey] : element[colonnaKey]?.toString();
    }

    castSort(itemElement: any): any {
        return isString(itemElement) ? itemElement.toLowerCase() : itemElement;
    }

    goTo(colonna: ConfigurazioneColonna, element, colonnaKey: string) {
        if (colonna.goTo.value && colonna.goTo.path) {
            const baseUrl = window.location.href.replace(this.router.url, '');
            const url = new URL([...colonna.goTo.path, element[colonna.goTo.value]].join('/'), baseUrl).href;
            console.log(url);
            window.open(url, '_blank');
        }
    }

    toggleSelectedRowData(element: any) {
        const elementExtracted = this.righeSelezionate.findIndex(value => value.key === element[this.selectedColumnKeyPrimaryKey]);
        if (elementExtracted !== -1) {
            this.righeSelezionate.splice(elementExtracted, 1);
            this.disableAllCheck = false;
        } else {
            this.righeSelezionate.push(
                {
                    key: element[this.selectedColumnKeyPrimaryKey],
                    data: element,
                    parentId: this._parentId,
                });
        }
        this.disableAllCheck = this._configuration.singleChoiceSelection && this.righeSelezionate.length > 0;
        this.toggleSelectedRowDataChild$.emit(  {
            key: element[this.selectedColumnKeyPrimaryKey],
            data: element,
            parentId: this._parentId,
        });
        if(this._configuration.singleChoiceSelection) {
            this.singleChoiceSelectedKey = this.righeSelezionate?.find(value => this._parentId === value.parentId)?.key;
        }
    }


    checkIfSelected(key: string) {
        return !!this.righeSelezionate.find(value => value.key === key);
    }

    disableNonSelectedCheck(element: any): boolean {
        if (this._configuration.singleChoiceSelection) {
            if (this.singleChoiceSelectedKey) {
                return element[this.selectedColumnKeyPrimaryKey] !== this.singleChoiceSelectedKey;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    //----CHIP
    getValueForChip(colonna: ConfigurazioneColonna, element, colonnaKey: string): Array<UserCodiceFiscaleNomeCognomeView> {
        return !!colonna.valueCheck ?
            !!colonna.getValue(element[colonna.valueCheck]) ? colonna.getValue(element[colonna.valueCheck])
                : element[colonnaKey] : element[colonnaKey];
    }

    getValueForStatus(colonna: ConfigurazioneColonna, element, colonnaKey: string): any {
        return !!colonna.valueCheck ?
            !!colonna.getValue(element[colonna.valueCheck]) ? colonna.getValue(element[colonna.valueCheck])
                : element[colonnaKey] : element[colonnaKey];
    }

    buildNomeCompleto(user: UserCodiceFiscaleNomeCognomeView): string {
        return trim((user?.cognome || '') + ' ' + (user?.nome || ''));
    }

    checkIfRigaReadOnly(element: any): boolean {
        if ((this._configuration?.selection?.selectedColumnKeyPrimaryKey
                && !element?.[this._configuration?.selection?.selectedColumnKeyPrimaryKey])
            || isEmpty(this.idRigheSolaLettura)) {
            return false;
        } else {
            return this.idRigheSolaLettura?.includes(element?.[this._configuration?.selection?.selectedColumnKeyPrimaryKey]);
        }
    }

    trackByFn(index: number, item: ConfigurazioneColonna): any {
        return item.colonnaKey || index;
    }


    onCellClick(element): void {
        if (this._configuration.selection?.isSelectable
            && !(this.readOnly || this. checkIfParentIsDifferent(element) || this.disableNonSelectedCheck(element) || this.checkIfRigaReadOnly(element))
        ) {
            this.toggleSelectedRowData(element);
        }
    }


    checkIfParentIsDifferent(element: any): boolean {
        return !!this.righeSelezionate.length ?  this.righeSelezionate?.some(riga => this._parentId !== riga.parentId && riga.key === element[this.selectedColumnKeyPrimaryKey]) : false;
    }

    updateRigheSelezionate(selezione: Array<SelectedRowI>): void {
        this._righeSelezionate = cloneDeep(selezione);
    }

    combineStyles(configurationCss: any, flex: number, colonnaCss: any): any {
        return {
            ...(configurationCss || {}),
            ...(colonnaCss || {}),
            'max-width': flex ? `${flex}%` : 'initial'
        };
    }


    private setDataSource() {
        this.dataSource$.asObservable().pipe(filter(Boolean), takeUntil(this.destroy$)).subscribe((x) => {
            setTimeout(() => {
                this.dataSourcePaginated.data = x;
                this.changeDetectorRef.detectChanges();
            });
        });
    }

    closeMenus() {
        for (let index = 0; index < this.trigger.toArray().length; index++) {
            this.trigger.toArray()[index].closeMenu();
        }
    }
}
