<ng-container *transloco="let t">


    <div style="padding: 20px 10px 10px 10px"
         class="flex flex-col flex-auto bg-card shadow overflow-hidden w-full justify-start items-center gap-5 pt-2.5">

        <mat-stepper orientation="horizontal"
                     [linear]="true"
                     class="w-full"
                     #stepper>
            <!--INTERMEDIATE STEPS-->
            <ng-container *ngIf="operazioneFormGroup?.get('operazione')?.value as operazione">
                <ng-container *ngFor="let step of steps; let i = index">
                    <mat-step [completed]="(step.dataInjector.get(CAN_GO_AHEAD$) | async)">
                        <ng-container>
                            <ng-template matStepLabel>
                                {{t(step.stepName)}}
                                {{step.previewStringFn(step.dataInjector.get(OPERAZIONE_MASSIVA_DATA$) | async)}}
                            </ng-template>
                            <ng-template matStepContent>
                                <ng-container *ngComponentOutlet="
                                                step.component;
                                                injector: step.dataInjector;"
                                ></ng-container>
                                <div class="flex flex-row gap-4 my-4 justify-between">

                                    <div [class]="(i === 0 || i == steps.length - 1) ? 'flex' : 'hidden'">
                                        <ng-container [ngTemplateOutlet]="tornaAllaListaTmpl">
                                        </ng-container>
                                    </div>

                                    <ng-container *ngIf="!step.hideNavButtons">
                                        <button mat-flat-button
                                                *ngIf="!step.hideBack"
                                                class="max-w-60"
                                                matStepperPrevious
                                                [color]="'accent'">
                                            <mat-icon class="mr-2">arrow_back</mat-icon>
                                            <span>{{t('massive_operations.back')}}</span>
                                        </button>
                                        <button mat-flat-button
                                                class="max-w-60"
                                                [disabled]="!(step.dataInjector.get(CAN_GO_AHEAD$) | async)"
                                                matStepperNext
                                                [color]="'primary'">
                                            <mat-icon class="mr-2">arrow_forward</mat-icon>
                                            <span>{{t('massive_operations.next')}}</span>
                                        </button>
                                    </ng-container>

                                </div>
                            </ng-template>
                        </ng-container>
                    </mat-step>
                </ng-container>
            </ng-container>

            <ng-template matStepperIcon="phone">
                <mat-icon>call_end</mat-icon>
            </ng-template>
            <ng-template matStepperIcon="chat">
                <mat-icon>forum</mat-icon>
            </ng-template>

        </mat-stepper>


    </div>

</ng-container>
