/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Stati di una Missione. Valori previsti: *RICHIESTA_APPROVAZIONE*, *APPROVATA_PARZIALE*, *APPROVATA*, *APPROVATA_DIRETTORE_DI_DIPARTIMENTO*, *RIFIUTATA*, *RICHIESTA_LIQUIDAZIONE*,*RICHIESTA_LIQUIDAZIONE_RIFIUTATA*,*LIQUIDATA*
 */
export type MissioneStatus = 'RICHIESTA_APPROVAZIONE' | 'APPROVATA_PARZIALE' | 'APPROVATA' | 'APPROVATA_DIRETTORE_DI_DIPARTIMENTO' | 'RIFIUTATA' | 'RICHIESTA_LIQUIDAZIONE' | 'RICHIESTA_LIQUIDAZIONE_RIFIUTATA' | 'LIQUIDATA';

export const MissioneStatus = {
    RICHIESTAAPPROVAZIONE: 'RICHIESTA_APPROVAZIONE' as MissioneStatus,
    APPROVATAPARZIALE: 'APPROVATA_PARZIALE' as MissioneStatus,
    APPROVATA: 'APPROVATA' as MissioneStatus,
    APPROVATADIRETTOREDIDIPARTIMENTO: 'APPROVATA_DIRETTORE_DI_DIPARTIMENTO' as MissioneStatus,
    RIFIUTATA: 'RIFIUTATA' as MissioneStatus,
    RICHIESTALIQUIDAZIONE: 'RICHIESTA_LIQUIDAZIONE' as MissioneStatus,
    RICHIESTALIQUIDAZIONERIFIUTATA: 'RICHIESTA_LIQUIDAZIONE_RIFIUTATA' as MissioneStatus,
    LIQUIDATA: 'LIQUIDATA' as MissioneStatus
};