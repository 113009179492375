/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Stati di un aggiornamento di un piano di studi. Valori previsti: *BOZZA*, *DA_APPROVARE*, *APPROVATO_PARZIALE*, *APPROVATO*, *RIFIUTATO*
 */
export type AggiornamentoPianoDiStudiStatus = 'BOZZA' | 'DA_APPROVARE' | 'APPROVATO_PARZIALE' | 'APPROVATO' | 'RIFIUTATO';

export const AggiornamentoPianoDiStudiStatus = {
    BOZZA: 'BOZZA' as AggiornamentoPianoDiStudiStatus,
    DAAPPROVARE: 'DA_APPROVARE' as AggiornamentoPianoDiStudiStatus,
    APPROVATOPARZIALE: 'APPROVATO_PARZIALE' as AggiornamentoPianoDiStudiStatus,
    APPROVATO: 'APPROVATO' as AggiornamentoPianoDiStudiStatus,
    RIFIUTATO: 'RIFIUTATO' as AggiornamentoPianoDiStudiStatus
};