import {Pipe, PipeTransform} from '@angular/core';
import {isNumber} from "lodash-es";

export const geCurrencyEuro = (value: number | string) => {
    return new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR'
    }).format(isNumber(value) ? value : Number(value));
}

@Pipe({
    name: 'currencyEuro'
})
export class CurrencyEuroPipe implements PipeTransform {

    // eslint-disable-next-line id-blacklist
    transform(number: number | string): string {
        // eslint-disable-next-line id-blacklist
        return geCurrencyEuro(number);
    }

}
