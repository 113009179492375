<ng-container *transloco="let t">


    <div class="flex flex-col w-full min-h-[20rem] items-center justify-center"
         *ngIf="loading; else stepTemplate">
        <app-loading-screen class="w-full h-full">
        </app-loading-screen>
    </div>


    <ng-template #stepTemplate>

        <div class="mt-4 mb-6">
            <fuse-alert class="w-full"
                        [showIcon]="true"
                        [appearance]="'outline'"
            >{{ t('massive_operations.superamento_corso.hint_choose_students') }}
            </fuse-alert>
        </div>

        <div class="w-full flex flex-col gap-4 items-center mt-2">

            <div class="flex   sm:flex-row flex-col  w-full">

                <form class="w-full" [formGroup]="searchForm" (ngSubmit)="onSearch()">
                    <div class="flex  sm:flex-row flex-col w-full gap-4">
                        <mat-form-field appearance="fill" class="w-full sm:w-1/3">
                            <mat-label>{{ t('common.nominative') }}</mat-label>
                            <input matInput formControlName="nominativo">
                        </mat-form-field>


                        <div class="flex justify-center items-center  sm:flex-row gap-4" >
                            <button mat-flat-button
                                    (click)="onReset()"
                                    type="button"
                                    [color]="'accent'">
                                <mat-icon class="mr-2">delete</mat-icon>
                                <span>{{ t('menu_filter.reset') }}</span>
                            </button>

                            <button mat-flat-button
                                    type="submit"
                                    [color]="'primary'">
                                <mat-icon class="mr-2">search</mat-icon>
                                <span>{{ t('home.apply') }}</span>
                            </button>
                        </div>
                    </div>
                </form>

            </div>


            <div class="w-full flex flex-row justify-center items-center"
                 *ngIf="currentFilterListChipLabels?.length > 0">
                <!-- CHIPS -->
                <div class="w-full flex items-center">

                    <div class="flex flex-col gap-1.5">

                        <mat-chip-list class="w-full">
                            <div class="w-full flex flex-row gap-1.5 p-2 flex-wrap">
                                <ng-container *ngFor="let chip of currentFilterListChipLabels">
                                    <mat-chip color="primary" class="justify-between height-unset">
                                        {{ chip | titlecase }}
                                    </mat-chip>
                                </ng-container>
                            </div>
                        </mat-chip-list>
                    </div>
                </div>


            </div>


            <!-- TABELLA STUDENTI -->
            <div class="w-full mt-4 mb-6">
                <app-generic-table *ngIf="!!studentsListConfiguration"
                                   [configuration]="studentsListConfiguration"
                                   (clickAction)="tableClickAction($event)"
                                   (pageAction)="pageAction($event)"
                                   (tableDataEmitter)="onStudentDataTableChanged($event)">
                </app-generic-table>
            </div>

        </div>


    </ng-template>


</ng-container>
