import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SpinnerComponent} from './spinner.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {FlexModule} from '@angular/flex-layout';
import {SpinnerDirective} from '../../directives/spinner/spinner.directive';


@NgModule({
    declarations: [SpinnerComponent, SpinnerDirective],
    exports: [
        SpinnerDirective,
        SpinnerComponent
    ],
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        FlexModule
    ]
})
export class SpinnerModule {
}
