/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Tipologie di modifica ad un piano di studi. Valori previsti: *BOZZA*, *SOTTOMISSIONE*
 */
export type TipoModificaPianoDiStudi = 'BOZZA' | 'SOTTOMISSIONE';

export const TipoModificaPianoDiStudi = {
    BOZZA: 'BOZZA' as TipoModificaPianoDiStudi,
    SOTTOMISSIONE: 'SOTTOMISSIONE' as TipoModificaPianoDiStudi
};