import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {BooleanInput} from '@angular/cdk/coercion';
import {LogoutService} from '../../../shared/service/logout.service';
import {ImageManagerService} from "../../../shared/service/image-manager.service";
import {tap} from "rxjs/operators";
import {SafeUrl} from "@angular/platform-browser";
import {LocalStorageService} from "../../../shared/service/local-storage.service";
import {AuthorityType, UserView} from "../../../../api-clients/generated/services";
import {PathEnum} from "../../../app.routing";
import {FormControl} from "@angular/forms";
import {AppInitService} from "../../../shared/service/app-init.service";
import {
    AbstractDefaultComponent
} from "../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {FuseConfirmationService} from "../../../../@fuse/services/confirmation";


@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user'
})
export class UserComponent extends AbstractDefaultComponent implements OnInit {

    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */
    @Input() showAvatar: boolean = true;
    @Input() showGoToGestionale: boolean;
    @Input() showGoToAmministrazione: boolean;
    avatar: SafeUrl;
    profile: UserView;
    ctrlAvatar = new FormControl(null);
    AuthorityType = AuthorityType;


    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private logoutService: LogoutService,
        private imageManagerService: ImageManagerService,
        private localStorageService: LocalStorageService,
        private router: Router,
        public appInitService: AppInitService,
        protected fuseConfirmationService: FuseConfirmationService,

    ) {
        super();
    }

    ngOnInit(): void {
        this.imageManagerService.checkCurrentAvatar$.asObservable().pipe(
            tap(value => this.showAvatar = false),
            tap(value => this.avatar = value),
            tap(value => this.ctrlAvatar.setValue(value)),
        ).subscribe({
                next: (value: SafeUrl) => {
                    if (!!value) {
                        this.showAvatar = true;
                        this._changeDetectorRef.detectChanges();
                    } else {
                        this.showAvatar = false;
                    }
                }
            }
        );

        this.localStorageService.checkCurrentProfile$.asObservable().pipe(
            tap(profile => this.profile = profile)
        ).subscribe();
    }

    signOut(): void {
        this.logoutService.logout();
    }

    navigateToProfile(): void {
        if (this.appInitService.isAreaAdministrator) {
            this._router.navigateByUrl('/' + PathEnum.ADMINISTRATOR + '/' + PathEnum.PROFILE);
        } else {
            this._router.navigateByUrl('/' + PathEnum.PROFILE);
        }

    }

    goToStudentPage(): void {
        const idStudente = this.localStorageService.idStudenteByProfile;
        if (idStudente) {
            this.router.navigate([PathEnum.STUDENTS, idStudente, PathEnum.PROFILE]);
        }
    }



    goToGestionale(): void {
        this.appInitService.gestionaleRoleSelectionChoiceSkipped = false;
        this.appInitService.goToGestionale();
    }

    goToAmministrazione(): void {
        this.appInitService.goToAmministrazione();
    }



}
