/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Valori previsti: *M(maschio)*, *F(femmina)*
 */
export type SessoType = 'M' | 'F';

export const SessoType = {
    M: 'M' as SessoType,
    F: 'F' as SessoType
};