/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Thread CreatedBy enum. Valori previsti: *customer*, *agent*
 */
export type ThreadCreatedByEnum = 'customer' | 'agent';

export const ThreadCreatedByEnum = {
    Customer: 'customer' as ThreadCreatedByEnum,
    Agent: 'agent' as ThreadCreatedByEnum
};