/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Filtri codice di stato risposta. Codici risposta: *CODE_1XX*, *CODE_2XX*, *CODE_3XX*, *CODE_4XX*, *CODE_5XX*
 */
export type CodiceRispostaEnum = 'CODE_1XX' | 'CODE_2XX' | 'CODE_3XX' | 'CODE_4XX' | 'CODE_5XX';

export const CodiceRispostaEnum = {
    _1XX: 'CODE_1XX' as CodiceRispostaEnum,
    _2XX: 'CODE_2XX' as CodiceRispostaEnum,
    _3XX: 'CODE_3XX' as CodiceRispostaEnum,
    _4XX: 'CODE_4XX' as CodiceRispostaEnum,
    _5XX: 'CODE_5XX' as CodiceRispostaEnum
};