import {Pipe, PipeTransform} from '@angular/core';
import {EsitoOperazione} from "../../../api-clients/generated/services";

@Pipe({
    name: 'operationSucceedWithSomeError'
})
export class OperationSucceedWithSomeErrorPipe implements PipeTransform {

    constructor() { }

    transform(operationEsiti: { esito?: EsitoOperazione }[], args?: any): boolean {
        return !!operationEsiti?.find(e => e.esito === EsitoOperazione.SUCCESS)
            && !!operationEsiti?.find(e => e.esito === EsitoOperazione.ERROR);
    }
}
