import {FormArray, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {Injectable} from '@angular/core';
import {formGroupConfigInterface} from '../generic-components.interface';
import {isNil} from 'lodash';
import {CustomValidators} from "../../../../shared/validators/custom-validators";
import {TypeDialogFormEnum} from "../generic-components-enum";


@Injectable({
    providedIn: 'root'
})


export class FormModuleService {

    constructor() {
    }

    formControl(name: string, formGroup: FormGroup): FormControl {
        return formGroup.get(name) as FormControl;
    }


    createFormGroup(formGroupConfig: formGroupConfigInterface[], formGroup: FormGroup, validatorFns?: ValidatorFn[], valueForm?: any) {
        formGroupConfig.forEach(form => {
            if (form.type === TypeDialogFormEnum.DOCUMENT) {
                formGroup.addControl(form.name, new FormArray(valueForm?.[form.name]?.map(_ => new FormControl()) ?? []))
                this.formControl(form.name, formGroup).addValidators(CustomValidators.DocumentValidator(form.numeroMinimoDocumenti, form.numeroMassimoDocumenti));
            } else {
                formGroup.addControl(form.name, new FormControl(null))
                if (form.required) {
                    if (form.type === TypeDialogFormEnum.TEXT || form.type === TypeDialogFormEnum.TEXTAREA) {
                        this.formControl(form.name, formGroup).addValidators([Validators.required, CustomValidators.noWhitespaceValidator()])
                    } else {
                        this.formControl(form.name, formGroup).addValidators(Validators.required)
                    }
                }
                if (form?.regex?.pattern) {
                    this.formControl(form.name, formGroup).addValidators(Validators.pattern(new RegExp(form?.regex?.pattern)))
                }
                if (!isNil(form?.max?.number)) {
                    this.formControl(form.name, formGroup).addValidators(Validators.max(form.max.number));
                }
                if (!isNil(form?.min?.number)) {
                    this.formControl(form.name, formGroup).addValidators(Validators.min(form.min.number));
                }
                if (form?.maxlength) {
                    this.formControl(form.name, formGroup).addValidators(Validators.maxLength(form?.maxlength));
                }
                /*if (form?.isSpace) {
                    this.formControl(form.name, formGroup).addValidators(CustomValidators.noSpace());
                }*/
                if (form.disable) {
                    this.formControl(form.name, formGroup).disable();
                }
            }
        })
        if (validatorFns && validatorFns.length > 0) {
            formGroup.setValidators(validatorFns);
        }

        console.log(formGroup)
    }

    createFormGroupWithValue(formGroupConfig: formGroupConfigInterface[], formGroup: FormGroup, validatorFns?: ValidatorFn[]): FormGroup {
        formGroupConfig.forEach((form) => {
            formGroup.addControl(form.name, new FormControl(form.value));
            if (form.required) {
                if(form.type === TypeDialogFormEnum.TEXT || form.type === TypeDialogFormEnum.TEXTAREA){
                    this.formControl(form.name, formGroup).addValidators([Validators.required, CustomValidators.noWhitespaceValidator()])
                } else {
                    this.formControl(form.name, formGroup).addValidators(Validators.required)
                }
            }
            if (form?.regex?.pattern) {
                this.formControl(form.name, formGroup).addValidators(Validators.pattern(new RegExp(form?.regex?.pattern)))
            }
            if (!isNil(form?.max?.number)) {
                this.formControl(form.name, formGroup).addValidators(Validators.max(form.max.number));
            }
            if (!isNil(form?.min?.number)) {
                this.formControl(form.name, formGroup).addValidators(Validators.min(form.min.number));
            }
            if (form?.maxlength) {
                this.formControl(form.name, formGroup).addValidators(Validators.maxLength(form?.maxlength));
            }
            if (form.disable) {
                this.formControl(form.name, formGroup).disable();
            }
        });
        if (validatorFns && validatorFns.length > 0) {
            formGroup.setValidators(validatorFns);
        }
        return formGroup;
    }
}
