import {Injectable} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {LocalStorageService} from './local-storage.service';
import {BehaviorSubject, map, Observable} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {
    AuthorityType,
    SottoruoloUtenteUserView,
    UserSottoruoloView
} from '../../../api-clients/generated/services';


@Injectable({
    providedIn: 'root'
})
export class SpeseBarFilterManagerService {

    mainFormGroup: FormGroup | undefined;
    checkApplyFilterClick$ = new BehaviorSubject(false);
    currentSottoRuolo: AuthorityType | undefined;
    filterApplied$= new BehaviorSubject(undefined);
    defaultFormPathList = [
        'codiceSpesa',
        'tipologiaSpesa',
        'descrizioneNoteSpesa',
        'nominativoStudente',
        'emailStudente',
        'cfStudente',
        'matricolaStudente',
    ];
    coordinatoreFormPathList = [
        'codiceSpesa',
        'tipologiaSpesa',
        'descrizioneNoteSpesa',
        'nominativoStudente',
        'emailStudente',
        'cfStudente',
        'matricolaStudente',
    ];
    fieldsLabelMap = new Map<string, string>([
        ['codiceSpesa', 'budget.codice_spesa'],
        ['tipologiaSpesa', 'budget.type'],
        ['descrizioneNoteSpesa', 'budget.description'],
        ['nominativoStudente', 'mobility.student_nominative'],
        ['emailStudente', 'budget.student_email'],
        ['cfStudente', 'mobility.cf_student'],
        ['matricolaStudente', 'mobility.enrollment_number'],
    ]);




    constructor(private localStorageService: LocalStorageService,
                private formBuilder: FormBuilder) {
    }

    init(): void {
        const sottoruolo = this.localStorageService.dipartimentoRuoloCiclo?.sottoruolo;
        if((this.currentSottoRuolo !== sottoruolo) || !this.currentSottoRuolo) {
            this.currentSottoRuolo = sottoruolo;
            if(!!this.mainFormGroup) {
                this.mainFormGroup.reset();
            } else {
                this.mainFormGroup = this.createDefaultFormGroup();
            }
        }
    }

    createDefaultFormGroup(): FormGroup {
        return this.formBuilder.group({
            codiceSpesa: null,
            tipologiaSpesa: null,
            descrizioneNoteSpesa: null,
            nominativoStudente: null,
            emailStudente: null,
            cfStudente: null,
            matricolaStudente: null,
        });
    }

    getCtrlByPath(path: string): FormControl<any> {
        return this.mainFormGroup?.get(path) as FormControl;
    }

    appliedFiltersObsevable$(): Observable<any> {
        return this.checkApplyFilterClick$.asObservable().pipe(
            filter(Boolean),
            map(() => this.mainFormGroup?.value)
        );
    }

    getFieldsByRole(ruolo?: AuthorityType, sottoruolo?: AuthorityType): string[] {
        switch(ruolo) {
            case AuthorityType.SUPERADMIN:
            case AuthorityType.ADMIN:
                return this.defaultFormPathList;
            default:
                switch (sottoruolo) {
                    case AuthorityType.GRUPPODICOORDINAMENTO:
                    case AuthorityType.COLLEGIODOCENTI:
                    case AuthorityType.GRUPPOPTA:
                    case AuthorityType.SEGRETARIOAMMINISTRATIVODIDIPARTIMENTO:
                        return this.defaultFormPathList;
                    case AuthorityType.COORDINATORE:
                        return this.coordinatoreFormPathList;
                    default:
                        return null;
                }
        }


    }


}
