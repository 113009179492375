import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DialogLicensesComponent} from './dialog-licenses.component';
import {MatDialogModule} from '@angular/material/dialog';
import {FlexModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {TranslocoModule} from '@ngneat/transloco';
import {TestoPrivacyModule} from '../../components/testo-privacy/testo-privacy.module';
import {DialogWrapModule} from '../../../layout/common/dialog-wrap/dialog-wrap.module';
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatExpansionModule} from "@angular/material/expansion";



@NgModule({
    declarations: [DialogLicensesComponent],
    imports: [
        CommonModule,
        DialogWrapModule,
        TestoPrivacyModule,
        MatDialogModule,
        FlexModule,
        MatButtonModule,
        TranslocoModule,
        MatIconModule,
        MatTooltipModule,
        MatExpansionModule
    ]
})
export class DialogLicensesModule {
}
