/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CicloDottoratoInfoView } from '../model/cicloDottoratoInfoView';
import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { ExtraFieldsMapperTipoBorsa } from '../model/extraFieldsMapperTipoBorsa';
import { TipoBorsaPerCicloWithExtraFieldsMapperView } from '../model/tipoBorsaPerCicloWithExtraFieldsMapperView';
import { UpdateHeaderOrFooterPdfRequest } from '../model/updateHeaderOrFooterPdfRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CicliService {

    protected basePath = 'https://api-dev-vanvitelli.southengineering.it/dottorandi-backend';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Restituisce il byte array del footer pdf per un tipo borsa di studi per ciclo.
     * 
     * @param idTipoBorsaPerCiclo 
     * @param nome_file nome del file footer da scaricare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFooterPdfBorsaDiStudiPerCiclo(idTipoBorsaPerCiclo: string, nome_file: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getFooterPdfBorsaDiStudiPerCiclo(idTipoBorsaPerCiclo: string, nome_file: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getFooterPdfBorsaDiStudiPerCiclo(idTipoBorsaPerCiclo: string, nome_file: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getFooterPdfBorsaDiStudiPerCiclo(idTipoBorsaPerCiclo: string, nome_file: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idTipoBorsaPerCiclo === null || idTipoBorsaPerCiclo === undefined) {
            throw new Error('Required parameter idTipoBorsaPerCiclo was null or undefined when calling getFooterPdfBorsaDiStudiPerCiclo.');
        }

        if (nome_file === null || nome_file === undefined) {
            throw new Error('Required parameter nome_file was null or undefined when calling getFooterPdfBorsaDiStudiPerCiclo.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (nome_file !== undefined && nome_file !== null) {
            queryParameters = queryParameters.set('nome_file', <any>nome_file);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/v1/ciclo-dottorato/tipologie-borse/${encodeURIComponent(String(idTipoBorsaPerCiclo))}/footer-pdf`,
            {
             responseType: "blob" as "json",
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array dell&#x27;header pdf per un tipo borsa di studi per ciclo.
     * 
     * @param idTipoBorsaPerCiclo 
     * @param nome_file nome del file header da scaricare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getHeaderPdfBorsaDiStudiPerCiclo(idTipoBorsaPerCiclo: string, nome_file: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getHeaderPdfBorsaDiStudiPerCiclo(idTipoBorsaPerCiclo: string, nome_file: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getHeaderPdfBorsaDiStudiPerCiclo(idTipoBorsaPerCiclo: string, nome_file: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getHeaderPdfBorsaDiStudiPerCiclo(idTipoBorsaPerCiclo: string, nome_file: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idTipoBorsaPerCiclo === null || idTipoBorsaPerCiclo === undefined) {
            throw new Error('Required parameter idTipoBorsaPerCiclo was null or undefined when calling getHeaderPdfBorsaDiStudiPerCiclo.');
        }

        if (nome_file === null || nome_file === undefined) {
            throw new Error('Required parameter nome_file was null or undefined when calling getHeaderPdfBorsaDiStudiPerCiclo.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (nome_file !== undefined && nome_file !== null) {
            queryParameters = queryParameters.set('nome_file', <any>nome_file);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/v1/ciclo-dottorato/tipologie-borse/${encodeURIComponent(String(idTipoBorsaPerCiclo))}/header-pdf`,
            {
             responseType: "blob" as "json",
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le info di un ciclo di dottorato.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInfoCicloDottorato(observe?: 'body', reportProgress?: boolean): Observable<CicloDottoratoInfoView>;
    public getInfoCicloDottorato(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CicloDottoratoInfoView>>;
    public getInfoCicloDottorato(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CicloDottoratoInfoView>>;
    public getInfoCicloDottorato(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CicloDottoratoInfoView>('get',`${this.basePath}/api/v1/ciclo-dottorato/info`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array dell&#x27;anteprima pdf per un tipo borsa di studi per ciclo.
     * 
     * @param idTipoBorsaPerCiclo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPdfAntemprimaHeaderFooter(idTipoBorsaPerCiclo: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getPdfAntemprimaHeaderFooter(idTipoBorsaPerCiclo: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getPdfAntemprimaHeaderFooter(idTipoBorsaPerCiclo: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getPdfAntemprimaHeaderFooter(idTipoBorsaPerCiclo: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idTipoBorsaPerCiclo === null || idTipoBorsaPerCiclo === undefined) {
            throw new Error('Required parameter idTipoBorsaPerCiclo was null or undefined when calling getPdfAntemprimaHeaderFooter.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/v1/ciclo-dottorato/tipologie-borse/${encodeURIComponent(String(idTipoBorsaPerCiclo))}/anteprima-pdf-header-footer`,
            {
             responseType: "blob" as "json",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce i tipi di borse per un ciclo.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTipologieBorseInfo(observe?: 'body', reportProgress?: boolean): Observable<Array<TipoBorsaPerCicloWithExtraFieldsMapperView>>;
    public getTipologieBorseInfo(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TipoBorsaPerCicloWithExtraFieldsMapperView>>>;
    public getTipologieBorseInfo(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TipoBorsaPerCicloWithExtraFieldsMapperView>>>;
    public getTipologieBorseInfo(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TipoBorsaPerCicloWithExtraFieldsMapperView>>('get',`${this.basePath}/api/v1/ciclo-dottorato/tipologie-borse`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di aggiungere/modificare l&#x27;extra fields mapper di un tipo borsa per ciclo.
     * 
     * @param body 
     * @param forzaCancellazione 
     * @param idTipoBorsaPerCiclo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateExtraFieldsMapperTipologiaBorsa(body: ExtraFieldsMapperTipoBorsa, forzaCancellazione: boolean, idTipoBorsaPerCiclo: string, observe?: 'body', reportProgress?: boolean): Observable<TipoBorsaPerCicloWithExtraFieldsMapperView>;
    public updateExtraFieldsMapperTipologiaBorsa(body: ExtraFieldsMapperTipoBorsa, forzaCancellazione: boolean, idTipoBorsaPerCiclo: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TipoBorsaPerCicloWithExtraFieldsMapperView>>;
    public updateExtraFieldsMapperTipologiaBorsa(body: ExtraFieldsMapperTipoBorsa, forzaCancellazione: boolean, idTipoBorsaPerCiclo: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TipoBorsaPerCicloWithExtraFieldsMapperView>>;
    public updateExtraFieldsMapperTipologiaBorsa(body: ExtraFieldsMapperTipoBorsa, forzaCancellazione: boolean, idTipoBorsaPerCiclo: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateExtraFieldsMapperTipologiaBorsa.');
        }

        if (forzaCancellazione === null || forzaCancellazione === undefined) {
            throw new Error('Required parameter forzaCancellazione was null or undefined when calling updateExtraFieldsMapperTipologiaBorsa.');
        }

        if (idTipoBorsaPerCiclo === null || idTipoBorsaPerCiclo === undefined) {
            throw new Error('Required parameter idTipoBorsaPerCiclo was null or undefined when calling updateExtraFieldsMapperTipologiaBorsa.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (forzaCancellazione !== undefined && forzaCancellazione !== null) {
            queryParameters = queryParameters.set('forzaCancellazione', <any>forzaCancellazione);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TipoBorsaPerCicloWithExtraFieldsMapperView>('put',`${this.basePath}/api/v1/ciclo-dottorato/tipologie-borse/${encodeURIComponent(String(idTipoBorsaPerCiclo))}/extra-fields-mapper`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di aggiungere/modificare il flag di presenza su tutte le pagine dell&#x27;immagine di footer pdf di un tipo borsa di studi per ciclo.
     * 
     * @param idTipoBorsaPerCiclo 
     * @param footerPdfSuTutteLePagine Flag di presenza su tutte le pagine del footer pdf
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateFlagFooterPdfTipoBorsaDiStudiPerCiclo(idTipoBorsaPerCiclo: string, footerPdfSuTutteLePagine: boolean, observe?: 'body', reportProgress?: boolean): Observable<TipoBorsaPerCicloWithExtraFieldsMapperView>;
    public updateFlagFooterPdfTipoBorsaDiStudiPerCiclo(idTipoBorsaPerCiclo: string, footerPdfSuTutteLePagine: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TipoBorsaPerCicloWithExtraFieldsMapperView>>;
    public updateFlagFooterPdfTipoBorsaDiStudiPerCiclo(idTipoBorsaPerCiclo: string, footerPdfSuTutteLePagine: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TipoBorsaPerCicloWithExtraFieldsMapperView>>;
    public updateFlagFooterPdfTipoBorsaDiStudiPerCiclo(idTipoBorsaPerCiclo: string, footerPdfSuTutteLePagine: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idTipoBorsaPerCiclo === null || idTipoBorsaPerCiclo === undefined) {
            throw new Error('Required parameter idTipoBorsaPerCiclo was null or undefined when calling updateFlagFooterPdfTipoBorsaDiStudiPerCiclo.');
        }

        if (footerPdfSuTutteLePagine === null || footerPdfSuTutteLePagine === undefined) {
            throw new Error('Required parameter footerPdfSuTutteLePagine was null or undefined when calling updateFlagFooterPdfTipoBorsaDiStudiPerCiclo.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (footerPdfSuTutteLePagine !== undefined && footerPdfSuTutteLePagine !== null) {
            queryParameters = queryParameters.set('footerPdfSuTutteLePagine', <any>footerPdfSuTutteLePagine);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TipoBorsaPerCicloWithExtraFieldsMapperView>('put',`${this.basePath}/api/v1/ciclo-dottorato/tipologie-borse/${encodeURIComponent(String(idTipoBorsaPerCiclo))}/footer-pdf-su-tutte-le-pagine`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di aggiungere/modificare il flag di presenza su tutte le pagine dell&#x27;immagine di header pdf di un tipo borsa di studi per ciclo.
     * 
     * @param idTipoBorsaPerCiclo 
     * @param headerPdfSuTutteLePagine Flag di presenza su tutte le pagine dell&#x27;header pdf
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateFlagHeaderPdfTipoBorsaDiStudiPerCiclo(idTipoBorsaPerCiclo: string, headerPdfSuTutteLePagine: boolean, observe?: 'body', reportProgress?: boolean): Observable<TipoBorsaPerCicloWithExtraFieldsMapperView>;
    public updateFlagHeaderPdfTipoBorsaDiStudiPerCiclo(idTipoBorsaPerCiclo: string, headerPdfSuTutteLePagine: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TipoBorsaPerCicloWithExtraFieldsMapperView>>;
    public updateFlagHeaderPdfTipoBorsaDiStudiPerCiclo(idTipoBorsaPerCiclo: string, headerPdfSuTutteLePagine: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TipoBorsaPerCicloWithExtraFieldsMapperView>>;
    public updateFlagHeaderPdfTipoBorsaDiStudiPerCiclo(idTipoBorsaPerCiclo: string, headerPdfSuTutteLePagine: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idTipoBorsaPerCiclo === null || idTipoBorsaPerCiclo === undefined) {
            throw new Error('Required parameter idTipoBorsaPerCiclo was null or undefined when calling updateFlagHeaderPdfTipoBorsaDiStudiPerCiclo.');
        }

        if (headerPdfSuTutteLePagine === null || headerPdfSuTutteLePagine === undefined) {
            throw new Error('Required parameter headerPdfSuTutteLePagine was null or undefined when calling updateFlagHeaderPdfTipoBorsaDiStudiPerCiclo.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (headerPdfSuTutteLePagine !== undefined && headerPdfSuTutteLePagine !== null) {
            queryParameters = queryParameters.set('headerPdfSuTutteLePagine', <any>headerPdfSuTutteLePagine);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TipoBorsaPerCicloWithExtraFieldsMapperView>('put',`${this.basePath}/api/v1/ciclo-dottorato/tipologie-borse/${encodeURIComponent(String(idTipoBorsaPerCiclo))}/header-pdf-su-tutte-le-pagine`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di aggiungere/modificare l&#x27;immagine di footer pdf di un tipo borsa di studi per ciclo.
     * 
     * @param idTipoBorsaPerCiclo 
     * @param request 
     * @param fileFooterPdf 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateFooterPdfTipoBorsaDiStudiPerCicloForm(idTipoBorsaPerCiclo: string, request?: UpdateHeaderOrFooterPdfRequest, fileFooterPdf?: Blob, observe?: 'body', reportProgress?: boolean): Observable<TipoBorsaPerCicloWithExtraFieldsMapperView>;
    public updateFooterPdfTipoBorsaDiStudiPerCicloForm(idTipoBorsaPerCiclo: string, request?: UpdateHeaderOrFooterPdfRequest, fileFooterPdf?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TipoBorsaPerCicloWithExtraFieldsMapperView>>;
    public updateFooterPdfTipoBorsaDiStudiPerCicloForm(idTipoBorsaPerCiclo: string, request?: UpdateHeaderOrFooterPdfRequest, fileFooterPdf?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TipoBorsaPerCicloWithExtraFieldsMapperView>>;
    public updateFooterPdfTipoBorsaDiStudiPerCicloForm(idTipoBorsaPerCiclo: string, request?: UpdateHeaderOrFooterPdfRequest, fileFooterPdf?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idTipoBorsaPerCiclo === null || idTipoBorsaPerCiclo === undefined) {
            throw new Error('Required parameter idTipoBorsaPerCiclo was null or undefined when calling updateFooterPdfTipoBorsaDiStudiPerCiclo.');
        }



        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (request !== undefined) {
            formParams = formParams.append('request', new Blob([JSON.stringify(request)], { type: 'application/json'})) as any || formParams;
        }
        if (fileFooterPdf !== undefined) {
            formParams = formParams.append('fileFooterPdf', <any>fileFooterPdf) as any || formParams;
        }

        return this.httpClient.request<TipoBorsaPerCicloWithExtraFieldsMapperView>('put',`${this.basePath}/api/v1/ciclo-dottorato/tipologie-borse/${encodeURIComponent(String(idTipoBorsaPerCiclo))}/footer-pdf`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di aggiornare il numero di giorni di mobilità retribuibili ad un dottorando in cotutela.
     * 
     * @param giorniMobilitaDottorandoRetribuibili 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateGiorniMobilitaDottorandoInCotutelaRetribuibili(giorniMobilitaDottorandoRetribuibili: number, observe?: 'body', reportProgress?: boolean): Observable<CicloDottoratoInfoView>;
    public updateGiorniMobilitaDottorandoInCotutelaRetribuibili(giorniMobilitaDottorandoRetribuibili: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CicloDottoratoInfoView>>;
    public updateGiorniMobilitaDottorandoInCotutelaRetribuibili(giorniMobilitaDottorandoRetribuibili: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CicloDottoratoInfoView>>;
    public updateGiorniMobilitaDottorandoInCotutelaRetribuibili(giorniMobilitaDottorandoRetribuibili: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (giorniMobilitaDottorandoRetribuibili === null || giorniMobilitaDottorandoRetribuibili === undefined) {
            throw new Error('Required parameter giorniMobilitaDottorandoRetribuibili was null or undefined when calling updateGiorniMobilitaDottorandoInCotutelaRetribuibili.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (giorniMobilitaDottorandoRetribuibili !== undefined && giorniMobilitaDottorandoRetribuibili !== null) {
            queryParameters = queryParameters.set('giorniMobilitaDottorandoRetribuibili', <any>giorniMobilitaDottorandoRetribuibili);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CicloDottoratoInfoView>('put',`${this.basePath}/api/v1/ciclo-dottorato/giorni-mobilita-dottorando-cotutela-retribuibili`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di aggiornare il numero minimo di giorni di mobilità retribuibili ad un dottorando in cotutela.
     * 
     * @param giorniMobilitaDottorandoRetribuibiliMin 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateGiorniMobilitaDottorandoInCotutelaRetribuibiliMin(giorniMobilitaDottorandoRetribuibiliMin: number, observe?: 'body', reportProgress?: boolean): Observable<CicloDottoratoInfoView>;
    public updateGiorniMobilitaDottorandoInCotutelaRetribuibiliMin(giorniMobilitaDottorandoRetribuibiliMin: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CicloDottoratoInfoView>>;
    public updateGiorniMobilitaDottorandoInCotutelaRetribuibiliMin(giorniMobilitaDottorandoRetribuibiliMin: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CicloDottoratoInfoView>>;
    public updateGiorniMobilitaDottorandoInCotutelaRetribuibiliMin(giorniMobilitaDottorandoRetribuibiliMin: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (giorniMobilitaDottorandoRetribuibiliMin === null || giorniMobilitaDottorandoRetribuibiliMin === undefined) {
            throw new Error('Required parameter giorniMobilitaDottorandoRetribuibiliMin was null or undefined when calling updateGiorniMobilitaDottorandoInCotutelaRetribuibiliMin.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (giorniMobilitaDottorandoRetribuibiliMin !== undefined && giorniMobilitaDottorandoRetribuibiliMin !== null) {
            queryParameters = queryParameters.set('giorniMobilitaDottorandoRetribuibiliMin', <any>giorniMobilitaDottorandoRetribuibiliMin);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CicloDottoratoInfoView>('put',`${this.basePath}/api/v1/ciclo-dottorato/giorni-mobilita-dottorando-cotutela-retribuibili-min`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di aggiornare il numero minimo di giorni di mobilità retribuibili ad un dottorando per un tipo borsa per ciclo.
     * 
     * @param idTipoBorsaPerCiclo 
     * @param giorniMobilitaDottorandoRetribuibiliMin 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateGiorniMobilitaDottorandoRetribuibiliMinTipologiaBorsa(idTipoBorsaPerCiclo: string, giorniMobilitaDottorandoRetribuibiliMin: number, observe?: 'body', reportProgress?: boolean): Observable<Array<TipoBorsaPerCicloWithExtraFieldsMapperView>>;
    public updateGiorniMobilitaDottorandoRetribuibiliMinTipologiaBorsa(idTipoBorsaPerCiclo: string, giorniMobilitaDottorandoRetribuibiliMin: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TipoBorsaPerCicloWithExtraFieldsMapperView>>>;
    public updateGiorniMobilitaDottorandoRetribuibiliMinTipologiaBorsa(idTipoBorsaPerCiclo: string, giorniMobilitaDottorandoRetribuibiliMin: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TipoBorsaPerCicloWithExtraFieldsMapperView>>>;
    public updateGiorniMobilitaDottorandoRetribuibiliMinTipologiaBorsa(idTipoBorsaPerCiclo: string, giorniMobilitaDottorandoRetribuibiliMin: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idTipoBorsaPerCiclo === null || idTipoBorsaPerCiclo === undefined) {
            throw new Error('Required parameter idTipoBorsaPerCiclo was null or undefined when calling updateGiorniMobilitaDottorandoRetribuibiliMinTipologiaBorsa.');
        }

        if (giorniMobilitaDottorandoRetribuibiliMin === null || giorniMobilitaDottorandoRetribuibiliMin === undefined) {
            throw new Error('Required parameter giorniMobilitaDottorandoRetribuibiliMin was null or undefined when calling updateGiorniMobilitaDottorandoRetribuibiliMinTipologiaBorsa.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (giorniMobilitaDottorandoRetribuibiliMin !== undefined && giorniMobilitaDottorandoRetribuibiliMin !== null) {
            queryParameters = queryParameters.set('giorniMobilitaDottorandoRetribuibiliMin', <any>giorniMobilitaDottorandoRetribuibiliMin);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TipoBorsaPerCicloWithExtraFieldsMapperView>>('put',`${this.basePath}/api/v1/ciclo-dottorato/tipologie-borse/${encodeURIComponent(String(idTipoBorsaPerCiclo))}/giorni-mobilita-dottorando-retribuibili-min`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di aggiornare il numero di giorni di mobilità retribuibili ad un dottorando per un tipo borsa per ciclo.
     * 
     * @param idTipoBorsaPerCiclo 
     * @param giorniMobilitaDottorandoRetribuibili 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateGiorniMobilitaDottorandoRetribuibiliTipologiaBorsa(idTipoBorsaPerCiclo: string, giorniMobilitaDottorandoRetribuibili: number, observe?: 'body', reportProgress?: boolean): Observable<Array<TipoBorsaPerCicloWithExtraFieldsMapperView>>;
    public updateGiorniMobilitaDottorandoRetribuibiliTipologiaBorsa(idTipoBorsaPerCiclo: string, giorniMobilitaDottorandoRetribuibili: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TipoBorsaPerCicloWithExtraFieldsMapperView>>>;
    public updateGiorniMobilitaDottorandoRetribuibiliTipologiaBorsa(idTipoBorsaPerCiclo: string, giorniMobilitaDottorandoRetribuibili: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TipoBorsaPerCicloWithExtraFieldsMapperView>>>;
    public updateGiorniMobilitaDottorandoRetribuibiliTipologiaBorsa(idTipoBorsaPerCiclo: string, giorniMobilitaDottorandoRetribuibili: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idTipoBorsaPerCiclo === null || idTipoBorsaPerCiclo === undefined) {
            throw new Error('Required parameter idTipoBorsaPerCiclo was null or undefined when calling updateGiorniMobilitaDottorandoRetribuibiliTipologiaBorsa.');
        }

        if (giorniMobilitaDottorandoRetribuibili === null || giorniMobilitaDottorandoRetribuibili === undefined) {
            throw new Error('Required parameter giorniMobilitaDottorandoRetribuibili was null or undefined when calling updateGiorniMobilitaDottorandoRetribuibiliTipologiaBorsa.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (giorniMobilitaDottorandoRetribuibili !== undefined && giorniMobilitaDottorandoRetribuibili !== null) {
            queryParameters = queryParameters.set('giorniMobilitaDottorandoRetribuibili', <any>giorniMobilitaDottorandoRetribuibili);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TipoBorsaPerCicloWithExtraFieldsMapperView>>('put',`${this.basePath}/api/v1/ciclo-dottorato/tipologie-borse/${encodeURIComponent(String(idTipoBorsaPerCiclo))}/giorni-mobilita-dottorando-retribuibili`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di aggiornare il numero di giorni di mobilità retribuibili ad un dottorando senza borsa per ciclo.
     * 
     * @param giorniMobilitaDottorandoRetribuibili 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateGiorniMobilitaDottorandoSenzaBorsaRetribuibili(giorniMobilitaDottorandoRetribuibili: number, observe?: 'body', reportProgress?: boolean): Observable<CicloDottoratoInfoView>;
    public updateGiorniMobilitaDottorandoSenzaBorsaRetribuibili(giorniMobilitaDottorandoRetribuibili: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CicloDottoratoInfoView>>;
    public updateGiorniMobilitaDottorandoSenzaBorsaRetribuibili(giorniMobilitaDottorandoRetribuibili: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CicloDottoratoInfoView>>;
    public updateGiorniMobilitaDottorandoSenzaBorsaRetribuibili(giorniMobilitaDottorandoRetribuibili: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (giorniMobilitaDottorandoRetribuibili === null || giorniMobilitaDottorandoRetribuibili === undefined) {
            throw new Error('Required parameter giorniMobilitaDottorandoRetribuibili was null or undefined when calling updateGiorniMobilitaDottorandoSenzaBorsaRetribuibili.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (giorniMobilitaDottorandoRetribuibili !== undefined && giorniMobilitaDottorandoRetribuibili !== null) {
            queryParameters = queryParameters.set('giorniMobilitaDottorandoRetribuibili', <any>giorniMobilitaDottorandoRetribuibili);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CicloDottoratoInfoView>('put',`${this.basePath}/api/v1/ciclo-dottorato/giorni-mobilita-dottorando-senza-borsa-retribuibili`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di aggiornare il numero minimo di giorni di mobilità retribuibili ad un dottorando senza borsa per ciclo.
     * 
     * @param giorniMobilitaDottorandoRetribuibiliMin 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateGiorniMobilitaDottorandoSenzaBorsaRetribuibiliMin(giorniMobilitaDottorandoRetribuibiliMin: number, observe?: 'body', reportProgress?: boolean): Observable<CicloDottoratoInfoView>;
    public updateGiorniMobilitaDottorandoSenzaBorsaRetribuibiliMin(giorniMobilitaDottorandoRetribuibiliMin: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CicloDottoratoInfoView>>;
    public updateGiorniMobilitaDottorandoSenzaBorsaRetribuibiliMin(giorniMobilitaDottorandoRetribuibiliMin: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CicloDottoratoInfoView>>;
    public updateGiorniMobilitaDottorandoSenzaBorsaRetribuibiliMin(giorniMobilitaDottorandoRetribuibiliMin: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (giorniMobilitaDottorandoRetribuibiliMin === null || giorniMobilitaDottorandoRetribuibiliMin === undefined) {
            throw new Error('Required parameter giorniMobilitaDottorandoRetribuibiliMin was null or undefined when calling updateGiorniMobilitaDottorandoSenzaBorsaRetribuibiliMin.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (giorniMobilitaDottorandoRetribuibiliMin !== undefined && giorniMobilitaDottorandoRetribuibiliMin !== null) {
            queryParameters = queryParameters.set('giorniMobilitaDottorandoRetribuibiliMin', <any>giorniMobilitaDottorandoRetribuibiliMin);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CicloDottoratoInfoView>('put',`${this.basePath}/api/v1/ciclo-dottorato/giorni-mobilita-dottorando-senza-borsa-retribuibili-min`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di aggiungere/modificare l&#x27;immagine di header pdf di un tipo borsa di studi per ciclo.
     * 
     * @param idTipoBorsaPerCiclo 
     * @param request 
     * @param fileHeaderPdf 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateHeaderPdfTipoBorsaDiStudiPerCicloForm(idTipoBorsaPerCiclo: string, request?: UpdateHeaderOrFooterPdfRequest, fileHeaderPdf?: Blob, observe?: 'body', reportProgress?: boolean): Observable<TipoBorsaPerCicloWithExtraFieldsMapperView>;
    public updateHeaderPdfTipoBorsaDiStudiPerCicloForm(idTipoBorsaPerCiclo: string, request?: UpdateHeaderOrFooterPdfRequest, fileHeaderPdf?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TipoBorsaPerCicloWithExtraFieldsMapperView>>;
    public updateHeaderPdfTipoBorsaDiStudiPerCicloForm(idTipoBorsaPerCiclo: string, request?: UpdateHeaderOrFooterPdfRequest, fileHeaderPdf?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TipoBorsaPerCicloWithExtraFieldsMapperView>>;
    public updateHeaderPdfTipoBorsaDiStudiPerCicloForm(idTipoBorsaPerCiclo: string, request?: UpdateHeaderOrFooterPdfRequest, fileHeaderPdf?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idTipoBorsaPerCiclo === null || idTipoBorsaPerCiclo === undefined) {
            throw new Error('Required parameter idTipoBorsaPerCiclo was null or undefined when calling updateHeaderPdfTipoBorsaDiStudiPerCiclo.');
        }



        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (request !== undefined) {
            formParams = formParams.append('request', new Blob([JSON.stringify(request)], { type: 'application/json'})) as any || formParams;
        }
        if (fileHeaderPdf !== undefined) {
            formParams = formParams.append('fileHeaderPdf', <any>fileHeaderPdf) as any || formParams;
        }

        return this.httpClient.request<TipoBorsaPerCicloWithExtraFieldsMapperView>('put',`${this.basePath}/api/v1/ciclo-dottorato/tipologie-borse/${encodeURIComponent(String(idTipoBorsaPerCiclo))}/header-pdf`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
