import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {catchError, map, Observable, of, tap} from 'rxjs';
import {GenericResponseDTO, InfoService} from "../../../../api-clients/generated/services";
import {AppInitService} from "../../../shared/service/app-init.service";

@Injectable({
    providedIn: 'root'
})
export class BeInfoResolver implements Resolve<string | null> {

    constructor(private infoService: InfoService,
                private appInitService: AppInitService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string | null> {
        return this.infoService.getVersione().pipe(
            catchError(() => of(null)),
            map((response: GenericResponseDTO) => response?.message),
            tap((version: string) => console.log('BE version:', version)),
            tap((version: string) => this.appInitService.BEversion = version)
        );
    }
}
