import {ConfigCol, numFmtEuro} from "../../../shared/service/excel.service";
import moment from "moment";
import {
    annoRiferimentoToRomanNumeral,
    getLanguagePathBySpesaStudenteStatus,
    removeUnderscore
} from "../../../shared/utils/utils";
import {
    GenericTableConfigurationModel,
    TipoClickEnum,
    TipoColonnaEnum
} from "../../../shared/components/table/model/generic-table-model";
import {Translation} from "@ngneat/transloco";
import {get} from "lodash";
import {DEFAULT_PAGE_SIZE} from "../audit/audit.component";
import {
    AggiornamentoBudgetStudenteStatus, AnnoRiferimentoValues, PageAggiornamentoBudgetStudentePerOpMassivaInfoView,
    PageSpesaStudentePerApprovMassivaInfoView,
    SpesaStudenteInfoViewImpl,
    SpesaStudentePerApprovMassivaInfoView,
    SpesaStudenteStatus
} from "../../../../api-clients/generated/services";

export function buildConfigurationForSpeseRequest(spese: PageSpesaStudentePerApprovMassivaInfoView,
                                                  pageSize = DEFAULT_PAGE_SIZE,
                                                  pageBE: number): GenericTableConfigurationModel {
    return {
        configuration: {
            data: spese.content.map(value => ({
                ...value,
                studenteUIMapped: [{
                    utenteNome:  value?.budgetStudente?.studenteCiclo?.utente?.nome,
                    utenteCognome:  value?.budgetStudente?.studenteCiclo?.utente?.cognome,
                    utenteMail: value?.budgetStudente?.studenteCiclo?.utente?.mail,
                    nome: value?.budgetStudente?.studenteCiclo?.utente?.nome,
                    cognome: value?.budgetStudente?.studenteCiclo?.utente?.cognome,
                    studenteCodiceFiscale: value?.budgetStudente?.studenteCiclo?.utente?.codiceFiscale,
                    codiceFiscale: value?.budgetStudente?.studenteCiclo?.utente?.codiceFiscale,
                    studenteMatricola: value?.budgetStudente?.studenteCiclo?.matricola,
                    urlImmagineProfilo: value?.budgetStudente?.studenteCiclo?.utente?.urlImmagineProfilo,
                    utenteTelefono: value?.budgetStudente?.studenteCiclo?.utente?.telefono
                }],
                annoRiferimento: annoRiferimentoToRomanNumeral(value.annoRiferimento),
            })),
            pageBE,
            totalElements: spese?.totalElements,
            pageSize: pageSize,
            pageSizeOptions: [10, 20, 30, 40, 50],
            isPaginatedBE: true,
            sticky: true,
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'budget.description',
                    colonnaKey: 'descrizione',
                    flex: 20,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'budget.codice_spesa',
                    colonnaKey: 'codiceSpesa',
                    flex: 15,
                },
                {
                    tipo: TipoColonnaEnum.CHIP_USER,
                    nomeColonna: 'mobility.student',
                    colonnaKey: 'studenteUIMapped',
                    flex: 25,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'budget.type',
                    colonnaKey: 'tipologia',
                    flex: 13,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'common.year',
                    colonnaKey: 'annoRiferimento',
                    flex: 4,
                },
                {
                    tipo: TipoColonnaEnum.AMOUNT,
                    nomeColonna: 'budget.appr_amount',
                    colonnaKey: 'importoApprovato',
                    flex: 8,
                },
                {
                    tipo: TipoColonnaEnum.AMOUNT,
                    nomeColonna: 'budget.req_amount',
                    colonnaKey: 'importoRichiesto',
                    flex: 8,
                },
                {
                    tipo: TipoColonnaEnum.CHIP_STATUS,
                    nomeColonna: 'budget.state',
                    colonnaKey: 'stato',
                    flex: 20,
                    statusType: SpesaStudenteStatus,
                    sottoruoloKey: 'sottoruoloPrimoApprovatore'
                },
                {
                    nomeColonna: ' ',
                    colonnaKey: 'action',
                    flex: 9,
                    tipo: TipoColonnaEnum.ACTION_MENU,
                    valueCheck: 'l',
                    button: [
                        {
                            nameIconButton: 'account_balance_wallet',
                            click: TipoClickEnum.GO_TO,
                            tooltip: 'common.go_to_budget',
                            color: 'accent',
                            valueShow: 'stato'
                        },
                        {
                            nameIconButton: 'work_outline',
                            click: TipoClickEnum.GO_TO_MISSION,
                            tooltip: 'budget.go_to_linked_mission',
                            show: (_, spesa: SpesaStudenteInfoViewImpl) => !!spesa?.dettaglioLiquidazioneMissione?.id_missione,
                            color: 'accent',
                            valueShow: 'stato'
                        },
                        {
                            nameIconButton: 'description',
                            click: TipoClickEnum.SHOW_NOTES,
                            tooltip: 'budget.show_notes',
                            color: 'accent',
                            show: (nota?: string) => (!!nota && nota !== ''),
                            valueShow: 'nota'
                        },
                        {
                            nameIconButton: 'visibility',
                            click: TipoClickEnum.SHOW_REFUSE_REASON,
                            tooltip: 'mobility.show_refuse_reason',
                            color: 'accent',
                            show: (motivazioniRifiuto, element: SpesaStudentePerApprovMassivaInfoView) => !!motivazioniRifiuto && element.stato === SpesaStudenteStatus.RIFIUTATO,
                            valueShow: 'motivazioneRifiuto'
                        },
                        {
                            nameIconButton: 'download',
                            click: TipoClickEnum.SHOW_ALLEGATO_BUDGET_REQUEST,
                            tooltip: 'budget.show_attachment_file',
                            color: 'accent',
                            show: (urlAllegatiRichiesta, spesa: SpesaStudenteInfoViewImpl) => spesa.urlAllegatiRichiesta?.length > 0,
                            valueShow: 'urlAllegatiRichiesta'
                        },
                    ]
                }
            ],
            css: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'min-width': 'unset'
            },
            messaggioDatiAssenti: 'custom_table.no_data_budget'
        }
    };
}


export function buildConfigurationForEditBudgetRequest(modificheBudget: PageAggiornamentoBudgetStudentePerOpMassivaInfoView,
                                                  pageSize = DEFAULT_PAGE_SIZE,
                                                  pageBE: number,
                                                  isInApprovalMode = false,
                                                  righeContainer?: { idRigheSolaLettura?: string[]}): GenericTableConfigurationModel {
    return {
        configuration: {
            data: modificheBudget.content.map(value => ({
                ...value,
                studenteAndYear: annoRiferimentoToRomanNumeral(value?.annoRiferimento)
                    + ' - ' + value?.budgetStudente?.studenteCiclo?.utente?.cognome
                    + ' ' + value?.budgetStudente?.studenteCiclo?.utente?.nome,
                studenteUIMapped: [{
                    utenteNome:  value?.budgetStudente?.studenteCiclo?.utente?.nome,
                    utenteCognome:  value?.budgetStudente?.studenteCiclo?.utente?.cognome,
                    utenteMail: value?.budgetStudente?.studenteCiclo?.utente?.mail,
                    nome: value?.budgetStudente?.studenteCiclo?.utente?.nome,
                    cognome: value?.budgetStudente?.studenteCiclo?.utente?.cognome,
                    studenteCodiceFiscale: value?.budgetStudente?.studenteCiclo?.utente?.codiceFiscale,
                    codiceFiscale: value?.budgetStudente?.studenteCiclo?.utente?.codiceFiscale,
                    studenteMatricola: value?.budgetStudente?.studenteCiclo?.matricola,
                    urlImmagineProfilo: value?.budgetStudente?.studenteCiclo?.utente?.urlImmagineProfilo,
                    utenteTelefono: value?.budgetStudente?.studenteCiclo?.utente?.telefono
                }],
                annoRichiesta: annoRiferimentoToRomanNumeral(value?.annoRiferimento),
                importoAssegnato: value?.annoRiferimento === AnnoRiferimentoValues.PRIMO ?
                    value?.budgetStudente?.budgetPrimoAnno : (
                        value?.annoRiferimento === AnnoRiferimentoValues.SECONDO ?
                            value?.budgetStudente?.budgetSecondoAnno : value?.budgetStudente?.budgetTerzoAnno
                    ),
                nuovoImportoAssegnato: value?.importo,
                dataRichiestaFormatted: moment.utc(value?.dataRichiesta).local().format('DD/MM/YYYY HH:mm'),
            })),
            pageBE,
            totalElements: modificheBudget?.totalElements,
            pageSize: pageSize,
            pageSizeOptions: [10, 20, 30, 40, 50],
            isPaginatedBE: true,
            sticky: true,
            selection: isInApprovalMode ? {
                isSelectable: true,
                selectedColumnKeyToShow: 'studenteAndYear',
                selectedColumnKeyPrimaryKey: 'id',
            } : undefined,
            highlightRowWhen: (element) => righeContainer?.idRigheSolaLettura?.includes(element.id) ? 'generic-table-removed-row' : undefined,
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'mobility.request_date',
                    colonnaKey: 'dataRichiestaFormatted',
                    flex: 14,
                },
                {
                    tipo: TipoColonnaEnum.CHIP_USER,
                    nomeColonna: 'mobility.student',
                    colonnaKey: 'studenteUIMapped',
                    flex: 25,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'budget.edit_year',
                    colonnaKey: 'annoRichiesta',
                    flex: 8,
                },
                {
                    tipo: TipoColonnaEnum.AMOUNT,
                    nomeColonna: 'budget.assigned_budget',
                    colonnaKey: 'importoAssegnato',
                    flex: 11,
                },
                {
                    tipo: TipoColonnaEnum.AMOUNT,
                    nomeColonna: 'budget.new_assigned_budget',
                    colonnaKey: 'nuovoImportoAssegnato',
                    flex: 11,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'budget.motivation_edit',
                    colonnaKey: 'motivazioneModifica',
                    flex: 20,
                },
                {
                    tipo: TipoColonnaEnum.CHIP_STATUS,
                    nomeColonna: 'common.status',
                    colonnaKey: 'stato',
                    flex: 20,
                    statusType: AggiornamentoBudgetStudenteStatus,
                    hideColonna: isInApprovalMode,
                },
                {
                    nomeColonna: ' ',
                    colonnaKey: 'action',
                    flex: 8,
                    tipo: TipoColonnaEnum.ACTION_MENU,
                    valueCheck: 'l',
                    button: [
                        {
                            nameIconButton: 'account_balance_wallet',
                            click: TipoClickEnum.GO_TO,
                            tooltip: 'common.go_to_budget',
                            color: 'accent',
                            valueShow: 'stato'
                        },
                        {
                            nameIconButton: 'close',
                            click: TipoClickEnum.REFUSE_PTA,
                            tooltip: 'budget.refuse',
                            valueShow: 'stato',
                            color: 'accent',
                            hide: !isInApprovalMode
                        },
                    ]
                }
            ],
            css: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'min-width': 'unset'
            },
            messaggioDatiAssenti: 'custom_table.no_data_edit_budget'
        }
    };
}




export const SPESE_EXCEL_SHEET_CONFIG: ConfigCol[] = [
    {
        key: 'codiceSpesa',
        translocoHeader: 'budget.codice_spesa',
        width: 25
    },
    {
        key: 'descrizione',
        translocoHeader: 'budget.description',
        width: 17
    },
    {
        key: 'importoRichiesto',
        translocoHeader: 'budget.req_amount',
        width: 20,
        style: {
            numFmt: numFmtEuro
        }
    },
    {
        key: 'tipologia',
        translocoHeader: 'common.type',
        width: 30
    },
    {
        key: 'stato',
        translocoHeader: 'requests.state',
        width: 30,
    },
    {
        key: 'dataRichiesta',
        translocoHeader: 'mobility.request_date',
        width: 15,
        style: { numFmt: 'DD/MM/YYYY' }
    },
    {
        key: 'studenteNomeCompleto',
        translocoHeader: 'common.nominative',
        width: 30,
    },

    {
        key: 'annoRiferimento',
        translocoHeader: 'common.year',
        width: 15
    },
    {
        key: 'utenteMail',
        translocoHeader: 'common.email',
        width: 20
    },
    {
        key: 'studenteCodiceFiscale',
        translocoHeader: 'common.fiscal_code',
        width: 20
    },
    {
        key: 'studenteMatricola',
        translocoHeader: 'common.enrollment_number',
        width: 20
    },
    {
        key: 'nota',
        translocoHeader: 'budget.nota',
        width: 30,
    },

]

export function mapSpeseForExcel(spese: SpesaStudentePerApprovMassivaInfoView[], translation: Translation): any[] {
    return spese.map(spesa => ({
        ...spesa,
        dataRichiesta: !!spesa?.dataRichiesta ? moment(spesa?.dataRichiesta, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
        studenteNomeCompleto: spesa?.budgetStudente?.studenteCiclo?.utente?.cognome + ' ' + spesa?.budgetStudente?.studenteCiclo?.utente?.nome,
        tipologia: removeUnderscore(spesa?.tipologia),
        annoRiferimento: removeUnderscore(spesa?.annoRiferimento),
        stato: get(translation, getLanguagePathBySpesaStudenteStatus(spesa.stato)) as SpesaStudenteStatus,
        utenteMail: spesa?.budgetStudente?.studenteCiclo?.utente?.mail,
        studenteCodiceFiscale: spesa?.budgetStudente?.studenteCiclo?.codiceFiscale,
        studenteMatricola: spesa?.budgetStudente?.studenteCiclo?.matricola,
    }))
}

