/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Tipologie di operazione eseguita sull'autorizzazione di mobilità studente. Tipologie: *CONCESSIONE*, *REVOCA*
 */
export type TipoOperazioneStoricoMobilitaStudente = 'CONCESSIONE' | 'REVOCA';

export const TipoOperazioneStoricoMobilitaStudente = {
    CONCESSIONE: 'CONCESSIONE' as TipoOperazioneStoricoMobilitaStudente,
    REVOCA: 'REVOCA' as TipoOperazioneStoricoMobilitaStudente
};