/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Stati di un questionario sottomesso ad uno studente. Valori previsti: *DA_COMPLETARE*, *BOZZA*, *COMPLETATO*
 */
export type QuestionarioSottomessoStatus = 'DA_COMPLETARE' | 'BOZZA' | 'COMPLETATO';

export const QuestionarioSottomessoStatus = {
    DACOMPLETARE: 'DA_COMPLETARE' as QuestionarioSottomessoStatus,
    BOZZA: 'BOZZA' as QuestionarioSottomessoStatus,
    COMPLETATO: 'COMPLETATO' as QuestionarioSottomessoStatus
};