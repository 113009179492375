import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot,} from '@angular/router';
import {AuthorityType} from "../../../api-clients/generated/services";
import {LocalStorageService} from "../../shared/service/local-storage.service";
import {PathEnum} from "../../app.routing";
import {CicloCorsoRuoloInterface} from "../../shared/interface/CicloCorsoRuoloInterface";


@Injectable({
    providedIn: 'root'
})
export class StudentsListGuard implements CanActivate {
    /**
     * Constructor
     */
    constructor(
        private _router: Router,
        private localStorageService: LocalStorageService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const cicloCorsoRuolo = this.localStorageService.getCicloCorsoRuolo(true);
        const canActivate = StudentsListGuard.accessCondition(cicloCorsoRuolo);
        if(!canActivate) {
            this._router.navigate([PathEnum.HOME]);
        }
        return canActivate;
    }


    static accessCondition(cicloCorsoRuolo: CicloCorsoRuoloInterface): boolean {
        return cicloCorsoRuolo?.ruolo !== AuthorityType.COMMISSIONEDIAUTOVALUTAZIONE;
    }

}
