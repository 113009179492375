<ng-container *transloco="let t">
    <div class="w-full flex flex-col flex-auto w-full studenti-topbar-filter-div" *ngIf="!!getMainFormGroup()"
         #mainContainer>
        <!-- FORMS -->
        <form [formGroup]="filterManagerService.mainFormGroup"
              (ngSubmit)="applyFilters()"
              (keydown.tab)="$event.stopPropagation()"
              class="flex flex-col gap-4">


            <!-- TITLE -->
            <div style="background-color: #ffffff; z-index: 1000;" #titleDiv
                 class="flex flex-col sm:flex-row gap-3 sm:justify-between">
                <div class="mt-2 text-2xl font-medium ml-2">
                    <strong>{{t('menu_filter.advanced_search')}}</strong>
                </div>

                <div class="pt-2 pb-2 grid grid-cols-1 sm:grid-cols-2 gap-3">

                    <!-- BUTTONS-->
                    <button mat-flat-button
                            (click)="reset()"
                            type="button"
                            [color]="'accent'">
                        <mat-icon class="mr-2">delete</mat-icon>
                        <span>{{t('menu_filter.reset')}}</span>
                    </button>

                    <button mat-flat-button
                            type="submit"
                            [color]="'primary'">
                        <mat-icon class="mr-2">search</mat-icon>
                        <span>{{t('home.apply')}}</span>
                    </button>
                </div>
                <mat-divider class="sm:hidden"></mat-divider>
            </div>

            <div style="overflow: auto">
               <div class="grid grid-cols-1 grid-rows-none sm:grid-cols-2 md:grid-cols-4 gap-3">
                    <ng-container *ngFor="let field of currentFields">
                        <ng-container
                                *ngIf="(field !== _tipologiaSpesa)">
                            <mat-form-field class="w-full mat-form-field-topbar-filter" appearance="outline">
                                <mat-label>{{t(fieldsLabelMap.get(field))}}</mat-label>
                                <input matInput
                                       [formControl]="getFormControlByPath(field)"
                                       (blur)="getFormControlByPath(field).setValue(getFormControlByPath(field)?.value?.trim())">
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="field === _tipologiaSpesa">
                            <mat-form-field appearance="outline" class="mat-form-field-topbar-filter">
                                <mat-label>{{t(fieldsLabelMap.get(field))}}</mat-label>
                                <mat-select [formControl]="getFormControlByPath(field)">
                                    <mat-option [value]="null">{{t('common.all')}}</mat-option>
                                    <mat-option *ngFor="let tipologia of Object.values(SpesaStudenteType)"
                                                [value]="tipologia">
                                        {{tipologia | removeUnderscores}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>

                    </ng-container>
                </div>

            </div>

        </form>
    </div>
</ng-container>
