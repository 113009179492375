/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Stati dello studente di un ciclo.Valori previsti: *IN_CORSO(\"A\", \"Attivo\")*, *SOSPESO(\"S\", \"Sospeso\")*, *IPOTESI(\"I\", \"Ipotesi\")*, *CESSATO(\"X\", \"Cessato\")*
 */
export type StudenteCicloStatus = 'IN_CORSO' | 'SOSPESO' | 'IPOTESI' | 'CESSATO';

export const StudenteCicloStatus = {
    INCORSO: 'IN_CORSO' as StudenteCicloStatus,
    SOSPESO: 'SOSPESO' as StudenteCicloStatus,
    IPOTESI: 'IPOTESI' as StudenteCicloStatus,
    CESSATO: 'CESSATO' as StudenteCicloStatus
};