/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Tipi di spesa studente. Valori previsti: *BENE_ATTREZZATURA*, *EVENTO*, *FORMAZIONE_SPECIFICA*, *ISCRIZIONE_CONVEGNO_SEMINARIO*, *MATERIALE_CONSUMO*, *MISSIONE_ESTERO*, *MISSIONE_ITALIA*, *MOBILITA_E_RICERCA*, *SPESE_DI_PUBBLICAZIONE*, *ALTRO*
 */
export type SpesaStudenteType = 'BENE_ATTREZZATURA' | 'EVENTO' | 'FORMAZIONE_SPECIFICA' | 'ISCRIZIONE_CONVEGNO_SEMINARIO' | 'MATERIALE_CONSUMO' | 'MISSIONE_ESTERO' | 'MISSIONE_ITALIA' | 'MOBILITA_E_RICERCA' | 'SPESE_DI_PUBBLICAZIONE' | 'ALTRO';

export const SpesaStudenteType = {
    BENEATTREZZATURA: 'BENE_ATTREZZATURA' as SpesaStudenteType,
    EVENTO: 'EVENTO' as SpesaStudenteType,
    FORMAZIONESPECIFICA: 'FORMAZIONE_SPECIFICA' as SpesaStudenteType,
    ISCRIZIONECONVEGNOSEMINARIO: 'ISCRIZIONE_CONVEGNO_SEMINARIO' as SpesaStudenteType,
    MATERIALECONSUMO: 'MATERIALE_CONSUMO' as SpesaStudenteType,
    MISSIONEESTERO: 'MISSIONE_ESTERO' as SpesaStudenteType,
    MISSIONEITALIA: 'MISSIONE_ITALIA' as SpesaStudenteType,
    MOBILITAERICERCA: 'MOBILITA_E_RICERCA' as SpesaStudenteType,
    SPESEDIPUBBLICAZIONE: 'SPESE_DI_PUBBLICAZIONE' as SpesaStudenteType,
    ALTRO: 'ALTRO' as SpesaStudenteType
};