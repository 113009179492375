/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { PageDottorandoCressiWSInfoView } from '../model/pageDottorandoCressiWSInfoView';
import { StudenteCicloMotivoStatus } from '../model/studenteCicloMotivoStatus';
import { StudenteCicloStatus } from '../model/studenteCicloStatus';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DottorandiCressiWsService {

    protected basePath = 'https://api-dev-vanvitelli.southengineering.it/dottorandi-backend';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Restituisce i Dottorandi Cressi WS con i filtri applicati.
     * 
     * @param codiceCorsoDiStudiEsse3 
     * @param codiceCorsoDiStudiCineca 
     * @param numeroCicloDottorato 
     * @param nominativo 
     * @param codiceFiscale 
     * @param statiStudente 
     * @param motiviStatoStudente 
     * @param ateneoProvenienza 
     * @param nazionalita 
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc),(ignorecase)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchDottorandiCressiWSForm(codiceCorsoDiStudiEsse3?: string, codiceCorsoDiStudiCineca?: string, numeroCicloDottorato?: number, nominativo?: string, codiceFiscale?: string, statiStudente?: Array<StudenteCicloStatus>, motiviStatoStudente?: Array<StudenteCicloMotivoStatus>, ateneoProvenienza?: string, nazionalita?: string, page?: number, size?: number, sort?: string, observe?: 'body', reportProgress?: boolean): Observable<PageDottorandoCressiWSInfoView>;
    public searchDottorandiCressiWSForm(codiceCorsoDiStudiEsse3?: string, codiceCorsoDiStudiCineca?: string, numeroCicloDottorato?: number, nominativo?: string, codiceFiscale?: string, statiStudente?: Array<StudenteCicloStatus>, motiviStatoStudente?: Array<StudenteCicloMotivoStatus>, ateneoProvenienza?: string, nazionalita?: string, page?: number, size?: number, sort?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageDottorandoCressiWSInfoView>>;
    public searchDottorandiCressiWSForm(codiceCorsoDiStudiEsse3?: string, codiceCorsoDiStudiCineca?: string, numeroCicloDottorato?: number, nominativo?: string, codiceFiscale?: string, statiStudente?: Array<StudenteCicloStatus>, motiviStatoStudente?: Array<StudenteCicloMotivoStatus>, ateneoProvenienza?: string, nazionalita?: string, page?: number, size?: number, sort?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageDottorandoCressiWSInfoView>>;
    public searchDottorandiCressiWSForm(codiceCorsoDiStudiEsse3?: string, codiceCorsoDiStudiCineca?: string, numeroCicloDottorato?: number, nominativo?: string, codiceFiscale?: string, statiStudente?: Array<StudenteCicloStatus>, motiviStatoStudente?: Array<StudenteCicloMotivoStatus>, ateneoProvenienza?: string, nazionalita?: string, page?: number, size?: number, sort?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {













        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (codiceCorsoDiStudiEsse3 !== undefined) {
            formParams = formParams.append('codiceCorsoDiStudiEsse3', <any>codiceCorsoDiStudiEsse3) as any || formParams;
        }
        if (codiceCorsoDiStudiCineca !== undefined) {
            formParams = formParams.append('codiceCorsoDiStudiCineca', <any>codiceCorsoDiStudiCineca) as any || formParams;
        }
        if (numeroCicloDottorato !== undefined) {
            formParams = formParams.append('numeroCicloDottorato', <any>numeroCicloDottorato) as any || formParams;
        }
        if (nominativo !== undefined) {
            formParams = formParams.append('nominativo', <any>nominativo) as any || formParams;
        }
        if (codiceFiscale !== undefined) {
            formParams = formParams.append('codiceFiscale', <any>codiceFiscale) as any || formParams;
        }
        if (statiStudente) {
            statiStudente.forEach((element) => {
                formParams = formParams.append('statiStudente', <any>element) as any || formParams;
            })
        }
        if (motiviStatoStudente) {
            motiviStatoStudente.forEach((element) => {
                formParams = formParams.append('motiviStatoStudente', <any>element) as any || formParams;
            })
        }
        if (ateneoProvenienza !== undefined) {
            formParams = formParams.append('ateneoProvenienza', <any>ateneoProvenienza) as any || formParams;
        }
        if (nazionalita !== undefined) {
            formParams = formParams.append('nazionalita', <any>nazionalita) as any || formParams;
        }

        return this.httpClient.request<PageDottorandoCressiWSInfoView>('post',`${this.basePath}/api/v1/dottorando-cressi-ws/search`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
