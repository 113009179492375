<div class="privacy">
    <div class="corpopagina">
        <ol>
            <li><strong>
                <p style="text-align: justify;"><span >PREMESSA</span></p>
            </strong></li>
        </ol>
        <p style="text-align: justify;"><span >La piattaforma che consente la gestione delle carriere dei dottorandi iscritti ad un ciclo di dottorato presso l&rsquo;Universit&agrave; della Campania -&nbsp;</span><em><span
            >Luigi Vanvitelli</span></em><span >&nbsp;(&ldquo;piattaforma&rdquo;) raccoglie alcuni Dati Personali (&ldquo;Dati&rdquo;) dei propri utenti.</span>
        </p>
        <p style="text-align: justify;"><span >Ai sensi degli artt. 13 e 14 del Regolamento (UE) 2016/679 sulla &ldquo;</span><em><span
            >protezione delle persone fisiche con riguardo al trattamento dei dati personali</span></em><span
            >&rdquo; (di seguito anche &ldquo;GDPR&rdquo;), si forniscono le informazioni richieste sul trattamento dei Dati.</span>
        </p>
        <p style="text-align: justify;"><span >Le presenti informazioni riguardano esclusivamente l&rsquo;utilizzo della piattaforma.</span>
        </p>
        <ol start="2">
            <li><strong>
                <p style="text-align: justify;"><span >TITOLARE DEL TRATTAMENTO DEI DATI</span>
                </p>
            </strong></li>
        </ol>
        <p style="text-align: justify;"><span >Titolare del trattamento dei Dati &egrave; l&rsquo;Universit&agrave; degli studi della Campania &ndash;&nbsp;</span><em><span
            >Luigi Vanvitelli</span></em><span >&nbsp;nella persona del Magnifico Rettore</span>
        </p>
        <p style="text-align: justify;"><span >I dati di contatto del Titolare sono:</span></p>
        <p style="text-align: justify;"><span >rettorato@unicampania.it</span></p>
        <p style="text-align: justify;"><span >protocollo@pec.unicampania.it</span></p>
        <ol start="3">
            <li><strong>
                <p style="text-align: justify;"><span
                    >RESPONSABILE DEL TRATTAMENTO DEI DATI</span></p>
            </strong></li>
        </ol>
        <p style="text-align: justify;"><span >Responsabile del trattamento dei Dati &egrave; South Engineering S.r.l. in persona del l.r.p.t.</span>
        </p>
        <p style="text-align: justify;"><span >I dati di contatto del Responsabile sono:</span>
        </p>
        <p style="text-align: justify;"><a href="mailto:amministrazione@southengineering.it"><u><span
            style="color:#0000ff;">amministrazione@southengineering.it</span></u></a></p>
        <p style="text-align: justify;"><span >g.capone@pec.southengineering.it&nbsp;</span></p>
        <ol start="4">
            <li><strong>
                <p style="text-align: justify;"><span
                    >&nbsp;FINALIT&Agrave; DEL TRATTAMENTO</span></p>
            </strong></li>
        </ol>
        <p style="text-align: justify;"><span >I Dati dell&rsquo;utente sono raccolti per consentire al Titolare e al Responsabile del trattamento di fornire il servizio di &ldquo;gestione delle carriere dottorandi&rdquo;, adempiere agli obblighi di legge, rispondere a richieste o azioni esecutive, tutelare i propri diritti ed interessi (o quelli di utenti o di terze parti), individuare eventuali attivit&agrave; dolose o fraudolente, nonch&eacute; per le seguenti finalit&agrave;:</span><span
            >&nbsp;</span><span
            >salvataggio e gestione di&nbsp;</span><em><span
            >backup</span></em><span >&nbsp;ed</span><em><span
            >&nbsp;assistenza tecnica.</span></em><span
            >&nbsp; &nbsp; &nbsp;</span><span >I Dati sono raccolti per le seguenti finalit&agrave; ed utilizzando i seguenti servizi:</span>
        </p>
        <p style="text-align: justify;"><strong><span >Autenticazione fornite direttamente dalla Piattaforma</span></strong>
        </p>
        <p style="text-align: justify;"><span >Con l&rsquo;autenticazione l&rsquo;utente consente alla piattaforma di identificarlo e di dargli accesso ai servizi dedicati. I Dati sono raccolti e conservati esclusivamente allo scopo di registrare/identificare l&rsquo;utente e gestire la sua posizione in ordine alla carriera &ldquo;dottorato di ricerca&rdquo;. I Dati raccolti sono solo quelli necessari a fornire il servizio.</span>
        </p>
        <p style="text-align: justify;"><strong><span
            >Salvataggio e gestione di&nbsp;</span></strong><strong><em><span
            >backup</span></em></strong>
        </p>
        <p style="text-align: justify;"><span >Questo tipo di servizi permette il salvataggio e la gestione dei&nbsp;</span><em><span
            >backup</span></em><span
            >&nbsp;della piattaforma su&nbsp;</span><em><span
            >server</span></em><span >&nbsp;esterni gestiti dal fornitore del servizio stesso. Questi&nbsp;</span><em><span
            >backup</span></em><span >&nbsp;possono comprendere sia il codice sorgente ed i contenuti dello stesso che i dati forniti alla piattaforma da parte dell&rsquo;utente.</span>
        </p>
        <p style="text-align: justify;"><strong><span >Backup su Amazon RDS</span></strong><span
            >&nbsp; &nbsp; &nbsp;</span><strong><span
            >&nbsp;(Amazon RDS)</span></strong></p>
        <p style="text-align: justify;"><span >&nbsp; &nbsp; &nbsp;</span><span
            >RDS &egrave; un servizio di salvataggio e gestione di&nbsp;</span><em><span
            >backup</span></em><span
            >&nbsp;fornito da Amazon.&nbsp;</span></p>
        <p style="text-align: justify;"><span >Luogo del trattamento: Italia</span></p>
        <p style="text-align: justify;"><strong><span >LocalStorage</span></strong></p>
        <p style="text-align: justify;"><span >LocalStorage permette alla piattaforma di memorizzare e accedere ai dati direttamente dal&nbsp;</span><em><span
            >browser</span></em><span >&nbsp;dell&rsquo;utente senza una data di scadenza.</span>
        </p>
        <ol start="5">
            <li><strong>
                <p style="text-align: justify;"><span >DESTINATARI DEI DATI</span></p>
            </strong></li>
        </ol>
        <p style="text-align: justify;"><span >I Dati raccolti sono conservati presso il DataCenter di Amazon e sono trattati dal personale della Societ&agrave; che agisce sulla base di specifiche istruzioni fornite in ordine a finalit&agrave; e modalit&agrave; del trattamento medesimo.</span>
        </p>
        <ol start="6">
            <li><strong>
                <p style="text-align: justify;"><span >BASE GIURIDICA DEL TRATTAMENTO</span></p>
            </strong></li>
        </ol>
        <p style="text-align: justify;"><span >I Dati sono trattati dal Titolare del trattamento nell&rsquo;esecuzione delle attivit&agrave; dei propri compiti di interesse pubblico o comunque connessi all&rsquo;esercizio dei propri pubblici poteri e dal Responsabile del trattamento nell&rsquo;esecuzione delle attivit&agrave; relative alla gestione della piattaforma.</span>
        </p>
        <p style="text-align: justify;"><span >Il Titolare e il Responsabile trattano i Dati relativi all&rsquo;utente in caso sussista una delle seguenti condizioni:</span>
        </p>
        <p style="text-align: justify;"><span >l&rsquo;utente ha prestato il consenso per una o pi&ugrave; finalit&agrave; specifiche;</span>
        </p>
        <p style="text-align: justify;"><span >il trattamento &egrave; necessario per adempiere un obbligo legale al quale sono soggetti il Titolare e il Responsabile del trattamento;</span>
        </p>
        <p style="text-align: justify;"><span >il trattamento &egrave; necessario per l&rsquo;esecuzione di un compito di interesse pubblico o per l&rsquo;esercizio di pubblici poteri di cui &egrave; investito il Titolare;</span>
        </p>
        <p style="text-align: justify;"><span >il trattamento &egrave; necessario per il perseguimento del legittimo interesse del Titolare e/o del Responsabile del trattamento.</span>
        </p>
        <p style="text-align: justify;"><span >&Egrave; comunque sempre possibile richiedere al Titolare e al Responsabile del trattamento di chiarire la concreta base giuridica di ciascun trattamento ed in particolare di specificare se il trattamento sia basato sulla legge, previsto da un contratto o necessario per concludere un contratto.</span>
        </p>
        <ol start="7">
            <li><strong>
                <p style="text-align: justify;"><span >NATURA DEL CONFERIMENTO DEI DATI</span>
                </p>
            </strong></li>
        </ol>
        <p style="text-align: justify;"><strong><span >Tipologie di Dati raccolti</span></strong>
        </p>
        <p style="text-align: justify;"><span >Fra i Dati raccolti dalla piattaforma, in modo autonomo o tramite terze parti, ci sono: nome; cognome; numero di telefono; indirizzo e-mail; dati di utilizzo; codice fiscale, fotografia, risposte alle domande</span><span
            >.</span></p>
        <p style="text-align: justify;"><span >I dati personali possono essere liberamente forniti dall&rsquo;utente o, nel caso di dati di utilizzo, raccolti automaticamente durante l&rsquo;uso di questa piattaforma.</span>
        </p>
        <p style="text-align: justify;"><span >Se non diversamente specificato, tutti i dati richiesti dalla piattaforma sono obbligatori per garantire il suo pieno funzionamento. Se l&rsquo;utente rifiutasse di comunicarli, potrebbe essere impossibile per la piattaforma fornire il servizio.</span>
        </p>
        <p style="text-align: justify;"><span >Nei casi in cui questa piattaforma indichi alcuni dati come facoltativi, gli utenti sono liberi di astenersi dal comunicarli, senza che ci&ograve; abbia alcuna conseguenza sulla disponibilit&agrave; del servizio o sulla sua operativit&agrave;.</span>
        </p>
        <p style="text-align: justify;"><span >Gli utenti che dovessero avere dubbi su quali dati siano obbligatori sono incoraggiati a contattare il Titolare o il Responsabile del trattamento.</span>
        </p>
        <p style="text-align: justify;"><span
            >Questa piattaforma non utilizza&nbsp;</span><em><span
            >cookie</span></em><span
            >&nbsp;o di altri strumenti di tracciamento.</span></p>
        <p style="text-align: justify;"><span >L&rsquo;utente con &ldquo;privilegi maggiori&rdquo; si assume la responsabilit&agrave; dei dati personali di terzi ottenuti, pubblicati o condivisi mediante questa piattaforma e garantisce di avere il diritto di comunicarli o diffonderli, liberando il Titolare e il Responsabile da qualsiasi responsabilit&agrave; verso terzi.</span>
        </p>
        <p style="text-align: justify;"><strong><span
            >Modalit&agrave; di trattamento</span></strong></p>
        <p style="text-align: justify;"><span >Il Titolare e il Responsabile del trattamento adottano le opportune misure di sicurezza volte ad impedire l&rsquo;accesso, la divulgazione, la modifica o la distruzione non autorizzata dei dati personali dalla piattaforma.</span>
        </p>
        <p style="text-align: justify;"><span >Il trattamento viene effettuato mediante strumenti informatici e/o telematici, con modalit&agrave; organizzative e con logiche strettamente correlate alle finalit&agrave; indicate. Oltre al Titolare e al Responsabile del trattamento hanno accesso ai Dati i soggetti coinvolti nella gestione/manutenzione della piattaforma (es. personale amministrativo, commerciale, legali, amministratori di sistema).</span>
        </p>
        <p style="text-align: justify;"><strong><span >Luogo</span></strong></p>
        <p style="text-align: justify;"><span >I Dati raccolti tramite la piattaforma sono trattati presso le sedi operative del Titolare e del Responsabile del trattamento e in ogni altro luogo in cui le parti coinvolte nel trattamento siano localizzate.</span>
        </p>
        <p style="text-align: justify;"><span >I Dati Personali dell&rsquo;utente non sono trasferiti fuori dall&rsquo;U.E.</span>
        </p>
        <p style="text-align: justify;"><span >Per ottenere ulteriori informazioni sul luogo del trattamento dei dati l&rsquo;utente pu&ograve; contattare il Titolare o il Responsabile del trattamento.</span>
        </p>
        <ol start="8">
            <li><strong>
                <p style="text-align: justify;"><span >PERIODO DI CONSERVAZIONE DEI DATI</span>
                </p>
            </strong></li>
        </ol>
        <p style="text-align: justify;"><span >Il Titolare e il Responsabile del trattamento trattano i Dati per il tempo strettamente necessario al perseguimento delle finalit&agrave; di cui sopra, fatti salvi gli eventuali termini di conservazione previsti da norme di legge o regolamenti.</span>
        </p>
        <p style="text-align: justify;"><span >I Dati raccolti per finalit&agrave; riconducibili all&rsquo;interesse legittimo del Titolare o del Responsabile del trattamento saranno trattenuti sino al soddisfacimento di tale interesse.</span>
        </p>
        <p style="text-align: justify;"><span >Quando il trattamento &egrave; basato sul consenso dell&rsquo;utente, il Titolare e il Responsabile potranno conservare i Dati Personali pi&ugrave; a lungo, sino a quando detto consenso non venga revocato. Inoltre, il Titolare e il Responsabile potrebbero essere obbligati a conservare i Dati per un periodo pi&ugrave; lungo in ottemperanza ad un obbligo di legge o per ordine di un&rsquo;autorit&agrave;.</span>
        </p>
        <p style="text-align: justify;"><span >Al termine del periodo di conservazione i Dati saranno cancellati. Pertanto, allo spirare di tale termine il diritto di accesso, cancellazione, rettificazione ed il diritto alla portabilit&agrave; dei Dati non potranno pi&ugrave; essere esercitati.</span>
        </p>
        <ol start="9">
            <li><strong>
                <p style="text-align: justify;"><span >DIRITTI DEGLI INTERESSATI</span></p>
            </strong></li>
        </ol>
        <p style="text-align: justify;"><span >L&rsquo; interessato ha il diritto di:</span></p>
        <ul>
            <li style="list-style-type:disc;">
                <p style="text-align: justify;"><span >Chiedere al Titolare o al Responsabile l&rsquo;accesso ai Dati, la loro cancellazione, la rettifica dei Dati inesatti, l&rsquo;integrazione dei Dati incompleti, nonch&eacute; la limitazione del trattamento nei casi previsti dall&rsquo;art. 18 del GDPR;</span>
                </p>
            </li>
            <li style="list-style-type:disc;">
                <p style="text-align: justify;"><span >Opporsi, in qualsiasi momento, in tutto od in parte, al trattamento dei propri Dati, salvo quanto previsto con riguardo alla necessit&agrave; ed obbligatoriet&agrave; del trattamento dati per poter fruire dei servizi offerti;</span>
                </p>
            </li>
            <li style="list-style-type:disc;">
                <p style="text-align: justify;"><span >Nel caso in cui siano presenti le condizioni per l&rsquo;esercizio del diritto alla portabilit&agrave; di cui all&rsquo;art. 20 del GDPR, ricevere in un formato strutturato, di uso comune e leggibile da dispositivo automatico i Dati forniti al Titolare, nonch&eacute;, se tecnicamente fattibile, trasmetterli ad altro Titolare senza impedimenti;</span>
                </p>
            </li>
            <li style="list-style-type:disc;">
                <p style="text-align: justify;"><span >Revocare il consenso prestato in qualsiasi momento, senza che ci&ograve; pregiudichi la liceit&agrave; del trattamento basata sul consenso prestato prima della revoca;</span>
                </p>
            </li>
            <li style="list-style-type:disc;">
                <p style="text-align: justify;"><span >Proporre reclamo ai sensi dell&rsquo;art. 77 GDPR all&rsquo;autorit&agrave; di controllo della protezione dei dati personali competente o agire in sede giudiziale se il trattamento dei dati personali effettuato attraverso la piattaforma avvenga in violazione di quanto previsto dal Regolamento (UE) 2016/679.</span>
                </p>
            </li>
        </ul>
        <p style="text-align: justify;"><span >Per l&rsquo;esercizio dei diritti di tutela dei propri dati personali, l&rsquo;interessato pu&ograve; rivolgersi al Titolare e al Responsabile del trattamento, utilizzando i contatti indicati.</span>
        </p>
        <p style="text-align: justify;"><strong><span
            >Dettagli sul diritto di opposizione</span></strong></p>
        <p style="text-align: justify;"><span >Quando i Dati sono trattati nell&rsquo;interesse pubblico, nell&rsquo;esercizio di pubblici poteri di cui &egrave; investito il Titolare oppure per perseguire un interesse legittimo del Titolare, gli utenti hanno diritto ad opporsi al trattamento per motivi connessi alla loro situazione particolare.</span>
        </p>
        <p style="text-align: justify;"><span >Si fa presente agli utenti che, ove i loro Dati fossero trattati con finalit&agrave; di&nbsp;</span><em><span
            >marketing</span></em><span >&nbsp;diretto, possono opporsi al trattamento senza fornire alcuna motivazione. Per scoprire se il Titolare o il Responsabile del trattamento trattino Dati con finalit&agrave; di&nbsp;</span><em><span
            >marketing</span></em><span >&nbsp;diretto, gli utenti possono rivolgersi al Titolare e al Responsabile del trattamento, utilizzando i contatti indicati.</span>
        </p>
        <p style="text-align: justify;"><strong><span >Difesa in giudizio</span></strong></p>
        <p style="text-align: justify;"><span >I Dati Personali dell&rsquo;Utente possono essere utilizzati da parte del Titolare e dal Responsabile del trattamento in giudizio o nelle fasi preparatorie alla sua eventuale instaurazione per la difesa da abusi nell&rsquo;utilizzo della Piattaforma. L&rsquo;utente dichiara di essere consapevole che il Titolare o il Responsabile del trattamento potrebbero essere obbligati a rivelare i Dati per ordine delle autorit&agrave; pubbliche.</span>
        </p>
        <p style="text-align: justify;"><strong><em><span >Log</span></em></strong><strong><span
            >&nbsp;di sistema e manutenzione</span></strong></p>
        <p style="text-align: justify;"><span >Per necessit&agrave; legate al funzionamento ed alla manutenzione, questa Piattaforma e gli eventuali servizi terzi da essa utilizzati potrebbero raccogliere&nbsp;</span><em><span
            >log</span></em><span
            >&nbsp;di sistema, ossia file&nbsp;</span><em><span
            >che</span></em><span >&nbsp;registrano le interazioni e che possono contenere anche Dati Personali, quali l&rsquo;indirizzo IP Utente.</span>
        </p>
        <p style="text-align: justify;"><strong><span
            >Informazioni non contenute in questa&nbsp;</span></strong><strong><em><span
            >privacy</span></em></strong><strong><span
            >&nbsp;</span></strong><strong><em><span
            >policy</span></em></strong>
        </p>
        <p style="text-align: justify;"><span >Ulteriori informazioni in relazione al trattamento dei Dati Personali nell&rsquo;utilizzo della piattaforma potranno essere richieste in qualsiasi momento al Titolare e al Responsabile del trattamento utilizzando gli estremi di contatto.</span>
        </p>
        <p style="text-align: justify;"><strong><span
            >Risposta alle richieste &ldquo;</span></strong><strong><em><span
            >Do Not Track</span></em></strong><strong><span
            >&rdquo;</span></strong></p>
        <p style="text-align: justify;"><span
            >La piattaforma non supporta le richieste &ldquo;</span><em><span
            >Do Not Track</span></em><span
            >&rdquo; in quanto l&rsquo;uso della piattaforma da parte dell&rsquo;utente non &egrave; oggetto di monitoraggio.</span>
        </p>
        <p style="text-align: justify;"><span >La piattaforma non utilizza servizi offerti da terze parti, in particolare non si serve di soluzioni come Google Analytics&nbsp;</span><em><span
            >et similia</span></em><span >.</span></p>
        <p><br></p>
        <ol start="10">
            <li><strong>
                <p style="text-align: justify;"><span
                    >MODIFICHE ALLA PRESENTE&nbsp;</span><em><span
                    >PRIVACY POLICY</span></em></p>
            </strong></li>
        </ol>
        <p style="text-align: justify;"><span >Il Titolare del Trattamento e il Responsabile del trattamento si riservano il diritto di apportare modifiche alla presente&nbsp;</span><em><span
            >privacy policy</span></em><span >. Le novit&agrave; apportate alla&nbsp;</span><em><span
            >privacy policy&nbsp;</span></em><span >devono essere di volta in volta accettate dall&rsquo;utente per poter utilizzare la piattaforma.</span><span
            style="font-size:10pt;">&nbsp;</span><span >Qualora le modifiche interessino trattamenti la cui base giuridica &egrave; il consenso, il Titolare e il Responsabile del trattamento provvederanno a raccogliere nuovamente il consenso dell&rsquo;utente, se necessario.</span>
        </p>

    </div>
</div>
