import {Component, Inject, Input, OnInit, Optional} from '@angular/core';
import {
    RichiestaDiRetribuzioneMobilitaInfoView
} from "../../../../api-clients/generated/services";
import {INFO_BOTTOM_COMPONENT_DATA} from "../../../layout/common/info-dialog/info-wrap.component";


@Component({
  selector: 'app-mobilita-validazione-dettaglio',
  templateUrl: './mobilita-validazione-dettaglio.component.html',
  styleUrls: ['./mobilita-validazione-dettaglio.component.scss']
})
export class MobilitaValidazioneDettaglioComponent implements OnInit {
    @Input() richiestaRetribuzione: RichiestaDiRetribuzioneMobilitaInfoView;
    @Input() anteprimaMode: boolean;

    constructor(@Optional() @Inject(INFO_BOTTOM_COMPONENT_DATA) public injectedRichiestaRetribuzione: RichiestaDiRetribuzioneMobilitaInfoView) {
        this.richiestaRetribuzione = this.richiestaRetribuzione || this.injectedRichiestaRetribuzione;
        console.log(this.injectedRichiestaRetribuzione)
    }

    ngOnInit(): void {

    }

}
