/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Tipi di operazione legati allo sblocco dei piani formativi. Valori previsti: *SBLOCCO_PRIMA_PRESENTAZIONE*, *REVOCA_SBLOCCO_PRIMA_PRESENTAZIONE*, *SBLOCCO_MODIFICA*, *REVOCA_SBLOCCO_MODIFICA*, *SBLOCCO_SOTTOMISSIONE_PIANO_NON_VALIDO*, *REVOCA_SBLOCCO_SOTTOMISSIONE_PIANO_NON_VALIDO*
 */
export type TipoOperazioneSbloccoPiano = 'SBLOCCO_PRIMA_PRESENTAZIONE' | 'REVOCA_SBLOCCO_PRIMA_PRESENTAZIONE' | 'SBLOCCO_MODIFICA' | 'REVOCA_SBLOCCO_MODIFICA' | 'SBLOCCO_SOTTOMISSIONE_PIANO_NON_VALIDO' | 'REVOCA_SBLOCCO_SOTTOMISSIONE_PIANO_NON_VALIDO';

export const TipoOperazioneSbloccoPiano = {
    SBLOCCOPRIMAPRESENTAZIONE: 'SBLOCCO_PRIMA_PRESENTAZIONE' as TipoOperazioneSbloccoPiano,
    REVOCASBLOCCOPRIMAPRESENTAZIONE: 'REVOCA_SBLOCCO_PRIMA_PRESENTAZIONE' as TipoOperazioneSbloccoPiano,
    SBLOCCOMODIFICA: 'SBLOCCO_MODIFICA' as TipoOperazioneSbloccoPiano,
    REVOCASBLOCCOMODIFICA: 'REVOCA_SBLOCCO_MODIFICA' as TipoOperazioneSbloccoPiano,
    SBLOCCOSOTTOMISSIONEPIANONONVALIDO: 'SBLOCCO_SOTTOMISSIONE_PIANO_NON_VALIDO' as TipoOperazioneSbloccoPiano,
    REVOCASBLOCCOSOTTOMISSIONEPIANONONVALIDO: 'REVOCA_SBLOCCO_SOTTOMISSIONE_PIANO_NON_VALIDO' as TipoOperazioneSbloccoPiano
};