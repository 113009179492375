<ng-container *transloco="let t">

    <!-- Button -->
    <button
        mat-icon-button
        [matTooltip]="t('common.support_section')"
        [matMenuTriggerFor]="userActions">
        <mat-icon>support</mat-icon>
    </button>

    <mat-menu

        [xPosition]="'before'"
        #userActions="matMenu"
        class="menu-background mat-menu-user">

        <ng-container *ngIf="!fuseConfirmationService.errorDuringAppInit">
            <button class="color-white" mat-menu-item (click)="goToUVDeskPage()">
                <mat-icon class="color-white" [svgIcon]="'heroicons_outline:question-mark-circle'" ></mat-icon>
                <span>{{t('common.support')}}</span>
            </button>
            <mat-divider class="my-2"></mat-divider>
            <div class="flex flex-col mb-2 min-w-60">
                <div *ngIf="loadingManuals" class="flex flex-col gap-1 items-center w-full p-2 justify-center pt-3">
                    <mat-progress-spinner
                        class="white-spinner"
                        [diameter]="20"
                        [mode]="'indeterminate'">
                    </mat-progress-spinner>
                    <span class="text-sm color-white">{{t('common.loading_manuals')}}</span>
                </div>
                <div *ngIf="errorLoadingManuals" class="flex flex-col gap-1 items-center w-full p-2 justify-center pt-3">
                    <mat-icon class="icon-size-6 text-red-600" [svgIcon]="'heroicons_outline:exclamation-circle'" ></mat-icon>
                    <span class="color-white text-sm">{{t('common.error_loading_manuals')}}</span>
                </div>
                <ng-container *ngFor="let manuale of manuali">
                    <button class="color-white" mat-menu-item (click)="openManualeRequest(manuale)">
                        <mat-icon class="color-white" [svgIcon]="'heroicons_outline:book-open'" ></mat-icon>
                        <span>{{manuale.nome}}</span>
                    </button>
                </ng-container>
            </div>
        </ng-container>
    </mat-menu>

</ng-container>
