import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {get, groupBy, head, isEqual, sortBy} from 'lodash';
import {TranslocoService} from '@ngneat/transloco';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {
    AuthorityType,
    CicloAbilitatoViewImpl,
    CorsoDiStudiViewImpl
} from '../../../../../../api-clients/generated/services';
import {AppInitService} from '../../../../../shared/service/app-init.service';
import {LocalStorageService} from '../../../../../shared/service/local-storage.service';
import {distinctUntilChanged, takeUntil} from 'rxjs';
import {CicloCorsoRuoloInterface} from '../../../../../shared/interface/CicloCorsoRuoloInterface';
import {uniqBy} from 'lodash-es';

import {
    AbstractDefaultComponent
} from '../../../../../shared/abstracts/abstract-default-component/abstract-default-component';
import {GenericComponentDialogConfig} from '../../../../common/generic-components/generic-components.interface';
import {PathEnum} from "../../../../../app.routing";
import {Router} from "@angular/router";
import {SnackbarTypes} from "../../../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {LogoutService} from "../../../../../shared/service/logout.service";

interface Dictionary<T> {
    [Key: string]: T;
}

@Component({
  selector: 'app-dialog-set-ciclo-corso-ruolo',
  templateUrl: './dialog-set-ciclo-corso-ruolo.component.html',
  styleUrls: ['./dialog-set-ciclo-corso-ruolo.component.scss']
})
export class DialogSetCicloCorsoRuoloComponent extends AbstractDefaultComponent implements OnInit {
    formGroup: FormGroup;
    ruoli: string[] = [];
    corsoStudi: CorsoDiStudiViewImpl[] = [];
    listaCicliCorsiRuoli: CicloAbilitatoViewImpl[] = [];
    listaCicliCorsiRuoliGroupByCiclo: Dictionary<CicloAbilitatoViewImpl[]>;
    dialogButtonConfirm: string;
    dialogButtonCancel: string;
    showAnnullaButton: boolean;
    isAmministratore: boolean;


    constructor(private appInitService: AppInitService,
                private fb: FormBuilder,
                private localStorageService: LocalStorageService,
                private _translocoService: TranslocoService,
                private dialog: MatDialog,
                @Inject(MAT_DIALOG_DATA) public data: GenericComponentDialogConfig,
                private router: Router,
                public dialogRef: MatDialogRef<DialogSetCicloCorsoRuoloComponent>,
                private fuseConfirmationService: FuseConfirmationService,
                private logoutService: LogoutService
    ) {
        super();
        this.appInitService.listaRuoliCodiciDipartimentiObs.pipe(takeUntil(this.destroy$)).subscribe((val) => {
            this.listaCicliCorsiRuoli = val;
            this.listaCicliCorsiRuoliGroupByCiclo = groupBy(this.listaCicliCorsiRuoli, 'numeroCiclo');
            this.isAmministratore =     this.appInitService.isAmministratore;
            this.createForm();
        });
        this._translocoService.selectTranslate('common.save').subscribe(value => this.dialogButtonConfirm = value);
        this._translocoService.selectTranslate('common.close').subscribe(value => this.dialogButtonCancel = value);
    }

    ngOnInit(): void {

    }

    get corsoStudiFormControl(): FormControl {
        return this.formGroup?.get('corsoStudi') as FormControl;
    }

    get ruoloFormControl(): FormControl {
        return this.formGroup?.get('ruolo') as FormControl;
    }

    get cicloFormControl(): FormControl {
        return this.formGroup?.get('ciclo') as FormControl;
    }


    createForm(): void {
        const cicloCorsoRuoloInStorage = this.localStorageService.getCicloCorsoRuolo();
        this.formGroup = this.fb.group({
            ciclo: [undefined , Validators.required],
            corsoStudi: [undefined, Validators.required],
            ruolo: [undefined, Validators.required]
        });
        this.formGroup.get('corsoStudi').disable();
        this.formGroup.get('ruolo').disable();

        this.valueChange();
        if (!!cicloCorsoRuoloInStorage?.codiceCorsoStudi && !!cicloCorsoRuoloInStorage?.ruolo && !!cicloCorsoRuoloInStorage.ciclo) {
            this.setCicloCorsoRuoloFromStorage(cicloCorsoRuoloInStorage);
        } else if (Object.keys(this.listaCicliCorsiRuoliGroupByCiclo)?.length === 1) {
            this.setCicloCorsoRuoloUnica();
        } else {
            this.formGroup.get('ruolo').disable();
            this.formGroup.get('corsoStudi').disable();
        }
    }


    applica(): void {
        const denominazioneCorsoStudiByCode= this.listaCicliCorsiRuoliGroupByCiclo[this.cicloFormControl.value]?.find(el => el.ruoloUtenteCorsoDiStudi?.corsoDiStudi?.codiceEsse3 === this.corsoStudiFormControl.value)?.ruoloUtenteCorsoDiStudi?.corsoDiStudi?.cicliCorsiDiStudi[0]?.denominazione;
        console.log(denominazioneCorsoStudiByCode)
        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            return;
        }
        this.localStorageService.resetSottoruolo();
        const dipartimentoRuoloCiclo: CicloCorsoRuoloInterface = {
            codiceCorsoStudi: this.corsoStudiFormControl.value,
            ruolo: this.ruoloFormControl.value,
            ciclo: this.cicloFormControl.value,
            sottoruolo: undefined,
            denominazioneCorsoStudi: denominazioneCorsoStudiByCode,
            codiceCorsoStudiCineca: this.listaCicliCorsiRuoliGroupByCiclo?.[this.cicloFormControl.value]
                ?.find(el => el.ruoloUtenteCorsoDiStudi?.corsoDiStudi?.codiceEsse3 === this.corsoStudiFormControl.value)
                ?.ruoloUtenteCorsoDiStudi?.corsoDiStudi?.codiceCineca
        };

        this.appInitService.cicloCorsoRuoloForSottoruoliRequest$.next(dipartimentoRuoloCiclo);
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);

        if(dipartimentoRuoloCiclo?.ruolo === AuthorityType.DOCENTE || dipartimentoRuoloCiclo?.ruolo === AuthorityType.PERSONALEPTA || dipartimentoRuoloCiclo?.ruolo === AuthorityType.ESTERNO) {
            console.log('dialog select ciclocorsoruolo get sottoruoli')
            this.appInitService.getSottoruoliUtente$().subscribe({
                next: () => {
                    this.dialog.closeAll();
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'common.operation_success', null),
                        type: SnackbarTypes.Success,
                    });
                    this.localStorageService.setDipartimentoRuoloCiclo(dipartimentoRuoloCiclo);
                    this.appInitService.cicloCorsoRuoloSelected$.next(dipartimentoRuoloCiclo);
                    this.appInitService.changedRoleByDialog$.next(true);
                    this.router.navigate([PathEnum.HOME]);
                },
                error: () => {
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'error.generic', null),
                        type: SnackbarTypes.Error,
                    });
                }
            });
        } else if(dipartimentoRuoloCiclo?.ruolo === AuthorityType.STUDENTE){
            this.appInitService.goToStudentDetailsPage();
            this.dialog.closeAll();
            this.localStorageService.setDipartimentoRuoloCiclo(dipartimentoRuoloCiclo);
            this.appInitService.cicloCorsoRuoloSelected$.next(dipartimentoRuoloCiclo);
            this.fuseConfirmationService.openSnackBar({
                message: get(translation, 'common.operation_success', null),
                type: SnackbarTypes.Success,
            });
        } else {
            this.router.navigate([PathEnum.HOME]);
            this.localStorageService.setDipartimentoRuoloCiclo(dipartimentoRuoloCiclo);
            this.appInitService.cicloCorsoRuoloSelected$.next(dipartimentoRuoloCiclo);
            this.dialog.closeAll();
            this.appInitService.changedRoleByDialog$.next(true);
            this.fuseConfirmationService.openSnackBar({
                message: get(translation, 'common.operation_success', null),
                type: SnackbarTypes.Success,
            });
        }

    }


    private valueChange(): void {
        this.cicloFormControl.valueChanges.pipe(
            distinctUntilChanged(),
            takeUntil(this.destroy$)
        ).subscribe((val) => {
            this.corsoStudi = sortBy(uniqBy(this.listaCicliCorsiRuoliGroupByCiclo[val]?.map(el => el.ruoloUtenteCorsoDiStudi?.corsoDiStudi), 'codiceEsse3'), 'codiceEsse3');
            if (!!this.corsoStudi?.length) {
                this.formGroup.get('corsoStudi').enable();
            }
            if (!this.corsoStudi.some(corso => corso.codiceEsse3 === this.corsoStudiFormControl.value)) {
                this.corsoStudiFormControl.reset();
                this.ruoloFormControl.reset();
            }
            else{
                this.corsoStudiFormControl.setValue( this.corsoStudiFormControl.value);
            }
        });

        this.corsoStudiFormControl.valueChanges.pipe(
            takeUntil(this.destroy$)
        ).subscribe((val) => {
            this.ruoli = this.listaCicliCorsiRuoliGroupByCiclo[this.formGroup.get('ciclo').value]?.filter(el => (el.ruoloUtenteCorsoDiStudi?.corsoDiStudi?.codiceEsse3 === val)).map((el => el.ruoloUtenteCorsoDiStudi?.ruolo));
            if (!!this.ruoli?.length) {
                this.formGroup.get('ruolo').enable();
            }
            if (!this.ruoli?.includes(this.ruoloFormControl.value)) {
                this.ruoloFormControl.reset();
            }
        });
    }

    setCicloCorsoRuoloFromStorage(cicloCorsoRuoloInStorage: CicloCorsoRuoloInterface){
        this.cicloFormControl.setValue(cicloCorsoRuoloInStorage?.ciclo);
        this.corsoStudiFormControl.setValue(cicloCorsoRuoloInStorage?.codiceCorsoStudi);
        this.ruoloFormControl.setValue(cicloCorsoRuoloInStorage?.ruolo);
        this.showAnnullaButton= true;
        if(cicloCorsoRuoloInStorage?.ruolo === AuthorityType.STUDENTE){
            this.appInitService.goToStudentDetailsPage();
        }
    }

    setCicloCorsoRuoloUnica(): void{
        const ciclo = head(Object.keys(this.listaCicliCorsiRuoliGroupByCiclo));
        this.cicloFormControl.setValue(ciclo);
        const codici = uniqBy(this.listaCicliCorsiRuoliGroupByCiclo[ciclo]?.map(el => el.ruoloUtenteCorsoDiStudi?.corsoDiStudi), 'codiceEsse3');
        const ruoli = this.listaCicliCorsiRuoliGroupByCiclo[this.formGroup.get('ciclo').value]?.filter(el => (el.ruoloUtenteCorsoDiStudi?.corsoDiStudi?.codiceEsse3 === head(codici)?.codiceEsse3)).map((el => el.ruoloUtenteCorsoDiStudi?.ruolo));
        if (codici.length === 1) {
            this.corsoStudiFormControl.setValue(head(codici)?.codiceEsse3);
            if (ruoli.length === 1) {
                this.ruoloFormControl.setValue(head(ruoli));
                this.applica();
                this.showAnnullaButton= true;
            }
        }
    }


    logout() {
        this.logoutService.logout();
    }

    get checkModificaForm(): boolean {
        const formValue= this.formGroup?.getRawValue();
        const serviceValue= this.appInitService.cicloCorsoRuoloSelected;
        if(!serviceValue){
            return false;
        }
        return isEqual(formValue,{ciclo:serviceValue.ciclo, corsoStudi: serviceValue.codiceCorsoStudi, ruolo: serviceValue.ruolo });
    }


}
