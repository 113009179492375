import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Pipe, ViewChild} from '@angular/core';
import {FormArray, FormControl} from "@angular/forms";
import {get, isNil} from "lodash";
import {TranslocoService} from "@ngneat/transloco";
import {FuseConfirmationService} from "../../../../@fuse/services/confirmation";
import {optionFile} from "../../costants/app-constants";

@Component({
    selector: 'app-document',
    templateUrl: './document.component.html',
    styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit {
    @Input() label: string;
    @Input() labelNoTranslate: string;
    @Input() form: FormArray;
    @Input() verificaId: boolean;
    @Input() idCampo: string;
    @Input() hideDeleteButton: boolean;
    @Input() customFileLabel: boolean;
    @Input() numeroMinimoDocumenti?: number;
    @Input() numeroMassimoDocumenti?: number;

    @Output() downloadEmitter = new EventEmitter<string>();
    @Output() saveBeforeUpload = new EventEmitter<void>();
    @ViewChild('inputFile') inputRef: ElementRef;
    @Input() sizeByte = optionFile.sizeMb * 1000000;
    // files showed in the window
    @Input() listFileAccepted = ['pdf', 'gz', 'rar', 'tar', 'gz', 'tgz', 'zip', '7z']
    // files excluded
    @Input() listFileExcluded = ['exe', 'bat', 'sh', 'com', 'wsh', 'run', 'osx']
    acceptedFileList: string;
    @Input() customNotSupportedFileMessageLabel: string;
    @Input() forceAcceptedFiles: boolean;
    @Input() pipe?: Pipe;

    constructor(private _translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService) {
    }

    ngOnInit(): void {
        console.log('FORM INPUT FILE', this.form);
        this.acceptedFileList = this.listFileAccepted.map(extension => '.' + extension).join(', ');
    }


    onFileSelected(event: any, index?: number) {
        if (event?.target?.files?.length === 0) {
            return;
        }
        const fileInput = event?.target?.files[0];
        const fileInputType = fileInput.name.substring(fileInput.name.lastIndexOf('.'))?.toLowerCase();

        const isFileTypeNotExcluded = !this.forceAcceptedFiles && !this.listFileExcluded.some(file => fileInputType.includes(file.toLowerCase()));
        const isFileTypeIncluded = this.forceAcceptedFiles && this.listFileAccepted.some(file => fileInputType.includes(file.toLowerCase()));

        if (isFileTypeNotExcluded || isFileTypeIncluded) {
            if (fileInput.size > this.sizeByte) {
                const activeLang = this._translocoService.getActiveLang();
                const translation = this._translocoService.getTranslation().get(activeLang);
                this.fuseConfirmationService.open({
                    title: get(translation, 'dialog.attention', null),
                    message: get(translation, 'upload.size_exceeded_file_number', null) + ' ' + this.sizeByte / 1000000  + ' Mb',
                    icon: {
                        name: 'mat_solid:error_outline',
                        color: 'error'
                    },
                    onBackdrop: {
                        show: true,
                        backdrop: false
                    }
                });
            } else {
                if(isNil(index)){
                    this.form.push(new FormControl(fileInput));
                } else {
                    this.form.at(index).patchValue(fileInput);
                }
            }
        } else {
            const activeLang = this._translocoService.getActiveLang();
            const translation = this._translocoService.getTranslation().get(activeLang);
            this.fuseConfirmationService.open({
                title: get(translation, 'dialog.attention', null),
                message: get(translation, this.customNotSupportedFileMessageLabel || 'upload.not_supported_file_document', null) + ' ' + this.acceptedFileList?.toLowerCase(),
                icon: {
                    name: 'mat_solid:error_outline',
                    color: 'error'
                },
                onBackdrop: {
                    show: true,
                    backdrop: false
                }
            });
        }

        this.inputRef.nativeElement.value = '';
    }

    download(index) {
        this.downloadEmitter.emit(this.form.at(index)?.value)
    }

    open(element: HTMLInputElement) {
        if (this.verificaId) {
            if (this.idCampo) {
                element.click()
            } else {
                this.saveBeforeUpload.emit();
            }
        } else {
            element.click()
        }

    }

    delete(index: number) {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
                title: get(translation, 'common.delete', null),
                message: get(translation, 'common.delete_message', null),
                icon: {
                    name: 'mat_outline:delete',
                    color: 'warning'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    },
                    {
                        color: 'primary',
                        label: get(translation, 'common.delete', null),
                        function: () => this.form.removeAt(index)
                    }]
            }
        );
    }

    protected readonly Object = Object;

    trackByFn(index: number, item: any): any
    {
        return index;
    }

    addFile(element: HTMLInputElement) {
        if(!this.form?.disabled) {
            element.click();
        }
    }

    getDocumentName(document: File | string) {
        return typeof document === 'string' ? document : document?.name;
    }
    isFile(document: File | string) {
        return typeof document !== 'string';
    }

    addDocument() {
        this.form.push(new FormControl());
    }

    deleteDocument(index: number) {
        this.form.removeAt(index);
    }

    clear(index: number){
        this.form.at(index).patchValue(undefined);
    }

}
