<ng-container *transloco="let t">

    <fuse-loading-bar></fuse-loading-bar>

    <!-- Navigation -->
    <fuse-vertical-navigation class="light menu-background print:hidden"
                              [mode]="isScreenSmall ? 'over' : 'side'"
                              [name]="'mainNavigation'"
                              [navigation]="navigation?.default && ((isAreaAdministrator && (appInitService.cicloAmmSelected$ | async))  || (!isAreaAdministrator && appInitService.isDipartimentoRuoloCicloSelectedInService | async)) ?  navigation.default : []"
                              [opened]="!isScreenSmall && !!((isAreaAdministrator && (appInitService.cicloAmmSelected$ | async))  || (!isAreaAdministrator && appInitService.isDipartimentoRuoloCicloSelectedInService | async))  && (currentRuolo !== AuthorityType.STUDENTE)"
    >
        <!-- Navigation header hook -->
        <ng-container fuseVerticalNavigationContentHeader>
            <!-- Logo -->
            <div class="flex flex-col justify-center items-center px-8 gap-3 py-1 mb-6">
                <!-- Light version -->
                <img
                    class="dark:hidden w-52"
                    src="assets/logo/logo_negativo_1050_309.png"
                    alt="Logo image">
                <!-- Dark version -->
                <img
                    class="hidden dark:flex w-52"
                    src="assets/logo/logo_negativo_1050_309.png"
                    alt="Logo image">
                <!-- logo -->
                <img class="w-36"
                     src="assets/logo/logo-ateneo-testo-negativo.png"
                     alt="Logo Ateneo image">
            </div>
        </ng-container>

        <ng-container fuseVerticalNavigationFooter>

            <div class="flex flex-col items-center gap-3 w-full mt-2">
                <div class="block sm:hidden cursor-pointer color-white"
                     (click)="openDialogLicenses()"
                     style="font-size: 10px">
                    {{ tagFE || '-' }}/{{ tagBE || '-' }}
                </div>
                <div (click)="signOut()" class="flex flex-row gap-5 cursor-pointer bg-accent w-full"
                     style="padding: 16px 28px">
                    <mat-icon class="color-black">
                        logout
                    </mat-icon>

                    <div>
                        <span
                            class="fuse-vertical-navigation-item font-semibold  color-black">{{ t('dialog.logout') }} </span>
                    </div>
                </div>
            </div>


        </ng-container>


    </fuse-vertical-navigation>

    <!-- Wrapper -->
    <div class="flex flex-col flex-auto w-full min-w-0">

        <!-- Header -->
        <div
            style="top: 0 !important;
                   width: 100% !important;
                   position: sticky !important;"
            [class.justify-between]="!!( (isAreaAdministrator && (appInitService.cicloAmmSelected$ | async))  || ((appInitService.isDipartimentoRuoloCicloSelectedInService | async) && !isAreaAdministrator))  && (currentRuolo !== AuthorityType.STUDENTE)"
            class="relative justify-end flex flex-0 items-center  w-full h-16 px-2 md:px-3 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
            <!-- Navigation toggle button -->
            <button
                *ngIf="!!( (isAreaAdministrator && (appInitService.cicloAmmSelected$ | async))  || ((appInitService.isDipartimentoRuoloCicloSelectedInService | async) && !isAreaAdministrator))  && (currentRuolo !== AuthorityType.STUDENTE)"
                mat-icon-button
                (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button>

            <!-- Header info ciclo, corso, ruolo -->
            <div class="flex h-full flex-row relative items-center ml-2 ">
                <ng-container *ngIf="isAreaAdministrator; else gestionaleTmp">

                    <div class="flex flex-row hidden lg:flex item-center" *ngIf="!!cicloAmmSelected ">
                        <div class="flex flex-col items-center min-w-20">
                            <div class=" font-bold">{{ t('students_list.phd_cicle') }}</div>
                            <div class="text-sm font-medium text-secondary">{{ cicloAmmSelected }}</div>
                        </div>
                    </div>

                    <div class="hidden lg:flex h-8 mr-4 ml-2 border-l-2"></div>
                    <div class="flex-row gap-2 mr-2 hidden lg:flex" *ngIf="userAmministrazioneRolesFiltered?.length > 0">
                        <mat-chip-list class="w-full">
                            <mat-chip
                                *ngFor="let administrationRole of userAmministrazioneRolesFiltered"
                                class="bg-gray-200 text-gray-600 justify-between flex flex-row gap-2 cursor-pointer text-sm"
                                (click)="openAdministrationRoleInfoDialog(administrationRole)">
                                    <span
                                        class="line-clamp-1">{{ t('administration.' + administrationRole + '_title') }}
                                    </span>
                                <mat-icon class="icon-size-5">
                                    info
                                </mat-icon>
                            </mat-chip>
                        </mat-chip-list>
                    </div>

                    <div *ngIf="cicloAmmSelected &&  (appInitService.userAmministrazioneInfoCicli?.cicli?.length > 1)"
                         class=" flex flex-row hidden lg:flex item-center pl-2 space-x-0.5 ">
                        <button mat-flat-button class="mr-2 "
                                type="button"
                                color="primary"
                                (click)="openModalModificaCicloAmm()"
                        >
                            <mat-icon
                                class="icon-size-4">edit
                            </mat-icon>
                            <span class="ml-2">
            {{ t('common.edit') }}
                </span>
                        </button>
                    </div>
                </ng-container>

                <ng-template #gestionaleTmp>

                    <div class="lg:flex-row hidden lg:flex items-center" *ngIf="!!cicloCorsoRuolo">
                        <div class="flex flex-col items-center min-w-20 justify-center">
                            <div class=" font-bold">{{ t('students_list.phd_cicle') }}</div>
                            <div class="text-sm font-medium text-secondary">{{ cicloCorsoRuolo?.ciclo }}</div>
                        </div>
                        <div class="hidden lg:flex h-8 mx-4 border-l-2"></div>
                        <div class="flex flex-col items-center justify-center">
                            <span class="font-bold">{{ t('common.course') }}</span>
                            <div class="w-full text-sm font-medium text-secondary line-clamp-1 "
                                 [matTooltip]="(cicloCorsoRuolo?.codiceCorsoStudi || '') + (cicloCorsoRuolo?.denominazioneCorsoStudi ? (' - ' + (cicloCorsoRuolo?.denominazioneCorsoStudi || '')) : '')">
                                {{ (cicloCorsoRuolo?.codiceCorsoStudi || '') + (cicloCorsoRuolo?.denominazioneCorsoStudi ? (' - ' + (cicloCorsoRuolo?.denominazioneCorsoStudi || '')) : '') }}
                            </div>
                        </div>
                        <div class="hidden lg:flex h-8 mx-4 border-l-2"></div>
                        <div class="flex flex-col items-center min-w-20 justify-center mr-1">
                            <span class="font-bold">{{ t('auth.role') }}</span>
                            <span
                                class="text-sm font-medium text-secondary">{{ cicloCorsoRuolo?.ruolo | removeUnderscores }}
                            </span>
                        </div>

                        <div class="hidden lg:flex h-8 mx-4 border-l-2" *ngIf="cicloCorsoRuolo?.sottoruolo"></div>
                        <div class="flex flex-col items-center min-w-20 mr-4 ml-1" *ngIf="cicloCorsoRuolo?.sottoruolo">
                            <span class="font-bold">{{ t('common.profile') }}</span>
                            <span
                                class="text-sm font-medium text-secondary">
                                <mat-chip
                                    [matTooltip]="cicloCorsoRuolo?.sottoruolo | removeUnderscores"
                                    class="rounded-2xl flex justify-between text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600 text-sm p-1 min-h-[23px] px-2 max-w-[260px]">
                                    <span
                                        class="line-clamp-1">{{cicloCorsoRuolo?.sottoruolo | removeUnderscores}}
                                    </span>
                                </mat-chip>
                            </span>
                        </div>

                    </div>

                    <div *ngIf="showButtonEdit && appInitService.listaRuoliCodiciDipartimenti?.length > 1"
                         class=" flex flex-row hidden lg:flex item-center pl-2 space-x-0.5 ">
                        <button mat-flat-button class="mr-2 "
                                type="button"
                                color="primary"
                                (click)="openModalModificaCicloCorsoRuolo()"
                        >
                            <mat-icon
                                class="icon-size-4">edit
                            </mat-icon>
                            <span class="ml-2">
            {{ t('common.edit') }}
                </span>
                        </button>
                    </div>



                </ng-template>


                <hr style="height: 80%; width: 1px;  border: none; border-left: 1px solid hsla(200, 10%, 50%,100);"
                    class="hidden lg:block ml-2">

                <!-- Components -->
                <div class="flex items-center pl-2 space-x-0.5 sm:space-x-1">
                    <languages></languages>
                    <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
                    <app-support-menu>
                    </app-support-menu>
                    <user
                        [showGoToAmministrazione]="!isAreaAdministrator && appInitService.isAmministratore"
                        [showGoToGestionale]="isAreaAdministrator && !!appInitService.listaRuoliCodiciDipartimenti?.length"
                    ></user>
                    <app-mobile-tris-select class="block lg:hidden"
                                            [isAreaAdministrator]="isAreaAdministrator"
                                            [showButtonEdit]="showButtonEdit"
                                            [userAmministrazioneRolesFiltered]="userAmministrazioneRolesFiltered"
                                            (openAdministrationRoleInfoDialog)="openAdministrationRoleInfoDialog($event)">
                    </app-mobile-tris-select>
                </div>
            </div>
        </div>

        <!-- Content -->
        <div class="flex flex-col flex-auto m-1.5 lg:m-5">
            <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
                 Otherwise, layout changes won't be registered and the view won't be updated! -->
            <router-outlet *ngIf="true"></router-outlet>
        </div>

        <!-- Footer -->
        <div style="bottom: 0 !important;
                    width: 100% !important;
                    position: sticky !important;"
             class="relative flex justify-center w-full z-49 print:hidden">
            <div
                class="flex items-center w-full h-14 sm:h-14 px-2 sm:px-8 sm:shadow-xl border-t bg-card dark:bg-default">
                <div class="flex flex-row justify-center sm:justify-between items-center w-full">

                    <div class="hidden sm:block w-1/3 cursor-pointer"
                         (click)="openDialogLicenses()"
                         style="font-size: 10px">
                        {{ tagFE || '-' }}/{{ tagBE || '-' }}
                    </div>

                    <div class="font-medium flex-row w-full sm:w-1/3 text-center">
                        <a class="text-primary-500 hover:underline cursor-pointer"
                           (click)="openPrivacyOrTerminiECondizioni(true)">
                            {{ t('privacy_and_condition.privacy') | titlecase }}
                        </a> - <a class="text-primary-500 hover:underline cursor-pointer"
                                  (click)="openPrivacyOrTerminiECondizioni(false)">
                        {{ t('privacy_and_condition.terms_of_use') | titlecase }}
                    </a>
                    </div>

                    <div class="hidden sm:block font-medium text-secondary cursor-pointer w-1/3 text-right"
                         (click)="navigateToSouth()"> South Engineering srl
                    </div>

                </div>

            </div>
        </div>


    </div>

</ng-container>
