import {Component, Inject, OnInit} from '@angular/core';
import {ClickEvent} from "../../../../../shared/components/table/model/generic-table-model";
import {
    AnnoRiferimentoValues,
    AuthorityType,
    DelibereService,
    PeriodiDiMobilitaService,
    SpesaStudentePerApprovMassivaInfoView,
    StudentiCicloService,
    TipoPeriodoEnum
} from "../../../../../../api-clients/generated/services";
import {BehaviorSubject, startWith} from "rxjs";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {TranslocoService} from "@ngneat/transloco";
import * as moment from "moment/moment";
import {formGroupConfigInterface} from "../../../../../layout/common/generic-components/generic-components.interface";
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApprovazioneSpesaPTAMassivaDataI} from '../approvazione-spesa-pta-steps-interface';
import {CAN_GO_AHEAD$, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {max, min, sum} from "lodash";
import {MatDialog} from "@angular/material/dialog";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {LogoutService} from "../../../../../shared/service/logout.service";
import {CustomValidators} from "../../../../../shared/validators/custom-validators";
import {
    RipartizioneImportoSpesaI
} from "../../../student-details/budget/dialog-pta-approve-spesa/dialog-pta-approve-spesa.component";
import {annoRiferimentoToRomanNumeral} from "../../../../../shared/utils/utils";
import {round} from "lodash-es";


@Component({
    selector: 'app-info-approvazione-spesa-pta-stepper',
    templateUrl: './compila-info-approvazione-spesa-pta.component.html',
    styleUrls: ['./compila-info-approvazione-spesa-pta.component.scss']
})
export class CompilaInfoApprovazioneSpesaPtaComponent extends AbstractDefaultComponent implements OnInit {

    loading: boolean;
    formConfig: formGroupConfigInterface[];
    valueForm: any;
    form: FormGroup;
    speseSelected: SpesaStudentePerApprovMassivaInfoView[];
    outputFormatData = 'DD/MM/YYYY';
    speseDetailsFormArray: FormArray;
    protected readonly AuthorityType = AuthorityType;
    protected readonly TipoPeriodoEnum = TipoPeriodoEnum;

    constructor(private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                private appInitService: AppInitService,
                private delibereService: DelibereService,
                private fb: FormBuilder,
                private dialog: MatDialog,
                private studentiCicloService: StudentiCicloService,
                private logoutService: LogoutService,
                private periodiDiMobilitaService: PeriodiDiMobilitaService,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<ApprovazioneSpesaPTAMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.initForm();
        this.operazioneMassivaData$.subscribe((inputOutputData: ApprovazioneSpesaPTAMassivaDataI) => {
            // if confirm step force refresh set as not completed the step
            if(inputOutputData.refreshSpese){
                this.canGoNext$.next(false);
                this.speseDetailsFormArray = undefined;
            }
            // if studenti selected changes reset delibera form and get plans for selected students
            if(inputOutputData.speseSelected !== this.speseSelected){
                this.speseSelected = inputOutputData?.speseSelected;
                this.buildSpeseDetailsFormGroup(this.speseSelected);
            }
        });
    }


    formatDay(date: string) {
        return moment(new Date(date)).format('DD/MM/YYYY');
    }



    tableClickAction($event: ClickEvent) {

    }

    private initForm() {
        this.form = this.fb.group({
            deliberaCollegio: undefined,
        });
        this.canGoNext$.next(this.speseDetailsFormArray?.valid);
    }


    private buildSpeseDetailsFormGroup(selectedSpese: SpesaStudentePerApprovMassivaInfoView[]) {
        this.speseDetailsFormArray = this.fb.array([]);
        selectedSpese?.forEach(spesa =>
            this.speseDetailsFormArray.push(this.getSpesaDetailsForm(spesa)))
        // subscribe to form group change
        this.speseDetailsFormArray.valueChanges?.pipe(startWith(this.speseDetailsFormArray.value)).subscribe(fa => {
            console.log('details value changed', fa)
            this.speseDetailsFormArray?.controls?.forEach(
                (g: FormGroup) => {
                    g.get('importoSpesaPrimoAnno').updateValueAndValidity({onlySelf: true});
                    g.get('importoSpesaSecondoAnno').updateValueAndValidity({onlySelf: true});
                    g.get('importoSpesaTerzoAnno').updateValueAndValidity({onlySelf: true});
                });
            this.canGoNext$.next(this.speseDetailsFormArray.valid);
            this.operazioneMassivaData$.next({
                ...this.operazioneMassivaData$?.getValue(),
                compileInfo: fa?.map(value => ({
                    id_utente: value.idUtente,
                    codice_spesa: value.codiceSpesa,
                    importo_approvato: value.importoSpesa,
                    importo_approvato_fondo_primo_anno: round(value.importoSpesaPrimoAnno, 2),
                    importo_approvato_fondo_secondo_anno: round(value.importoSpesaSecondoAnno, 2),
                    importo_approvato_fondo_terzo_anno: round(value.importoSpesaTerzoAnno, 2),
                    importo_approvato_altro_fondo: round(value.importoSpesaAltriFondi, 2),
                    descrizione_altro_fondo: value.notaAltriFondi,
                }))
            });
        })
    }

    private getSpesaDetailsForm(spesa: SpesaStudentePerApprovMassivaInfoView) {
        const ripartizioneImporto = this.getRipartizioneImporto(spesa?.importoApprovato ?? spesa?.importoRichiesto, spesa);
        const formGroupSpesa = this.fb.group({
            idUtente: [spesa.budgetStudente?.studenteCiclo?.utente?.id],
            codiceSpesa: [spesa.codiceSpesa],
            importoSpesa: [spesa.importoApprovato ?? spesa.importoRichiesto, [Validators.required, Validators.min(0.1)]],
            importoSpesaPrimoAnno: [ripartizioneImporto.importoSpesaPrimoAnno,
                [Validators.required, (control: AbstractControl) => Validators.max(max([0, round(spesa.budgetStudente?.budgetResiduoPrimoAnno-this.getSumImportiForOtherRequestsOfSameStudent(spesa, AnnoRiferimentoValues.PRIMO), 2)]))(control)]],
            importoSpesaSecondoAnno: [ripartizioneImporto.importoSpesaSecondoAnno,
                [Validators.required, (control: AbstractControl) => Validators.max(max([0, round(spesa.budgetStudente?.budgetResiduoSecondoAnno-this.getSumImportiForOtherRequestsOfSameStudent(spesa, AnnoRiferimentoValues.SECONDO), 2)]))(control)]],
            importoSpesaTerzoAnno: [ripartizioneImporto.importoSpesaTerzoAnno,
                [Validators.required, (control: AbstractControl) => Validators.max(max([0, round(spesa.budgetStudente?.budgetResiduoTerzoAnno-this.getSumImportiForOtherRequestsOfSameStudent(spesa, AnnoRiferimentoValues.TERZO), 2)]))(control)]],
            importoSpesaAltriFondi: [ripartizioneImporto.importoSpesaAltriFondi, Validators.required],
            notaAltriFondi: [undefined, Validators.required],
        }, {validators: [CustomValidators.RipartizioneImportoSpesaValidator()]});
        // disabling/enabling note field according importoSpesaAltriFondi value
        formGroupSpesa.get('importoSpesaAltriFondi').valueChanges.pipe(
            startWith(formGroupSpesa.get('importoSpesaAltriFondi').value)
        ).subscribe((value) => {
            if(!value || value == 0){
                formGroupSpesa.get('notaAltriFondi').setValue(undefined, {emitEvent: false});
                formGroupSpesa.get('notaAltriFondi').disable({emitEvent: false});
            } else {
                formGroupSpesa.get('notaAltriFondi').enable({emitEvent: false});
            }
        });
        formGroupSpesa.get('importoSpesa').valueChanges
            .subscribe((value) => {
                formGroupSpesa.patchValue(this.getRipartizioneImporto(value, spesa));
            });
        return formGroupSpesa;
    }

    private getRipartizioneImporto(importoApprovato: number, spesa?: SpesaStudentePerApprovMassivaInfoView): RipartizioneImportoSpesaI {
        if (!importoApprovato) {
            return {
                importoSpesaPrimoAnno: 0,
                importoSpesaSecondoAnno: 0,
                importoSpesaTerzoAnno: 0,
                importoSpesaAltriFondi: 0,
            }
        } else {
            const importoSpesaPrimoAnno = min([
                importoApprovato,
                max([0, spesa?.budgetStudente?.budgetResiduoPrimoAnno-this.getSumImportiForOtherRequestsOfSameStudent(spesa, AnnoRiferimentoValues.PRIMO)])
            ]);
            const importoSpesaSecondoAnno = min([
                (importoApprovato - importoSpesaPrimoAnno),
                max([0, spesa?.budgetStudente?.budgetResiduoSecondoAnno-this.getSumImportiForOtherRequestsOfSameStudent(spesa, AnnoRiferimentoValues.SECONDO)])
            ]);
            const importoSpesaTerzoAnno = min([
                (importoApprovato - importoSpesaPrimoAnno - importoSpesaSecondoAnno),
                max([0, spesa?.budgetStudente?.budgetResiduoTerzoAnno-this.getSumImportiForOtherRequestsOfSameStudent(spesa, AnnoRiferimentoValues.TERZO)])
            ]);
            const importoSpesaAltriFondi = importoApprovato - importoSpesaPrimoAnno - importoSpesaSecondoAnno - importoSpesaTerzoAnno;
            return {
                importoSpesaPrimoAnno,
                importoSpesaSecondoAnno,
                importoSpesaTerzoAnno,
                importoSpesaAltriFondi,
            }
        }
    }


    clearDateField(fieldCtrl: AbstractControl) {
        fieldCtrl.setValue(undefined);
        fieldCtrl.setErrors(null);
    }


    getSumImportiForOtherRequestsOfSameStudent(spesa: SpesaStudentePerApprovMassivaInfoView, annoToCount: AnnoRiferimentoValues) {
        const requests = this.speseSelected
            ?.map((otherSpesa, index) => {
                if(spesa.codiceSpesa !== otherSpesa.codiceSpesa && spesa.budgetStudente?.studenteCiclo?.utente?.id === otherSpesa.budgetStudente?.studenteCiclo?.utente?.id) {
                    if (annoToCount === AnnoRiferimentoValues.PRIMO)
                        return this.speseDetailsFormArray?.at(index)?.value?.importoSpesaPrimoAnno ?? 0;
                    else if (annoToCount === AnnoRiferimentoValues.SECONDO)
                        return this.speseDetailsFormArray?.at(index)?.value?.importoSpesaSecondoAnno ?? 0;
                    else (annoToCount === AnnoRiferimentoValues.TERZO)
                    return this.speseDetailsFormArray?.at(index)?.value?.importoSpesaTerzoAnno ?? 0;
                } else {
                    return 0;
                }
            }) ?? [];
        return sum(requests);
    }

    protected readonly max = max;
    protected readonly AnnoRiferimentoValues = AnnoRiferimentoValues;
    protected readonly annoRiferimentoToRomanNumeral = annoRiferimentoToRomanNumeral;
}
