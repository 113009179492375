/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Motivazioni per richiesta di utilizzo mezzo proprio.
 */
export type MotivazioneUtilizzoMezzoProprioValues = 'ECONOMICITA_IN_RELAZIONE_ALLA_SPESA_GLOBALE_DELLA_MISSIONE' | 'LUOGO_NON_SERVITO_FREQUENTEMENTE_DA_MEZZI_DI_LINEA' | 'NATURA_DELLA_MISSIONE' | 'TRASPORTO_MATERIALI_DELICATI' | 'INDISPONIBILITA_DEI_MEZZI_ORDINARI' | 'URGENZA';

export const MotivazioneUtilizzoMezzoProprioValues = {
    ECONOMICITAINRELAZIONEALLASPESAGLOBALEDELLAMISSIONE: 'ECONOMICITA_IN_RELAZIONE_ALLA_SPESA_GLOBALE_DELLA_MISSIONE' as MotivazioneUtilizzoMezzoProprioValues,
    LUOGONONSERVITOFREQUENTEMENTEDAMEZZIDILINEA: 'LUOGO_NON_SERVITO_FREQUENTEMENTE_DA_MEZZI_DI_LINEA' as MotivazioneUtilizzoMezzoProprioValues,
    NATURADELLAMISSIONE: 'NATURA_DELLA_MISSIONE' as MotivazioneUtilizzoMezzoProprioValues,
    TRASPORTOMATERIALIDELICATI: 'TRASPORTO_MATERIALI_DELICATI' as MotivazioneUtilizzoMezzoProprioValues,
    INDISPONIBILITADEIMEZZIORDINARI: 'INDISPONIBILITA_DEI_MEZZI_ORDINARI' as MotivazioneUtilizzoMezzoProprioValues,
    URGENZA: 'URGENZA' as MotivazioneUtilizzoMezzoProprioValues
};