<ng-container *transloco="let t">
    <app-dialog-wrap [data]="data">

        <mat-tab-group class="w-full mt-4" #tabsGroup appScrollRetainer *ngIf="!!data.dettaglioImporto; else noDettaglioImportoTmp">

            <mat-tab [label]="t('common.info')">
                <ng-template matTabContent>
                    <div style="margin-top: 10px" class="w-full mb-2 flex flex-wrap rounded-2xl bg-gray-50">
                        <div class=" w-full grid grid-cols-1  md:grid-cols-2 lg:grid-cols-2 gap-4 m-4 mb-4">
                            <ng-container *ngFor="let inf of data.readonlyData">
                                <ng-container *ngIf="!inf?.hide">

                                    <app-input-value-key [label]="t(inf.key)"
                                                         [value]=" inf.value"
                                                         [pipe]="inf.pipe"
                                                         [showVisualizzaButton]="!!inf.showVisualizzaButton && !!inf.buttonAction"
                                                         (onDownloadButtonPressed)="inf.buttonAction()">
                                    </app-input-value-key>

                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </ng-template>
            </mat-tab>

            <mat-tab [label]="t('mobility.amount_detail')" *ngIf="data.dettaglioImporto && !isEmpty(data.dettaglioImporto)">
                <ng-template matTabContent>
                <app-info-maggiorazione [dettaglioImporto]="data.dettaglioImporto"></app-info-maggiorazione>
                </ng-template>
            </mat-tab>

        </mat-tab-group>


        <ng-template #noDettaglioImportoTmp>
            <div style="margin-top: 10px" class="w-full mb-2 flex flex-wrap rounded-2xl bg-gray-50">
                <div class=" w-full grid grid-cols-1  md:grid-cols-2 lg:grid-cols-2 gap-4 m-4 mb-4">
                    <ng-container *ngFor="let inf of data.readonlyData">
                        <ng-container *ngIf="!inf?.hide">

                            <app-input-value-key [label]="t(inf.key)"
                                                 [value]=" inf.value"
                                                 [pipe]="inf.pipe"
                                                 [showVisualizzaButton]="!!inf.showVisualizzaButton && !!inf.buttonAction"
                                                 (onDownloadButtonPressed)="inf.buttonAction()">
                            </app-input-value-key>

                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </ng-template>


    </app-dialog-wrap>

    <div  class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">
        <button color="accent" mat-flat-button [matDialogClose]="'cancelled'">
            <mat-icon class="mr-2">close</mat-icon>
            {{t('common.close')}}
        </button>
    </div>
</ng-container>
