import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {finalize, switchMap, take, takeUntil} from "rxjs";
import {FormBuilder, FormGroup} from "@angular/forms";
import {DialogWrapI} from "../../../../../layout/common/dialog-wrap/dialog-wrap.component";
import {
    AttivitaCategoriaAssociationInfoView,
    CategoriaOffertaFormativaInfoView,
    ElementoOffertaFormativaType,
    OffertaFormativaInfoViewImpl,
    OfferteFormativeService,
    SpostaAttivitaInCategoriaOffertaFormativaRequest
} from "../../../../../../api-clients/generated/services";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {AbstractDefaultComponent} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {TranslocoService} from "@ngneat/transloco";
import {get} from "lodash";
import {SnackbarTypes} from "../../../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {filter} from "rxjs/operators";
import {CustomValidators} from "../../../../../shared/validators/custom-validators";
import {ButtonType} from "../../../../../shared/components/custom-button/custom-button.component";
import {
    DialogInvalidStudyPlansComponent,
    DialogInvalidStudyPlansComponentI
} from "../dialog-invalid-study-plans/dialog-invalid-study-plans.component";
import {tr} from "date-fns/locale";

export interface DialogMoveActivityI extends DialogWrapI {
    annoInsegnamento?: string;
    actToMove?: AttivitaCategoriaAssociationInfoView;
    isOffertaPubblica?: boolean;
    idCategoria: string;
    category: CategoriaOffertaFormativaInfoView;
    trainingOffer: OffertaFormativaInfoViewImpl;
    filteredCategories: CategoriaOffertaFormativaInfoView[];
}


@Component({
    selector: 'app-dialog-move-activity',
    templateUrl: './dialog-move-activity.component.html',
    styleUrls: ['./dialog-move-activity.component.scss']
})
export class DialogMoveActivityComponent extends AbstractDefaultComponent implements OnInit {


    loading: boolean;

    formGroupYears: FormGroup;
    selectCategoryForm: FormGroup;
    showMiniLoader = false;
    type = ElementoOffertaFormativaType;
    showTerzoAnno: boolean;
    showPrimoAnno: boolean;
    showSecondoAnno: boolean;


    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogMoveActivityI,
                public dialogRef: MatDialogRef<DialogMoveActivityComponent>,
                private fb: FormBuilder,
                private appInitService: AppInitService,
                private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                private offerteFormativeService: OfferteFormativeService,
                private dialog: MatDialog,
    ) {
        super();
        this.createForm();
    }

    ngOnInit(): void {
    }

    private showYears(category: CategoriaOffertaFormativaInfoView) {
        this.showTerzoAnno = false;
        this.showPrimoAnno = false;
        this.showSecondoAnno = false;
        category?.configurazione?.configurazioni?.forEach(({terzo_anno, primo_anno, secondo_anno}) => {
            if(primo_anno) {
                this.showPrimoAnno = true;
            }
            if(secondo_anno) {
                this.showSecondoAnno = true;
            }
            if(terzo_anno) {
                this.showTerzoAnno = true;
            }
        });
    }

    createForm(): void {
        this.formGroupYears = this.fb.group({
            primoAnno: undefined,
            secondoAnno: undefined,
            terzoAnno: undefined,
        }, {validators: CustomValidators.AtLeastOneYearValidator()});
        this.selectCategoryForm = this.fb.group({
            array: this.fb.array([], {validators: CustomValidators.minArrayLength(1)})
        });
        this.selectCategoryForm.get('array').valueChanges.subscribe(selectedCategory => {
            const categoryInOffer = this.data?.trainingOffer?.categorie?.find(c => c.id === selectedCategory?.[0]?.key);
            this.showYears(categoryInOffer);
        });
    }

    closeDialog() {
        this.dialogRef.close();
    }


    saveBySelect(forced = undefined) {

        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        if (!this.selectCategoryForm.valid || this.formGroupYears.invalid) {
            this.fuseConfirmationService.openSnackBar({
                message: get(translation, 'form.not_valid', null),
                type: SnackbarTypes.Warning,
            });
        } else {
            this.showMiniLoader = true;
            const formRawValue = this.selectCategoryForm.value;
            console.log(formRawValue)
            const body: SpostaAttivitaInCategoriaOffertaFormativaRequest = {
                id_categoria_offerta_formativa_destinazione: this.selectCategoryForm?.value?.array?.[0]?.key,
                primo_anno: !!this.formGroupYears.get('primoAnno').value,
                secondo_anno: !!this.formGroupYears.get('secondoAnno').value,
                terzo_anno: !!this.formGroupYears.get('terzoAnno').value,
                tipo_attivita: this.data.actToMove.attivitaOffertaFormativa.tipo
            }
            this.formGroupYears.disable();
            this.selectCategoryForm.disable({emitEvent: false});
            const successMessage = 'training_offer.activity_moved';
            const errorMessage = 'training_offer.error_moving_activity';

            this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
                filter(Boolean),
                take(1),
                switchMap(() => this.offerteFormativeService.spostaAttivitaInCategoriaOffertaFormativa(body, this.data.idCategoria, this.data.actToMove?.attivitaOffertaFormativa?.id, forced)),
                takeUntil(this.destroy$),
                finalize(() => {
                    this.selectCategoryForm.enable();
                    this.formGroupYears.enable();
                    this.showMiniLoader = false;
                })
            ).subscribe({
                next: (offertaFormativaInfo: OffertaFormativaInfoViewImpl) => {
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, successMessage, null),
                        type: SnackbarTypes.Success,
                    });
                    this.dialogRef.close(offertaFormativaInfo);
                },
                error: (err) => {
                    if(err.status === 409 && err?.error?.code == 1034){
                        this.openInvalidStudyPlansDialog(err?.error?.utentiConPianoNonPiuValido, () => this.saveBySelect(true));
                    } else {
                        this.fuseConfirmationService.openSnackBar({
                            message: get(translation, errorMessage, null),
                            type: SnackbarTypes.Error,
                        });
                    }
                }
            });

        }
    }

    protected readonly ButtonType = ButtonType;

    private openInvalidStudyPlansDialog(utentiConPianoNonPiuValido: any[],
                                        confirmCallback: () => void) {
        const data: DialogInvalidStudyPlansComponentI = {
            utentiConPianoNonPiuValido: utentiConPianoNonPiuValido,
            icon: {
                name: 'warning_amber',
                color: 'warning'
            },
            onConfirm: (dialogRef2) =>{
                dialogRef2?.close();
                confirmCallback();
            }
        };
        this.dialog.open(DialogInvalidStudyPlansComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            autoFocus: false,
            disableClose: true,
            width: '80vw',
        });
    }


    checkSelectionValid() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        if(this.selectCategoryForm.invalid){
            this.fuseConfirmationService.openSnackBar({
                message: get(translation, 'study_plan_status.must_select_one_category', null),
                type: SnackbarTypes.Warning,
            });
        }
    }
}
