import {Injectable} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {BehaviorSubject, Subject} from 'rxjs';
import {
    AnnoRiferimentoValues,
    AttivitaExtraPianoDiStudiStatus,
    AuthorityType,
    MobilitaRequestSelectFormValuesDTO
} from 'api-clients/generated/services';
import {LocalStorageService} from "../../../../../shared/service/local-storage.service";
import {ApplyFiltersQuicklyStudentsI} from "../../../students-list/students-list.component";

export const DEFAULT_PAGE_SIZE = 10;

export interface AttivitaExtraFilters {
    denomDescrAttivita?: string;
    dettaglioAttivitaTrasversale?: string;
    annoInsegnamento?: AnnoRiferimentoValues;
    nominativoStudente?: string;
    emailStudente?: string;
    cfStudente?: string;
    matricolaStudente?: string;
    statiAttivitaExtra?: AttivitaExtraPianoDiStudiStatus[];
    showOnlyApprovableRequests?: boolean;
}

@Injectable({
    providedIn: "root"
})
export class AttivitaExtraFilterService {

    mainFormGroup: FormGroup | undefined;
    selectValues: MobilitaRequestSelectFormValuesDTO;
    checkAggiornamentoSelectValues$ = new BehaviorSubject(undefined);
    reset$ = new BehaviorSubject(false);
    checkApplyFilterClick$ = new BehaviorSubject(false);
    currentSottoRuolo: AuthorityType | undefined;
    filterApplied$ = new BehaviorSubject(undefined);
    filterApplied = undefined;
    pageSize = DEFAULT_PAGE_SIZE;
    page = 0;
    defaultFormPathList = [
        'denomDescrAttivita',
        'dettaglioAttivitaTrasversale',
        'annoInsegnamento',
        'nominativoStudente',
        'matricolaStudente',
        'emailStudente',
        'cfStudente',
        'statiAttivitaExtra',
        'showOnlyApprovableRequests',
    ];
    fieldsLabelMap = new Map<string, string>([
        ['denomDescrAttivita', 'common.description'],
        ['dettaglioAttivitaTrasversale', 'common.detail'],
        ['annoInsegnamento', 'common.year'],
        ['nominativoStudente', 'common.nominative'],
        ['matricolaStudente', 'common.enrollment_number'],
        ['emailStudente', 'common.email'],
        ['statiAttivitaExtra', 'common.status'],
        ['cfStudente', 'common.fiscal_code'],
        ['showOnlyApprovableRequests', 'menu_filter.only_approvable_by_me'],

    ]);

    openDrawer = new Subject();
    applyFiltersQuickly: ApplyFiltersQuicklyStudentsI;


    constructor(public localStorageService: LocalStorageService,
                public formBuilder: FormBuilder) {
    }


    createDefaultFormGroup(): FormGroup {
        return this.formBuilder.group({
            denomDescrAttivita: null,
            dettaglioAttivitaTrasversale: null,
            annoInsegnamento: null,
            nominativoStudente: null,
            matricolaStudente: null,
            emailStudente: null,
            statiAttivitaExtra: null,
            cfStudente: null,
            showOnlyApprovableRequests: null,
        });
    }


    getFieldsByRole(): string[] {
        return this.defaultFormPathList;
    }

    getCtrlByPath(path: string): FormControl<any> {
        return this.mainFormGroup?.get(path) as FormControl;
    }

    init(force?: boolean): void {
        const sottoruolo = this.localStorageService.dipartimentoRuoloCiclo?.sottoruolo;
        if ((this.currentSottoRuolo !== sottoruolo) || !this.currentSottoRuolo) {
            this.currentSottoRuolo = sottoruolo;
            this.buildForm();
        } else if(force){
            this.buildForm();
            this.filterApplied = this.mainFormGroup.getRawValue();
        }
        if(this.applyFiltersQuickly?.statiAttivitaExtra) {
            this.mainFormGroup.get('statiAttivitaExtra').setValue(this.applyFiltersQuickly?.statiAttivitaExtra);
            this.filterApplied = this.mainFormGroup.getRawValue();
        }
        if(this.applyFiltersQuickly?.showOnlyApprovableRequests) {
            this.mainFormGroup.get('showOnlyApprovableRequests').setValue(this.applyFiltersQuickly?.showOnlyApprovableRequests);
            this.filterApplied = this.mainFormGroup.getRawValue();
        }
    }

    private buildForm() {
        if (!!this.mainFormGroup) {
            this.mainFormGroup.reset();
        } else {
            this.mainFormGroup = this.createDefaultFormGroup();
        }
    }






    reset(): void {
        this.page = 0;
        this.pageSize = DEFAULT_PAGE_SIZE;
        this.mainFormGroup.reset();
        this.filterApplied = this.mainFormGroup.getRawValue();
        this.checkApplyFilterClick$.next(true);
    }


    restore(): void {
        this.page = 0;
        this.pageSize = DEFAULT_PAGE_SIZE;
        this.mainFormGroup.reset();
        this.buildForm();
        this.filterApplied = this.mainFormGroup.getRawValue();
        this.checkApplyFilterClick$.next(true);
    }
}
