import {Translation} from "@ngneat/transloco";
import {get} from "lodash";

export function getOperazioneDescrizione(value: string, t: any): string {
    const description = t(this.operazioniDisponibili?.find(o => o.tipo === value)?.descrizione, null);
    if(value && description){
        return '- ' + description;
    } else {
        return '';
    }
}
