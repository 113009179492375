import {ScegliCorsoComponent} from "./superamento-corso-steps/scegli-corso/scegli-corso.component";
import {Injector, Type} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {SuperamentoCorsoMassivoDataI} from "./superamento-corso-steps/superamento-corso-steps-interface";
import {ScegliStudentiComponent} from "./superamento-corso-steps/scegli-studenti/scegli-studenti.component";
import {
    CompilaInfoSuperamentoComponent
} from "./superamento-corso-steps/compila-info-superamento/compila-info-superamento.component";
import {
    ConfirmSuperamentoCorsoComponent
} from "./superamento-corso-steps/confirm-superamento-corso/confirm-superamento-corso.component";
import {AuthorityType, TipoPeriodoEnum} from "../../../../api-clients/generated/services";
import {ApprovazioneSpeseMassivaDataI} from "./approvazione-spesa-steps/approvazione-spese-steps-interface";
import {ScegliSpeseComponent} from "./approvazione-spesa-steps/scegli-spese/scegli-spese.component";
import {
    ConfirmApprovazioneSpeseComponent
} from "./approvazione-spesa-steps/confirm-approvazione-spese/confirm-approvazione-spese.component";
import {
    CompilaInfoApprovazioneComponent
} from "./approvazione-spesa-steps/compila-info-approvazione/compila-info-approvazione.component";
import {
    ApprovazioneTrasversaliMassivaDataI
} from "./approvazione-trasversale-steps/approvazione-trasversale-steps-interface";
import {
    ScegliTrasversaliComponent
} from "./approvazione-trasversale-steps/scegli-trasversali/scegli-trasversali.component";
import {
    CompilaInfoApprovazioneTrasversaliComponent
} from "./approvazione-trasversale-steps/compila-info-approvazione-trasversali/compila-info-approvazione-trasversali.component";
import {
    ConfirmApprovazioneTrasversaliComponent
} from "./approvazione-trasversale-steps/confirm-approvazione-trasversali/confirm-approvazione-trasversali.component";
import {ApprovazionePianiMassivaDataI} from "./approvazione-piani-steps/approvazione-piani-steps-interface";
import {
    ScegliStudentiPianiComponent
} from "./approvazione-piani-steps/scegli-studenti-piani/scegli-studenti-piani.component";
import {
    CompilaInfoApprovazionePianiComponent
} from "./approvazione-piani-steps/compila-info-approvazione-piani/compila-info-approvazione-piani.component";
import {
    ConfirmApprovazionePianiComponent
} from "./approvazione-piani-steps/confirm-approvazione-piani/confirm-approvazione-piani.component";
import moment from "moment";
import {
    ScegliPeriodiRicercaComponent
} from "./approvazione-ricerca-steps/scegli-periodi-ricerca/scegli-periodi-ricerca.component";
import {ApprovazioneRicercaMassivaDataI} from "./approvazione-ricerca-steps/approvazione-ricerca-steps-interface";
import {
    CompilaInfoApprovazioneRicercaComponent
} from "./approvazione-ricerca-steps/compila-info-approvazione-ricerca/compila-info-approvazione-ricerca.component";
import {
    ConfirmApprovazioneRicercaComponent
} from "./approvazione-ricerca-steps/confirm-approvazione-ricerca/confirm-approvazione-ricerca.component";
import {
    ScegliPeriodiMobilitaComponent
} from "./approvazione-mobilita-steps/scegli-periodi-mobilita/scegli-periodi-mobilita.component";
import {
    CompilaInfoApprovazioneMobilitaComponent
} from "./approvazione-mobilita-steps/compila-info-approvazione-mobilita/compila-info-approvazione-mobilita.component";
import {ApprovazioneMobilitaMassivaDataI} from "./approvazione-mobilita-steps/approvazione-mobilita-steps-interface";
import {
    ConfirmApprovazioneMobilitaComponent
} from "./approvazione-mobilita-steps/confirm-approvazione-mobilita/confirm-approvazione-mobilita.component";
import {CAN_GO_AHEAD$, CURRENT_PROFILE, OPERAZIONE_MASSIVA_DATA$, PERIOD_TYPE$} from "./operazioni-massive.component";
import {ShowWithDeliberaStepComponent} from "./common-steps/show-with-delibera-step/show-with-delibera-step.component";
import {
    AutorizzazioneMobilitaMassivaDataI
} from "./autorizzazione-mobilita-steps/autorizzazione-mobilita-steps-interface";
import {
    ConfirmAutorizzazioneMobilitaComponent
} from "./autorizzazione-mobilita-steps/confirm-autorizzazione-mobilita/confirm-autorizzazione-mobilita.component";
import {
    ScegliStudentiAutorizzazioneMobilitaComponent
} from "./autorizzazione-mobilita-steps/scegli-studenti-autorizzazione-mobilita/scegli-studenti-autorizzazione-mobilita.component";
import {
    ApprovazioneRegistroAttivitaMassivaDataI
} from "./approvazione-registro-attivita-steps/approvazione-registro-attivita-steps-interface";
import {
    ScegliRegistriAttivitaComponent
} from "./approvazione-registro-attivita-steps/scegli-registri-attivita/scegli-registri-attivita.component";
import {
    CompilaInfoApprovazioneRegistroComponent
} from "./approvazione-registro-attivita-steps/compila-info-approvazione/compila-info-approvazione-registro.component";
import {
    ConfirmApprovazioneRegistroAttivitaComponent
} from "./approvazione-registro-attivita-steps/confirm-approvazione-registro-attivita/confirm-approvazione-registro-attivita.component";
import {
    ShowWithDeliberaForPlanStepComponent,
    ShowWithDeliberaForPlanStepI, StudyPlanWithDeliberaMode
} from "./common-steps/show-with-delibera-for-plan-step/show-with-delibera-for-plan-step.component";
import {ApprovazioneMissioniMassivaDataI} from "./approvazione-missioni-steps/approvazione-missioni-steps-interface";
import {ScegliMissioniComponent} from "./approvazione-missioni-steps/scegli-missioni/scegli-missioni.component";
import {
    ConfirmApprovazioneMissioniComponent
} from "./approvazione-missioni-steps/confirm-approvazione-missioni/confirm-approvazione-missioni.component";
import {
    ApprovazioneValidazioneMobilitaMassivaDataI
} from "./approvazione-validazione-mobilita-steps/approvazione-validazione-mobilita-steps-interface";
import {
    ScegliRichiesteValidazioneComponent
} from "./approvazione-validazione-mobilita-steps/scegli-richieste-validazione-mobilita/scegli-richieste-validazione.component";
import {
    CompilaInfoApprovazioneRichiesteValidazioneComponent
} from "./approvazione-validazione-mobilita-steps/compila-info-approvazione-richieste-validazione/compila-info-approvazione-richieste-validazione.component";
import {
    ConfirmApprovazioneRichiesteValidazioneComponent
} from "./approvazione-validazione-mobilita-steps/confirm-approvazione-richieste-validazione/confirm-approvazione-richieste-validazione.component";
import {
    ApprovazioneConclusioneMobilitaMassivaDataI
} from "./approvazione-conclusione-mobilita-steps/approvazione-conclusione-mobilita-steps-interface";
import {
    ScegliConclusioneMobilitaComponent
} from "./approvazione-conclusione-mobilita-steps/scegli-conclusione-mobilita/scegli-conclusione-mobilita.component";
import {
    ConfirmApprovazioneConclusioneMobilitaComponent
} from "./approvazione-conclusione-mobilita-steps/confirm-approvazione-conclusione-mobilita/confirm-approvazione-conclusione-mobilita.component";
import {
    ApprovazioneModificheBudgetMassivaDataI
} from "./approvazione-modifiche-budget-steps/approvazione-modifiche-budget-steps-interface";
import {
    ScegliModificheBudgetComponent
} from "./approvazione-modifiche-budget-steps/scegli-modifiche-budget/scegli-modifiche-budget.component";
import {
    CompilaInfoApprovazioneModificheBudgetComponent
} from "./approvazione-modifiche-budget-steps/compila-info-approvazione-modifiche-budget/compila-info-approvazione-modifiche-budget.component";
import {
    ConfirmApprovazioneModificheBudgetComponent
} from "./approvazione-modifiche-budget-steps/confirm-approvazione-modifiche-budget/confirm-approvazione-modifiche-budget.component";
import {ApprovazioneSpesaPTAMassivaDataI} from "./approvazione-spesa-pta-steps/approvazione-spesa-pta-steps-interface";
import {ScegliSpesaPtaComponent} from "./approvazione-spesa-pta-steps/scegli-spesa-pta/scegli-spesa-pta.component";
import {
    CompilaInfoApprovazioneSpesaPtaComponent
} from "./approvazione-spesa-pta-steps/compila-info-approvazione-spesa-pta/compila-info-approvazione-spesa-pta.component";
import {
    ConfirmApprovazioneSpesaPtaComponent
} from "./approvazione-spesa-pta-steps/confirm-approvazione-spesa-pta/confirm-approvazione-spesa-pta.component";
import {ApprovazioneExtraMassivaDataI} from "./approvazione-extra-steps/approvazione-extra-steps-interface";
import {ScegliExtraComponent} from "./approvazione-extra-steps/scegli-extra/scegli-extra.component";
import {CompilaInfoExtraComponent} from "./approvazione-extra-steps/compila-info-extra/compila-info-extra.component";
import {
    ConfirmSuperamentoExtraComponent
} from "./approvazione-extra-steps/confirm-superamento-extra/confirm-superamento-extra.component";

// models

export enum OperazioneMassivaTipo {
    SUPERAMENTO_CORSO = "SUPERAMENTO_CORSO",
    APPROVAZIONE_SPESE = "APPROVAZIONE_SPESE",
    APPROVAZIONE_TRASVERSALI = "APPROVAZIONE_TRASVERSALI",
    APPROVAZIONE_PIANI = "APPROVAZIONE_PIANI",
    APPROVAZIONE_RICERCA = "APPROVAZIONE_RICERCA",
    APPROVAZIONE_MOBILITA = "APPROVAZIONE_MOBILITA",
    AUTORIZZAZIONE_MOBILITA = "AUTORIZZAZIONE_MOBILITA",
    AUTORIZZAZIONE_REGISTRO_ATTIVITA = "AUTORIZZAZIONE_REGISTRO_ATTIVITA",
    APPROVAZIONE_MISSIONI = 'APPROVAZIONE_MISSIONI',
    APPROVAZIONE_VALIDAZIONE_MOBILITA = 'APPROVAZIONE_VALIDAZIONE_MOBILITA',
    APPROVAZIONE_VALIDAZIONE_RICERCA = 'APPROVAZIONE_VALIDAZIONE_RICERCA',
    APPROVAZIONE_CONCLUSIONE_MOBILITA = 'APPROVAZIONE_CONCLUSIONE_MOBILITA',
    SUPERAMENTO_ATTIVITA_EXTRA = 'SUPERAMENTO_ATTIVITA_EXTRA',
    APPROVAZIONE_CONCLUSIONE_RICERCA = 'APPROVAZIONE_CONCLUSIONE_RICERCA',
    // PTA
    APPROVAZIONE_SPESE_PTA = 'APPROVAZIONE_SPESE_PTA',
    APPROVAZIONE_MODIFICA_BUDGET = 'APPROVAZIONE_MODIFICA_BUDGET',
}

export interface OperazioneMassivaI {
    descrizione: string;
    tipo: OperazioneMassivaTipo;
}

export interface OperazioneMassivaStepConfig {
    stepName: string;
    stepState: string;
    component: Type<any>;
    dataInjector: Injector;
    previewStringFn: (value: any) => string;
    hideNavButtons?: boolean;
    hideBack?: boolean;
}

export interface OperazioneMassivaDataI {
    showWithDelibera?: boolean;
    deliberaMode?: StudyPlanWithDeliberaMode;
    showWithDeliberaTranslatedLabel?: string;
    refreshData?: boolean;
}


// get operations and steps utils

export function getOperazioniDisponibiliBySottoruolo(sottoruolo: AuthorityType): OperazioneMassivaI[] {
    switch (sottoruolo) {
        case AuthorityType.DOCENTECORSO:
            return [
                {
                    descrizione: "massive_operations.superamento_corso.op_title",
                    tipo: OperazioneMassivaTipo.SUPERAMENTO_CORSO
                }
            ];
        case AuthorityType.COORDINATORE:
            return [
                {
                    descrizione: "massive_operations.approvazione_trasversali.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_TRASVERSALI
                },
                {
                    descrizione: "massive_operations.approvazione_mobilita.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_MOBILITA
                },
                {
                    descrizione: "massive_operations.approvazione_ricerca.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_RICERCA
                },
                {
                    descrizione: "massive_operations.approvazione_piani.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_PIANI
                },
                {
                    descrizione: "massive_operations.approvazione_spese.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_SPESE
                },
                {
                    descrizione: "massive_operations.autorizzazione_mobilita.op_title",
                    tipo: OperazioneMassivaTipo.AUTORIZZAZIONE_MOBILITA
                },
                {
                    descrizione: "massive_operations.approvazione_registro_attivita.op_title",
                    tipo: OperazioneMassivaTipo.AUTORIZZAZIONE_REGISTRO_ATTIVITA
                },
                {
                    descrizione: "massive_operations.approvazione_missioni.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_MISSIONI
                },
                { descrizione: "massive_operations.approvazione_validazione_mobilita.op_title", tipo: OperazioneMassivaTipo.APPROVAZIONE_VALIDAZIONE_MOBILITA},
                { descrizione: "massive_operations.approvazione_validazione_ricerca.op_title", tipo: OperazioneMassivaTipo.APPROVAZIONE_VALIDAZIONE_RICERCA},
                { descrizione: "massive_operations.approvazione_conclusione_mobilita.op_title", tipo: OperazioneMassivaTipo.APPROVAZIONE_CONCLUSIONE_MOBILITA},
                { descrizione: "massive_operations.approvazione_conclusione_ricerca.op_title", tipo: OperazioneMassivaTipo.APPROVAZIONE_CONCLUSIONE_RICERCA},
                {
                    descrizione: "massive_operations.approvazione_modifiche_budget.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_MODIFICA_BUDGET
                },
                {
                    descrizione: "massive_operations.approvazione_spese_pta.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_SPESE_PTA
                },
            ];
        case AuthorityType.SUPERVISORE:
        case AuthorityType.COSUPERVISORE:
            return [
                {
                    descrizione: "massive_operations.approvazione_trasversali.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_TRASVERSALI
                },
                {
                    descrizione: "massive_operations.approvazione_mobilita.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_MOBILITA
                },
                {
                    descrizione: "massive_operations.approvazione_ricerca.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_RICERCA
                },
                {
                    descrizione: "massive_operations.approvazione_piani.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_PIANI
                },
                {
                    descrizione: "massive_operations.approvazione_spese.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_SPESE
                },
                {
                    descrizione: "massive_operations.autorizzazione_mobilita.op_title",
                    tipo: OperazioneMassivaTipo.AUTORIZZAZIONE_MOBILITA
                },
                {
                    descrizione: "massive_operations.approvazione_registro_attivita.op_title",
                    tipo: OperazioneMassivaTipo.AUTORIZZAZIONE_REGISTRO_ATTIVITA
                },
                {
                    descrizione: "massive_operations.approvazione_missioni.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_MISSIONI
                },
                {
                    descrizione: "massive_operations.approvazione_validazione_mobilita.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_VALIDAZIONE_MOBILITA
                },
                {
                    descrizione: "massive_operations.approvazione_conclusione_mobilita.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_CONCLUSIONE_MOBILITA
                },
                {
                    descrizione: "massive_operations.superamento_extra.op_title",
                    tipo: OperazioneMassivaTipo.SUPERAMENTO_ATTIVITA_EXTRA
                },
            ];
        case AuthorityType.GRUPPOPTA:
            return [
                {
                    descrizione: "massive_operations.approvazione_modifiche_budget.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_MODIFICA_BUDGET
                },
                {
                    descrizione: "massive_operations.approvazione_spese_pta.op_title",
                    tipo: OperazioneMassivaTipo.APPROVAZIONE_SPESE_PTA
                },
            ];
        default:
            return [];
    }
}

export function getStepsByOperazione(tipo: OperazioneMassivaTipo, injector: Injector, profilo: AuthorityType): OperazioneMassivaStepConfig[] {
    switch (tipo) {
        case OperazioneMassivaTipo.SUPERAMENTO_CORSO:
            return getSuperamentoCorsoMassivoConfig(injector);
        case OperazioneMassivaTipo.APPROVAZIONE_SPESE:
            return getApprovazioneSpeseMassivaConfig(injector, profilo);
        case OperazioneMassivaTipo.APPROVAZIONE_TRASVERSALI:
            return getApprovazioneTrasversaliMassivaConfig(injector, profilo);
        case OperazioneMassivaTipo.APPROVAZIONE_PIANI:
            return getApprovazionePianiMassivaConfig(injector, profilo);
        case OperazioneMassivaTipo.APPROVAZIONE_RICERCA:
            return getApprovazioneRicercaMassivaConfig(injector, profilo);
        case OperazioneMassivaTipo.APPROVAZIONE_MOBILITA:
            return getApprovazioneMobilitaMassivaConfig(injector, profilo);
        case OperazioneMassivaTipo.AUTORIZZAZIONE_MOBILITA:
            return getAutorizzazioneMobilitaMassivaConfig(injector);
        case OperazioneMassivaTipo.AUTORIZZAZIONE_REGISTRO_ATTIVITA:
            return getApprovazioneRegistroAttivitaMassivaConfig(injector, profilo);
        case OperazioneMassivaTipo.APPROVAZIONE_MISSIONI:
            return getApprovazioneMissioniMassivaConfig(injector, profilo);
        case OperazioneMassivaTipo.APPROVAZIONE_VALIDAZIONE_MOBILITA:
            return getApprovazioneValidazioneMobilitaMassivaConfig(injector, profilo, TipoPeriodoEnum.SOGGIORNOESTERO);
        case OperazioneMassivaTipo.APPROVAZIONE_CONCLUSIONE_MOBILITA:
            return getApprovazioneConclusioneMobilitaMassivaConfig(injector, profilo, TipoPeriodoEnum.SOGGIORNOESTERO);
        case OperazioneMassivaTipo.APPROVAZIONE_VALIDAZIONE_RICERCA:
            return getApprovazioneValidazioneMobilitaMassivaConfig(injector, profilo, TipoPeriodoEnum.RICERCA);
        case OperazioneMassivaTipo.APPROVAZIONE_CONCLUSIONE_RICERCA:
            return getApprovazioneConclusioneMobilitaMassivaConfig(injector, profilo, TipoPeriodoEnum.RICERCA);
        case OperazioneMassivaTipo.APPROVAZIONE_MODIFICA_BUDGET:
            return getApprovazioneModificheBudgetMassivaConfig(injector);
        case OperazioneMassivaTipo.APPROVAZIONE_SPESE_PTA:
            return getApprovazioneSpesePTAMassivaConfig(injector);
        case OperazioneMassivaTipo.SUPERAMENTO_ATTIVITA_EXTRA:
            return getSuperamentoExtraMassivaConfig(injector, profilo);
        default:
            return [];
    }
}


// configs creator methods

export function getSuperamentoCorsoMassivoConfig(injector: Injector): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<SuperamentoCorsoMassivoDataI>({});
    return [
        {
            stepState: 'phone',
            hideBack: true,
            stepName: 'massive_operations.superamento_corso.steps.choose_course',
            component: ScegliCorsoComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                ], parent: injector
            }),
            previewStringFn: (stepperDataSuperamento: SuperamentoCorsoMassivoDataI) => {
                /*if(stepperDataSuperamento.corsoSelected){
                    return '- ' + stepperDataSuperamento.corsoSelected?.denominazione;
                } else {
                    return '';
                }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.superamento_corso.steps.choose_students',
            component: ScegliStudentiComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                ], parent: injector
            }),
            previewStringFn: (stepperDataSuperamento: SuperamentoCorsoMassivoDataI) => {
                /* if(stepperDataSuperamento.studentiSelected?.length > 0){
                     return '- ' + stepperDataSuperamento.studentiSelected.map(s => s.nome + ' ' + s.cognome).join(', ');
                 } else {
                     return '';
                 }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.superamento_corso.steps.add_compile_info',
            component: CompilaInfoSuperamentoComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                ], parent: injector
            }),
            previewStringFn: (stepperDataSuperamento: SuperamentoCorsoMassivoDataI) => {
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.superamento_corso.steps.confirm',
            component: ConfirmSuperamentoCorsoComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                ], parent: injector
            }),
            previewStringFn: (stepperDataSuperamento: SuperamentoCorsoMassivoDataI) => {
                return '';
            }
        },
    ];
}

export function getApprovazioneSpeseMassivaConfig(injector: Injector, profilo: AuthorityType): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<ApprovazioneSpeseMassivaDataI>({});
    return [
        {
            stepState: 'phone',
            hideBack: true,
            stepName: 'massive_operations.approvazione_spese.steps.choose_budgets',
            component: ScegliSpeseComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneSpeseMassivaDataI) => {
                /* if(stepperData.speseSelected?.length > 0){
                     return '- ' + stepperData.speseSelected?.map(s => s.codiceSpesa).join(', ');
                 } else {
                     return '';
                 }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_spese.steps.add_compile_info',
            component: CompilaInfoApprovazioneComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneSpeseMassivaDataI) => {
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_spese.steps.confirm',
            component: ConfirmApprovazioneSpeseComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneSpeseMassivaDataI) => {
                return '';
            }
        },
    ];
}


export function getSuperamentoExtraMassivaConfig(injector: Injector, profilo: AuthorityType): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<ApprovazioneExtraMassivaDataI>({});
    return [
        {
            stepState: 'phone',
            hideBack: true,
            stepName: 'massive_operations.superamento_extra.steps.choose_extra',
            component: ScegliExtraComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: CURRENT_PROFILE, useValue: profilo},
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneExtraMassivaDataI) => {
                /* if(stepperData.speseSelected?.length > 0){
                     return '- ' + stepperData.speseSelected?.map(s => s.codiceSpesa).join(', ');
                 } else {
                     return '';
                 }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.superamento_extra.steps.add_compile_info',
            component: CompilaInfoExtraComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: CURRENT_PROFILE, useValue: profilo},
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneExtraMassivaDataI) => {
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.superamento_extra.steps.confirm',
            component: ConfirmSuperamentoExtraComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({
                providers: [
                    {provide: CURRENT_PROFILE, useValue: profilo},
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneExtraMassivaDataI) => {
                return '';
            }
        },
    ];
}

export function getApprovazioneSpesePTAMassivaConfig(injector: Injector): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<ApprovazioneSpesaPTAMassivaDataI>({});
    return [
        {
            stepState: 'phone',
            hideBack: true,
            stepName: 'massive_operations.approvazione_spese_pta.steps.choose_budgets',
            component: ScegliSpesaPtaComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneSpesaPTAMassivaDataI) => {
                /* if(stepperData.speseSelected?.length > 0){
                     return '- ' + stepperData.speseSelected?.map(s => s.codiceSpesa).join(', ');
                 } else {
                     return '';
                 }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_spese_pta.steps.add_compile_info',
            component: CompilaInfoApprovazioneSpesaPtaComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneSpesaPTAMassivaDataI) => {
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_spese_pta.steps.confirm',
            component: ConfirmApprovazioneSpesaPtaComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneSpesaPTAMassivaDataI) => {
                return '';
            }
        },
    ];
}

export function getApprovazioneModificheBudgetMassivaConfig(injector: Injector): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<ApprovazioneModificheBudgetMassivaDataI>({});
    return [
        {
            stepState: 'phone',
            hideBack: true,
            stepName: 'massive_operations.approvazione_modifiche_budget.steps.choose_budgets',
            component: ScegliModificheBudgetComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},

                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneModificheBudgetMassivaDataI) => {
                /* if(stepperData.speseSelected?.length > 0){
                     return '- ' + stepperData.speseSelected?.map(s => s.codiceSpesa).join(', ');
                 } else {
                     return '';
                 }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_modifiche_budget.steps.add_compile_info',
            component: CompilaInfoApprovazioneModificheBudgetComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneModificheBudgetMassivaDataI) => {
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_modifiche_budget.steps.confirm',
            component: ConfirmApprovazioneModificheBudgetComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneModificheBudgetMassivaDataI) => {
                return '';
            }
        },
    ];
}

export function getApprovazioneValidazioneMobilitaMassivaConfig(injector: Injector, profilo: AuthorityType, tipo: TipoPeriodoEnum): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<ApprovazioneValidazioneMobilitaMassivaDataI>({});
    return [
        {
            stepState: 'phone',
            hideBack: true,
            stepName: 'massive_operations.approvazione_validazione_mobilita.steps.choose_requests',
            component: ScegliRichiesteValidazioneComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo},
                    { provide: PERIOD_TYPE$, useValue: tipo }
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneValidazioneMobilitaMassivaDataI) => {
                /* if(stepperData.speseSelected?.length > 0){
                     return '- ' + stepperData.speseSelected?.map(s => s.codiceSpesa).join(', ');
                 } else {
                     return '';
                 }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_validazione_mobilita.steps.add_compile_info',
            component: CompilaInfoApprovazioneRichiesteValidazioneComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneValidazioneMobilitaMassivaDataI) => {
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_validazione_mobilita.steps.confirm',
            component: ConfirmApprovazioneRichiesteValidazioneComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneValidazioneMobilitaMassivaDataI) => {
                return '';
            }
        },
    ];
}

export function getApprovazioneConclusioneMobilitaMassivaConfig(injector: Injector, profilo: AuthorityType, tipo: TipoPeriodoEnum): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<ApprovazioneConclusioneMobilitaMassivaDataI>({});
    return [
        {
            stepState: 'phone',
            hideBack: true,
            stepName: 'massive_operations.approvazione_conclusione_mobilita.steps.choose_periods',
            component: ScegliConclusioneMobilitaComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo},
                    { provide: PERIOD_TYPE$, useValue: tipo }
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneConclusioneMobilitaMassivaDataI) => {
                /* if(stepperData.speseSelected?.length > 0){
                     return '- ' + stepperData.speseSelected?.map(s => s.codiceSpesa).join(', ');
                 } else {
                     return '';
                 }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_conclusione_mobilita.steps.confirm',
            component: ConfirmApprovazioneConclusioneMobilitaComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneConclusioneMobilitaMassivaDataI) => {
                return '';
            }
        },
    ];
}

export function getApprovazioneMissioniMassivaConfig(injector: Injector, profilo: AuthorityType): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<ApprovazioneMissioniMassivaDataI>({});
    return [
        {
            stepState: 'phone',
            hideBack: true,
            stepName: 'massive_operations.approvazione_missioni.steps.choose_budgets',
            component: ScegliMissioniComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneMissioniMassivaDataI) => {
                /* if(stepperData.speseSelected?.length > 0){
                     return '- ' + stepperData.speseSelected?.map(s => s.codiceSpesa).join(', ');
                 } else {
                     return '';
                 }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_missioni.steps.confirm',
            component: ConfirmApprovazioneMissioniComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneMissioniMassivaDataI) => {
                return '';
            }
        },
    ];
}

export function getApprovazioneRegistroAttivitaMassivaConfig(injector: Injector, profilo: AuthorityType): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<ApprovazioneRegistroAttivitaMassivaDataI>({});
    return [
        {
            stepState: 'phone',
            hideBack: true,
            stepName: 'massive_operations.approvazione_registro_attivita.steps.choose_registri',
            component: ScegliRegistriAttivitaComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneRegistroAttivitaMassivaDataI) => {
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_registro_attivita.steps.add_compile_info',
            component: CompilaInfoApprovazioneRegistroComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneRegistroAttivitaMassivaDataI) => {
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_registro_attivita.steps.confirm',
            component: ConfirmApprovazioneRegistroAttivitaComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneRegistroAttivitaMassivaDataI) => {
                return '';
            }
        },
    ];
}

export function getApprovazioneTrasversaliMassivaConfig(injector: Injector, profilo: AuthorityType): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<ApprovazioneTrasversaliMassivaDataI>({});
    const deliberaStep = {
        stepState: 'phone',
        hideBack: true,
        stepName: 'massive_operations.approvazione_trasversali.steps.with_delibera',
        component: ShowWithDeliberaStepComponent,
        dataInjector: Injector.create({
            providers: [
                {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)}
            ], parent: injector
        }),
        previewStringFn: (stepperData: OperazioneMassivaDataI) => {
            /* if(!!stepperData.showWithDeliberaTranslatedLabel){
                 return ' - ' + stepperData.showWithDeliberaTranslatedLabel;
             } else {
                 return '';
             }*/
            return '';
        }
    };
    const steps = [
        {
            stepState: 'phone',
            stepName: 'massive_operations.approvazione_trasversali.steps.choose_trasversali',
            component: ScegliTrasversaliComponent,
            hideBack: profilo !== AuthorityType.COORDINATORE,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneTrasversaliMassivaDataI) => {
                // if (stepperData.attivitaSelected?.length > 0) {
                //     return '- ' + stepperData.attivitaSelected?.map(a => a.denominazione)?.join(', ');
                // } else {
                //     return '';
                // }
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: profilo === AuthorityType.COORDINATORE ?
                'massive_operations.approvazione_trasversali.steps.add_compile_info' :  'massive_operations.approvazione_trasversali.steps.add_compile_info_supervisor' ,
            component: CompilaInfoApprovazioneTrasversaliComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneTrasversaliMassivaDataI) => {
                /*if(stepperData.deliberaCollegio){
                    return '- ' + stepperData.deliberaCollegio?.numero + ' - ' + moment(stepperData?.deliberaCollegio?.data, 'YYYY-MM-DD').format('DD/MM/YYYY');
                } else {
                    return '';
                }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_trasversali.steps.confirm',
            component: ConfirmApprovazioneTrasversaliComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneTrasversaliMassivaDataI) => {
                return '';
            }
        },
    ];
    if (profilo === AuthorityType.COORDINATORE) {
        return [deliberaStep, ...steps];
    } else {
        return steps
    }
}

export function getApprovazionePianiMassivaConfig(injector: Injector, profilo: AuthorityType): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<ApprovazionePianiMassivaDataI>({});
    const deliberaStep = {
        stepState: 'phone',
        hideBack: true,
        stepName: 'massive_operations.approvazione_piani.steps.with_delibera_title',
        component: ShowWithDeliberaForPlanStepComponent,
        dataInjector: Injector.create({
            providers: [
                {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)}
            ], parent: injector
        }),
        previewStringFn: (stepperData: OperazioneMassivaDataI) => {
            /* if(!!stepperData.showWithDeliberaTranslatedLabel){
                 return ' - ' + stepperData.showWithDeliberaTranslatedLabel;
             } else {
                 return '';
             }*/
            return '';
        }
    };
    const steps = [
        {
            stepState: 'phone',
            stepName: 'massive_operations.approvazione_piani.steps.choose_students',
            component: ScegliStudentiPianiComponent,
            hideBack: profilo !== AuthorityType.COORDINATORE,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazionePianiMassivaDataI) => {
                /*if(stepperData.studentiSelected?.length > 0){
                    return '- ' + stepperData.studentiSelected?.map(s => s.cognome + ' ' + s.nome).join(', ');
                } else {
                    return '';
                }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_piani.steps.add_compile_info',
            component: CompilaInfoApprovazionePianiComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazionePianiMassivaDataI) => {
                /* if(stepperData.deliberaCollegio){
                     return '- ' + stepperData.deliberaCollegio?.numero + ' - ' + moment(stepperData?.deliberaCollegio?.data, 'YYYY-MM-DD').format('DD/MM/YYYY');
                 } else {
                     return '';
                 }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_piani.steps.confirm',
            component: ConfirmApprovazionePianiComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazionePianiMassivaDataI) => {
                return '';
            }
        },
    ];
    if (profilo === AuthorityType.COORDINATORE) {
        return [deliberaStep, ...steps];
    } else {
        return steps;
    }
}

export function getApprovazioneRicercaMassivaConfig(injector: Injector, profilo: AuthorityType): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<ApprovazioneRicercaMassivaDataI>({});
    return [
        {
            stepState: 'phone',
            hideBack: true,
            stepName: 'massive_operations.approvazione_ricerca.steps.choose_periods',
            component: ScegliPeriodiRicercaComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneRicercaMassivaDataI) => {
                /*if(stepperData.periodiSelected?.length > 0){
                    return '- ' + stepperData.periodiSelected?.map(s =>
                        s?.codiceMobilita + ' (' + s?.studenteCiclo?.utente?.cognome + ' ' + s?.studenteCiclo?.utente?.nome + ')'
                    ).join(', ');
                } else {
                    return '';
                }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_ricerca.steps.add_compile_info',
            component: CompilaInfoApprovazioneRicercaComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneRicercaMassivaDataI) => {
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_ricerca.steps.confirm',
            component: ConfirmApprovazioneRicercaComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneRicercaMassivaDataI) => {
                return '';
            }
        },
    ];
}

export function getApprovazioneMobilitaMassivaConfig(injector: Injector, profilo: AuthorityType): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<ApprovazioneRicercaMassivaDataI>({});
    return [
        {
            stepState: 'phone',
            hideBack: true,
            stepName: 'massive_operations.approvazione_mobilita.steps.choose_periods',
            component: ScegliPeriodiMobilitaComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneMobilitaMassivaDataI) => {
                /* if(stepperData.periodiSelected?.length > 0){
                     return '- ' + stepperData.periodiSelected?.map(s =>
                         s?.codiceMobilita + ' (' + s?.studenteCiclo?.utente?.cognome + ' ' + s?.studenteCiclo?.utente?.nome + ')'
                     ).join(', ');
                 } else {
                     return '';
                 }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_mobilita.steps.add_compile_info',
            component: CompilaInfoApprovazioneMobilitaComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneMobilitaMassivaDataI) => {
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_mobilita.steps.confirm',
            component: ConfirmApprovazioneMobilitaComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                    {provide: CURRENT_PROFILE, useValue: profilo}
                ], parent: injector
            }),
            previewStringFn: (stepperData: ApprovazioneMobilitaMassivaDataI) => {
                return '';
            }
        },
    ];
}

export function getAutorizzazioneMobilitaMassivaConfig(injector: Injector): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<AutorizzazioneMobilitaMassivaDataI>({});
    return [
        {
            stepState: 'phone',
            hideBack: true,
            stepName: 'massive_operations.autorizzazione_mobilita.steps.choose_students',
            component: ScegliStudentiAutorizzazioneMobilitaComponent,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                ], parent: injector
            }),
            previewStringFn: (stepperData: AutorizzazioneMobilitaMassivaDataI) => {
                /*if(stepperData.studentiSelected?.length > 0){
                    return '- ' + stepperData.studentiSelected?.map(s => s.cognome + ' ' + s.nome).join(', ');
                } else {
                    return '';
                }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.autorizzazione_mobilita.steps.confirm',
            component: ConfirmAutorizzazioneMobilitaComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({
                providers: [
                    {provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData},
                    {provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false)},
                ], parent: injector
            }),
            previewStringFn: (stepperData: AutorizzazioneMobilitaMassivaDataI) => {
                return '';
            }
        },
    ];
}
