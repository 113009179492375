/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Tipologie di membri nello storico studente. Tipologie: *GRUPPO_DI_COORDINAMENTO*, *COMMISSIONE_DI_AUTOVALUTAZIONE*, *COLLEGIO_DOCENTI*, *COORDINATORE*, *GRUPPO_PTA*
 */
export type TipoMembroCorsoDiStudi = 'GRUPPO_DI_COORDINAMENTO' | 'COMMISSIONE_DI_AUTOVALUTAZIONE' | 'DIRETTORE_DI_DIPARTIMENTO' | 'COLLEGIO_DOCENTI' | 'COORDINATORE' | 'GRUPPO_PTA' | 'SEGRETARIO_AMMINISTRATIVO_DI_DIPARTIMENTO';

export const TipoMembroCorsoDiStudi = {
    GRUPPODICOORDINAMENTO: 'GRUPPO_DI_COORDINAMENTO' as TipoMembroCorsoDiStudi,
    COMMISSIONEDIAUTOVALUTAZIONE: 'COMMISSIONE_DI_AUTOVALUTAZIONE' as TipoMembroCorsoDiStudi,
    DIRETTOREDIDIPARTIMENTO: 'DIRETTORE_DI_DIPARTIMENTO' as TipoMembroCorsoDiStudi,
    COLLEGIODOCENTI: 'COLLEGIO_DOCENTI' as TipoMembroCorsoDiStudi,
    COORDINATORE: 'COORDINATORE' as TipoMembroCorsoDiStudi,
    GRUPPOPTA: 'GRUPPO_PTA' as TipoMembroCorsoDiStudi,
    SEGRETARIOAMMINISTRATIVODIDIPARTIMENTO: 'SEGRETARIO_AMMINISTRATIVO_DI_DIPARTIMENTO' as TipoMembroCorsoDiStudi
};