import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree,} from '@angular/router';
import {Observable, of} from "rxjs";
import {FuseConfirmationService} from "../../../@fuse/services/confirmation";


@Injectable({
    providedIn: 'root'
})
export class SupportGuard implements CanDeactivate<any> {
    /**
     * Constructor
     */
    constructor(
        private fuseConfirmationService: FuseConfirmationService,
    ) {
    }

    canDeactivate(component: any, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean> {
        return of(!this.fuseConfirmationService.errorDuringAppInit);
    }





}
