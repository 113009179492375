/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Stati temporali filter. Valori previsti: *DA_COMINCIARE*, *IN_CORSO*, *CONCLUSO*
 */
export type StatoTemporaleEnum = 'DA_COMINCIARE' | 'IN_CORSO' | 'CONCLUSO';

export const StatoTemporaleEnum = {
    DACOMINCIARE: 'DA_COMINCIARE' as StatoTemporaleEnum,
    INCORSO: 'IN_CORSO' as StatoTemporaleEnum,
    CONCLUSO: 'CONCLUSO' as StatoTemporaleEnum
};