import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TabsEnum} from "../requests-to-be-resolved.component";
import {AuthorityType, MediaService, PeriodiDiMobilitaService} from "../../../../../api-clients/generated/services";
import {AbstractTableMobilityResearchComponent} from "../abstract/abstract-table-mobility-research-component";
import {FuseConfirmationService} from "../../../../../@fuse/services/confirmation";
import {LocalStorageService} from "../../../../shared/service/local-storage.service";
import {AppInitService} from "../../../../shared/service/app-init.service";
import {MobilitaFilterService} from "../mobilita-sidebar-filter-container/mobilita-filter.service";
import {TranslocoService} from "@ngneat/transloco";
import {LogoutService} from "../../../../shared/service/logout.service";
import {Router} from "@angular/router";
import {DomSanitizer} from "@angular/platform-browser";
import {MatDialog} from "@angular/material/dialog";
import {ExcelService} from "../../../../shared/service/excel.service";
import {get, isEmpty} from "lodash";
import moment from "moment";
import {GenericTableComponent} from "../../../../shared/components/table/generic-table/generic-table.component";

@Component({
    selector: 'app-table-mobility',
    templateUrl: './table-mobility.component.html',
    styleUrls: ['./table-mobility.component.scss']
})
export class TableMobilityComponent extends AbstractTableMobilityResearchComponent implements OnInit {

    @Input() currentRuolo: AuthorityType;
    @Output() toogleFilterContainer$ = new EventEmitter<{ hasToogleInternalMenu: boolean, tab: TabsEnum }>();
    @Input() selectedTabTipo: TabsEnum;
    @Output() reset$ = new EventEmitter<TabsEnum>();
    @ViewChild(GenericTableComponent) set genericTable(gt: GenericTableComponent) {
        this.table = gt;
    }
    constructor(public fuseConfirmationService: FuseConfirmationService,
                public localStorageService: LocalStorageService,
                public appInitService: AppInitService,
                public abstractFilterService: MobilitaFilterService,
                public translocoService: TranslocoService,
                public logoutService: LogoutService,
                public router: Router,
                public mediaService: MediaService,
                public sanitizer: DomSanitizer,
                public dialog: MatDialog,
                public excelService: ExcelService,
                public periodiDiMobilitaService: PeriodiDiMobilitaService) {
        super(fuseConfirmationService,
            localStorageService,
            appInitService,
            abstractFilterService,
            translocoService,
            logoutService, router,
            mediaService,
            sanitizer,
            dialog,
            excelService,
            periodiDiMobilitaService);
        this.mobilitaFieldsLabelMap = this.abstractFilterService.fieldsLabelMap;
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    reset(): void {
        this.reset$.next(this.selectedTabTipo);
    }


}
