import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
    AbstractDefaultComponent
} from '../../../../../shared/abstracts/abstract-default-component/abstract-default-component';
import {
    AttivitaOffertaFormativaOfCurrentDocenteCorsoCountDTO,
    AuthorityType,
    MediaService,
    MobilitaRequestSelectFormValuesDTO,
    OfferteFormativeService,
    StudentiCicloService
} from '../../../../../../api-clients/generated/services';
import {GenericTableComponent} from '../../../../../shared/components/table/generic-table/generic-table.component';
import {
    ClickEvent,
    GenericTableConfigurationModel,
    TipoClickEnum
} from '../../../../../shared/components/table/model/generic-table-model';
import {DEFAULT_PAGE_SIZE} from '../superamento-corsi.component';
import {FuseConfirmationService} from '../../../../../../@fuse/services/confirmation';
import {LocalStorageService} from '../../../../../shared/service/local-storage.service';
import {AppInitService} from '../../../../../shared/service/app-init.service';
import {TranslocoService} from '@ngneat/transloco';
import {LogoutService} from '../../../../../shared/service/logout.service';
import {Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {MatDialog} from '@angular/material/dialog';
import {ExcelService} from '../../../../../shared/service/excel.service';
import {finalize, switchMap, take, takeUntil} from 'rxjs';
import {filter} from 'rxjs/operators';
import {createTableConfigurationForCourses} from '../../requests-utils';
import {StudentDetailManagerService} from '../../../../../shared/service/student-detail-manager.service';
import {cloneDeep} from 'lodash-es';
import {CorsiFilter, CorsiFilterService} from '../corsi-sidebar-filter-container/corsi-filter.service';
import {get, isEmpty} from 'lodash';


@Component({
    selector: 'app-table-superamento-corsi',
    templateUrl: './superamento-corsi-table.component.html',
    styleUrls: ['./superamento-corsi-table.component.scss']
})
export class SuperamentoCorsiTableComponent extends AbstractDefaultComponent implements OnInit {

    @Input() currentRuolo: AuthorityType;
    @Output() toogleFilterContainer$ = new EventEmitter<{ hasToogleInternalMenu: boolean }>();
    table: GenericTableComponent;
    attivitaOffertaFormativa: AttivitaOffertaFormativaOfCurrentDocenteCorsoCountDTO[];

    @ViewChild(GenericTableComponent) set genericTable(gt: GenericTableComponent) {
        this.table = gt;
    }

    tableConfiguration: GenericTableConfigurationModel;
    page: number = 0;
    pageSize: number = DEFAULT_PAGE_SIZE;
    showMiniLoader: boolean;
    loading: boolean;
    selectValues: MobilitaRequestSelectFormValuesDTO;
    fieldsLabelMap: Map<string, string>;
    currentFilterListChipLabels: Array<string>;
    error: boolean;

    isFirstTime = true;

    constructor(protected fuseConfirmationService: FuseConfirmationService,
                protected localStorageService: LocalStorageService,
                protected appInitService: AppInitService,
                protected translocoService: TranslocoService,
                protected logoutService: LogoutService,
                protected router: Router,
                protected corsiFilterService: CorsiFilterService,
                protected mediaService: MediaService,
                protected sanitizer: DomSanitizer,
                protected dialog: MatDialog,
                protected excelService: ExcelService,
                protected studentiCicloService: StudentiCicloService,
                private offertaFormativaService: OfferteFormativeService,
                private studentDetailManagerService: StudentDetailManagerService) {
        super();
        this.fieldsLabelMap = this.corsiFilterService.fieldsLabelMap;
    }

    ngOnInit(): void {
        this.isFirstTime = true;
        this.getCorsiRequest();
    }

    private getCorsiRequest() {
        this.loading = true;
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.offertaFormativaService.getOffertaFormativaOfCurrentDocenteCorso()),
            takeUntil(this.destroy$),
            finalize(() => this.loading = false)
        ).subscribe({
            next: (attivitaOffertaFormativa: AttivitaOffertaFormativaOfCurrentDocenteCorsoCountDTO[]) => {
                this.attivitaOffertaFormativa = attivitaOffertaFormativa;
                this.applyFilter();
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => {
                        this.logoutService.goToHome();
                    },
                    () => this.getCorsiRequest(),
                    'common.go_to_home',
                    err?.error?.message);
            }
        });
    }


    ngOnDestroy() {
        super.ngOnDestroy();
    }


    tableClickAction($event: ClickEvent): void {
        switch ($event?.tipoClick) {
            case TipoClickEnum.SHOW:
                this.studentDetailManagerService.openDescriptionDialog($event?.value?.descrizione);
                break;
        }
    }

    getDataForFilters(): void {
        this.toogleFilterContainer$.emit({hasToogleInternalMenu: true});
    }


    applyFilter() {
        const filters: CorsiFilter = this.corsiFilterService.mainFormGroup.getRawValue();
        const attivitaOffertaFormativa = cloneDeep(this.attivitaOffertaFormativa).filter(value => filtriSuperamentoCorsi(filters, value));
        if(!this.isFirstTime){
            this.fuseConfirmationService.showLoader();
        }
        setTimeout(() => {
            this.tableConfiguration = createTableConfigurationForCourses(attivitaOffertaFormativa);
            this.fuseConfirmationService.hideLoader();
            this.currentFilterListChipLabels = this.getListChipsLabel();
            this.isFirstTime = false;
            this.loading = false;
        }, this.isFirstTime ? 0  : 500);

    }

    reset(): void {
        this.corsiFilterService?.mainFormGroup?.reset();
        this.applyFilter();
    }

    getListChipsLabel(): Array<string> {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        if (!isEmpty(this.corsiFilterService?.mainFormGroup.getRawValue())) {
            const entries: [string, any][] = Object.entries(this.corsiFilterService.mainFormGroup?.value);
            return  chipsSupermanetoCorsi(entries, this.fieldsLabelMap, translation);
        } else {
            return [];
        }
    }

}

export const chipsSupermanetoCorsi = ( entries: [string, any][], fieldsLabelMap: Map<string, string>, translation: any) => {
    const filteredEntries = entries?.filter(item => item.every(value => !!value));
    return filteredEntries.map(([key, value]) => {
        const translatedKey: string = get(translation, fieldsLabelMap.get(key), key);
        if (key === 'nonEseguita') {
            return translatedKey;
        }
        return translatedKey + ': ' + value;
    });
};

export const filtriSuperamentoCorsi = (filters: CorsiFilter, value: AttivitaOffertaFormativaOfCurrentDocenteCorsoCountDTO) => {
    const isDenominazioneAttivita = !filters?.denominazioneAttivita?.trim() || ricercaLike(value.attivitaOffertaFormativa.denominazione, filters.denominazioneAttivita.trim());
    const isNonEseguita = filters?.nonEseguita ? value.totDottorandi - value.numDottorandiSuperato > 0 : true;
    return isDenominazioneAttivita && isNonEseguita;
};

export const ricercaLike = (testostringa, fraseDaCercare) => {
    const testoMinuscolo = testostringa.toLowerCase();
    const fraseMinuscola = fraseDaCercare.toLowerCase();

    if (testoMinuscolo === fraseMinuscola) {return true;}

    if (testoMinuscolo.includes(fraseMinuscola)) {return true;}

    return false;
};
