<ng-container *transloco="let t">
    <app-dialog-wrap class="w-full" [data]="data">

        <mat-dialog-content>
            <div class="w-full flex flex-col gap-4 mt-2">
                <div class="flex flex-col gap-4 items-center justify-between w-full">
                    <div class="flex flex-col items-center">
                        <div class="font-medium"
                             *ngIf="data?.deliberaDettaglio?.utenteCaricamentoDelibera as utenteCaricamentoDelibera">
                            {{t('mobility.did_by')}} {{utenteCaricamentoDelibera.cognome | uppercase}} {{utenteCaricamentoDelibera.nome | uppercase}}
                        </div>
                        <span class="text-secondary text-sm" *ngIf="data?.deliberaDettaglio?.dataCaricamentoDelibera">
                            {{italianDateWithFormat(data?.deliberaDettaglio?.dataCaricamentoDelibera)}}
                        </span>
                    </div>
                    <button *ngIf="data?.deliberaDettaglio?.delibera"
                            [color]="'primary'"
                            mat-raised-button
                            class="max-w-fit"
                            type="button"
                            (click)="showDelibera()">
                        <mat-icon class="mr-2">visibility</mat-icon>
                        {{t('documents_dashboard.approval_download')}}
                    </button>
                </div>
                <div class="flex w-full flex-col gap-4 my-2" *ngIf="!data?.hideStudents">
                    <div class="text-lg font-medium">{{t('mobility.students_in_delibera')}}</div>
                    <div *ngIf="data?.deliberaDettaglio?.studentiCiclo?.length > 0; else noStudents"
                         class="w-full border-t-2 border-slate-200 border-solid">
                        <ng-container *ngFor="let studente of data?.deliberaDettaglio?.studentiCiclo">
                            <div class="w-full flex flex-row items-center justify-between py-2 px-0.5 border-b-2 border-slate-200 border-solid">
                                <div class="grid grid-cols-1 grid-rows-3 lg:grid-rows-1 lg:grid-cols-3 w-full gap-2 items-center">
                                    <div class="font-medium">
                                        {{(studente.utente?.cognome + ' ' + studente.utente?.nome) | uppercase}}
                                    </div>
                                    <div class="font-normal flex flex-col">
                                        <span class="font-semibold text-sm">{{t('sign-up.fiscal_code')}}</span>
                                        <span>{{studente.utente?.codiceFiscale}}</span>
                                    </div>
                                    <div class="font-normal flex flex-col">
                                        <span class="font-semibold text-sm">{{t('students_list.enrollment_number')}}</span>
                                        <span>{{studente.matricola}}</span>
                                    </div>
                                </div>
                                <button mat-mini-fab
                                        (click)="goToStudentPage(studente?.utente?.id)"
                                        [matTooltip]="t('mobility.go_to_detail')">
                                    <mat-icon>person</mat-icon>
                                </button>
                            </div>
                        </ng-container>
                    </div>
                    <ng-template #noStudents>
                        <div class="mt-4 text-lg font-medium">
                            <fuse-alert
                                    class="w-full"
                                    [showIcon]="true"
                                    [appearance]="'outline'"
                                    [type]="'warning'"
                            >{{t('custom_table.no_data')}}</fuse-alert>
                        </div>
                    </ng-template>
                </div>
            </div>
        </mat-dialog-content>

    </app-dialog-wrap>

    <div
        class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">
        <button color="accent" mat-flat-button [matDialogClose]="'cancelled'">
            <mat-icon class="mr-2">close</mat-icon>
            {{t('common.close')}}
        </button>
    </div>
</ng-container>
