import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot,} from '@angular/router';
import {AuthorityType} from "../../../api-clients/generated/services";
import {LocalStorageService} from "../../shared/service/local-storage.service";
import {PathEnum} from "../../app.routing";
import {map, Observable, of} from "rxjs";
import {AppInitService} from "../../shared/service/app-init.service";
import {first} from "rxjs/operators";
import {CicloCorsoRuoloInterface} from "../../shared/interface/CicloCorsoRuoloInterface";


@Injectable({
    providedIn: 'root'
})
export class SpesePtaManagementGuard implements CanActivate {
    /**
     * Constructor
     */
    constructor(
        private _router: Router,
        private localStorageService: LocalStorageService,
        private appInitService: AppInitService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const cicloCorsoRuolo = this.localStorageService.getCicloCorsoRuolo(true);
        console.log('cicloCorsoRuolo from richieste da delibnerare guard', cicloCorsoRuolo)
        if (cicloCorsoRuolo?.ruolo === AuthorityType.PERSONALEPTA) {
            return this.appInitService.currentSottoruoliUtente$.pipe(
                first(),
                map(sottoruoli => {
                    if(!!sottoruoli) {
                        // condition for users with subroles
                        const canActivate = SpesePtaManagementGuard.accessConditionForSubroles(sottoruoli);
                        if(!canActivate) {
                            this._router.navigate([PathEnum.HOME]);
                        }
                        return canActivate;
                    }
                })
            );
        } else {
            // condition for users without subroles
            const canActivate = SpesePtaManagementGuard.accessConditionForRoles(cicloCorsoRuolo);
            if(!canActivate) {
                this._router.navigate([PathEnum.HOME]);
            }
            return of(canActivate);
        }
    }

    static accessConditionForRoles(cicloCorsoRuolo: CicloCorsoRuoloInterface): boolean {
        return cicloCorsoRuolo?.ruolo === AuthorityType.SUPERADMIN
            || cicloCorsoRuolo?.ruolo === AuthorityType.ADMIN;
    }

    static accessConditionForSubroles(sottoruoli: AuthorityType[]): boolean {
        return !!sottoruoli?.find(s =>
            s === AuthorityType.GRUPPOPTA
            || s === AuthorityType.SEGRETARIOAMMINISTRATIVODIDIPARTIMENTO);
    }

    static accessCondition(cicloCorsoRuolo: CicloCorsoRuoloInterface, sottoruoli: AuthorityType[]) {
        return SpesePtaManagementGuard.accessConditionForRoles(cicloCorsoRuolo) || SpesePtaManagementGuard.accessConditionForSubroles(sottoruoli);
    }


}
