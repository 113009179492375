import {Component, Inject} from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition
} from '@angular/material/snack-bar';

@Component({
    selector: 'app-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent  {

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarMessage,
                public snackBar: MatSnackBar) {
    }

    getIcon() {
        switch (this.data.type) {
            case SnackbarTypes.Success:
                return 'done';
            case SnackbarTypes.Error:
                return 'error_outline';
            case SnackbarTypes.Warning:
                return 'report_problem';
            default:
                return 'cancel';
        }
    }

    closeSnackbar() {
        this.snackBar.dismiss();
    }
}






export enum SnackbarTypes {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
}

export interface SnackBarMessage {
  message: string;
  horizontalPosition?: MatSnackBarHorizontalPosition;
  verticalPosition?: MatSnackBarVerticalPosition;
  duration?: number;
  type?: SnackbarTypes;
}
