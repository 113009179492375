<ng-container *transloco="let t">

    <div class="h-full w-full" fxLayout="column">
        <!--HEADER-->
        <app-header [title]="'common.support'"></app-header>

        <app-loading-screen *ngIf="loading; else surveysTemplate" class="w-full h-full"></app-loading-screen>
        <ng-template #surveysTemplate>
            <div class="h-full w-full" fxLayout="column">
                <div class="flex flex-col flex-auto bg-card shadow w-full h-full overflow-auto p-6">

                        <div class="w-full flex flex-col justify-between items-start gap-4">
                            <div class="w-full flex flex-row justify-end">
                                <button class="width-min-content multi-line-button"
                                        mat-flat-button
                                        color="primary"
                                        (click)="openAddNewTicketDialog()">
                                    <mat-icon>add</mat-icon>
                                    {{t('support.add_new_ticket')}}
                                </button>
                            </div>
                            <div class="w-full p-y-2.5" *ngIf="ticketsListTableConfiguration">
                                <app-generic-table *ngIf="!!ticketsListTableConfiguration"
                                                   [configuration]="ticketsListTableConfiguration"
                                                   (clickAction)="tableClickAction($event)"
                                                   (pageAction)="pageAction($event)">
                                </app-generic-table>
                            </div>
                        </div>

                </div>
            </div>
        </ng-template>

    </div>

</ng-container>

