<ng-container *transloco="let t">
    <app-dialog-wrap [data]="data">

        <mat-dialog-content>

            <div class="flex flex-col gap-4 w-full">

                <div class="flex flex-row gap-4 justify-between items-center p-4 bg-gray-50 dark:bg-black dark:bg-opacity-10 rounded-xl">
                    <div class="flex flex-row gap-4 justify-start items-center">
                        <span class="font-semibold">{{data.audit?.metodoHttp}}</span>
                        <span>{{data.audit?.uri}}</span>
                    </div>
                    <div class="flex flex-row gap-4 justify-start items-center">
                        <mat-chip [class]="data.audit?.codiceRisposta | statusEnumToColorTheme: undefined"
                                  class="justify-between">
                            <span class="line-clamp-1">{{data.audit?.codiceRisposta}}</span>
                        </mat-chip>
                    </div>
                </div>

                <mat-accordion class="w-full">

                    <mat-expansion-panel class="mb-2 w-full audit-panel">
                        <mat-expansion-panel-header class="min-h-6 pl-4">
                            <span class="font-semibold">{{t('audit.info_request')}}</span>
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                            <div class="flex flex-col gap-4">
                                <div class="text-sm w-full flex flex-col gap-1" *ngIf="data.audit?.headersRichiesta">
                                    <span class="font-semibold">{{t('audit.request_headers')}}</span>
                                    <ngx-json-viewer class="w-full json-container" [json]="data.audit?.headersRichiesta" [expanded]="false"></ngx-json-viewer>
                                </div>
                                <div class="text-sm w-full flex flex-col gap-1" *ngIf="parsedBodyParts?.length > 0">
                                    <span class="font-semibold">{{t('audit.body_parts')}}</span>
                                    <div class="w-full flex flex-col gap-3 bg-slate-50 p-4">
                                        <ngx-json-viewer class="w-full json-container"
                                                         *ngFor="let part of parsedBodyParts"
                                                         [json]="part"
                                                         [expanded]="false">
                                        </ngx-json-viewer>
                                    </div>
                                </div>
                                <div class="text-sm w-full flex flex-col gap-1" *ngIf="data.audit?.queryParams?.length > 0">
                                    <span class="font-semibold">{{t('audit.query_params')}}</span>
                                    <ngx-json-viewer class="w-full json-container" [json]="data.audit?.queryParams" [expanded]="false"></ngx-json-viewer>
                                </div>
                            </div>
                        </ng-template>
                    </mat-expansion-panel>

                    <mat-expansion-panel class="mb-2 w-full audit-panel" *ngIf="parsedBody">
                        <mat-expansion-panel-header class="min-h-6 pl-4">
                            <span class="font-semibold">{{t('audit.info_response')}}</span>
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                            <div class="flex flex-col gap-4">
                                <div class="text-sm w-full flex flex-col gap-1" *ngIf="parsedBody">
                                    <span class="font-semibold">{{t('audit.response_body')}}</span>
                                    <ngx-json-viewer class="w-full json-container" [json]="parsedBody" [expanded]="false"></ngx-json-viewer>
                                </div>
                            </div>
                        </ng-template>
                    </mat-expansion-panel>

                </mat-accordion>

            </div>


        </mat-dialog-content>

    </app-dialog-wrap>

    <div
        class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">
        <button color="accent" mat-flat-button [matDialogClose]="'cancelled'">
            <mat-icon class="mr-2">close</mat-icon>
            {{t('common.close')}}
        </button>
    </div>
</ng-container>
