import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TestoPrivacyComponent} from "./testo-privacy.component";
import {MatIconModule} from "@angular/material/icon";
import {TranslocoModule} from "@ngneat/transloco";


@NgModule({
    declarations: [TestoPrivacyComponent],
    exports: [
        TestoPrivacyComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        TranslocoModule
    ]
})
export class TestoPrivacyModule {
}
