import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogShowDeliberaComponent} from "./dialog-show-delibera.component";
import {TranslocoModule} from "@ngneat/transloco";
import {DialogWrapModule} from "../../../layout/common/dialog-wrap/dialog-wrap.module";
import {InputValueKeyModule} from "../../input-value-key/input-value-key.module";
import {FuseAlertModule} from "../../../../@fuse/components/alert";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";



@NgModule({
  declarations: [DialogShowDeliberaComponent],
    imports: [
        CommonModule,
        TranslocoModule,
        DialogWrapModule,
        InputValueKeyModule,
        FuseAlertModule,
        MatFormFieldModule,
        MatIconModule
    ]
})
export class DialogShowDeliberaModule { }
