import {Audit, PageAudit} from "../../../../api-clients/generated/services";
import moment from "moment";
import {
    GenericTableConfigurationModel,
    TipoClickEnum,
    TipoColonnaEnum
} from "../../../shared/components/table/model/generic-table-model";
import {DEFAULT_PAGE_SIZE} from "./audit.component";

export function buildAuditRequestsTableConfiguration(pageAudit: PageAudit, pageSize: number = DEFAULT_PAGE_SIZE): GenericTableConfigurationModel {
    console.log(pageAudit)
    const periodiDiMobilitaStudenteForUI = pageAudit?.content?.map((audit: Audit) =>
        ({
            ...audit,
            requestTimeFormatted: audit?.requestTime ? moment.utc(audit.requestTime).local().format('DD/MM/YYYY HH:mm') : undefined,
            utenteNomeCognomeEmail: ((audit?.cognome || '') + ' ' + (audit?.nome || '')).toUpperCase() + (audit?.email ? (' (' + audit?.email?.toLowerCase() + ')') : '')
        }));
    let config: GenericTableConfigurationModel = {
        configuration: {
            data: periodiDiMobilitaStudenteForUI,
            totalElements: pageAudit?.totalElements,
            pageSize: pageSize,
            pageSizeOptions: [10, 20, 30, 40, 50],
            isPaginatedBE: true,
            sticky: true,
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'audit.request_date',
                    colonnaKey: 'requestTimeFormatted',
                    flex: 11,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'audit.user_complete_name',
                    colonnaKey: 'utenteNomeCognomeEmail',
                    flex: 20,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'audit.operation',
                    colonnaKey: 'operazione',
                    flex: 15,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'audit.http_method',
                    colonnaKey: 'metodoHttp',
                    flex: 6,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'audit.uri',
                    colonnaKey: 'uri',
                    flex: 35,
                },
                {
                    tipo: TipoColonnaEnum.CHIP_STATUS,
                    nomeColonna: 'audit.response_code',
                    colonnaKey: 'codiceRisposta',
                    flex: 6,
                    statusType: undefined
                },


                {
                    nomeColonna: ' ',
                    colonnaKey: 'action',
                    flex: 9,
                    tipo: TipoColonnaEnum.ACTION_MENU,
                    valueCheck: 'l',
                    button: [
                        {
                            nameIconButton: 'info',
                            click: TipoClickEnum.SHOW,
                            tooltip: 'audit.info',
                            color: 'accent',
                            valueShow: 'stato'
                        },
                    ]
                }
            ],
            css: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'min-width': 'unset'
            },
            messaggioDatiAssenti: 'custom_table.no_data_audit'
        }
    };
    return config;
}
