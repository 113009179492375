import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree
} from '@angular/router';
import {Observable, of} from 'rxjs';
import {LocalStorageService} from '../../../shared/service/local-storage.service';
import {PathEnum} from '../../../app.routing';
import {AppInitService} from "../../../shared/service/app-init.service";


@Injectable({
    providedIn: 'root'
})
export class OnlyAreaAdministrationGuard implements CanActivate, CanActivateChild, CanLoad {

    constructor(
        private _router: Router,
        private localStorageService: LocalStorageService,
        private appInitService: AppInitService,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this._check();
    }


    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this._check();
    }


    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        return this._check();
    }


    private _check(): Observable<boolean> {
        if (!!this.appInitService.cicloAmmSelected && !this.appInitService.listaRuoliCodiciDipartimenti?.length) {
            this._router.navigate([PathEnum.ADMINISTRATOR, PathEnum.HOME]);
            return of(false);
        }

        return of(true);
    }
}
