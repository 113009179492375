<ng-container *transloco="let t">

    <form [formGroup]="formGroup">

        <div class="mt-2 mb-3">
            <fuse-alert class="w-full"
                        [showIcon]="true"
                        [appearance]="'outline'"
            >{{t('massive_operations.approvazione_piani.steps.with_delibera')}}.</fuse-alert>
        </div>

        <mat-radio-group [formControlName]="'withDelibera'">
            <ng-container  *ngFor="let option of Object.values(StudyPlanWithDeliberaMode)">
                <!-- Item content -->
                <div
                    (click)="formGroup?.get('withDelibera').patchValue(option)"
                    class="cursor-pointer flex flex-row justify-start w-full py-5 pr-4 pl-5 border-solid border-t first:border-0 hover:bg-hover items-center"
                    [ngClass]="{'bg-blue-100 dark:bg-black dark:bg-opacity-5': formGroup?.get('withDelibera').value === option}">
                    <mat-radio-button class="caricamento-delibere-radio-btn" [value]="option">
                        <div class="flex items-center w-full">
                            <div class="mr-2 font-medium truncate"
                                 [ngClass]="{'font-semibold': formGroup?.get('withDelibera').value === option}">
                                {{t(getOptionLabel(option))}}
                            </div>
                        </div>
                    </mat-radio-button>

                </div>
            </ng-container>
        </mat-radio-group>
    </form>

</ng-container>
