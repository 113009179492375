import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {InfoWrapComponent} from './info-wrap.component';
import {TranslocoModule} from '@ngneat/transloco';
import {ImageHeaderModule} from "../../../modules/header/image-header.module";
import {InputValueKeyModule} from "../../../shared/input-value-key/input-value-key.module";
import {PortalModule} from "@angular/cdk/portal";
import {SharedModule} from "../../../shared/shared.module";



@NgModule({
    declarations: [InfoWrapComponent],
    exports: [
        InfoWrapComponent
    ],
    imports: [
        CommonModule,
        TranslocoModule,
        ImageHeaderModule,
        InputValueKeyModule,
        PortalModule,
        SharedModule
    ]
})
export class InfoWrapModule { }
