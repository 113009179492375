<ng-container *transloco="let t">


    <div class="flex flex-col w-full min-h-[20rem] items-center justify-center"
         *ngIf="loading; else stepTemplate">
        <app-loading-screen class="w-full h-full">
        </app-loading-screen>
    </div>


    <ng-template #stepTemplate>

        <div class="mt-4 mb-6">
            <fuse-alert class="w-full"
                        [showIcon]="true"
                        [appearance]="'outline'"
            >{{t(currentProfile === AuthorityType.COORDINATORE ? 'massive_operations.approvazione_spese.hint_compile_info' : 'massive_operations.approvazione_spese.hint_compile_no_info')}}</fuse-alert>
        </div>

        <div class="w-full flex flex-col items-center" *ngIf="currentProfile === AuthorityType.COORDINATORE">

            <!-- TABELLA STUDENTI -->
            <div class="lg:w-[95%] my-4 mx-8 flex flex-col items-center gap-5 bg-gray-50 rounded-xl p-6"
                 *ngIf="!!speseListConfiguration">
                <app-generic-table-editable
                    class="w-full"
                    [parentFormGroup]="compileInfoFormGroup"
                    (clickAction)="tableClickAction($event)"
                    [configuration]="speseListConfiguration">
                </app-generic-table-editable>
            </div>

        </div>



    </ng-template>



</ng-container>
