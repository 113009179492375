<ng-container *transloco="let t">


    <div class="flex flex-col w-full min-h-[20rem] items-center justify-center"
         *ngIf="loading; else stepTemplate">
        <app-loading-screen class="w-full h-full">
        </app-loading-screen>
    </div>


    <ng-template #stepTemplate>

        <div class="mt-4 mb-6">
            <fuse-alert class="w-full"
                        [showIcon]="true"
                        [appearance]="'outline'"
            >{{t('massive_operations.approvazione_modifiche_budget.hint_compile_info')}}</fuse-alert>
        </div>

        <div class="w-full flex flex-col items-center">

            <!-- DELIBERA -->
            <div class="w-full flex flex-col md:flex-row gap-3 items-start mt-2">

                <form [formGroup]="form" class="w-full">

                    <mat-form-field appearance="outline"
                                    class="w-full add-mobilita-mat-form-field"
                                    *ngIf="form?.get('deliberaCollegio') as fieldCtrl">
                        <mat-label><strong>{{t('mobility.collegio_approval')}}</strong></mat-label>
                        <mat-select formControlName='deliberaCollegio'>
                            <mat-option [value]="undefined">-</mat-option>
                            <mat-option *ngFor="let delibera of delibere" [value]="delibera.id">
                                {{t('mobility.delibera_name')}} {{delibera.numero}} - {{formatDeliberaDate(delibera?.data)}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="fieldCtrl.hasError('required')">
                            {{t('form.required')}}
                        </mat-error>

                    </mat-form-field>

                </form>

                <button [color]="'primary'"
                        mat-raised-button
                        type="button"
                        class="add-delibera-button"
                        [disabled]="loading"
                        (click)="this.openAddNewDeliberaDialog()">
                    <mat-icon class="mr-2">add</mat-icon>
                    {{t('common.add_delibera')}}
                </button>

            </div>

            <!-- TABELLA STUDENTI -->
            <div class="lg:w-[95%] my-4 mx-8 flex flex-col items-center gap-5 bg-gray-50 rounded-xl p-6"
                 *ngIf="!!speseListConfiguration">
                <app-generic-table-editable
                    class="w-full"
                    [parentFormGroup]="compileInfoFormGroup"
                    (clickAction)="tableClickAction($event)"
                    [configuration]="speseListConfiguration">
                </app-generic-table-editable>
            </div>

        </div>



    </ng-template>



</ng-container>
