import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {
    ClickEvent,
    ExpandedGenericTable,
    GenericTableConfigurationModel,
    TableData,
    TipoClickEnum,
    TipoColonnaEnum
} from "../../../../../shared/components/table/model/generic-table-model";
import {
    AggiornamentoPianoDiStudiStatus,
    AuthorityType,
    CorsoPianoDiStudiPerExportViewImpl, EsitoRichiestaRetribuzioneMobilita,
    MediaService,
    PagePeriodoDiMobilitaStudenteInfoView,
    PeriodiDiMobilitaService,
    PeriodoDiMobilitaStudenteInfoView,
    PeriodoDiMobilitaStudenteStatus,
    RichiestaDiRetribuzioneMobilitaInfoView,
    RichiestaDiRetribuzioneMobilitaStatus,
    SpesaStudenteInfoViewImpl,
    SpesaStudentePerApprovMassivaInfoView,
    SpesaStudenteStatus,
    StudentiCicloService,
    TipoPeriodoEnum, UserCodiceFiscaleNomeCognomeView
} from "../../../../../../api-clients/generated/services";
import {filter, switchMap, tap} from "rxjs/operators";
import {
    BehaviorSubject,
    catchError,
    finalize,
    map,
    Observable,
    ObservedValueOf,
    of,
    OperatorFunction,
    take,
    takeUntil
} from "rxjs";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {TranslocoService} from "@ngneat/transloco";
import {get, isEmpty} from "lodash";
import * as moment from "moment/moment";
import {PageEvent} from "@angular/material/paginator";
import {FormGroup} from "@angular/forms";
import {LogoutService} from "../../../../../shared/service/logout.service";
import {LocalStorageService} from "../../../../../shared/service/local-storage.service";
import {
    SpeseBarFilterContainerComponent
} from "../../../../../shared/components/spese-bar-filter-container/spese-bar-filter-container.component";
import {ApprovazioneValidazioneMobilitaMassivaDataI} from "../approvazione-validazione-mobilita-steps-interface";
import {
    CAN_GO_AHEAD$,
    CURRENT_PROFILE,
    DEFAULT_PAGE_SIZE,
    OPERAZIONE_MASSIVA_DATA$,
    PERIOD_TYPE$
} from "../../operazioni-massive.component";
import {getLanguagePathByMobilitaStatus, makeFilenameFromFE, removeUnderscore} from "../../../../../shared/utils/utils";
import {
    GenericComponentDialogConfig
} from "../../../../../layout/common/generic-components/generic-components.interface";
import {
    GenericDialogComponent
} from "../../../../../layout/common/generic-components/generic-dialog/generic-dialog.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {
    StudyPlanWithDeliberaMode
} from "../../common-steps/show-with-delibera-for-plan-step/show-with-delibera-for-plan-step.component";
import {HttpResponse} from "@angular/common/http";
import {CicloCorsoRuoloInterface} from "../../../../../shared/interface/CicloCorsoRuoloInterface";
import fs from "file-saver";
import {SnackbarTypes} from "../../../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {DialogInfoComponent, DialogInfoI} from "../../../../../shared/components/dialog-info/dialog-info.component";
import {UpperCasePipe} from "@angular/common";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {
    MobilitaFilterManagerRequestService,
    MobilitaFilters
} from "../../../requests/approvazione-periodi-di-mobilita-ricerca/mobilita-sidebar-filter-container/mobilita-filter-manager-request.service";
import {TypeDialogFormEnum} from "../../../../../layout/common/generic-components/generic-components-enum";
import {
    MobilitaValidazioneDettaglioComponent
} from "../../../../../shared/components/mobilita-validazione-dettaglio/mobilita-validazione-dettaglio.component";
import {PathEnum} from "../../../../../app.routing";
import {Router} from "@angular/router";
import {
    RicercaFilterManagerRequestService
} from "../../../requests/approvazione-periodi-di-mobilita-ricerca/ricerca-sidebar-filter-container-request/ricerca-filter-manager-request.service";


@Component({
    selector: 'app-scegli-richieste-validazione-stepper',
    templateUrl: './scegli-richieste-validazione.component.html',
    styleUrls: ['./scegli-richieste-validazione.component.scss']
})
export class ScegliRichiesteValidazioneComponent extends AbstractDefaultComponent implements OnInit, OnDestroy {

    @Output() toogleFilterContainer$ = new EventEmitter<{ hasToogleInternalMenu: boolean}>();

    loading: boolean;
    speseListConfiguration: GenericTableConfigurationModel;
    private corso: any;
    private pageSize: number = DEFAULT_PAGE_SIZE;
    private fieldsLabelMap: Map<string, string>;
    currentFilterListChipLabels: Array<string>;
    isFirstTime: boolean;
    showMiniLoader: boolean = false;
    filterContainerComponent: SpeseBarFilterContainerComponent;
    showTopbar: boolean;
    idRigheDaDisabilitare: string[] = [];

    @ViewChild(SpeseBarFilterContainerComponent) set speseBarFilterContainer(component: SpeseBarFilterContainerComponent) {
        this.filterContainerComponent = component;
    }

    constructor(private fuseConfirmationService: FuseConfirmationService,
                private translocoService: TranslocoService,
                private studentiCicloService: StudentiCicloService,
                private periodiDiMobilitaService: PeriodiDiMobilitaService,
                private logOutService: LogoutService,
                private filterManagerServiceMobility: MobilitaFilterManagerRequestService,
                private filterManagerServiceResearch: RicercaFilterManagerRequestService,
                private localStorageService: LocalStorageService,
                private dialog: MatDialog,
                protected mediaService: MediaService,
                protected sanitizer: DomSanitizer,
                private appInitService: AppInitService,
                private router: Router,
                @Inject(CURRENT_PROFILE) protected currentProfile: AuthorityType,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(PERIOD_TYPE$) protected periodType: TipoPeriodoEnum,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<ApprovazioneValidazioneMobilitaMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.fieldsLabelMap = this.filterManagerService.fieldsLabelMap;
        this.isFirstTime = true;
        this.getDataAndFilterSubscribe();
        this.operazioneMassivaData$?.subscribe(inputOutputData => {
            if(inputOutputData.refreshData) {
                console.log('REFRESHING SPESE IN SCEGLI SPESE')
                this.speseListConfiguration = undefined;
                this.idRigheDaDisabilitare = [];
                this.resetRefreshData();
                this.canGoNext$.next(false);
                this.getDataRequest$(false, this.filterManagerService?.filterApplied);
            }
        });
    }

    get filterManagerService(){
        return this.periodType === TipoPeriodoEnum.SOGGIORNOESTERO ? this.filterManagerServiceMobility : this.filterManagerServiceResearch;
    }

    openFilters(){
        this.filterManagerService.openDrawer.next(true)
    }

    getDataRequest$(isFirstTime?: boolean, filters?: any, page: number = 0, size: number = 10): void {
        this.getDataFormObs$(isFirstTime, filters, page, size).subscribe({
            next: (value) => {},
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => {this.logOutService.goToHome()},
                    () => this.getDataRequest$(isFirstTime, filters, page, size),
                    'common.go_to_home',
                    err?.error?.message);
            }
        });
    }

    getDataFormObs$(isFirstTime?: boolean, filters?: MobilitaFilters, page: number = 0, size: number = 10): Observable<PagePeriodoDiMobilitaStudenteInfoView> {
        if (isFirstTime) {
            this.loading = true;
        } else {
            this.fuseConfirmationService.showLoader();
        }
        return this.periodiDiMobilitaService.getPeriodiDiMobilitaStudenteForm(
            !!filters?.codiceMobilita ? filters.codiceMobilita : undefined,
            this.periodType,
            !!filters?.nominativoStudente ? filters.nominativoStudente : undefined,
            undefined,
            undefined,
            !!filters?.stato ? filters.stato : undefined,
            !!filters?.statoSvolgimento ? filters?.statoSvolgimento : undefined,
            !!filters?.periodoDa ? filters.periodoDa : undefined,
            !!filters?.periodoA ? filters.periodoA : undefined,
            !!filters?.annoAccademico ? filters.annoAccademico : undefined,
            !!filters?.nominativoStrutturaEsteraOspitante ? filters.nominativoStrutturaEsteraOspitante : undefined,
            !!filters?.codiceNazioneStruttura ? filters.codiceNazioneStruttura : undefined,
            !!filters?.dataFineNull ? filters.dataFineNull : undefined,
            !!filters?.codiceTipologiaMobilita ? filters.codiceTipologiaMobilita : undefined,
            !!filters?.codiceTipologiaBonus ? filters.codiceTipologiaBonus : undefined,
            !!filters?.codiceTipoInterventoSupporto ? filters.codiceTipoInterventoSupporto : undefined,
            !!filters?.dataUltimaModifica ? moment(filters.dataUltimaModifica).format('DD/MM/YYYY HH:mm') as unknown as Date : undefined,
            [(this.currentProfile === AuthorityType.SUPERVISORE || this.currentProfile === AuthorityType.COSUPERVISORE) ?
                RichiestaDiRetribuzioneMobilitaStatus.DAAPPROVARE : RichiestaDiRetribuzioneMobilitaStatus.APPROVATAPARZIALE],
            this.currentProfile === AuthorityType.COSUPERVISORE ? true : undefined,
            page, size, undefined).pipe(
            tap((pagePeriodi: PagePeriodoDiMobilitaStudenteInfoView) => {
                this.speseListConfiguration = this.buildMobilityRequestsTableConfiguration(pagePeriodi, size, page);
                const periodoToExpand = pagePeriodi.content?.find(request =>
                    request.richiesteDiRetribuzioneMobilita?.find(valRequest =>
                        (this.currentProfile === AuthorityType.COORDINATORE && valRequest.stato === RichiestaDiRetribuzioneMobilitaStatus.APPROVATAPARZIALE)
                        || valRequest.stato === RichiestaDiRetribuzioneMobilitaStatus.DAAPPROVARE
                    )
                );
                this.expandedCodiceMobilita = periodoToExpand?.codiceMobilita;
            }),
            takeUntil(this.destroy$),
            finalize(() => {
                    if (isFirstTime) {
                        this.isFirstTime = false;
                        this.loading = false;
                    } else {
                        this.fuseConfirmationService.hideLoader();
                    }
                }
            )
        );


    }

    formatDay(date: string) {
        return moment(new Date(date)).format('DD/MM/YYYY');
    }

    buildMobilityRequestsTableConfiguration(pagePeriodi: PagePeriodoDiMobilitaStudenteInfoView,
                                            pageSize: number = DEFAULT_PAGE_SIZE,
                                            page: number): GenericTableConfigurationModel {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        const disabledPeriodsCodiciMobilita = pagePeriodi?.content
            ?.filter(p => p.tipoPeriodoEnum === TipoPeriodoEnum.RICERCA && p.codiceMobilitaFiglio)
            ?.map(p => p.codiceMobilita) ?? [];
        this.idRigheDaDisabilitare = [...(this.idRigheDaDisabilitare ?? []), ...disabledPeriodsCodiciMobilita];
        const periodiDiMobilitaStudenteForUI = pagePeriodi?.content?.map((periodo: PeriodoDiMobilitaStudenteInfoView) =>
            ({
                ...periodo,
                strutturaEsteraOspitanteDescrizione: (periodo?.strutturaEsteraOspitante?.descrizione || periodo?.strutturaEsteraNonCodificata?.descrizione)
                    + ' (' + periodo?.nazioneStrutturaEstera?.codice + ')',
                tipologiaMobilitaDescrizione: periodo?.tipologiaMobilita?.descrizione,
                tipologiaBonusDescrizione: periodo?.tipologiaBonus?.descrizioneTipologiaBonus,
                dataRichiestaFormatted: moment(periodo?.dataRichiesta).format('DD/MM/YYYY HH:mm'),
                dataInizio: !!periodo?.dataInizio ? moment(periodo?.dataInizio, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
                dataFine: !!periodo?.dataFine ? moment(periodo?.dataFine, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
                studenteUIMapped: [{
                    ...periodo?.studenteCiclo,
                    nome: periodo?.studenteCiclo?.utente?.nome,
                    cognome: periodo?.studenteCiclo?.utente?.cognome,
                    codiceFiscale: periodo?.studenteCiclo?.utente?.codiceFiscale
                }],
                richiesteRetribuzione: periodo.richiesteDiRetribuzioneMobilita
                    ?.filter(val => this.currentProfile === AuthorityType.COORDINATORE ?
                        (val.stato === RichiestaDiRetribuzioneMobilitaStatus.APPROVATAPARZIALE) : (val.stato === RichiestaDiRetribuzioneMobilitaStatus.DAAPPROVARE))
                    ?.map(periodoRetribuzione => ({
                        ...periodoRetribuzione,
                        studenteCiclo: periodo.studenteCiclo,
                        periodoMobilita: periodo,
                        selectionText: periodo?.codiceMobilita + ' - ' + periodo?.studenteCiclo?.utente?.cognome + ' ' +  periodo?.studenteCiclo?.utente?.nome,
                        periodiFormatted: periodoRetribuzione.periodi?.sort(
                        (p1, p2) =>
                            moment(p1.dataInizio, 'YYYY-MM-DD').unix() - moment(p2.dataInizio, 'YYYY-MM-DD').unix()
                    )?.map(p =>
                        get(translation, 'common.from', null) + ' ' +
                        moment(p?.dataInizio, 'YYYY-MM-DD').format('DD/MM/YYYY') + ' ' +
                        get(translation, 'common.to', null) + ' ' +
                        moment(p?.dataFine, 'YYYY-MM-DD').format('DD/MM/YYYY'))?.
                    join(', ')
                }))
            }));
        let config: GenericTableConfigurationModel = {
            configuration: {
                data: periodiDiMobilitaStudenteForUI,
                totalElements: pagePeriodi?.totalElements,
                pageSize: pageSize,
                pageSizeOptions: [10, 20, 30, 40, 50],
                isPaginatedBE: true,
                sticky: true,
                pageBE: page,
                disabledCssClass: 'generic-table-disabled-row',
                configurazioneTabella: [
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'mobility.mobility_code',
                        colonnaKey: 'codiceMobilita',
                        flex: 11,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'mobility.mobility_type',
                        colonnaKey: 'tipologiaMobilitaDescrizione',
                        flex: 11,
                    },
                    {
                        tipo: TipoColonnaEnum.CHIP_USER,
                        nomeColonna: 'mobility.student',
                        colonnaKey: 'studenteUIMapped',
                        flex: 25,
                    },
                    {
                        tipo: TipoColonnaEnum.CHIP_STATUS,
                        nomeColonna: 'mobility.state',
                        colonnaKey: 'stato',
                        flex: 10,
                        statusType: PeriodoDiMobilitaStudenteStatus
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'mobility.start_date',
                        colonnaKey: 'dataInizio',
                        flex: 9,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'mobility.end_date',
                        colonnaKey: 'dataFine',
                        flex: 9,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'mobility.foreign_university',
                        colonnaKey: 'strutturaEsteraOspitanteDescrizione',
                        flex: 18,
                    },
                    {
                        nomeColonna: ' ',
                        colonnaKey: 'action',
                        flex: 9,
                        tipo: TipoColonnaEnum.ACTION_MENU,
                        valueCheck: 'l',
                        button: [
                            {
                                nameIconButton: 'event',
                                click: TipoClickEnum.GO_TO_MOBILITA_STUDENTE,
                                tooltip: 'common.go_to_mobility',
                                color: 'accent',
                                valueShow: 'stato'
                            },
                            {
                                nameIconButton: 'info',
                                click: TipoClickEnum.SHOW,
                                tooltip: 'mobility.info',
                                color: 'accent',
                                valueShow: 'stato'
                            },
                            {
                                nameIconButton: 'visibility',
                                click: TipoClickEnum.SHOW_MOBILITY_REQUEST_DELIBERA,
                                tooltip: 'mobility.show_delibera',
                                color: 'accent',
                                show: (stato, periodo: PeriodoDiMobilitaStudenteInfoView) => !!periodo?.delibera?.data,
                                valueShow: 'stato'
                            },
                            {
                                nameIconButton: 'download',
                                click: TipoClickEnum.DOWNLOAD_CONCLUSION_MOBILITY_REQUEST_ATTACHMENTS,
                                tooltip: 'mobility.download_conclusion_attachments',
                                color: 'accent',
                                show: (stato, periodo: PeriodoDiMobilitaStudenteInfoView) => periodo?.urlAttestatiDiConclusione?.length > 0,
                                valueShow: 'stato'
                            },
                        ]
                    }
                ],
                css: {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'min-width': 'unset'
                },
                messaggioDatiAssenti: 'custom_table.no_data_mobility',
                expandRow: this.buildConfigurationForRetributionRequests()
            }
        };
        return config;
    }

    buildConfigurationForRetributionRequests(): ExpandedGenericTable {
        return {
            expand: true,
            tableTitle: 'mobility.partial_request_mixed',
            dataKey: 'richiesteRetribuzione',
            parentIdKey: 'codiceMobilita',
            hidePaginator: true,
            selection: {
                isSelectable: true,
                selectedColumnKeyToShow: 'selectionText',
                selectedColumnKeyPrimaryKey: 'id',
            },
            disabledCssClass: 'generic-table-disabled-row',
            highlightRowWhen: (element, parentElement) => (this.idRigheDaDisabilitare?.includes(element.id) &&
                !(parentElement?.tipoPeriodoEnum === TipoPeriodoEnum.RICERCA && parentElement?.codiceMobilitaFiglio)) ? 'generic-table-removed-row' : undefined,
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'mobility.periods',
                    colonnaKey: 'periodiFormatted',
                    flex: 25,
                },
                {
                    tipo: TipoColonnaEnum.CHIP_STATUS,
                    nomeColonna: 'mobility.retribution_status',
                    colonnaKey: 'stato',
                    flex: 25,
                    statusType: RichiestaDiRetribuzioneMobilitaStatus,
                    sottoruoloKey: 'sottoruoloPrimoApprovatore'
                },
                {
                    tipo: TipoColonnaEnum.AMOUNT,
                    nomeColonna: 'mobility.retribution_amount',
                    colonnaKey: 'importo',
                    flex: 15,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'mobility.days',
                    colonnaKey: 'giorni',
                    flex: 15,
                },
                {
                    nomeColonna: ' ',
                    colonnaKey: 'action',
                    flex: 20,
                    tipo: TipoColonnaEnum.ACTION_MENU,
                    valueCheck: 'l',
                    button: [
                        {
                            nameIconButton: 'download',
                            click: TipoClickEnum.DOWNLOAD_RETRIBUTION_MOBILITY_ATTACHMENTS,
                            tooltip: 'mobility.download_partial_request_attachments',
                            color: 'accent',
                            show: (stato, richiestaRetribuzione: RichiestaDiRetribuzioneMobilitaInfoView) => richiestaRetribuzione.urlFileAttestato?.length > 0,
                            valueShow: 'stato'
                        },
                        {
                            nameIconButton: 'visibility',
                            click: TipoClickEnum.SHOW_REFUSE_REASON,
                            tooltip: 'mobility.show_refuse_reason',
                            color: 'accent',
                            show: (stato, richiestaRetribuzione: RichiestaDiRetribuzioneMobilitaInfoView) => !!richiestaRetribuzione.motivazioneRifiuto,
                            valueShow: 'stato'
                        },
                        {
                            nameIconButton: 'visibility',
                            click: TipoClickEnum.SHOW_RETRIBUTION_MOBILITA_DETAILS,
                            show: (stato, richiestaRetribuzione: RichiestaDiRetribuzioneMobilitaInfoView) =>
                                !!richiestaRetribuzione.dettaglioCalcoloImporto ||
                                !!richiestaRetribuzione.notePrimoApprovatore ||
                                !!richiestaRetribuzione.noteSecondoApprovatore ||
                                !!richiestaRetribuzione.noteValidatorePta,
                            tooltip: 'mobility.show_retribution_details',
                            color: 'accent',
                            valueShow: 'stato'
                        },
                        {
                            nameIconButton: 'close',
                            click: TipoClickEnum.REFUSE_REQUEST,
                            tooltip: 'mobility.refuse_partial_validation_request',
                            show: (_, element) => !this.idRigheDaDisabilitare?.includes(element.id),
                            valueShow: 'stato',
                            color: 'accent'
                        },
                    ]
                }

            ],
        };
    }

    tableClickAction($event: ClickEvent) {
        switch ($event?.tipoClick) {
            case TipoClickEnum.CHIP_DETAIL:
                this.openInfoStudent($event.value?.utente);
                break;
            case TipoClickEnum.SHOW_NOTES:
                this.openNotesDialog($event.value);
                break;
            case TipoClickEnum.SHOW_ALLEGATO_BUDGET_REQUEST:
                this.downloadAllegatiRequest($event.value);
                break;
            case TipoClickEnum.REFUSE_REQUEST:
                this.tryOpenRefuseDialog($event);
                break;
            case TipoClickEnum.SHOW_RETRIBUTION_MOBILITA_DETAILS:
                this.openRetributionInfoDialog($event?.value);
                break;
            case TipoClickEnum.DOWNLOAD_RETRIBUTION_MOBILITY_ATTACHMENTS:
                this.donwloadRetributionAttachments($event?.value, $event?.parentValue);
                break;
            case TipoClickEnum.GO_TO_MOBILITA_STUDENTE:
                const periodoMobilita: PeriodoDiMobilitaStudenteInfoView = $event?.value;
                const url = this.router.serializeUrl(this.router.createUrlTree([PathEnum.STUDENTS, periodoMobilita?.studenteCiclo?.utente?.id, PathEnum.MOBILITA], {
                    fragment: periodoMobilita?.tipoPeriodoEnum === TipoPeriodoEnum.SOGGIORNOESTERO ? 'mobilita' : 'ricerca'
                }));
                window.open(url, '_blank');
                break;
        }
    }

    private donwloadRetributionAttachments(richiestaRetribuzione: RichiestaDiRetribuzioneMobilitaInfoView, periodo: PeriodoDiMobilitaStudenteInfoView) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        const idUtente = periodo?.studenteCiclo?.utente?.id;
        this.fuseConfirmationService.showLoader();
        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.studentiCicloService.getZipAttestatiRichiestaDiRetribuzioneMobilita(idUtente, periodo?.codiceMobilita, richiestaRetribuzione?.id, 'response')),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (fileResponse: HttpResponse<Blob>) => {
                const fileTypeForName = 'ALLEGATI_RICHIESTA_DI_VALIDAZIONE_' + periodo?.codiceMobilita;
                const studentCicloCorsoRuolo: CicloCorsoRuoloInterface = {
                    codiceCorsoStudi: periodo?.studenteCiclo?.codiceCorsoDiStudiEsse3,
                    ciclo: this.localStorageService?.getCicloCorsoRuolo()?.ciclo || (this.localStorageService?.cicloAmm + '') ,
                    ruolo: AuthorityType.STUDENTE,
                    denominazioneCorsoStudi: ''
                }
                const outputFileName = makeFilenameFromFE(studentCicloCorsoRuolo, '.zip', fileTypeForName);
                fs.saveAs(fileResponse.body, outputFileName);
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'student.file_download_success', null),
                    type: SnackbarTypes.Success,
                });
            },
            error: (err) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'student.file_download_error', null),
                    type: SnackbarTypes.Warning,
                });
            }
        });
    }

    private openRetributionInfoDialog(richiestaRetribuzione: RichiestaDiRetribuzioneMobilitaInfoView) {
        const data: DialogInfoI = {
            showImage: false,
            title: 'mobility.retribution_details',
            icon: {
                name: 'mat_outline:info',
                color: 'primary'
            },
            readonlyData: [],
            bottomComponent: MobilitaValidazioneDettaglioComponent,
            bottomComponentData: richiestaRetribuzione,
        };
        this.dialog.open(DialogInfoComponent, {
            data,
            panelClass: 'dialog-responsive-full-screen',
            minWidth: '80vw'
        });
    }


    private tryOpenRefuseDialog(event: ClickEvent) {
        if(this.operazioneMassivaData$.getValue().richiesteValidazioneSelected?.find(
            s => s.id === event.value.id)){
            this.openDeselectFirstDialog();
        } else {
            this.openRefuseRetributionDialog(event.value, event.value.periodoMobilita)
        }
    }

    private openRefuseRetributionDialog(richiestaRetribuzione: RichiestaDiRetribuzioneMobilitaInfoView, periodo: PeriodoDiMobilitaStudenteInfoView) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        let confirmLabel = get(translation, 'dialog.confirm', null);
        let cancelLabel = get(translation, 'dialog.cancel', null);
        const data: GenericComponentDialogConfig = {
            title: 'mobility.refuse_partial_validation_request',
            message: get(translation, 'mobility.refuse_partial_validation_request_message', null),
            icon: {
                show: true,
                name: 'mat_outline:close',
                color: 'error'
            },
            actions: {
                confirm: {
                    show: true,
                    label: confirmLabel,
                    color: 'primary',
                    function: (form, dialogRef) => this.refuseRetributionRequest(form, richiestaRetribuzione, periodo, dialogRef)
                },
                cancel: {
                    show: true,
                    label: cancelLabel,
                }
            },
            dismissible: true,
            formConfig: [
                {
                    show: true,
                    name: 'motivazioneRifiuto',
                    transloco: 'budget.refuse_reason',
                    required: true,
                    type: TypeDialogFormEnum.TEXT
                },
            ],
            valueForm: {
                motivazioneRifiuto: undefined
            }
        };
        this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: data.dismissible,
            disableClose: true,
        });
    }

    private refuseRetributionRequest(form: FormGroup,
                                     richiestaRetribuzione: RichiestaDiRetribuzioneMobilitaInfoView,
                                     periodo: PeriodoDiMobilitaStudenteInfoView,
                                     dialogRef: MatDialogRef<GenericDialogComponent>) {
        let request;
        const requestBody: EsitoRichiestaRetribuzioneMobilita = {
            motivazione_rifiuto: form.getRawValue()?.motivazioneRifiuto,
            approvato: false,
        }
        request = this.currentProfile === AuthorityType.COORDINATORE ?
            this.studentiCicloService.verificaRichiestaDiRetribuzioneMobilita(requestBody, periodo?.studenteCiclo?.utente?.id, periodo?.codiceMobilita, richiestaRetribuzione?.id) :
            this.studentiCicloService.verificaParzialeRichiestaDiRetribuzioneMobilita(requestBody, periodo?.studenteCiclo?.utente?.id, periodo?.codiceMobilita, richiestaRetribuzione?.id);
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.appInitService.selectedInService.pipe(
            switchMap(() => request),
            takeUntil(this.destroy$),
        ).subscribe({
            next: (periodi: PeriodoDiMobilitaStudenteInfoView[]) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'common.operation_success', null),
                    type: SnackbarTypes.Success,
                });
                this.idRigheDaDisabilitare = [...(this.idRigheDaDisabilitare ?? []), richiestaRetribuzione.id];
                dialogRef.close();
            },
            error: (err) => {
                console.log(err)
                this.fuseConfirmationService.openErrorDialog({error: err},
                    this.translocoService,
                    () => {},
                    () => this.refuseRetributionRequest(form, richiestaRetribuzione, periodo, dialogRef),
                    'dialog.cancel',
                    err?.error?.message);
            }
        });
    }

    private openDeselectFirstDialog() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
            title: get(translation, 'dialog.attention', null),
            message: get(translation, 'common.deselect_first', null),
            icon: {
                name: 'mat_solid:error_outline',
                color: 'error'
            },
            onBackdrop: {
                show: true,
                backdrop: false
            },
            actions: [
                {
                    color: 'accent',
                    label: get(translation, 'common.close', null), icon: 'close',
                },
            ]
        });
    }


    private openInfoStudent(studente: UserCodiceFiscaleNomeCognomeView) {
        if (!!studente.urlImmagineProfilo && !!studente.codiceFiscale) {
            this.fuseConfirmationService.showLoader();
            this.mediaService.getImmagineProfiloForm(studente.urlImmagineProfilo, studente.codiceFiscale)
                .pipe(
                    catchError(err => of(undefined)),
                    map((image) => {
                        if (image) {
                            const objectURL = URL.createObjectURL(image);
                            return this.sanitizer.bypassSecurityTrustUrl(objectURL);
                        } else {
                            return undefined;
                        }
                    }),
                    takeUntil(this.destroy$),
                    finalize(() => this.fuseConfirmationService.hideLoader())
                ).subscribe({
                next: (image: SafeUrl) => {
                    this.openStudenteInfoDialog(studente, image);
                },
                error: (error) => {
                    this.fuseConfirmationService.openErrorDialog({error: error},
                        this.translocoService,
                        () => {
                        },
                        () => this.openInfoStudent(studente),
                        'dialog.close',
                        error?.error?.message);
                }
            });
        } else {
            this.openStudenteInfoDialog(studente);
        }
    }

    private openStudenteInfoDialog(studente: UserCodiceFiscaleNomeCognomeView, profileImage?: SafeUrl) {
        const data: DialogInfoI = {
            imageSafeUrl: profileImage,
            showImage: true,
            title: (studente.cognome || '') + ' ' + (studente.nome || ''),
            icon: {
                name: 'mat_outline:info',
                color: 'primary'
            },
            imageIniziali: (studente?.cognome?.charAt(0) || '') + (studente?.nome?.charAt(0) || ''),
            readonlyData: [
                {
                    key: 'user_list.surname',
                    value: studente?.cognome,
                    pipe: UpperCasePipe
                },
                {
                    key: 'user_list.name',
                    value: studente?.nome,
                    pipe: UpperCasePipe
                },
                {
                    key: 'sign-up.email',
                    value: studente?.mail?.toLowerCase()
                },
                { //telefono
                    key: 'sign-up.phone_number',
                    value: studente?.telefono
                },
                {
                    key: 'sign-up.fiscal_code',
                    value: studente?.codiceFiscale
                },
            ]
        };
        this.dialog.open(DialogInfoComponent, {
            data,
            panelClass: 'dialog-responsive-full-screen',
        });
    }

    private downloadAllegatiRequest(spesa: SpesaStudentePerApprovMassivaInfoView) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.showLoader();
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.studentiCicloService.getZipAllegatiRichiestaDiSpesaStudente(spesa.budgetStudente?.studenteCiclo?.utente?.id, spesa?.codiceSpesa, 'response')),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (fileResponse: HttpResponse<Blob>) => {
                //const fileName = fileResponse.headers?.get('Content-Disposition')?.split('=').pop();
                const fileTypeForName = 'ALLEGATI_RICHIESTA_DI_SPESA_' + spesa?.codiceSpesa;
                const studentCicloCorsoRuolo: CicloCorsoRuoloInterface = {
                    codiceCorsoStudi: spesa?.budgetStudente?.studenteCiclo?.codiceCorsoDiStudiEsse3,
                    ciclo:  spesa?.budgetStudente?.studenteCiclo?.numeroCicloDottorato + '',
                    ruolo: AuthorityType.STUDENTE,
                    denominazioneCorsoStudi: ''
                }
                const outputFileName = makeFilenameFromFE(studentCicloCorsoRuolo, '.zip', fileTypeForName);
                fs.saveAs(fileResponse?.body, outputFileName);
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'student.file_download_success', null),
                    type: SnackbarTypes.Success,
                });
            },
            error: (err) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'budget.file_download_error', null),
                    error: err, type: SnackbarTypes.Error,
                });
                console.log(err)
            }
        });
    }



    onRichiesteDataTableChanged($event: TableData) {
        console.log('SELECTION CHANGED')
        this.operazioneMassivaData$.next({
            ...this.operazioneMassivaData$?.getValue(),
            richiesteValidazioneSelected: $event.selectedRows.length > 0 ? $event.selectedRows.map(row => row.data) as
                (RichiestaDiRetribuzioneMobilitaInfoView & { periodoMobilita: PeriodoDiMobilitaStudenteInfoView })[] : undefined,
        });
        this.canGoNext$.next($event.selectedRows.length > 0);
    }

    pageAction($event: PageEvent): void {
        console.log('PageEvent', $event);
        const page = $event.pageIndex;
        this.pageSize = $event.pageSize;
        this.getDataRequest$(false, this.getMainFormGroup()?.value, page, this.pageSize);
    }

    getMainFormGroup(): FormGroup<any> {
        return this.filterManagerService?.mainFormGroup;
    }

    catchErrorInSpeseList$(inputObs: Observable<any>): OperatorFunction<unknown, ObservedValueOf<Observable<any>> | unknown> {
        return this.fuseConfirmationService.catchErrorCustom$(inputObs, {openModalError: {goToHome: true}}, true, true, true, false);
    }

    getListChipsLabel(): Array<string> {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        if (!isEmpty(this.getMainFormGroup()?.value)) {
            const entries: [string, any][] = Object.entries(this.getMainFormGroup()?.value)?.filter(item =>
                item[0] !== 'richiesteDiRetribuzioneStatus' && item[0] !== 'showOnlyApprovableRequests');
            const filteredEntries = entries?.filter(item => item.every(value => !!value));
            let labels = filteredEntries?.map(filteredItem => filteredItem?.map((element) => {
                    if (filteredItem[0] === 'stato') {
                        const value = get(translation, getLanguagePathByMobilitaStatus(filteredItem[1]), filteredItem[1]);
                        const translatedKey = get(translation, this.fieldsLabelMap.get(filteredItem[0]), filteredItem[0]);
                        return translatedKey + ': ' + value;
                    }
                    if (typeof element === 'string') {
                        if (!!this.fieldsLabelMap.get(element)) {

                            return get(translation, this.fieldsLabelMap.get(element), element);
                        }else {
                            return removeUnderscore(element);
                        }
                    }
                    if (typeof element === 'boolean') {
                        return '';
                    }
                    else if (!!element?.codiceFiscale) {
                        const completeName = (element?.cognome || '') + ' ' + (element?.nome || '');
                        return (!!element?.cognome ? completeName : '')?.toUpperCase();
                    }
                    else if (!!element?.id && !!element?.denominazione) {
                        return (!!element?.denominazione ? element.denominazione : '');
                    }
                    else {
                        return element;
                    }
                }
            ).join(': '));
            console.log('Lables chips', labels);
            labels = labels.map(label => label?.endsWith(': ')? label.slice(0, -2) : label);
            return labels;
        } else {
            return [];
        }
    }


    toogleFilterContainer(hasToogleInternalMenu: boolean): void {
        this.filterContainerComponent?.setFilterApplied();
        this.showTopbar = true;
    }


    private getSottoruolo() {
        return this.localStorageService.getSottoruoloCiclo();
    }

    resetFilters(): void {
        //this.filterManagerService.restore();
    }

    closeBar() {
        this.showTopbar = false;
    }

    private resetRefreshData() {
        console.log(this.operazioneMassivaData$.getValue())
        this.operazioneMassivaData$.next({
            ...this.operazioneMassivaData$.getValue(),
            refreshData: false
        })
    }

    private getDataAndFilterSubscribe() {
        this.currentFilterListChipLabels = this.getListChipsLabel();
        if(this.isFirstTime){
            this.getDataRequest$(this.isFirstTime, this.filterManagerService?.filterApplied);
        }

        this.filterManagerService.checkApplyFilterClick$.asObservable().pipe(
            filter(Boolean),
            switchMap(value => {
                if(!this.isFirstTime){
                    return this.getDataFormObs$(this.isFirstTime,  this.filterManagerService?.filterApplied, 0, this.pageSize).pipe(
                        this.catchErrorInSpeseList$(this.getDataFormObs$(this.isFirstTime,  this.filterManagerService?.filterApplied, 0, this.pageSize))
                    );
                }
                return of(null);
            }),
            takeUntil(this.destroy$)
        ).subscribe(
            {
                next: (value) => {
                    this.isFirstTime = false;
                    this.filterManagerService.filterApplied$.next( this.getMainFormGroup().getRawValue());
                    this.currentFilterListChipLabels = this.getListChipsLabel();
                }
            }
        );
    }

    private openNotesDialog(spesa: SpesaStudenteInfoViewImpl) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        let closeLabel = get(translation, 'dialog.close', null);
        const data: GenericComponentDialogConfig = {
            title: 'budget.note',
            message: spesa.nota,
            icon: {
                show: true,
                name: 'description',
                color: 'accent',
            },
            actions: {
                cancel: {
                    show: true,
                    label: closeLabel,
                },
                confirm: {
                    show: false,
                    function: form => {}
                }
            },
            dismissible: true,
        };
        this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: data.dismissible,
            disableClose: true,
        });
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    protected readonly AggiornamentoPianoDiStudiStatus = AggiornamentoPianoDiStudiStatus;
    protected readonly StudyPlanWithDeliberaMode = StudyPlanWithDeliberaMode;
    protected readonly SpesaStudenteStatus = SpesaStudenteStatus;
    protected readonly RichiestaDiRetribuzioneMobilitaStatus = RichiestaDiRetribuzioneMobilitaStatus;
    protected readonly AuthorityType = AuthorityType;
    expandedCodiceMobilita: string;
}
