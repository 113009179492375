/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Stati dello studente di un ciclo Per Statistica.Valori previsti: *IN_CORSO*, *SOSPESO*, *COMPLETATO*, *CESSATO*, *INCOMING*
 */
export type StudenteCicloStatusPerStatsConIncoming = 'IN_CORSO' | 'SOSPESO' | 'COMPLETATO' | 'CESSATO' | 'INCOMING';

export const StudenteCicloStatusPerStatsConIncoming = {
    INCORSO: 'IN_CORSO' as StudenteCicloStatusPerStatsConIncoming,
    SOSPESO: 'SOSPESO' as StudenteCicloStatusPerStatsConIncoming,
    COMPLETATO: 'COMPLETATO' as StudenteCicloStatusPerStatsConIncoming,
    CESSATO: 'CESSATO' as StudenteCicloStatusPerStatsConIncoming,
    INCOMING: 'INCOMING' as StudenteCicloStatusPerStatsConIncoming
};