<ng-container *transloco="let t">
    <app-dialog-wrap class="w-full" [data]="data">
        <mat-dialog-content>

            <div class="flex flex-col gap-4 mt-1 ">

                <div >{{t('common.clone_operation_result_message')}}</div>

                <div class="flex flex-col gap-3 max-w-[75rem]">

                    <div *ngFor="let clonedGroup of data.results; trackBy: clonedGroupTrackByFn"
                         class="flex flex-col gap-2 p-4 bg-slate-100 rounded-xl">

                        <div class="font-semibold w-full text-lcenter text-gray-600 text-lg">{{clonedGroup.tipo | removeUnderscores}}</div>

                        <div class="w-full text-center flex flex-row items-center gap-1 font-medium">
                            <mat-icon class="icon-size-5 text-green-700"
                                      [svgIcon]="'heroicons_solid:check-circle'">
                            </mat-icon>
                            {{t('common.success_cloned_users')}}:
                            <span class="font-semibold">
                                {{clonedGroup.numElementiClonatiConSuccesso}}/{{clonedGroup.numElementiCicloPrecedente}}
                            </span>
                        </div>

                        <div *ngIf="clonedGroup.errori?.length > 0"
                             class="text-amber-600 bg-amber-50 rounded-xl p-2 px-3 flex flex-col">
                            <div *ngFor="let errore of clonedGroup.errori">
                                <div class="flex flex-row gap-1 items-center">
                                    <mat-icon class="icon-size-4"
                                              [class]="'text-amber-600'"
                                              [svgIcon]="'heroicons_solid:exclamation'">
                                    </mat-icon>
                                    <span [class]="'text-amber-600'">{{errore}}</span>
                                </div>
                            </div>
                        </div>

<!--                        <div *ngIf="clonedGroup.messages?.length > 0"-->
<!--                             class="text-gray-700 bg-amber-50 rounded-xl p-2 flex flex-col gap-2">-->
<!--                            <div class="w-full text-center font-semibold text-amber-500">-->
<!--                                {{t('common.errors_cloned_users')}}:-->
<!--                            </div>-->
<!--                            <ul>-->
<!--                                <li *ngFor="let message of clonedGroup.messages">-->
<!--                                    <div class="flex flex-row gap-1 items-center">-->
<!--                                        <mat-icon class="icon-size-4"-->
<!--                                                  [class]="message.tipo === 'error' ? 'text-red-600' : 'text-amber-600'"-->
<!--                                                  [svgIcon]="message.tipo === 'error' ? 'heroicons_solid:exclamation-circle' : 'heroicons_solid:exclamation'">-->
<!--                                        </mat-icon>-->
<!--                                        <span class="text-gray-500 font-medium">{{message.message}}</span>-->
<!--                                    </div>-->
<!--                                </li>-->
<!--                            </ul>-->
<!--                        </div>-->

                    </div>

                </div>

            </div>



        </mat-dialog-content>
    </app-dialog-wrap>

    <div
        class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">
        <button color="accent" mat-flat-button [matDialogClose]="'cancelled'">
            <mat-icon class="mr-2">close</mat-icon>
            {{t('common.close')}}
        </button>
    </div>

</ng-container>
