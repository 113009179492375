<ng-container *transloco="let t">

    <div class="w-full flex flex-col gap-4 mt-4 mb-20" *ngIf="!operationSucceed && !operationError">

        <div>
            {{t('massive_operations.superamento_extra.confirm_1')}}
        </div>
        <div class="flex flex-col">
            <div *ngFor="let attivita of approvazioneAttivitaData?.attivitaSelected">
                <span>
                    <span class="font-medium">{{attivita?.dettaglioAttivitaTrasversale ? (attivita?.dettaglioAttivitaTrasversale + ' - ') : ''}}  {{attivita?.denominazione}}</span>
                    ({{attivita.utenteCognome + ' ' + attivita.utenteNome}})
                </span>
            </div>
        </div>

        <div class="flex flex-row gap-4 my-4 w-full justify-center">
            <button mat-flat-button
                    class="max-w-80"
                    (click)="openConfirmOperationDialog()"
                    [color]="'primary'">
                <mat-icon class="mr-2">done</mat-icon>
                <span>{{t('dialog.confirm')}}</span>
            </button>
        </div>

    </div>

    <div class="w-full flex flex-col gap-8 mt-4 mb-20" *ngIf="operationSucceed">

        <div class="flex flex-col items-center justify-center text-center w-full gap-2">
            <mat-icon *ngIf="operationEsiti | operationSucceedWithAllSuccess"
                      class="text-green-700"
                      style="font-size: 40px; height: 40px; width: 40px; color: #FFD374">check_circle</mat-icon>
            <mat-icon *ngIf="operationEsiti | operationSucceedWithSomeError"
                      class="text-amber-700"
                      style="font-size: 40px; height: 40px; width: 40px; color: #FFD374">warning</mat-icon>
            <mat-icon *ngIf="operationEsiti | operationSucceedWithAllError"
                      class="text-red-700"
                      style="font-size: 40px; height: 40px; width: 40px; color: #FFD374">warning</mat-icon>
            <span>{{t('massive_operations.superamento_extra.success')}}</span>
        </div>

        <ng-container *ngIf="!!operationEsiti">
            <div class="flex flex-col">
                <div *ngFor="let esito of operationEsiti" class="flex flex-row gap-1">
                    <span>
                        <span class="font-medium max-w-[50%]" *ngIf="esito?.esito === EsitoOperazione.SUCCESS">{{getDenominazioneFromEsito(esito)}}</span>
                        <span class="font-medium mr-1">({{getStudenteByEsito(esito)}})</span>
                    </span>
                    <mat-icon
                        [ngClass]="{'text-green-700': esito?.esito === EsitoOperazione.SUCCESS, 'text-red-700': esito?.esito === EsitoOperazione.ERROR}"
                        style="font-size: 20px; height: 20px; width: 20px; color: #FFD374">{{esito?.esito === EsitoOperazione.SUCCESS ? 'check_circle' : 'warning'}}</mat-icon>
                    <span>{{t(esito?.esito === EsitoOperazione.SUCCESS ? 'massive_operations.superamento_extra.student_success' : 'massive_operations.superamento_extra.student_error' )}}</span>
                    {{esito?.causa_errore}}
                </div>
            </div>
        </ng-container>

    </div>

    <div class="w-full flex flex-col gap-4 mt-4 mb-20" *ngIf="operationError">

        <div class="flex flex-col items-center justify-center text-center w-full gap-2">
            <mat-icon
                class="text-red-800"
                style="font-size: 40px; height: 40px; width: 40px; color: #FFD374">warning</mat-icon>
            <span>{{t('massive_operations.superamento_extra.error_during_op')}}</span>
        </div>

        <div class="flex flex-row gap-4 my-4 w-full items-center justify-center">
            <button mat-flat-button
                    class="max-w-80"
                    (click)="openConfirmOperationDialog()"
                    [color]="'primary'">
                <mat-icon class="mr-2">refresh</mat-icon>
                <span>{{t('common.try_again')}}</span>
            </button>
        </div>

    </div>

</ng-container>
