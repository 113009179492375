import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogWrapI} from "../../../layout/common/dialog-wrap/dialog-wrap.component";
import {licensesInfo} from "../../../../../version-info";
import {Observable} from "rxjs";

export interface LicenseData {
    name: string;
    version?: string;
    homepage?: string;
    username?: string;
    repo?: string;
    license_url?: string;
    license_text_url?: string;
    license_text?: string;
    license_text_html?: string;
}

export interface DialogLicensesI extends DialogWrapI {
    tagFE: string;
    tagBE: string;
}

@Component({
    selector: 'app-dialog-licenses',
    templateUrl: './dialog-licenses.component.html',
    styleUrls: ['./dialog-licenses.component.scss']
})
export class DialogLicensesComponent implements OnInit {
    licenses: LicenseData[];

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogLicensesI,
                public dialogRef: MatDialogRef<DialogLicensesComponent>) {
        this.licenses = licensesInfo;
        console.log(this.licenses)
    }

    ngOnInit(): void {
    }

    closeDialog(): void {
        this.dialogRef.close();
    }
}
