import { Component, OnInit } from '@angular/core';
import {PathEnum} from "../../../app.routing";
import {Router} from "@angular/router";

@Component({
  selector: 'app-testo-termini-e-condizioni',
  templateUrl: './testo-termini-e-condizioni.component.html',
  styleUrls: ['./testo-termini-e-condizioni.component.scss']
})
export class TestoTerminiECondizioniComponent implements OnInit {

  constructor(private _router: Router) { }

  ngOnInit(): void {
  }

    openPrivacyOrTerminiECondizioni(isPrivacy: boolean): void {
        const url = this._router.serializeUrl(
            this._router.createUrlTree([isPrivacy ? PathEnum.PRIVACY : PathEnum.TERMS_CONDITIONS])
        );
        window.open(url, '_blank');
    }

}
