import {Component, Inject, OnInit} from '@angular/core';
import {
    AggiornamentoBudgetStudentePerOpMassivaInfoView,
    ApprovazioneMassivaAggiornamentiBudgetStudenteByGruppoPTARequest,
    ApprovazioneMassivaAggiornamentiBudgetStudenteResponse,
    DeliberaApprovaSpeseStudentiRequest,
    DeliberaApprovaSpeseStudentiResponse,
    EsitoOperazione, EsitoSingolaApprovazioneAggiornamentoBudgetStudente,
    EsitoSingolaApprovazioneSpesa,
    OperazioniMassiveService,
    SpesaStudentePerApprovMassivaInfoView
} from "../../../../../../api-clients/generated/services";
import {filter, switchMap, tap} from "rxjs/operators";
import {BehaviorSubject, finalize, take, takeUntil} from "rxjs";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {ApprovazioneModificheBudgetMassivaDataI} from "../approvazione-modifiche-budget-steps-interface";
import {CAN_GO_AHEAD$, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {cloneDeep} from "lodash-es";
import {TranslocoService} from "@ngneat/transloco";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {get} from "lodash";
import {annoRiferimentoToRomanNumeral} from "../../../../../shared/utils/utils";


@Component({
    selector: 'app-confirm-approvazione-modifiche-budget-stepper',
    templateUrl: './confirm-approvazione-modifiche-budget.component.html',
    styleUrls: ['./confirm-approvazione-modifiche-budget.component.scss']
})
export class ConfirmApprovazioneModificheBudgetComponent extends AbstractDefaultComponent implements OnInit {

    approvazioneModificheBudgetData: ApprovazioneModificheBudgetMassivaDataI;
    operationSucceed: boolean;
    operationError: boolean;
    operationEsiti: Array<EsitoSingolaApprovazioneAggiornamentoBudgetStudente> | undefined;
    resultsModificheBudgetSelected: AggiornamentoBudgetStudentePerOpMassivaInfoView[];
    private resultsCompileInfo: any[];

    constructor(private appInitService: AppInitService,
                private operazioniMassiveService: OperazioniMassiveService,
                private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<ApprovazioneModificheBudgetMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.operazioneMassivaData$.subscribe((inputOutputData: ApprovazioneModificheBudgetMassivaDataI) => {
            this.approvazioneModificheBudgetData = inputOutputData;
            if(this.approvazioneModificheBudgetData?.budgetSelected?.length > 0) {
                this.operationSucceed = false;
                this.operationError = false;
            }
        })
    }


    confirmOperation() {
        this.operationError = undefined;
        this.operationSucceed = undefined;
        this.fuseConfirmationService.showLoader();
        const requestBody: ApprovazioneMassivaAggiornamentiBudgetStudenteByGruppoPTARequest = {
            id_delibera: this.approvazioneModificheBudgetData?.deliberaCollegio?.id,
            specifiche: this.approvazioneModificheBudgetData?.budgetSelected?.map((s, index) => ({
                id_utente: s.budgetStudente?.studenteCiclo?.utente?.id,
                idAggiornamento: s.id,
                nota: this.approvazioneModificheBudgetData?.compileInfo?.find(c => c.id === s.id)?.nota
            }))
        }
        console.log(this.approvazioneModificheBudgetData)
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.operazioniMassiveService.approvazioneMassivaAggiornamentiBudgetStudentiByGruppoPTAForm(requestBody)),
            takeUntil(this.destroy$),
            tap((approvaModificheBudgetResponse: DeliberaApprovaSpeseStudentiResponse) => {
                this.resultsModificheBudgetSelected  = cloneDeep(this.approvazioneModificheBudgetData?.budgetSelected);
                this.resultsCompileInfo = cloneDeep(this.approvazioneModificheBudgetData?.compileInfo);
                // forcing the refresh of spese
                this.operazioneMassivaData$.next({
                    budgetSelected: undefined,
                    refreshData: true,
                });
            }),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe(
            {
                next: (approvaModificheBudgetResponse: ApprovazioneMassivaAggiornamentiBudgetStudenteResponse) => {
                    this.operationSucceed = true;
                    this.operationEsiti = approvaModificheBudgetResponse?.esiti_operazioni;
                },
                error: (err) => {
                    this.operationError = true;
                    console.log(err)
                }
            }
        );

    }

    protected readonly EsitoOperazione = EsitoOperazione;

    getStudenteById(id_utente: string) {
        const studente = this.resultsModificheBudgetSelected?.find(s => s.budgetStudente?.studenteCiclo?.utente?.id === id_utente);
        return studente?.budgetStudente?.studenteCiclo?.utente?.cognome + ' ' + studente?.budgetStudente?.studenteCiclo?.utente?.nome;
    }

    getImportoForBudget(id: string) {
        return this.approvazioneModificheBudgetData.budgetSelected?.find(s => s.id === id)?.importo || 0;
    }

    getImportoForEsito(esito: EsitoSingolaApprovazioneAggiornamentoBudgetStudente) {
        return this.resultsModificheBudgetSelected.find(s => s.id === esito.idAggiornamento)?.importo || 0;
    }
    getAnnoForEsito(esito: EsitoSingolaApprovazioneAggiornamentoBudgetStudente) {
        return this.resultsModificheBudgetSelected.find(s => s.id === esito.idAggiornamento)?.annoRiferimento;
    }

    openConfirmOperationDialog() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
                title: get(translation, 'dialog.attention', null),
                message: get(translation, 'massive_operations.confirm_operation_attention_message', null),
                icon: {
                    name: 'mat_outline:warning',
                    color: 'warning'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    },
                    {
                        color: 'primary',
                        label: get(translation, 'common.confirm', null), icon: 'check',
                        function: () => this.confirmOperation()
                    }]
            }
        );
    }

    protected readonly annoRiferimentoToRomanNumeral = annoRiferimentoToRomanNumeral;
}
