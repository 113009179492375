import {Directive, ElementRef, HostListener, Input, OnInit} from '@angular/core';

@Directive({
    selector: '[appOnlyDecimalNumbers]'
})
export class OnlyDecimalNumbersDirective implements OnInit {


    private specialKeys: Array<string> = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight'];

    @Input() numOfDecimal;

    private regex: RegExp = new RegExp(`^\\d*[.]?\\d{0,2}$`, 'g');

    constructor(private el: ElementRef) {
    }

    ngOnInit() {
        if(this.numOfDecimal){
            this.regex = new RegExp(`^\\d*[.]?\\d{0,${this.numOfDecimal}}$`, 'g');
        }
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent): void {
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        const current: string = this.el.nativeElement.value;
        const next: string = current.concat(event.key);
        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        }
    }

}
