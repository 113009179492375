import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {catchError, Observable} from "rxjs";
import {LocalStorageService} from "../shared/service/local-storage.service";
import moment from "moment/moment";
import * as fs from 'file-saver';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(private localStorageService: LocalStorageService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError( (error: HttpErrorResponse) =>  {
                console.log(error)
                const errorObject = {
                    happened_at: moment().format('DD/MM/YYYY HH:mm:ss.SSS'),
                    user_id: this.localStorageService?.profile?.id,
                    name: error.name,
                    message: error.message,
                    status: error.status,
                    headers: error.headers,
                    url: error.url
                };
                this.localStorageService.enqueueError(errorObject);
                throw error;
            }));
    }
}
