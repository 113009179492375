import {Injectable} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {BehaviorSubject, map, Observable} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {PeriodoDiMobilitaStudenteStatus, StatoTemporaleEnum} from 'api-clients/generated/services';
import {AuthorityType, MobilitaRequestSelectFormValuesDTO} from "../../../../../api-clients/generated/services";
import {LocalStorageService} from "../../../../shared/service/local-storage.service";
import {AbstractFilterService, MobilitaFilters} from "../mobilita-sidebar-filter-container/mobilita-filter.service";
import {DEFAULT_PAGE_SIZE} from "../requests-to-be-resolved.component";


@Injectable({
    providedIn: 'root'
})
export class RicercaFilterService implements AbstractFilterService {

    mainFormGroup: FormGroup | undefined;
    selectValues: MobilitaRequestSelectFormValuesDTO;
    checkAggiornamentoSelectValues$ = new BehaviorSubject(undefined);
    reset$ = new BehaviorSubject(false);
    checkApplyFilterClick$ = new BehaviorSubject(false);
    currentSottoRuolo: AuthorityType | undefined;
    filterApplied$ = new BehaviorSubject(undefined);
    filterApplied =  undefined;
    page: number = 0;
    pageSize: number = DEFAULT_PAGE_SIZE;
    defaultFormPathList = [
        'codiceMobilita',
        'nominativoStudente',
        'statoSvolgimento',
        'stato',
        'periodoDa',
        'periodoA',
        'annoAccademico',
        'nominativoStrutturaEsteraOspitante',
        'codiceNazioneStruttura',
        'dataFineNull',
        'codiceTipologiaMobilita',
        'codiceTipologiaBonus',
        'codiceTipoInterventoSupporto',
        'dataUltimaModifica'
    ];
    supervisorFormPathList = [
        'codiceMobilita',
        'nominativoStudente',
        'statoSvolgimento',
        'stato',
        'periodoDa',
        'periodoA',
        'annoAccademico',
        'nominativoStrutturaEsteraOspitante',
        'codiceNazioneStruttura',
        'dataFineNull',
        'codiceTipologiaMobilita',
        'codiceTipologiaBonus',
        'codiceTipoInterventoSupporto',
        'dataUltimaModifica'
    ];
    cosupervisorFormPathList = this.supervisorFormPathList;
    coordinatoreFormPathList = [
        'codiceMobilita',
        'nominativoStudente',
        'statoSvolgimento',
        'stato',
        'periodoDa',
        'periodoA',
        'annoAccademico',
        'nominativoStrutturaEsteraOspitante',
        'codiceNazioneStruttura',
        'dataFineNull',
        'codiceTipologiaMobilita',
        'codiceTipologiaBonus',
        'codiceTipoInterventoSupporto',
        'dataUltimaModifica'
    ];
    fieldsLabelMap = new Map<string, string>([
        ['codiceMobilita', 'mobility.mobility_code'],
        ['nominativoStudente', 'mobility.student_nominative'],
        ['statoSvolgimento', 'mobility.execution_state'],
        ['periodoDa', 'mobility.research_period_from'],
        ['periodoA', 'mobility.research_period_to'],
        ['annoAccademico', 'mobility.year'],
        ['nominativoStrutturaEsteraOspitante', 'mobility.foreign_university_nominative'],
        ['codiceNazioneStruttura', 'mobility.foreign_university_nationality'],
        ['dataFineNull', 'mobility.without_datafine'],
        ['codiceTipologiaMobilita','mobility.mobility_type'],
        ['codiceTipologiaBonus','mobility.bonus_tipology'],
        ['codiceTipoInterventoSupporto', 'mobility.support_tipology'],
        ['stato', 'mobility.state'],
        ['dataUltimaModifica', 'mobility.last_modified_date'],
    ]);


    constructor(public localStorageService: LocalStorageService,
                public formBuilder: FormBuilder) {
    }


    createDefaultFormGroup(): FormGroup {
        return this.formBuilder.group({
            nominativoStudente: null,
            statoSvolgimento: null,
            periodoDa: null,
            periodoA: null,
            annoAccademico: null,
            nominativoStrutturaEsteraOspitante: null,
            codiceNazioneStruttura: null,
            dataFineNull: null,
            codiceTipologiaMobilita: null,
            codiceTipologiaBonus: null,
            codiceTipoInterventoSupporto: null,
            codiceMobilita: null,
            stato: PeriodoDiMobilitaStudenteStatus.APPROVATOPARZIALE,
            dataUltimaModifica: null
        });
    }



    getFieldsByRole(ruolo?: AuthorityType, sottoruolo?: AuthorityType): string[] {
        switch(ruolo) {
            case AuthorityType.SUPERADMIN:
            case AuthorityType.ADMIN:
                return this.defaultFormPathList;
            default:
                switch (sottoruolo) {
                    case AuthorityType.GRUPPODICOORDINAMENTO:
                    case AuthorityType.COLLEGIODOCENTI:
                    case AuthorityType.GRUPPOPTA:
                    case AuthorityType.SEGRETARIOAMMINISTRATIVODIDIPARTIMENTO:
                        return this.defaultFormPathList;
                    case AuthorityType.SUPERVISORE:
                        return this.supervisorFormPathList;
                    case AuthorityType.COSUPERVISORE:
                        return this.cosupervisorFormPathList;
                    case AuthorityType.COORDINATORE:
                        return this.coordinatoreFormPathList;
                    default:
                        return null;
                }
        }


    }


    init(force?: boolean): void {
        const sottoruolo = this.localStorageService.dipartimentoRuoloCiclo?.sottoruolo;
        if ((this.currentSottoRuolo !== sottoruolo) || !this.currentSottoRuolo) {
            this.currentSottoRuolo = sottoruolo;
            this.buildForm();
        } else if(force){
            this.buildForm();
            this.filterApplied = this.mainFormGroup.getRawValue();
        }
    }

    private buildForm() {
        if (!!this.mainFormGroup) {
            this.mainFormGroup.reset();
            this.mainFormGroup.patchValue({stato: PeriodoDiMobilitaStudenteStatus.APPROVATOPARZIALE})
        } else {
            this.mainFormGroup = this.createDefaultFormGroup();
        }
    }

    getCtrlByPath(path: string): FormControl<any> {
        return this.mainFormGroup?.get(path) as FormControl;
    }



    setSelectValues(selectValues?: MobilitaRequestSelectFormValuesDTO): void {
        this.selectValues = selectValues;
        this.checkAggiornamentoSelectValues$.next(this.selectValues);
    }


    reset(): void {
        this.page = 0;
        this.pageSize = DEFAULT_PAGE_SIZE;
        this.mainFormGroup.reset();
        this.filterApplied = this.mainFormGroup.getRawValue();
        this.checkApplyFilterClick$.next(true);
    }


    restore(): void {
        this.page = 0;
        this.pageSize = DEFAULT_PAGE_SIZE;
        this.mainFormGroup.reset();
        this.buildForm();
        this.filterApplied = this.mainFormGroup.getRawValue();
        this.checkApplyFilterClick$.next(true);
    }
}
