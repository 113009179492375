import {Component, Inject, OnInit} from '@angular/core';
import {
    CorsoPianoDiStudiPerExportViewImpl,
    EsitoOperazione,
    EsitoSingolaOperazioneSuAttivitaExtra,
    OperazioneMassivaSuAttivitaExtraResponse,
    OperazioniMassiveService,
    SuperamentoMassivoAttivitaExtraDottorandiRequest
} from "../../../../../../api-clients/generated/services";
import {filter, switchMap, tap} from "rxjs/operators";
import {BehaviorSubject, finalize, take, takeUntil} from "rxjs";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {ApprovazioneExtraMassivaDataI} from "../approvazione-extra-steps-interface";
import {CAN_GO_AHEAD$, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {cloneDeep} from "lodash-es";
import {TranslocoService} from "@ngneat/transloco";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {get} from "lodash";
import moment from "moment";


@Component({
    selector: 'app-confirm-superamento-extra-stepper',
    templateUrl: './confirm-superamento-extra.component.html',
    styleUrls: ['./confirm-superamento-extra.component.scss']
})
export class ConfirmSuperamentoExtraComponent extends AbstractDefaultComponent implements OnInit {

    approvazioneAttivitaData: ApprovazioneExtraMassivaDataI;
    operationSucceed: boolean;
    operationError: boolean;
    operationEsiti: Array<EsitoSingolaOperazioneSuAttivitaExtra> | undefined;
    resultsAttivitaSelected: CorsoPianoDiStudiPerExportViewImpl[];
    private resultsCompileInfo: any[];

    constructor(private appInitService: AppInitService,
                private operazioniMassiveService: OperazioniMassiveService,
                private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<ApprovazioneExtraMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.operazioneMassivaData$.subscribe((inputOutputData: ApprovazioneExtraMassivaDataI) => {
            if(inputOutputData?.compileInfo?.length > 0){
                this.approvazioneAttivitaData = inputOutputData;
            }
            if (this.approvazioneAttivitaData?.attivitaSelected?.length > 0) {
                this.operationSucceed = false;
                this.operationError = false;
            }
        })
    }


    confirmOperation() {
        this.operationError = undefined;
        this.operationSucceed = undefined;
        this.fuseConfirmationService.showLoader();
        const requestBody: SuperamentoMassivoAttivitaExtraDottorandiRequest = {
            specifiche: this.approvazioneAttivitaData?.attivitaSelected?.map((att, index) => {
                const compileInfo = this.approvazioneAttivitaData?.compileInfo?.find(
                    ci =>
                        (ci.id === att.idCorsoInOffertaFormativa
                            || ci.id === att.idAttivitaProposta
                            || ci.id === att.idAttivitaPresunta)
                        && ci.idCategoria === att.idCategoriaOffertaFormativa);
                return {
                    attivita_extra_ids: {
                        id_corso_in_offerta_formativa: att.idCorsoInOffertaFormativa,
                        id_attivita_presunta: att.idAttivitaPresunta,
                        id_attivita_proposta: att.idAttivitaProposta,
                    },
                    id_categoria_offerta_formativa: att.idCategoriaOffertaFormativa,
                    data_superamento: moment(compileInfo.data).format('DD/MM/YYYY'),
                    cfu_ottenuti: compileInfo.cfu,
                    nota_superamento: compileInfo.nota,
                    nome_file_supervisore: this.approvazioneAttivitaData?.compileInfoFiles?.get(compileInfo.id)?.name,
                    id_piano_di_studi: att.idPianoDiStudi,
                }
            })
        }
        console.log(this.approvazioneAttivitaData)
        const fileAllegati = [...(this.approvazioneAttivitaData?.compileInfoFiles?.values() ?? [])];
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.operazioniMassiveService.superamentoMassivoAttivitaExtraDottorandiForm(requestBody, fileAllegati)),
            takeUntil(this.destroy$),
            tap((registraSuperamentoExtra: OperazioneMassivaSuAttivitaExtraResponse) => {
                this.resultsAttivitaSelected = cloneDeep(this.approvazioneAttivitaData?.attivitaSelected);
                this.resultsCompileInfo = cloneDeep(this.approvazioneAttivitaData?.compileInfo);
                // forcing the refresh of spese
                this.operazioneMassivaData$.next({
                    attivitaSelected: undefined,
                    refreshData: true,
                });
            }),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe(
            {
                next: (registraSuperamentoExtra: OperazioneMassivaSuAttivitaExtraResponse) => {
                    this.operationSucceed = true;
                    this.operationEsiti = registraSuperamentoExtra?.esiti_operazioni;
                },
                error: (err) => {
                    this.operationError = true;
                    console.log(err)
                }
            }
        );

    }


    protected readonly EsitoOperazione = EsitoOperazione;

    getStudenteByEsito(esito: EsitoSingolaOperazioneSuAttivitaExtra) {
        const studente = this.resultsAttivitaSelected?.find(a =>
           (a.idCorsoInOffertaFormativa ===  esito?.id_corso_in_offerta_formativa
               || a.idAttivitaProposta === esito?.id_attivita_proposta
               || a.idAttivitaPresunta === esito?.id_attivita_presunta
           ) && a.idCategoriaOffertaFormativa ===  esito?.id_categoria_offerta_formativa
        );
        return studente?.utenteCognome + ' ' + studente?.utenteNome;
    }



    getDenominazioneFromEsito(esito: EsitoSingolaOperazioneSuAttivitaExtra) {
        return this.resultsAttivitaSelected?.find(a =>
            (a.idCorsoInOffertaFormativa ===  esito?.id_corso_in_offerta_formativa
                || a.idAttivitaProposta === esito?.id_attivita_proposta
                || a.idAttivitaPresunta === esito?.id_attivita_presunta
            ) && a.idCategoriaOffertaFormativa ===  esito?.id_categoria_offerta_formativa
        )?.denominazione;
    }

    openConfirmOperationDialog() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
                title: get(translation, 'dialog.attention', null),
                message: get(translation, 'massive_operations.confirm_operation_attention_message', null),
                icon: {
                    name: 'mat_outline:warning',
                    color: 'warning'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    },
                    {
                        color: 'primary',
                        label: get(translation, 'common.confirm', null), icon: 'check',
                        function: () => this.confirmOperation()
                    }]
            }
        );
    }

}
