/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Stati di una richiesta di retribuzione di un periodo di mobilità studente. Valori previsti: *DA_APPROVARE*, *APPROVATA_PARZIALE*, *APPROVATA*, *VALIDATA_PTA*, *RIFIUTATA*
 */
export type RichiestaDiRetribuzioneMobilitaStatus = 'DA_APPROVARE' | 'APPROVATA_PARZIALE' | 'APPROVATA' | 'VALIDATA_PTA' | 'RIFIUTATA';

export const RichiestaDiRetribuzioneMobilitaStatus = {
    DAAPPROVARE: 'DA_APPROVARE' as RichiestaDiRetribuzioneMobilitaStatus,
    APPROVATAPARZIALE: 'APPROVATA_PARZIALE' as RichiestaDiRetribuzioneMobilitaStatus,
    APPROVATA: 'APPROVATA' as RichiestaDiRetribuzioneMobilitaStatus,
    VALIDATAPTA: 'VALIDATA_PTA' as RichiestaDiRetribuzioneMobilitaStatus,
    RIFIUTATA: 'RIFIUTATA' as RichiestaDiRetribuzioneMobilitaStatus
};