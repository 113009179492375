import {Component, Inject, InjectionToken, Input, OnInit, Optional} from '@angular/core';
import {DettaglioCalcoloImportoMobilita, PercorsoDiStudiView} from "../../../../api-clients/generated/services";
import {INFO_BOTTOM_COMPONENT_DATA} from "../../../layout/common/info-dialog/info-wrap.component";


@Component({
  selector: 'app-info-maggiorazione',
  templateUrl: './info-maggiorazione.component.html',
  styleUrls: ['./info-maggiorazione.component.scss']
})
export class InfoMaggiorazioneComponent implements OnInit {
    @Input() dettaglioImporto: DettaglioCalcoloImportoMobilita;
    @Input() anteprimaMode: boolean;

    constructor(@Optional() @Inject(INFO_BOTTOM_COMPONENT_DATA) public injectedDettaglioImporto: DettaglioCalcoloImportoMobilita) {
        this.dettaglioImporto = this.dettaglioImporto || this.injectedDettaglioImporto;
        console.log(this.injectedDettaglioImporto)
    }

    ngOnInit(): void {

    }

}
