<ng-container *transloco="let t">
    <div class="container justify-content_center" [style]="_configuration?.css">
        <div *ngIf="!hideSelectionHeader && ((isSelectable  && !_configuration?.singleChoiceSelection)
        || (_configuration.expandRow?.selection?.isSelectable))"
             class="py-4 px-2 rounded-lg bg-gray-500 dark:bg-gray-800 ng-star-inserted w-full">
            <mat-chip-list>
                <div
                    *ngIf="(righeSelezionate !== undefined && righeSelezionate.length > 0) || _righeSelezionateCustom?.length > 0; else noSelezione"
                    [style]="(righeSelezionate.length > 8)? 'height: 150px !important;' : 'height: 100px !important;'"
                    class="table-selection grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 w-full">
                    <ng-container *ngFor="let riga of righeSelezionate">
                        <div class="flex flex-col w-full items-center">
                            <mat-chip color="primary" class="mat-chip-multiselection flex flex-row justify-between">
                                <span
                                    *ngIf="selectedColumnKeyToShow || _configuration.expandRow?.selection?.selectedColumnKeyToShow"
                                    class="line-clamp-1">{{ (riga?.parentId ? riga.data[_configuration.expandRow?.selection?.selectedColumnKeyToShow] : riga.data[selectedColumnKeyToShow]) | uppercase }}</span>
                                <button [style]="'padding-left: 5px'" [disabled]="readOnly"
                                        *ngIf="!checkIfRigaReadOnly(riga?.data) && !_configuration.selection?.selectedAndDisableByDefaultIf?.(riga?.data); else rigaSolaLetturaIcon"
                                        (click)="removeToggleSelectedRowData(riga)">
                                    <mat-icon [style]="'margin-top: 5px'">delete</mat-icon>
                                </button>
                                <ng-template #rigaSolaLetturaIcon>
                                    <button [style]="'padding-left: 5px'"
                                            *ngIf="checkIfRigaReadOnly(riga?.data) && !!infoRigheInSolaLettura">
                                        <mat-icon
                                            [matTooltip]="t(infoRigheInSolaLettura?.tooltip)">{{ infoRigheInSolaLettura?.iconName }}
                                        </mat-icon>
                                    </button>
                                </ng-template>
                            </mat-chip>
                        </div>
                    </ng-container>
                    <ng-container *ngFor="let riga of _righeSelezionateCustom">
                        <div class="flex flex-col w-full items-center">
                            <mat-chip color="primary"
                                      class="mat-chip-multiselection flex flex-row justify-between bg-red-200">
                                <span *ngIf="selectedColumnKeyToShow"
                                      class="line-clamp-1">{{ riga.data[selectedColumnKeyToShow] | uppercase }}</span>
                                <div class="flex flex-row gap-1 pl-2">
                                    <button [disabled]="readOnly"
                                            (click)="toggleSelectedCustomRowData(riga)">
                                        <mat-icon [style]="'margin-top: 5px'">delete</mat-icon>
                                    </button>
                                </div>
                            </mat-chip>
                        </div>
                    </ng-container>
                </div>
                <ng-template #noSelezione>
                    <div class="w-full table-no-selection flex flex-col items-center justify-center gap-2.5">
                        <span>{{ t("common.no_elements") }}</span>
                    </div>
                </ng-template>
            </mat-chip-list>
        </div>

        <div *ngIf="!hideSelectionHeader && isSelectable  && _configuration?.singleChoiceSelection"
             class="py-2 px-2 rounded-lg bg-gray-500 dark:bg-gray-800 ng-star-inserted w-full">
            <mat-chip-list class="flex flex-col w-full items-center">
                <div *ngIf="(righeSelezionate !== undefined && righeSelezionate.length > 0); else noSelezione">
                    <div class="flex flex-col w-full items-center">
                        <mat-chip color="primary" class=" flex flex-row justify-between">
                                <span *ngIf="selectedColumnKeyToShow"
                                      class="line-clamp-1">{{ (righeSelezionate[0])?.data[selectedColumnKeyToShow] | uppercase }}</span>
                            <button [style]="'padding-left: 5px'"
                                    (click)="toggleSelectedRowData((righeSelezionate[0])?.data)">
                                <mat-icon [style]="'margin-top: 5px'">delete</mat-icon>
                            </button>
                        </mat-chip>
                    </div>

                </div>
            </mat-chip-list>

            <ng-template #noSelezione>
                <div class="w-full table-no-single-selection flex flex-col items-center justify-center gap-2.5">
                    <span>{{ t("common.no_elements") }}</span>
                </div>
            </ng-template>

        </div>


        <mat-table #table [dataSource]="dataSourcePaginated" matSort
                   (matSortChange)="onSortChange($event)"
                   multiTemplateDataRows
                   [ngClass]="_configuration.classTable || undefined"
                   class="flex flex-col items-center xl:block xl:items-start"
                   [style]="!!_configuration?.cssMatTable ? _configuration?.cssMatTable : _configuration?.css">
            <ng-container *ngFor="let colonna of _configuration.configurazioneTabella; trackBy: trackByFn"
                          matColumnDef="{{colonna.colonnaKey}}">
                <mat-header-cell *matHeaderCellDef
                                 [ngStyle]="colonna.css || {}"
                                 mat-sort-header
                                 [disabled]="!colonna.enableSort"
                                 class="justify-center hidden md:flex"> {{
                        t((colonna.nomeColonna || colonna.colonnaKey)) |  customTitleCase: colonna.uppercase | removeUnderscores
                    }}
                </mat-header-cell>

                <mat-cell *matCellDef="let element; let idx = index"
                          [ngStyle]="colonna.css || {}"
                          [ngClass]="{'cursor-pointer': _configuration.selection?.isSelectable}"
                          (click)="onCellClick(element, colonna.tipo)"
                          [matTooltip]="checkIfRigaReadOnly(element) ? disabledRowTooltipText : undefined"
                          [class]="(readOnly || disableNonSelectedCheck(element) ||  checkIfParentIsDifferent(element) || checkIfRigaReadOnly(element)) ?
                           _configuration?.disabledCssClass : ''">

                    <ng-container [ngSwitch]="colonna.tipo">
                    <span [ngStyle]="_configuration?.css"
                          class="mobile-label"> {{ t((colonna.nomeColonna || colonna.colonnaKey)) |  customTitleCase: colonna.uppercase | removeUnderscores }}</span>


                        <ng-container *ngSwitchCase="tipoColonna.EXPAND">
                            <button mat-icon-button aria-label="expand row"
                                    *ngIf="!!element[_configuration.expandRow.dataKey]?.length"
                                    (click)="expandRow($event, element)">
                                <mat-icon *ngIf="expandedElementId !== element?.[_configuration.expandRow.parentIdKey]">
                                    keyboard_arrow_down
                                </mat-icon>
                                <mat-icon *ngIf="expandedElementId === element?.[_configuration.expandRow.parentIdKey]">
                                    keyboard_arrow_up
                                </mat-icon>
                            </button>
                        </ng-container>

                        <ng-container *ngSwitchCase="tipoColonna.SELECTION">
                            <ng-container
                                *ngIf="(!_configuration?.expandRow?.expand || !element[_configuration?.expandRow?.dataKey]?.length)">
                                <mat-checkbox class="m-1" color="primary"
                                              (change)="toggleSelectedRowData(element, $event)"
                                              (click)="$event.stopPropagation()"
                                              [matTooltip]="t(_configuration.selection?.selectedAndDisableByDefaultIf?.(element))"
                                              [checked]="checkIfSelected(element[selectedColumnKeyPrimaryKey]) || _configuration.selection?.selectedAndDisableByDefaultIf?.(element)|| _configuration.selection?.selectedByDefaultIfAndCallbackOnUnselect?.(element)"
                                              [disabled]="readOnly || disableNonSelectedCheck(element) ||  checkIfParentIsDifferent(element) || checkIfRigaReadOnly(element) || _configuration.selection?.selectedAndDisableByDefaultIf?.(element)"></mat-checkbox>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="tipoColonna.ICON">
                            <ng-container *ngFor="let icon of colonna.icon">
                                <mat-icon
                                    *ngIf="!icon.showIf || icon.showIf(element[colonna.colonnaKey])"
                                    [color]="icon.colorIcon"
                                    [class]="icon.classes"
                                    (click)="icon.onClick ? icon.onClick(element) : null"
                                    [matTooltip]="(icon.tootlip || '') | customTitleCase: colonna.uppercase  | removeUnderscores">{{ icon.nameIcon }}
                                </mat-icon>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="tipoColonna.STRING">
                            <ng-container *ngIf="(element | valueShowPipe:colonna:colonna.colonnaKey )as value; else emptyTmp">
                                <div class="flex flex-row items-center">
                                    <ng-container *ngIf="colonna.alsoShowChipIf?.(element) as chipConfig">
                                        <mat-icon style="margin: 5px"  [matTooltip]=" t(chipConfig.chipText)"
                                                  class="icon-size-4" [class]="chipConfig.classes">
                                            {{ chipConfig.iconName }}
                                        </mat-icon>
                                    </ng-container>
                                    <div style="  text-align: center;   white-space: pre-line;"
                                         *ngIf="(colonna.colonnaKey === 'email' || colonna.colonnaKey === 'username'); else showValue">
                                        <span class="mobile-text" style="word-break: break-word;">
                                            {{ value }}
                                        </span>
                                    </div>
                                    <ng-template #showValue>
                                        <div class="flex flex-row items-center gap-2">
                                            <ng-container *ngIf="colonna.showAlsoIconIf?.(element) as iconConfig">
                                                <mat-icon class="cursor-pointer"
                                                          [matTooltip]="t(iconConfig.tooltipText)"
                                                          [color]="iconConfig.color">
                                                    {{ iconConfig.name }}
                                                </mat-icon>
                                            </ng-container>
                                            <span
                                                style="  text-align: center;   white-space: pre-line; word-break: break-word;"
                                                *ngIf="!colonna?.show || colonna?.show(element[colonna.colonnaKey])"
                                                class="mobile-text">
                                                {{ value  | customTitleCase: colonna.uppercase | removeUnderscores }}
                                            </span>
                                        </div>
                                    </ng-template>
                                </div>
                            </ng-container>
                            <ng-template #emptyTmp>
                                <ng-container *ngIf="!hideDashForEmptyChipStatus">
                                    -
                                </ng-container>
                            </ng-template>
                        </ng-container>

                        <ng-container *ngSwitchCase="tipoColonna.NUMBER">
                            <ng-container *ngIf="(element | valueShowPipe:colonna:colonna.colonnaKey )as value; else emptyTmp">
                            <span style="  text-align: center;"
                                  *ngIf="!colonna?.show || colonna?.show(element[colonna.colonnaKey])"
                                  class="mobile-text">
                                {{ value }}</span>
                            </ng-container>
                            <ng-template #emptyTmp>
                                <ng-container *ngIf="!hideDashForEmptyChipStatus">
                                    -
                                </ng-container>
                            </ng-template>
                        </ng-container>

                        <ng-container *ngSwitchCase="tipoColonna.HREF">
                            <ng-container *ngIf="(element | valueShowPipe:colonna:colonna.colonnaKey )as value">
                            <span style="text-align: center;"
                                  (click)="goTo(colonna, element, colonna.colonnaKey); $event.stopPropagation()"
                                  *ngIf="!colonna?.show || colonna?.show(element[colonna.colonnaKey])"
                                  class="mobile-text cursor-pointer">
                                {{ value }}</span>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="tipoColonna.AMOUNT">
                            <ng-container *ngIf="(element | valueShowPipe:colonna:colonna.colonnaKey )as value; else emptyTmp">
                            <span style="  text-align: center;"
                                  *ngIf="!colonna?.show || colonna?.show(element[colonna.colonnaKey])"
                                  class="mobile-text">
                                {{ value | currencyEuro }}</span>
                            </ng-container>
                            <ng-template #emptyTmp>
                                <ng-container *ngIf="!hideDashForEmptyChipStatus">
                                    -
                                </ng-container>
                            </ng-template>
                        </ng-container>


                        <ng-container *ngSwitchCase="tipoColonna.TEXT_BUTTON">
                            <ng-container *ngIf="(element | valueShowPipe:colonna:colonna.colonnaKey )as value">
                                <div class="flex flex-row items-center justify-between">
                                    <div><span style="  text-align: center;   white-space: pre-line"
                                               *ngIf="!colonna?.show || colonna?.show(element[colonna.colonnaKey])"
                                               class="mobile-text"
                                    >
                                {{ value }}</span>
                                    </div>

                                    <mat-icon matTooltip="Visualizza in tabella" *ngIf="!!value"
                                              style="margin-left: 5px"
                                              class="cursor-pointer icon-size-4"
                                              (click)="colonna.dialog.openDialog(element[colonna.dialog.fieldValueDialog]); $event.stopPropagation()">
                                        open_in_new
                                    </mat-icon>
                                </div>


                            </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="tipoColonna.BOOLEAN">
                            <ng-container *ngIf="!colonna?.show || colonna?.show(element[colonna.colonnaKey], element); else emptyTmp">
                                <ng-container *ngIf="!!colonna.convertiValoreBooleanInIcon">
                                    <mat-icon
                                        *ngIf="convertiValoreInBo(colonna, element[colonna.colonnaKey]) as iconConfig"
                                        [color]="iconConfig.colorIcon"
                                        [matTooltip]="(iconConfig.tootlip || '') | customTitleCase: colonna.uppercase | removeUnderscores">{{ iconConfig.nameIcon }}
                                    </mat-icon>
                                </ng-container>
                                <ng-container *ngIf="!!colonna.convertiValoreBoolean">
                                    <span
                                        class="mobile-text">{{ t((element[colonna.colonnaKey] | getValuePipe: colonna))| customTitleCase: colonna.uppercase | removeUnderscores }}</span>
                                </ng-container>
                            </ng-container>
                            <ng-template #emptyTmp>
                                <ng-container *ngIf="!hideDashForEmptyChipStatus">
                                    -
                                </ng-container>
                            </ng-template>

                        </ng-container>

                        <ng-container *ngSwitchCase="tipoColonna.ACTION">
                            <div
                                class="flex flex-col items-center justify-center"
                                style="width: 100%"
                                [class.custom-button]="_configuration.customButton">
                                <div class="flex flex-wrap items-center justify-center"
                                     style="width: 100%">
                                    <ng-container
                                        *ngFor="let button of (colonna | getButtonPipe: readOnly)">
                                        <ng-container *ngIf="!button.hide">
                                            <button *ngIf="show(button, element, button?.valueShow) && !button.isIcon"
                                                    style="margin: 2px; box-shadow: none"
                                                    [color]="button.color" mat-mini-fab
                                                    [matTooltip]="t(button.tooltip) || ''"
                                                    (click)="clickAction.emit({tipoClick: button.click, index: idx, value: element, data: button.data}); $event.stopPropagation()">
                                                <mat-icon>{{ button.nameIconButton }}</mat-icon>
                                            </button>

                                            <mat-icon *ngIf="button.isIcon && show(button, element, button?.valueShow)"
                                                      [color]="button.color"
                                                      [matTooltip]="button?.iconTooltip || button?.tooltip">{{ button.nameIconButton }}
                                            </mat-icon>
                                        </ng-container>

                                    </ng-container>
                                </div>

                            </div>

                        </ng-container>

                        <ng-container *ngSwitchCase="tipoColonna.ACTION_MENU">
                            <button style="box-shadow: unset !important;"
                                    *ngIf="!colonna?.show || colonna?.show(element[colonna.valueCheck || colonna.colonnaKey], element)"
                                    color="accent"
                                    mat-mini-fab
                                    (click)="$event.stopPropagation()"
                                    [matMenuTriggerFor]="action"
                            >
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu class="custom-mat-menu-action max-w-[unset]" [xPosition]="'after'"
                                      #action="matMenu">

                                <ng-container
                                    *ngFor="let button of (colonna | getButtonPipe: readOnly)">
                                    <ng-container *ngIf="!button.hide">
                                        <button *ngIf="show(button, element, button?.valueShow) && !button.isIcon"
                                                style="margin: 2px"
                                                mat-menu-item
                                                (click)="clickAction.emit({tipoClick: button.click, index: idx, value: element,  data: button.data}); closeMenus(); $event.stopPropagation()">
                                            <mat-icon>{{ button.nameIconButton }}</mat-icon>
                                            <span class="ml-2">{{ t(button.tooltip) || '' }}</span>
                                        </button>

                                        <mat-icon *ngIf="button.isIcon && show(button, element, button?.valueShow)"
                                                  [color]="button.color"
                                                  [matTooltip]="button?.iconTooltip || button?.tooltip">{{ button.nameIconButton }}
                                        </mat-icon>
                                    </ng-container>

                                </ng-container>

                            </mat-menu>
                        </ng-container>

                        <ng-container *ngSwitchCase="tipoColonna.CHIP_USER">
                            <ng-container
                                *ngIf="(element | valueForStatusPipe:colonna: colonna.colonnaKey) as chipList; else emptyTmp">

                                <mat-chip-list [style]="'width:93%'">
                                    <div class="chips-container w-full justify-center items-center my-2">
                                        <ng-container *ngIf="chipList?.length > 0; else emptyTmp">
                                            <ng-container *ngFor="let chipItem of chipList;">
                                                <mat-chip
                                                    [ngClass]="{
                                                        'pointer-events-none': (colonna.isChipDisabled || !chipItem.codiceFiscale) && !chipItem.highlightChip,
                                                        'cursor-pointer': !(colonna.isChipDisabled || !chipItem.codiceFiscale) || chipItem.highlightChip,
                                                        'bg-red-200 ': chipItem.highlightChip
                                                    }"
                                                    (click)="clickAction.emit({tipoClick: chipClickType, index: idx, value: chipItem, rowValue: element}); $event.stopPropagation()"
                                                    class="chip-width user-chip justify-between gap-1 m-0"
                                                    color="primary"
                                                    [matTooltip]="!!chipItem.highlightChip ? t(colonna.chipIconTooltip) : undefined">
                                                    <!--
                                                                                                    [matTooltip]="buildNomeCompleto(chipItem).toUpperCase()"
                                                    -->
                                                    <span
                                                        class="break-words">{{ chipItem | buildNomeCompletoPipe | uppercase }}</span>
                                                    <mat-icon *ngIf="!colonna.isChipDisabled && !!chipItem.codiceFiscale">
                                                        info
                                                    </mat-icon>
                                                    <mat-icon *ngIf="chipItem.highlightChip"
                                                              class="text-red-400">
                                                        person_add
                                                    </mat-icon>
                                                </mat-chip>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </mat-chip-list>

                            </ng-container>
                            <ng-template #emptyTmp>
                                <ng-container *ngIf="!hideDashForEmptyChipStatus">
                                    -
                                </ng-container>
                            </ng-template>
                        </ng-container>

                        <ng-container *ngSwitchCase="tipoColonna.CHIP_STATUS">
                            <ng-container *ngIf="!colonna?.show || colonna?.show(element[colonna.colonnaKey], element); else emptyTmp">
                                <ng-container
                                    *ngIf="(element | valueForStatusPipe:colonna: colonna.colonnaKey) as chipStatus; else emptyTmp">
                                    <mat-chip-list [style]="colonna.chipWidthAuto ? 'width: auto' : 'width:93%'">
                                        <div class="chips-container w-full justify-center items-center text-center">
                                            <mat-chip [class]="chipStatus | statusEnumToColorTheme: colonna.statusType "
                                                      [matTooltip]="!!colonna.tooltip ? t(colonna.tooltip(chipStatus, element)) : t(chipStatus | statusEnumToLanguagePath: colonna.statusType: element[colonna.sottoruoloKey])"
                                                      class="chip-width user-chip flex flex-row justify-center"
                                                      style="word-break: break-word;">
                                                <span>{{ t(chipStatus | statusEnumToLanguagePath: colonna.statusType : element[colonna.sottoruoloKey]) }} </span>
                                                <mat-icon *ngIf="colonna.showIconInChipStatus?.(element[colonna.colonnaKey]) as iconConfig"
                                                          class="icon-size-5 ml-2"
                                                          [class]="iconConfig.classes">
                                                    {{iconConfig.icon}}
                                                </mat-icon>
                                            </mat-chip>
                                        </div>
                                    </mat-chip-list>
                                </ng-container>
                            </ng-container>
                            <ng-template #emptyTmp>
                                <ng-container *ngIf="!hideDashForEmptyChipStatus">
                                    -
                                </ng-container>
                            </ng-template>
                        </ng-container>


                        <!----------- MARK: PARTE CON FORM CONTROL -------------->
                        <ng-container *ngSwitchCase="tipoColonna.CHECKBOX">
                            <ng-container
                                *ngIf="!colonna.showOnlyWhenSelected || checkIfSelected(element[selectedColumnKeyPrimaryKey])">
                                <ng-container *ngIf="getFormControlOfCheckboxColonna(element) as formCtrl">
                                    <mat-checkbox class="m-1" color="primary"
                                                  (click)="$event.stopPropagation()"
                                                  (change)="setCheckBoxValue(element, colonna?.colonnaKey)"
                                                  [checked]="!!element[colonna?.colonnaKey]"
                                                  [formControl]="formCtrl">
                                    </mat-checkbox>
                                </ng-container>
                            </ng-container>
                        </ng-container>

                    </ng-container>


                </mat-cell>
            </ng-container>


            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
                <td class="w-full h-full pr-0" mat-cell *matCellDef="let element"
                    [attr.colspan]="displayedColumns.length">
                    <div class="element-detail w-full "
                         [@detailExpand]="element?.[_configuration.expandRow.parentIdKey] === expandedElementId ? 'expanded' : 'collapsed'">
                        <div class="w-full h-full expandend-row"
                             *ngIf="element?.[_configuration?.expandRow?.parentIdKey] === expandedElementId">
                            <app-expanded-generic-table
                                [_parentId]="_configuration?.expandRow?.parentIdKey ? element?.[_configuration?.expandRow?.parentIdKey] : undefined"
                                [configuration]="{configuration: _configuration.expandRow, value: element[_configuration.expandRow.dataKey]}"
                                [righeSelezionate]="righeSelezionate"
                                [righeOriginali]="righeOriginali"
                                [idRigheSolaLettura]="idExpandedRigheSolaLettura"
                                [parentElement]="element"
                                (clickAction)="clickAction.emit($event)"
                                (toggleSelectedRowDataChild$)="toggleSelectedRowDataChild($event)"
                            >
                            </app-expanded-generic-table>
                        </div>
                    </div>
                </td>
            </ng-container>

            <!-- GROUP HEADER -->
            <ng-container matColumnDef="groupHeaderRow">
                <td class="w-full h-full pr-0" mat-cell *matCellDef="let element"
                    [attr.colspan]="displayedColumns.length">
                    <div class="element-detail w-full"
                         *ngIf="element[_configuration?.groups?.firstRowOfGroupKey] as groupElement">
                        <div
                            class="w-full h-full bg-slate-400 border-solid border-slate-400 border-[1.5px] text-center text-white flex flex-col items-center gap-1">
                            <ng-container
                                *ngTemplateOutlet="groupsHeaderTmpl; context: {$implicit: element}"></ng-container>
                        </div>
                    </div>
                </td>
            </ng-container>


            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: _configuration.sticky"
                            class="w-full"></mat-header-row>

            <ng-container *ngIf="true">
                <tr mat-row *matRowDef="let row; columns: ['groupHeaderRow']" class="group-header-row"
                    [ngStyle]="{'visibility' : !row?.[_configuration?.groups?.firstRowOfGroupKey] ? 'collapse' : 'visible'}">
                </tr>
            </ng-container>

            <mat-row *matRowDef="let row; columns: displayedColumns; let i = dataIndex;"
                     class="w-full"
                     [class]="_configuration?.highlightRowWhen?.(row)"
                     [ngClass]="{
                         'bg-slate-100': i % 2 == 0 && !_configuration.highlightRowWhen?.(row),
                         'expandend-parent-row': (expandedElementId && (expandedElementId === row?.[_configuration?.expandRow?.parentIdKey]))
                     }">
            </mat-row>

            <tr class="mat-row margin flex flex-row items-center justify-center" *matNoDataRow>
                <td class="mat-cell justify-content_center" [attr.colspan]=displayedColumns.length>
                    <br>{{ t(_configuration?.messaggioDatiAssenti) || messaggioDatiAssenti }}<br>
                </td>
            </tr>

            <ng-container *ngIf="_configuration?.expandRow?.expand">
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"
                    [ngStyle]="{'visibility' : row?.[_configuration?.expandRow?.parentIdKey] !== expandedElementId ? 'collapse' : 'visible'}">
                </tr>
            </ng-container>

        </mat-table>

        <div *ngIf="!_configuration?.hidePaginator"
             class="paginator flex flex-row items-end justify-end"
             [ngStyle]="!!_configuration?.cssMatPaginator ? _configuration?.cssMatPaginator : _configuration?.css">
            <mat-paginator [length]="totalElements"
                           [pageSize]="_configuration?.pageSize || 25"
                           [ngStyle]="_configuration?.css"
                           [pageSizeOptions]="_configuration?.pageSizeOptions || [25, 50, 100]" showFirstLastButtons
                           (page)="onPageChange($event)">
            </mat-paginator>
        </div>
    </div>
</ng-container>




