import {EventEmitter, Injectable, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {catchError, finalize, map, Observable, of, Subject, take, takeUntil} from 'rxjs';
import {
    AbstractDefaultComponent
} from "../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {
    AuthorityType,
    MediaService,
    MobilitaRequestSelectFormValuesDTO, PagePeriodoDiMobilitaStudenteInfoView,
    PeriodiDiMobilitaService, PeriodoDiMobilitaStudenteInfoView,
    PeriodoDiMobilitaStudenteStatus, StudenteCicloIdAndUtenteInfoView,
    TipoPeriodoEnum
} from "../../../../../api-clients/generated/services";
import {DEFAULT_PAGE_SIZE, TabsEnum} from "../requests-to-be-resolved.component";
import {
    ClickEvent,
    GenericTableConfigurationModel, TipoClickEnum
} from "../../../../shared/components/table/model/generic-table-model";
import {FuseConfirmationService} from "../../../../../@fuse/services/confirmation";
import {LocalStorageService} from "../../../../shared/service/local-storage.service";
import {AppInitService} from "../../../../shared/service/app-init.service";
import {
    AbstractFilterService,
    MobilitaFilters,
    MobilitaFilterService
} from "../mobilita-sidebar-filter-container/mobilita-filter.service";
import {TranslocoService} from "@ngneat/transloco";
import {LogoutService} from "../../../../shared/service/logout.service";
import {Router} from "@angular/router";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {MatDialog} from "@angular/material/dialog";
import {ExcelService} from "../../../../shared/service/excel.service";
import {filter, switchMap, tap} from "rxjs/operators";
import moment from "moment/moment";
import {
    buildMobilityRequestsTableConfiguration,
    mapPeriodiForExcel,
    PERIODI_EXCEL_SHEET_CONFIG
} from "../requests-to-be-resolved-utils";
import {PathEnum} from "../../../../app.routing";
import {PageEvent} from "@angular/material/paginator";
import {DialogInfoComponent, DialogInfoI} from "../../../../shared/components/dialog-info/dialog-info.component";
import {UpperCasePipe} from "@angular/common";
import {get, isEmpty} from "lodash";
import {getLanguagePathByMobilitaStatus, makeFilenameFromFE} from "../../../../shared/utils/utils";
import * as fs from "file-saver";
import {SnackbarTypes} from "../../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {GenericTableComponent} from "../../../../shared/components/table/generic-table/generic-table.component";

@Injectable()
export abstract class AbstractTableMobilityResearchComponent extends AbstractDefaultComponent implements OnInit, OnDestroy {

    @Input() currentRuolo: AuthorityType;
    @Output() toogleFilterContainer$ = new EventEmitter<{ hasToogleInternalMenu: boolean, tab: TabsEnum }>();
    @Input() selectedTabTipo: TabsEnum;
    protected table: GenericTableComponent;

    tableConfiguration: GenericTableConfigurationModel;

    showMiniLoader: boolean;
    loading: boolean;
    selectValues: MobilitaRequestSelectFormValuesDTO;
    mobilitaFieldsLabelMap: Map<string, string>;
    currentFilterListChipLabels: Array<string>;
    error: boolean;
    isFirstTime = true;

    protected constructor(protected fuseConfirmationService: FuseConfirmationService,
                          protected localStorageService: LocalStorageService,
                          protected appInitService: AppInitService,
                          protected abstractFilterService: AbstractFilterService,
                          protected translocoService: TranslocoService,
                          protected logoutService: LogoutService,
                          protected router: Router,
                          protected mediaService: MediaService,
                          protected sanitizer: DomSanitizer,
                          protected dialog: MatDialog,
                          protected excelService: ExcelService,
                          protected periodiDiMobilitaService: PeriodiDiMobilitaService) {
        super();
        this.mobilitaFieldsLabelMap = this.abstractFilterService.fieldsLabelMap;
    }

    ngOnInit(): void {
        this.abstractFilterService.checkApplyFilterClick$.asObservable().pipe(takeUntil(this.destroy$)).subscribe(value => {
            this.abstractFilterService.page = 0;
            if (value) {
                this.getMobilityPeriodsRequest(this.isFirstTime, this.abstractFilterService.filterApplied, this.abstractFilterService.page, this.abstractFilterService.pageSize,  true);
            } else {
                this.getMobilityPeriodsRequest(this.isFirstTime, this.abstractFilterService.filterApplied, 0, this.abstractFilterService.pageSize);
            }
        })
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    getMobilityPeriodsRequest(isFirstTime?: boolean, filters?: MobilitaFilters, page: number = 0, size: number = 10, valueForm?: boolean): void {
        this.error = false;

        if (isFirstTime) {
            this.loading = true;
        } else {
            this.fuseConfirmationService.showLoader();
        }
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(value => this.getMobilityPeriods$(isFirstTime, filters, page, size, valueForm))
        ).subscribe({
            next: (value) => {
                this.abstractFilterService.mainFormGroup?.patchValue(filters, {emitEvent: true});
                this.currentFilterListChipLabels = this.getListChipsLabel();
            },
            error: (err) => {
                console.log(err);
                this.error = true;
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => this.logoutService.goToHome(),
                    () => this.getMobilityPeriodsRequest(isFirstTime, filters, page, size, valueForm),
                    'common.go_to_home',
                    err?.error?.message);
            }
        });
    }


    getMobilityPeriods$(isFirstTime?: boolean, filters: MobilitaFilters = {}, page: number = 0, size: number = DEFAULT_PAGE_SIZE, valueForm?: boolean): Observable<PagePeriodoDiMobilitaStudenteInfoView> {
        if (isFirstTime) {
            this.loading = true;

        } else {
            this.fuseConfirmationService.showLoader();
        }

        if(!valueForm){
            filters.stato = PeriodoDiMobilitaStudenteStatus.APPROVATOPARZIALE;
        }

        return this.periodiDiMobilitaService.getPeriodiDiMobilitaStudenteForm(
            !!filters?.codiceMobilita ? filters.codiceMobilita : undefined,
            this.selectedTabTipo as TipoPeriodoEnum,
            !!filters?.nominativoStudente ? filters.nominativoStudente : undefined,
            undefined,
            undefined,
            filters.stato || undefined,
        !!filters?.statoSvolgimento ? filters?.statoSvolgimento : undefined,
            !!filters?.periodoDa ? filters.periodoDa : undefined,
            !!filters?.periodoA ? filters.periodoA : undefined,
            !!filters?.annoAccademico ? filters.annoAccademico : undefined,
            !!filters?.nominativoStrutturaEsteraOspitante ? filters.nominativoStrutturaEsteraOspitante : undefined,
            !!filters?.codiceNazioneStruttura ? filters.codiceNazioneStruttura : undefined,
            !!filters?.dataFineNull ? filters.dataFineNull : undefined,
            !!filters?.codiceTipologiaMobilita ? filters.codiceTipologiaMobilita : undefined,
            !!filters?.codiceTipologiaBonus ? filters.codiceTipologiaBonus : undefined,
            !!filters?.codiceTipoInterventoSupporto ? filters.codiceTipoInterventoSupporto : undefined,
            !!filters?.dataUltimaModifica ? moment(filters.dataUltimaModifica).format('DD/MM/YYYY HH:mm') as unknown as Date : undefined,
            undefined,
            undefined,
            page, size, undefined
        ).pipe(
            tap((pagePeriodi: PagePeriodoDiMobilitaStudenteInfoView) => {
                this.tableConfiguration = buildMobilityRequestsTableConfiguration(pagePeriodi, this.abstractFilterService.pageSize, page, this.selectedTabTipo === TabsEnum.RICERCA);
                this.abstractFilterService.filterApplied = filters;
                this.abstractFilterService.page = page;
                this.abstractFilterService.pageSize = size;
            }),
            takeUntil(this.destroy$),
            finalize(() => {
                if (isFirstTime) {
                    this.loading = false;
                    this.isFirstTime = false;
                } else {
                    this.fuseConfirmationService.hideLoader();
                }
            })
        );
    }


    getSottoruolo(): AuthorityType {
        return this.localStorageService.getSottoruoloCiclo();
    }

    tableClickAction($event: ClickEvent): void {
        switch ($event?.tipoClick) {
            case TipoClickEnum.CHIP_DETAIL:
                this.openInfoStudent($event.value);
                break;
            case TipoClickEnum.GO_TO_MOBILITA_STUDENTE:
                const periodo: PeriodoDiMobilitaStudenteInfoView = $event?.value;
                const periodType = periodo?.tipoPeriodoEnum === TipoPeriodoEnum.SOGGIORNOESTERO ? 'mobilita' : 'ricerca';
                this.router.navigate([PathEnum.STUDENTS, periodo?.studenteCiclo?.utente?.id, PathEnum.MOBILITA], {fragment: periodType});
                break;
        }
    }

    pageAction($event: PageEvent) {
        this.abstractFilterService.page = $event.pageIndex;
        this.abstractFilterService.pageSize = $event.pageSize;
        this.getMobilityPeriodsRequest(false, this.abstractFilterService.filterApplied, $event.pageIndex, $event.pageSize);
    }

    private openInfoStudent(studente: StudenteCicloIdAndUtenteInfoView) {
        if (!!studente?.utente?.urlImmagineProfilo && !!studente?.utente?.codiceFiscale) {
            this.fuseConfirmationService.showLoader();
            this.mediaService.getImmagineProfiloForm(studente?.utente?.urlImmagineProfilo, studente?.utente?.codiceFiscale)
                .pipe(
                    catchError(err => of(undefined)),
                    map((image) => {
                        if (image) {
                            const objectURL = URL.createObjectURL(image);
                            return this.sanitizer.bypassSecurityTrustUrl(objectURL);
                        } else {
                            return undefined;
                        }
                    }),
                    takeUntil(this.destroy$),
                    finalize(() => this.fuseConfirmationService.hideLoader())
                ).subscribe({
                next: (image: SafeUrl) => {
                    this.openStudenteInfoDialog(studente, image);
                },
                error: (error) => {
                    this.fuseConfirmationService.openErrorDialog({error: error},
                        this.translocoService,
                        () => {
                            this.logoutService.goToHome();
                        },
                        () => this.openInfoStudent(studente),
                        'dialog.close',
                        error?.error?.message);
                }
            });
        } else {
            this.openStudenteInfoDialog(studente);
        }
    }

    private openStudenteInfoDialog(studente: StudenteCicloIdAndUtenteInfoView, profileImage?: SafeUrl) {
        const data: DialogInfoI = {
            imageSafeUrl: profileImage,
            showImage: true,
            title: (studente?.utente?.cognome || '') + ' ' + (studente?.utente?.nome || ''),
            icon: {
                name: 'mat_outline:info',
                color: 'primary'
            },
            imageIniziali: (studente?.utente?.cognome?.charAt(0) || '') + (studente?.utente?.nome?.charAt(0) || ''),
            readonlyData: [
                {
                    key: 'user_list.surname',
                    value: studente?.utente?.cognome,
                    pipe: UpperCasePipe
                },
                {
                    key: 'user_list.name',
                    value: studente?.utente?.nome,
                    pipe: UpperCasePipe
                },
                {
                    key: 'sign-up.email',
                    value: studente?.emailAteneo?.toLowerCase()
                },
                { //telefono
                    key: 'sign-up.phone_number',
                    value: studente?.utente.telefono
                },
                {
                    key: 'sign-up.fiscal_code',
                    value: studente?.utente?.codiceFiscale
                },
                {
                    key: 'students_list.enrollment_number',
                    value: studente?.matricola
                },
            ]
        };
        this.dialog.open(DialogInfoComponent, {
            data,
            panelClass: 'dialog-responsive-full-screen',
        });
    }

    getListChipsLabel(): Array<string> {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        if (!isEmpty(this.abstractFilterService?.mainFormGroup?.getRawValue())) {
            const entries: [string, any][] = Object.entries(this.abstractFilterService.mainFormGroup?.value);
            const filteredEntries = entries?.filter(item => item.every(value => !!value));
            return filteredEntries.map(([key, value]) => {
                const translatedKey: string = get(translation, this.mobilitaFieldsLabelMap.get(key), key);
                if (key === 'codiceNazioneStruttura') {
                    value = this.abstractFilterService.selectValues?.nazioniEstere?.find(n => n.codice === value)?.descrizione;
                } else if (key === 'codiceTipologiaMobilita') {
                    value = this.abstractFilterService.selectValues?.tipologieMobilita?.find(n => n.codice === value)?.descrizione;
                } else if (key === 'codiceTipologiaBonus') {
                    value = this.abstractFilterService.selectValues?.tipologieBonus?.find(n => n.numeroTipologiaBonus === value)?.descrizioneTipologiaBonus;
                } else if (key === 'codiceTipoInterventoSupporto') {
                    value = this.abstractFilterService.selectValues?.tipiInterventoSupporto?.find(n => n.codice === value)?.descrizione;
                } else if (key === 'dataFineNull') {
                    return translatedKey;
                } else if (key === 'dataUltimaModifica') {
                    value = moment(value, 'YYYY-MM-DDTHH:mm').format('DD/MM/YYYY HH:mm');
                } else if(key === 'stato'){
                   value =  get(translation, getLanguagePathByMobilitaStatus(value) , value);
                }
                return translatedKey + ': ' + value;
            });
        } else {
            return [];
        }
    }

    getDataForFilters(): void {
        this.showMiniLoader = true;
        const http$ = this.abstractFilterService.selectValues ? of(this.abstractFilterService.selectValues) : this.periodiDiMobilitaService.getMobilitaRequestSelectFormValues()
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => http$),
            tap((selectValues: MobilitaRequestSelectFormValuesDTO) => {
                this.abstractFilterService.setSelectValues(selectValues);
                this.abstractFilterService.selectValues = selectValues;
            }),
            takeUntil(this.destroy$),
            finalize(() => this.showMiniLoader = false)
        ).subscribe({
            next: () => {
                this.toogleFilterContainer$.emit({tab: this.selectedTabTipo, hasToogleInternalMenu: true});
            },
            error: (err) => {

                this.abstractFilterService.setSelectValues(undefined);
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => {
                        this.logoutService.goToHome();
                    },
                    () => this.getDataForFilters(),
                    'auth.cancel',
                    err?.error?.message);
            }
        });
    }


    openExportExcelDialog() {
        const appliedFiltersFormValues = this.abstractFilterService.mainFormGroup.getRawValue();
        const appliedFiltersNumber = Object.values(appliedFiltersFormValues).filter(f => !!f).length;
        if (appliedFiltersNumber > 0) {
            const activeLang = this.translocoService.getActiveLang();
            const translation = this.translocoService.getTranslation().get(activeLang);
            this.fuseConfirmationService.open({
                    title: get(translation, 'mobility.export_excel_dialog', null),
                    message: get(translation, 'mobility.export_excel_dialog_message', null),
                    icon: {
                        name: 'mat_outline:info',
                        color: 'info'
                    },
                    onBackdrop: {
                        show: true,
                        backdrop: false
                    },
                    actions: [
                        {
                            color: 'accent',
                            label: get(translation, 'dialog.cancel', null),
                        },
                        {
                            color: 'primary',
                            label: get(translation, 'mobility.export_without_filters', null),
                            function: () => this.exportExcelRequest()
                        },
                        {
                            color: 'primary',
                            label: get(translation, 'mobility.export_with_filters', null),
                            function: () => this.exportExcelRequest(appliedFiltersFormValues)
                        }]
                }
            );
        } else {
            this.exportExcelRequest();
        }
    }

    private exportExcelRequest(filters?: MobilitaFilters) {
        this.fuseConfirmationService.showLoader();
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.periodiDiMobilitaService.getPeriodiDiMobilitaStudenteForm(
                !!filters?.codiceMobilita ? filters.codiceMobilita : undefined,
                this.selectedTabTipo as TipoPeriodoEnum,
                !!filters?.nominativoStudente ? filters.nominativoStudente : undefined,
                undefined,
                undefined,
                !!filters?.stato ? filters.stato : undefined,
                !!filters?.statoSvolgimento ? filters?.statoSvolgimento : undefined,
                !!filters?.periodoDa ? filters.periodoDa : undefined,
                !!filters?.periodoA ? filters.periodoA : undefined,
                !!filters?.annoAccademico ? filters.annoAccademico : undefined,
                !!filters?.nominativoStrutturaEsteraOspitante ? filters.nominativoStrutturaEsteraOspitante : undefined,
                !!filters?.codiceNazioneStruttura ? filters.codiceNazioneStruttura : undefined,
                !!filters?.dataFineNull ? filters.dataFineNull : undefined,
                !!filters?.codiceTipologiaMobilita ? filters.codiceTipologiaMobilita : undefined,
                !!filters?.codiceTipologiaBonus ? filters.codiceTipologiaBonus : undefined,
                !!filters?.codiceTipoInterventoSupporto ? filters.codiceTipoInterventoSupporto : undefined,
                !!filters?.dataUltimaModifica ? moment(filters.dataUltimaModifica).format('DD/MM/YYYY HH:mm') as unknown as Date : undefined,
            )),
            switchMap((periodi: PagePeriodoDiMobilitaStudenteInfoView) => {
                const currentBaseURL = location.origin;
                const excelConfig = [{
                    nameSheet: 'Periodi di ' + (this.selectedTabTipo === TipoPeriodoEnum.SOGGIORNOESTERO ? 'Mobilità' : 'Ricerca'),
                    data: mapPeriodiForExcel(periodi?.content || [], currentBaseURL, translation),
                    config: PERIODI_EXCEL_SHEET_CONFIG,
                }];
                return this.excelService.generateExcel(excelConfig);
            }),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (excelFile: Blob) => {
                const appliedFiltersNumber = Object.values(filters || []).filter(f => !!f).length;
                const tipoPeriodo = this.selectedTabTipo === TipoPeriodoEnum.SOGGIORNOESTERO ? 'MOBILITA' : 'RICERCA';
                const fileTypeForName = 'PERIODI_DI_' + tipoPeriodo + '_EXPORT_EXCEL' + (appliedFiltersNumber >= 1 ? '_FILTRATI' : '');
                const outputFileName = makeFilenameFromFE(this.localStorageService.getCicloCorsoRuolo(), '.xlsx', fileTypeForName);
                fs.saveAs(excelFile, outputFileName);
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'student.file_download_success', null),
                    type: SnackbarTypes.Success,
                });
            },
            error: (err) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'student.file_download_error', null),
                    error: err, type: SnackbarTypes.Error,
                });
                console.log(err);
            }
        });
    }

    restore(): void {
        this.abstractFilterService.restore();
    }
}
