/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Tipologie di membri nella Commissione Di Autovalutazione. Tipologie: *SUPERVISORE*, *COSUPERVISORE*, *RELATORE*
 */
export type TipoMembroCommissioneDiAutovalutazione = 'COLLEGIO_DOCENTI' | 'DOTTORANDO';

export const TipoMembroCommissioneDiAutovalutazione = {
    COLLEGIODOCENTI: 'COLLEGIO_DOCENTI' as TipoMembroCommissioneDiAutovalutazione,
    DOTTORANDO: 'DOTTORANDO' as TipoMembroCommissioneDiAutovalutazione
};