import {Pipe, PipeTransform} from '@angular/core';
import {EsitoOperazione} from "../../../api-clients/generated/services";

@Pipe({
    name: 'operationSucceedWithAllError'
})
export class OperationSucceedWithAllErrorPipe implements PipeTransform {

    constructor() { }

    transform(operationEsiti: { esito?: EsitoOperazione }[], args?: any): boolean {
        return operationEsiti?.every(e => e.esito === EsitoOperazione.ERROR);
    }
}
