import {FormArray, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {get, head, isEmpty, isEqual} from 'lodash';
import {formGroupConfigInterface, optionsKeyValueI} from '../generic-components.interface';
import {FormModuleService} from './form-module.service';
import {TypeDialogFormEnum} from '../generic-components-enum';
import {keyPressNumbers} from '../../../../shared/utils/utils';
import {distinctUntilChanged, startWith} from 'rxjs';
import * as moment from 'moment';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {CustomValidators} from '../../../../shared/validators/custom-validators';
import {SnackbarTypes} from "../../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {InputFileRejected} from "../../../../shared/input-file-with-preview/interfaces/input-file-rejected";
import {TranslocoService} from "@ngneat/transloco";
import {FuseConfirmationService} from "../../../../../@fuse/services/confirmation";
import {optionFile} from "../../../../shared/costants/app-constants";


@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, AfterViewInit, OnDestroy {


    @Input() formGroupConfig: formGroupConfigInterface[];
    @Input() validatorFns: ValidatorFn[];
    @Output() formValueChange = new EventEmitter<FormGroup>();
    @Input() touched = false;
    @Input() valueForm: any;
    @Input() readOnly: boolean;

    type = TypeDialogFormEnum;
    formGroup: FormGroup = new FormGroup({});
    outputFormatData = 'DD/MM/YYYY';
    private status: 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED';


    constructor(private formService: FormModuleService,
                private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService) {
    }

    ngOnDestroy(): void {
        this.formGroup.reset();
    }


    ngOnInit() {
        if (!isEmpty(this.formGroupConfig)) {
            this.formService.createFormGroup(this.formGroupConfig, this.formGroup, this.validatorFns, this.valueForm);
        }


        if (this.readOnly) {
            this.formGroup.disable();
        } else {
            this.formGroup.enable();
            this.disableFormField();
            this.formGroup.statusChanges.pipe(distinctUntilChanged()).subscribe((value) => {
                if (this.status === 'DISABLED') {
                    this.disableFormField();
                }
                this.status = value;
            });
        }

        this.valueChanges();
        console.log(this.formGroup, 'formGroup');

        if (this.valueForm) {
            this.formGroup.patchValue(this.valueForm);
        }

        this.formGroup.valueChanges.subscribe(() => this.formValueChange?.emit(this.formGroup));
    }

    private disableFormField(): void {
        this.formGroupConfig.forEach((value) => {
            if (value.disable) {
                this.getFormControl(value.name).disable();
            }
        });
    }

    getFormControl(name: string): FormControl {
        return this.formGroup.get(name) as FormControl;
    }

    keyPressNumbers(event) {
        return keyPressNumbers(event);
    }


    private valueChanges() {
        this.formGroupConfig.forEach((value) => {
            if (value.showIf) {
                this._valueChanges(value.showIf.referenceForm, value.name, value.showIf.referenceFormValue);
            }
            if (value.changeValue) {
                this._changesOption(value.name, value.changeValue.keyOptionChange, value.changeValue.values);
            }
            if (value.onSelectValueChange) {
                this._subscribeToOnSelectValueChange(value.name, value.onSelectValueChange, value.getOptionsKeyValue, value.showIf?.referenceForm);
            }
            if (value.onValueChange) {
                this._subscribeToOnValueChange(value.name, value.onValueChange, value.getOptionsKeyValue, value.showIf?.referenceForm);
            }
            if (value.requiredIf) {
                this._valueChangesForRequiredIf(value.requiredIf.referenceForm, value.name, value.requiredIf.referenceFormCheckValueFn);
            }
        });
    }

    _subscribeToOnSelectValueChange(control: string, callback: (string, FormGroup, optionsKeyValueI) => void,
                                    getOptionsKeyValue?: (value) => optionsKeyValueI[], referenceForm?: string) {
        this.formGroup.get(control).valueChanges
            .pipe(distinctUntilChanged())
            .subscribe((key) => {
                const selectedOption = getOptionsKeyValue?.(this.formGroup?.get(referenceForm)?.value)?.find(keyOpt => isEqual(keyOpt?.key, key));
                callback(key, this.formGroup, selectedOption);
            });
    }

    _subscribeToOnValueChange(control: string, callback: (string, FormGroup) => void,
                                    getOptionsKeyValue?: (value) => optionsKeyValueI[], referenceForm?: string) {
        this.formGroup.get(control).valueChanges
            .pipe(distinctUntilChanged())
            .subscribe((key) => {
                callback(key, this.formGroup);
            });
    }

    _valueChanges(control: string, mainControl: string, valueArray: any[]) {
        this.formGroup.get(control).valueChanges
            .pipe(distinctUntilChanged())
            .subscribe((value) => {
                console.log('value changes', value)
                if (valueArray.includes(value)) {
                    const conf = this.formGroupConfig.find(form => form.name === mainControl);
                    conf.show = true;
                    if(conf.getOptionsKeyValue){
                        this.formGroup.get(mainControl).setValue(null);
                        conf.optionsKeyValue = conf.getOptionsKeyValue(value);
                    }
                    if (this.formGroupConfig.find(form => form.name === mainControl).required) {
                        this.formGroup.get(mainControl).setValidators(Validators.required);
                    }
                    if (this.formGroupConfig.find(form => form.name === mainControl).isSpace) {
                        this.formGroup.get(mainControl).setValidators(CustomValidators.noSpace);
                    }
                    if (this.formGroupConfig.find(form => form.name === mainControl).regex?.pattern) {
                        this.formGroup.get(mainControl).setValidators(Validators.pattern(this.formGroupConfig.find(form => form.name === mainControl).regex?.pattern));
                    }
                    this.formGroup.get(mainControl).updateValueAndValidity();
                } else {
                    this.formGroup.get(mainControl).setValue(null);
                    const conf = this.formGroupConfig.find(form => form.name === mainControl);
                    conf.show = false;
                    if(conf.getOptionsKeyValue){
                        conf.optionsKeyValue = [];
                    }
                    this.formGroup.get(mainControl).setErrors(null);
                    this.formGroup.get(mainControl).setValidators([]);
                }
            });
    }

    _valueChangesForRequiredIf(control: string, mainControl: string, checkFn: (value: any) => boolean) {
        this.formGroup.get(control).valueChanges
            .pipe(
                startWith(this.formGroup.get(control).value),
                distinctUntilChanged()
            )
            .subscribe((value) => {
                console.log('_valueChangesForRequiredIf', value)
                if (checkFn(value)) {
                    this.formGroup.get(mainControl).setValidators(Validators.required);
                    this.formGroup.get(mainControl).updateValueAndValidity();
                } else {
                    this.formGroup.get(mainControl).setErrors(null);
                    this.formGroup.get(mainControl).setValidators([]);
                }
            });
    }


    dateValue(nameControl: string) {
        return this.getFormControl(nameControl).value ? moment(this.getFormControl(nameControl).value, this.outputFormatData).toDate() : null;
    }

    event(type: string, event: MatDatepickerInputEvent<Date>, nameControl: string) {
        this.getFormControl(nameControl).setValue(moment(event.value).format(this.outputFormatData));
        this.getFormControl(nameControl).setErrors(null);
        this.getFormControl(nameControl).setValidators([]);
        if (this.formGroupConfig?.find(conf => conf?.name === nameControl)?.required) {
            this.getFormControl(nameControl).addValidators(Validators.required);
        }

    }

    ngAfterViewInit(): void {
        if (this.valueForm) {
            this.formGroupConfig.forEach((v) => {
                if (v.type === TypeDialogFormEnum.DATA) {
                    this.getFormControl(v.name).setErrors(null);
                    this.getFormControl(v.name).setValidators([]);
                    if (v.required) {
                        this.getFormControl(v.name).addValidators(Validators.required);
                        this.getFormControl(v.name).updateValueAndValidity();
                    }
                } else if (v.type === TypeDialogFormEnum.DATAORA) {
                    if (v.required) {
                        this.getFormControl(v.name).addValidators(Validators.required);
                        this.getFormControl(v.name).updateValueAndValidity();
                    }
                }

            });
        }
    }

    private _changesOption(control: string, keyOptionChange: string, values: {
        key: string;
        value: string[];
    }[]) {
        this.formGroup.get(control).valueChanges
            .pipe(distinctUntilChanged())
            .subscribe((value) => {
                const index = values.findIndex(v => v.key === value);
                if (index > -1) {
                    const indexConf = this.formGroupConfig.findIndex(conf => conf.name === keyOptionChange);
                    this.formGroupConfig[indexConf].options = values[index].value;
                    if (!this.formGroupConfig[indexConf]?.options?.includes(this.formGroup.get(keyOptionChange).value)) {
                        this.formGroup.get(keyOptionChange).setValue(null);
                    }
                } else {
                    const indexConf = this.formGroupConfig.findIndex(conf => conf.name === keyOptionChange);
                    this.formGroupConfig[indexConf].options = [];
                    this.formGroup.get(keyOptionChange).setValue(null);
                }
            });
    }


    isValid() {
        return this.formGroup.valid;
    }

    fileRejected($event: InputFileRejected) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        const message = get(translation, 'upload.not_supported_file', null);
        this.fuseConfirmationService.openSnackBar({
            message: message,
            type: SnackbarTypes.Error
        })
    }

    getUploadedFile(file: File, nameControl: string) {
        //const blob = new Blob([event], {type: event.type});
        this.getFormControl(nameControl).setValue(file);
    }

    protected readonly isEqual = isEqual;
    protected readonly optionFile = optionFile;
    protected readonly FormArray = FormArray;
}



