<ng-container *transloco="let t">

    <app-loading-screen *ngIf="loading; else studentDeatilsTemplate" class="w-full h-full">

    </app-loading-screen>

    <ng-template #studentDeatilsTemplate>
        <div class="flex flex-col flex-auto bg-card shadow overflow-hidden w-full">

            <mat-drawer-container class="flex-auto" >

                <!-- Drawer -->
                <mat-drawer #drawer
                            class="w-72 dark:bg-gray-900"
                            [mode]="drawerMode"
                            [opened]="drawerOpened"
                            [autoFocus]="false">
                    <app-sidebar-internal-menu *ngIf="showSidebar"
                                               class="sidebar-internal-menu"
                        (isScreenSmallEmitter)="isSmallScreen($event)"
                        (internalHeaderTitle)="setTitle($event)"
                        (setShowRouterOutlet)="setShowRouterOutlet($event)"
                                               [ctrlShowRouterOutlet]="ctrlShowRouterOutlet">
                    </app-sidebar-internal-menu>

                </mat-drawer>

                <!-- Drawer content -->
                <mat-drawer-content class="flex flex-col overflow-hidden">
                    <app-header [title]="internalHeaderTitle"
                                [subtitle]="planUpdateStatusIfInPlanPage()"
                                [isInternalMenuHeader]="true"
                                (toogleInternalMenuEmitter)="toogleInternalMenu($event)"
                                [dataChip]="planStatusChip"
                                [dataChipDialogList]="planInvalidReasons"
                                [showChip]="showPlanStatusChipChip(internalHeaderTitle)">
                        <div class="hidden md:flex flex-row gap-5 justify-center items-center sm:justify-end mt-4 sm:mt-0">
                            <mat-chip
                                class="bg-gray-200 text-gray-600 justify-between flex flex-row gap-2 cursor-pointer"
                                (click)="openPermessiApprovazioneInfoDialog()"
                                *ngIf="currentSottoruolo === AuthorityType.COSUPERVISORE">
                                    <span
                                        class="line-clamp-1">{{t('student.involved_into_approval_flows')}}: {{t(approvalPermission ? 'common.yes' : 'common.no')}}
                                    </span>
                                    <mat-icon>
                                        info
                                    </mat-icon>
                            </mat-chip>
                            <app-download-user-info *ngIf="showDownloadInfoButton() && !loading"
                                                    [disableDownloadButton]="showAndDisableDownloadInfoButtonForStudyPlan()"
                                                    [customTooltipWhenButtonDisabled]="t('student.no_approved_plan')"
                                                    (onDownloadPressed)="downloadInfoRequest()">
                            </app-download-user-info>
                            <!-- SELECT SELECT SOTTORUOLO ON DESKTOP -->
              <!--              <ng-container *ngIf="showSelect">
                                <mat-form-field style="width:100%" class="hidden sm:block">
                                    <mat-label>{{t('students_list.subrole')}}</mat-label>
                                    <mat-select [formControl]="ctrlSelectSottoruoloFormControl">
                                        <mat-option *ngFor="let sottoruolo of sottoruoli" [value]="sottoruolo">
                                            {{sottoruolo | removeUnderscores}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                    <mat-chip-list *ngIf="ctrlSelectSottoruoloFormControl.value">
                        <div class="flex flex-col w-full items-center text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600">
                            <mat-chip
                                      class="justify-between">
                                    <span
                                        class="line-clamp-1">{{ctrlSelectSottoruoloFormControl.value | removeUnderscores}}</span>
                            </mat-chip>
                        </div>
                    </mat-chip-list>
                            </ng-container>-->
                        </div>
                    </app-header>

                    <!-- SELECT SELECT SOTTORUOLO ON DESKTOP -->
  <!--                  <ng-container *ngIf="showSelect">
                        <mat-form-field style="width:100%" class="block sm:hidden py-1">
                            <mat-label>{{t('students_list.subrole')}}</mat-label>
                            <mat-select [formControl]="ctrlSelectSottoruoloFormControl">
                                <mat-option *ngFor="let sottoruolo of sottoruoli" [value]="sottoruolo">
                                    {{sottoruolo | removeUnderscores}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>-->
                    <div class="flex md:hidden w-full flex-col items-center">
                        <div *ngIf="currentSottoruolo"
                             class="flex flex-col w-full items-center p-2 gap-2">
                            <mat-chip
                                class="bg-gray-200 text-gray-600 justify-between flex flex-row gap-2 cursor-pointer"
                                (click)="openPermessiApprovazioneInfoDialog()"
                                *ngIf="currentSottoruolo === AuthorityType.COSUPERVISORE">
                                    <span
                                        class="line-clamp-1">{{t('student.involved_into_approval_flows')}}: {{t(approvalPermission ? 'common.yes' : 'common.no')}}
                                    </span>
                                <mat-icon>
                                    info
                                </mat-icon>
                            </mat-chip>
                        </div>
                        <app-download-user-info class="w-full"
                                                *ngIf="showDownloadInfoButton() && !loading"
                                                [disableDownloadButton]="showAndDisableDownloadInfoButtonForStudyPlan()"
                                                [customTooltipWhenButtonDisabled]="t('student.no_approved_plan')"
                                                (onDownloadPressed)="downloadInfoRequest()">
                        </app-download-user-info>
                    </div>
                    <!-- Main -->
                    <div class="flex flex-auto w-full" *ngIf="showRouterOutlet">
                        <router-outlet></router-outlet>
                    </div>


                </mat-drawer-content>

            </mat-drawer-container>

        </div>
    </ng-template>

</ng-container>

