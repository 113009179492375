import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogWrapI} from '../../../layout/common/dialog-wrap/dialog-wrap.component';
import {EsitoClonazioneGruppo} from "../../../../api-clients/generated/services";


export interface DialogCloneResultsI extends DialogWrapI {
    results: EsitoClonazioneGruppo[];
}

@Component({
    selector: 'app-dialog-clone-results',
    templateUrl: './dialog-clone-results.component.html',
    styleUrls: ['./dialog-clone-results.component.scss']
})
export class DialogCloneResultsComponent implements OnInit {


    clonedGroupTrackByFn(index: number, item: EsitoClonazioneGruppo): any
    {
        return item.tipo;
    }



    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogCloneResultsI,
                public dialogRef: MatDialogRef<DialogCloneResultsComponent>) {

    }

    ngOnInit(): void {

    }

    closeDialog() {
        this.dialogRef.close();
    }



}
