/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Stati del superamento di anno studente. Valori previsti: *NON_AVVIATO*, *IN_ATTESA_DOCUMENTI_DOTTORANDO*, *DOCUMENTI_DOTTORANDO_SOTTOMESSI*, *DOCUMENTI_DOTTORANDO_RIFIUTATI*, *DOCUMENTI_SUPERVISORE_SOTTOMESSI*, *DOCUMENTI_SUPERVISORE_RIFIUTATI*, *IN_ATTESA_ATTESTAZIONE_SUPERAMENTO*, *IN_ATTESA_CORREZIONE_CFU_ORE_DOTTORANDO*, *IN_ATTESA_VERIFICA_CFU_ORE_SUPERVISORE*, *IN_ATTESA_VERIFICA_CFU_ORE_COORDINATORE*, *COMPLETATO*
 */
export type SuperamentoAnnoStudenteStatus = 'NON_AVVIATO' | 'IN_ATTESA_DOCUMENTI_DOTTORANDO' | 'DOCUMENTI_DOTTORANDO_SOTTOMESSI' | 'DOCUMENTI_DOTTORANDO_RIFIUTATI' | 'DOCUMENTI_SUPERVISORE_SOTTOMESSI' | 'DOCUMENTI_SUPERVISORE_RIFIUTATI' | 'IN_ATTESA_ATTESTAZIONE_SUPERAMENTO' | 'IN_ATTESA_CORREZIONE_CFU_ORE_DOTTORANDO' | 'IN_ATTESA_VERIFICA_CFU_ORE_SUPERVISORE' | 'IN_ATTESA_VERIFICA_CFU_ORE_COORDINATORE' | 'COMPLETATO';

export const SuperamentoAnnoStudenteStatus = {
    NONAVVIATO: 'NON_AVVIATO' as SuperamentoAnnoStudenteStatus,
    INATTESADOCUMENTIDOTTORANDO: 'IN_ATTESA_DOCUMENTI_DOTTORANDO' as SuperamentoAnnoStudenteStatus,
    DOCUMENTIDOTTORANDOSOTTOMESSI: 'DOCUMENTI_DOTTORANDO_SOTTOMESSI' as SuperamentoAnnoStudenteStatus,
    DOCUMENTIDOTTORANDORIFIUTATI: 'DOCUMENTI_DOTTORANDO_RIFIUTATI' as SuperamentoAnnoStudenteStatus,
    DOCUMENTISUPERVISORESOTTOMESSI: 'DOCUMENTI_SUPERVISORE_SOTTOMESSI' as SuperamentoAnnoStudenteStatus,
    DOCUMENTISUPERVISORERIFIUTATI: 'DOCUMENTI_SUPERVISORE_RIFIUTATI' as SuperamentoAnnoStudenteStatus,
    INATTESAATTESTAZIONESUPERAMENTO: 'IN_ATTESA_ATTESTAZIONE_SUPERAMENTO' as SuperamentoAnnoStudenteStatus,
    INATTESACORREZIONECFUOREDOTTORANDO: 'IN_ATTESA_CORREZIONE_CFU_ORE_DOTTORANDO' as SuperamentoAnnoStudenteStatus,
    INATTESAVERIFICACFUORESUPERVISORE: 'IN_ATTESA_VERIFICA_CFU_ORE_SUPERVISORE' as SuperamentoAnnoStudenteStatus,
    INATTESAVERIFICACFUORECOORDINATORE: 'IN_ATTESA_VERIFICA_CFU_ORE_COORDINATORE' as SuperamentoAnnoStudenteStatus,
    COMPLETATO: 'COMPLETATO' as SuperamentoAnnoStudenteStatus
};