<ng-container *transloco="let t">
    <div class="flex flex-col flex-auto w-full pb-2.5 pl-2.5 pr-2.5" *ngIf="!!getMainFormGroup()"
         #mainContainer>
        <!-- FORMS -->
        <form [formGroup]="auditFilterManagerService.mainFormGroup" (ngSubmit)="applyFilters()" (keydown.tab)="$event.stopPropagation()">

            <!-- TITLE -->
            <div style="background-color: #ffffff; z-index: 1000;" #titleDiv
                 class="flex flex-col sm:flex-row gap-3 sm:justify-between pr-2.5"
                 [style]="{'width': (mainContainer?.offsetWidth-10)+'px !important'}">
                <div class="mt-4 text-2xl font-medium ml-2">
                    <strong>{{t('menu_filter.advanced_search')}}</strong>
                </div>

                <div class="pt-2 pb-2 grid grid-cols-1 sm:grid-cols-2 gap-3">

                    <!-- BUTTONS-->
                    <button mat-flat-button
                            (click)="reset()"
                            type="button"
                            [color]="'accent'">
                        <mat-icon class="mr-2">delete</mat-icon>
                        <span>{{t('menu_filter.reset')}}</span>
                    </button>

                    <button mat-flat-button
                            type="submit"
                            [color]="'primary'">
                        <mat-icon class="mr-2">search</mat-icon>
                        <span>{{t('home.apply')}}</span>
                    </button>
                </div>
                <mat-divider class="sm:hidden"></mat-divider>
            </div>

            <div style="overflow: auto">
               <div class="grid grid-cols-1 grid-rows-none sm:grid-cols-2 gap-3">
                    <ng-container *ngFor="let field of currentFields">

                        <mat-form-field class="w-full" appearance="outline" *ngIf="field !== _codiceRisposta">
                            <mat-label>{{t(fieldsLabelMap.get(field))}}</mat-label>
                            <input matInput
                                   [formControl]="getFormControlByPath(field)"
                                   (blur)="getFormControlByPath(field).setValue(getFormControlByPath(field)?.value?.trim())">
                        </mat-form-field>

                        <div class="w-full flex flex-row gap-1" *ngIf="field === _codiceRisposta">
                            <mat-button-toggle-group [disabled]="!getFormControlByPath(field)?.value"
                                                     [formControl]="getFormControlByPath(field+'NonMode')"
                                                     class="h-[56px] mt-[3px] border-[#CBD5E1] border-solid border-[1.5px] rounded-[6px] p-1.5 w-[180px]">
                                <mat-button-toggle class="w-1/2 rounded-[5px] font-medium text-secondary px-1 text-sm"
                                                   [value]="false">
                                    {{t('menu_filter.is') | titlecase}}
                                </mat-button-toggle>
                                <mat-button-toggle class="w-1/2 rounded-[5px] font-medium text-secondary px-1 text-sm"
                                                   [value]="true">
                                    {{t('menu_filter.not_is') | titlecase}}
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                            <mat-form-field appearance="outline" class="w-full">
                                <mat-label>{{ t(fieldsLabelMap.get(field)) }}</mat-label>
                                <mat-select [formControl]="getFormControlByPath(field)">
                                    <mat-option [value]="null">{{t('common.all')}}</mat-option>
                                    <mat-option *ngFor="let codiceRisposta of Object.keys(CodiceRispostaEnum)"
                                                [value]="CodiceRispostaEnum[codiceRisposta]">
                                        {{t('audit.code') | uppercase}} {{ codiceRisposta | removeUnderscores }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>


                    </ng-container>
                </div>

            </div>

        </form>
    </div>
</ng-container>
