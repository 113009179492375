/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Ticket Priority. Valori previsti: *low*, *medium*, *high*, *urgent*
 */
export type TicketPriority = 'low' | 'medium' | 'high' | 'urgent';

export const TicketPriority = {
    Low: 'low' as TicketPriority,
    Medium: 'medium' as TicketPriority,
    High: 'high' as TicketPriority,
    Urgent: 'urgent' as TicketPriority
};