
<ng-container *transloco="let t">

    <div class="h-full w-full" fxLayout="column">
        <mat-drawer-container class="flex-auto h-full" style="overflow: hidden">
            <!-- Drawer -->
            <mat-drawer #drawer style="overflow: hidden"
                        class="w-72 sm:w-180 dark:bg-gray-900 my-mat-drawer"
                        mode='over'
                        [opened]="drawerOpened"
                        [autoFocus]="false">

                <app-mobilita-sidebar-filter-container #mobilitaSidebarContainer
                                                       *ngIf="selectedTabTipo === TipoMobilitaDashboardTab.SOGGIORNOESTERO"
                                                       style="overflow: hidden"
                                                       (closeDrawer)="closeDrawer()">
                </app-mobilita-sidebar-filter-container>

                <app-ricerca-sidebar-filter-container #ricercaSidebarContainer
                                                       *ngIf="selectedTabTipo === TipoMobilitaDashboardTab.RICERCA"
                                                       style="overflow: hidden"
                                                       (closeDrawer)="closeDrawer()">
                </app-ricerca-sidebar-filter-container>
                <app-delibere-sidebar-filter-container #delibereSidebarContainer
                                                      *ngIf="selectedTabTipo === TipoMobilitaDashboardTab.DELIBERE"
                                                      style="overflow: hidden"
                                                      (closeDrawer)="closeDrawer()">
                </app-delibere-sidebar-filter-container>
                <app-autorizzazioni-sidebar-filter-container #autorizzazioniSidebarContainer
                                                      *ngIf="selectedTabTipo === TipoMobilitaDashboardTab.AUTORIZZAZIONI"
                                                      style="overflow: hidden"
                                                      (closeDrawer)="closeDrawer()">
                </app-autorizzazioni-sidebar-filter-container>


            </mat-drawer>

            <!-- Drawer content -->
            <mat-drawer-content class="flex flex-col overflow-hidden">


                <div class="h-full w-full" fxLayout="column">
                    <!--HEADER-->
                    <app-header [title]="'sidebar.mobility'">

                        <!-- SELECT SOTTORUOLO -->
                        <ng-container *ngIf="!loading && mustChooseSubrole">
                            <div class="flex flex-col xl:flex-row gap-3 xl:gap-5 xl:items-center md:items-end items-center justify-center md:justify-end">
                                <fuse-alert *ngIf="ctrlSelectFormControl.value && sottoruoli.length > 1"
                                            class="w-full"
                                            [showIcon]="true"
                                            [appearance]="'outline'"
                                >{{t('students_list.modify_subrole')}}</fuse-alert>
                                <mat-form-field style="width:50%; min-width: 300px; max-width: 500px" appearance="outline" class="mat-form-field-select-sottoruolo"
                                                [ngClass]="{'mat-form-field-border-red': mustChooseSubrole && !isSubroleSelected}">
                                    <mat-label>{{t('students_list.subrole')}}</mat-label>
                                    <mat-select [formControl]="ctrlSelectFormControl">
                                        <mat-option *ngFor="let sottoruolo of sottoruoli" [value]="sottoruolo">
                                            {{sottoruolo | removeUnderscores}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </ng-container>

                    </app-header>

                    <app-loading-screen *ngIf="loading; else mobilityTemplate" class="w-full h-full"></app-loading-screen>
                    <ng-template #mobilityTemplate>

                        <div class="w-full h-full bg-card shadow px-2 py-6"
                             *ngIf="!mustChooseSubrole || (isSubroleSelected && currentSubroleCanAccess)">

                            <mat-tab-group appScrollRetainer
                                           (selectedIndexChange)="onTabChanged($event)">

                                <mat-tab *ngFor="let periodType of [mobilitaTabData, ricercaTabData, delibereTabData, autorizzazioniTabData]"
                                         [label]="t(periodType?.tabLabel) | titlecase"
                                         [aria-label]="periodType?.tabAriaLabel"
                                         class="overflow-x-hidden">

                                    <ng-template matTabContent>

                                        <div class="flex flex-col w-full h-full overflow-auto px-2 gap-0">

                                            <div class="flex flex-col md:flex-row gap-4 justify-end w-full items-center"
                                                 *ngIf="!!periodType.tableConfiguration">

                                                <button mat-flat-button
                                                        class="max-w-60"
                                                        color="primary"
                                                        [disabled]="showMiniLoader"
                                                        (click)="getDataForFilters()">
                                                    <mat-spinner *ngIf="showMiniLoader" [diameter]="20" color="accent" class="mr-2"></mat-spinner>
                                                    <mat-icon class="mr-2" *ngIf="!showMiniLoader">manage_search</mat-icon>
                                                    {{t('menu_filter.advanced_search')}}
                                                </button>

                                                <button mat-flat-button
                                                        class="multi-line-button"
                                                        type="submit"
                                                        *ngIf="periodType.tipologiaPeriodi !== TipoMobilitaDashboardTab.DELIBERE && periodType.tipologiaPeriodi !== TipoMobilitaDashboardTab.AUTORIZZAZIONI"
                                                        (click)="openExportExcelDialog()"
                                                        [color]="'primary'">
                                                    <mat-icon class="mr-2">download</mat-icon>
                                                    <span>{{t('mobility.export_excel_dialog')}}</span>
                                                </button>
                                                <!--<button mat-flat-button
                                                        class="max-w-60"
                                                        type="submit"
                                                        (click)="openExportPDFDialog()"
                                                        [color]="'primary'">
                                                    <mat-icon class="mr-2">download</mat-icon>
                                                    <span>{{t('mobility.export_pdf')}}</span>
                                                </button>-->
                                            </div>

                                            <div class="flex flex-col flex-auto w-full h-full overflow-auto p-2 gap-4"
                                                 *ngIf="!!periodType.tableConfiguration">

                                                <div class="flex flex-row justify-between w-full">

                                                    <div class="mt-0.5 px-2 py-4 flex flex-row gap-4"
                                                         *ngIf="currentFilterListChipLabels.get(periodType.tipologiaPeriodi)?.length > 0">
                                                        <!-- TESTO FILTRI APPLICATI -->
                                                        <div class=" text-2xl font-semibold mt-[0.35rem]">{{t('students_list.applied_filters')}}</div>
                                                        <!--RESET BUTTON -->
                                                        <button mat-flat-button
                                                                (click)="reset(periodType.tipologiaPeriodi)"
                                                                [color]="'accent'">
                                                            <mat-icon class="mr-2">delete</mat-icon>
                                                            <span>{{t('menu_filter.reset')}}</span>
                                                        </button>
                                                    </div>


                                                </div>

                                                <div class="w-full flex flex-row justify-center items-center" *ngIf="currentFilterListChipLabels.get(periodType.tipologiaPeriodi)?.length > 0">
                                                    <!-- CHIPS -->
                                                    <div class="w-full flex items-center">

                                                        <div class="flex flex-col gap-1.5">

                                                            <mat-chip-list class="w-full">
                                                                <div class="w-full flex flex-row gap-1.5 p-2 flex-wrap">
                                                                    <ng-container *ngFor="let chip of currentFilterListChipLabels.get(periodType.tipologiaPeriodi)">
                                                                        <mat-chip color="primary" class="justify-between">
                                                                            {{chip | titlecase | removeUnderscores}}
                                                                        </mat-chip>
                                                                    </ng-container>
                                                                </div>
                                                            </mat-chip-list>
                                                        </div>
                                                    </div>


                                                </div>

                                                <div class="w-full p-y-2.5" *ngIf="periodType.tableConfiguration">
                                                    <app-generic-table *ngIf="!!periodType.tableConfiguration"
                                                                       [configuration]="periodType.tableConfiguration"
                                                                       (clickAction)="tableClickAction($event)"
                                                                       (pageAction)="pageAction($event)">
                                                    </app-generic-table>
                                                </div>
                                            </div>

                                        </div>

                                    </ng-template>

                                </mat-tab>

                            </mat-tab-group>

                        </div>

                        <div *ngIf="mustChooseSubrole && !isSubroleSelected" class="mt-4">
                            <fuse-alert class="w-full"
                                        [showIcon]="true"
                                        [appearance]="'outline'"
                            >{{t('common.select_subr')}}</fuse-alert>
                        </div>
                        <div *ngIf="mustChooseSubrole && isSubroleSelected && !currentSubroleCanAccess" class="mt-4">
                            <fuse-alert class="w-full"
                                        [showIcon]="true"
                                        type="warning"
                                        [appearance]="'outline'"
                            >{{t('mobility.subrole_not_admitted')}}</fuse-alert>
                        </div>
                    </ng-template>

                </div>

            </mat-drawer-content>
        </mat-drawer-container>

    </div>

</ng-container>


