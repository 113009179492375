<ng-container *transloco="let t">


    <div class="flex flex-col w-full min-h-[20rem] items-center justify-center"
         *ngIf="loading; else stepTemplate">
        <app-loading-screen class="w-full h-full">
        </app-loading-screen>
    </div>


    <ng-template #stepTemplate>

        <div class="mt-4">
            <fuse-alert class="w-full"
                        [showIcon]="true"
                        [appearance]="'outline'"
            >{{t('massive_operations.approvazione_validazione_mobilita.hint_compile_info')}}</fuse-alert>
        </div>

        <div class="w-full flex flex-col items-center">

            <div class="flex flex-col gap-4 mt-6 w-full mb-2" *ngIf="periodiFormArray">
                <ng-container *ngFor="let period of richiesteSelected; let periodIndex = index" class="w-full">

                    <div class="flex flex-col gap-3 bg-gray-100 rounded-xl px-4 pb-4 pt-2">
                        <div class="font-semibold text-lg mt-3">
                            {{period?.periodoMobilita?.codiceMobilita}}
                            ({{period?.periodoMobilita?.studenteCiclo?.utente?.cognome + ' ' + period?.periodoMobilita?.studenteCiclo?.utente?.nome}})</div>
                        <div class="w-full" *ngIf="periodiFormArray.at(periodIndex) as periodoFormGroup">

                            <!-- ALERT GIORNI DISPONIBILI DI MOBILITA -->
                            <!--<div class="w-full flex flex-row mb-6" *ngIf="richiesteSelected[periodIndex]?.periodoMobilita?.studenteCiclo as studentInfo">
                                <fuse-alert
                                    class="w-full"
                                    [appearance]="'outline'"
                                    [type]="(studentInfo.giorniMobilitaDottorandoRetribuiti || 0) >= studentInfo.giorniMobilitaDottorandoRetribuibili  ? 'warn' : 'info'"
                                    [showIcon]="true">
                                    <div class="w-full flex flex-col gap-4">
                                        <span>
                                            {{t('mobility.validated_days')}}: {{studentInfo.giorniMobilitaDottorandoRetribuiti || 0}}
                                            / {{t('mobility.available_days')}}: {{studentInfo.giorniMobilitaDottorandoRetribuibili}}
                                        </span>
                                                            <span class="font-semibold"
                                                                  *ngIf="(studentInfo.giorniMobilitaDottorandoRetribuiti || 0) >= studentInfo.giorniMobilitaDottorandoRetribuibili">
                                            {{t('mobility.reached_available_days')}}
                                        </span>
                                    </div>
                                </fuse-alert>
                            </div>-->

                            <!-- ALERT PREVISIONE MAGGIORAZIONE -->
                            <div class="w-full flex flex-row mb-6"
                                 *ngIf="loadingAnteprimaRetribuzione.get(periodIndex) || erroreAnteprimaRetribuzione.get(periodIndex) || anteprimaRetribuzione.get(periodIndex)">
                                <fuse-alert
                                    class="w-full retribution-preview-alert"
                                    [appearance]="'outline'"
                                    [type]="'basic'"
                                    [showIcon]="true">
                                    <div class="flex flex-col">
                                        <div class="flex flex-row gap-2 w-full items-center">
                                            <div
                                                class="font-semibold text-lg">{{t('mobility.retribution_preview')}}</div>
                                            <mat-spinner *ngIf="loadingAnteprimaRetribuzione.get(periodIndex)"
                                                         [diameter]="20"
                                                         [mode]="'indeterminate'"
                                                         [strokeWidth]="3"></mat-spinner>
                                        </div>
                                        <div *ngIf="erroreAnteprimaRetribuzione.get(periodIndex)"
                                             class="italic mt-2">{{t('mobility.retribution_preview_not_available')}}</div>
                                        <app-info-maggiorazione *ngIf="anteprimaRetribuzione"
                                                                [dettaglioImporto]="anteprimaRetribuzione.get(periodIndex)"
                                                                [anteprimaMode]="true">
                                        </app-info-maggiorazione>
                                    </div>

                                </fuse-alert>

                            </div>

                            <!--PERIODI-->
                            <form class="items-center flex flex-col gap-4" *ngIf="periodoFormGroup" [formGroup]="periodoFormGroup">

                                <div class="w-full flex flex-col gap-2 items-center"
                                     formArrayName='sottoperiodi'>

                                    <div class="w-full flex flex-col lg:flex-row gap-2 items-center bg-gray-100 rounded-xl px-3 pt-3 pb-2"
                                         *ngFor="let validazioneFormGroup of validazioniFormArray(periodoFormGroup)?.controls; let validationIndex = index">

                                        <div class="w-full flex flex-col lg:flex-row gap-2" [formGroup]="validazioneFormGroup">

                                            <!-- DATA INIZIO -->
                                            <mat-form-field class="w-full lg:w-1/2 mat-button-datapicker add-mobilita-mat-form-field reduced-padding-form-field" appearance="outline"
                                                            *ngIf="validazioneFormGroup?.get('dataInizio') as fieldCtrl">
                                                <mat-label>{{t('mobility.start_date')}}</mat-label>
                                                <ng-container>
                                                    <input matInput
                                                           [placeholder]="t('mobility.start_date')"
                                                           readonly
                                                           [matDatepickerFilter]="datesFilters.get(periodIndex)?.get(validationIndex)"
                                                           [max]="validazioneFormGroup?.get('dataFine')?.value"
                                                           formControlName='dataInizio'
                                                           [matDatepicker]="picker1"
                                                    >
                                                </ng-container>
                                                <ng-template #dateReadOnly>
                                                    <input matInput
                                                           [placeholder]="t('mobility.start_date')"
                                                           readonly
                                                           [disabled]="true"
                                                           formControlName='dataInizio'
                                                    >
                                                </ng-template>
                                                <mat-datepicker #picker1 (opened)="datepickerOpened(periodIndex, validationIndex)"></mat-datepicker>
                                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                                <mat-error *ngIf="fieldCtrl.hasError('required')">
                                                    {{t('form.required')}}
                                                </mat-error>
                                                <mat-error *ngIf="fieldCtrl.hasError('LessThanToday')">
                                                    {{t('form.date_not_less_than_today')}}
                                                </mat-error>
                                                <mat-error *ngIf="fieldCtrl.hasError('matDatepickerFilter')">
                                                    {{t('form.retribution_period_error')}}
                                                </mat-error>
                                            </mat-form-field>

                                            <!-- DATA FINE -->
                                            <mat-form-field class="w-full lg:w-1/2 mat-button-datapicker add-mobilita-mat-form-field reduced-padding-form-field" appearance="outline"
                                                            *ngIf="validazioneFormGroup?.get('dataFine') as fieldCtrl">
                                                <mat-label>{{t('mobility.end_date')}}</mat-label>
                                                <ng-container>
                                                    <input matInput
                                                           [placeholder]="t('mobility.end_date')"
                                                           readonly
                                                           [matDatepickerFilter]="datesFilters.get(periodIndex)?.get(validationIndex)"
                                                           [min]="validazioneFormGroup?.get('dataInizio')?.value"
                                                           formControlName='dataFine'
                                                           [matDatepicker]="picker2"
                                                    >
                                                </ng-container>
                                                <ng-template #dateReadOnly>
                                                    <input matInput
                                                           [placeholder]="t('mobility.end_date')"
                                                           readonly
                                                           [disabled]="true"
                                                           formControlName='dataFine'
                                                    >
                                                </ng-template>
                                                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                                <mat-datepicker #picker2 (opened)="datepickerOpened(periodIndex, j)"></mat-datepicker>
                                                <mat-error *ngIf="fieldCtrl.hasError('required')">
                                                    {{t('form.required')}}
                                                </mat-error>
                                                <mat-error *ngIf="fieldCtrl.hasError('dataAMaggioreDiDataA')">
                                                    {{t('form.error_data_range')}}
                                                </mat-error>
                                                <mat-error *ngIf="fieldCtrl.hasError('LessThanToday')">
                                                    {{t('form.date_not_less_than_today')}}
                                                </mat-error>
                                                <mat-error *ngIf="fieldCtrl.hasError('matDatepickerFilter')">
                                                    {{t('form.retribution_period_error')}}
                                                </mat-error>
                                            </mat-form-field>

                                        </div>

                                        <button mat-mini-fab
                                                color="warn"
                                                type='button'
                                                class="mb-2.5"
                                                [disabled]="validazioniFormArray(periodoFormGroup).length < 2 || periodoFormGroup.disabled"
                                                (click)="removeSottoperiodo(periodoFormGroup, validationIndex)">
                                            <mat-icon>delete</mat-icon>
                                        </button>

                                    </div>

                                    <div class="py-4">
                                        <button mat-raised-button
                                                [color]="'primary'"
                                                type='button'
                                                (click)="addSottoperiodo(periodoFormGroup)">
                                            <mat-icon class="mr-2">add</mat-icon>
                                            {{t('mobility.add_subperiod')}}

                                        </button>
                                    </div>

                                </div>

                                <!-- NOTA -->
                                <mat-form-field class="w-full add-mobilita-mat-form-field reduced-padding-form-field" appearance="outline"
                                                *ngIf="periodoFormGroup?.get('nota') as formCtrl">
                                    <mat-label>{{t('mobility.partial_request_nota')}}</mat-label>
                                    <input matInput
                                           (blur)="formCtrl.setValue(formCtrl?.value?.trim())"
                                           formControlName="nota">
                                </mat-form-field>

                            </form>

                        </div>
                    </div>


                </ng-container>

            </div>


        </div>



    </ng-template>



</ng-container>
