import {
    ChangeDetectorRef,
    Component,
    EventEmitter, HostListener,
    Input,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {
    ButtonInterface,
    ClickEvent,
    ConfigurazioneColonna,
    ConfigurazioneTabella,
    GenericTableConfigurationModel,
    IconInterface,
    TableData,
    TipoClickEnum,
    TipoColonnaEnum
} from '../model/generic-table-model';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {get, isArray, isEmpty, isString, startCase, trim} from 'lodash';
import {MatSort, Sort} from '@angular/material/sort';
import {Router} from '@angular/router';
import {cloneDeep} from 'lodash-es';
import {AuthorityType, UserCodiceFiscaleNomeCognomeView} from '../../../../../api-clients/generated/services';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FormModuleService} from '../../../../layout/common/generic-components/form/form-module.service';
import {formGroupConfigInterface} from '../../../../layout/common/generic-components/generic-components.interface';
import {SnackbarTypes} from "../../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {FuseConfirmationService} from "../../../../../@fuse/services/confirmation";
import {TranslocoService} from "@ngneat/transloco";
import {MatDatepickerInputEvent} from "@angular/material/datepicker";
import * as moment from "moment/moment";
import {MatMenuTrigger} from "@angular/material/menu";


@Component({
    selector: 'app-generic-table-editable',
    styleUrls: ['generic-table-editable.component.scss'],
    templateUrl: 'generic-table-editable.component.html',
})
export class GenericTableEditableComponent {
    @Input() parentFormGroup: FormGroup;
    myFormGroup: FormGroup = new FormGroup<any>({});
    private sort: MatSort;
    private paginator: MatPaginator;
    private isPaginatedBE: boolean;
    private _righeOriginali: Array<any> = [];
    private _righeSelezionate: Array<any> = [];
    private _righeAggiunte: Array<any> = [];
    private _righeRimosse: Array<any> = [];
    private outputFormatData: 'DD/MM/YYYY';

    constructor(private router: Router,
                private formBuilder: FormBuilder,
                private formModuleService: FormModuleService,
                private _changeDetectorRef: ChangeDetectorRef,
                private fuseConfirmationService: FuseConfirmationService,
                private _translocoService: TranslocoService,) {
    }

    @ViewChildren(MatMenuTrigger) trigger: QueryList<MatMenuTrigger>;
    @HostListener('window:scroll', [])
    scrollHandler() {
        for (let index = 0; index < this.trigger.toArray().length; index++) {
            this.trigger.toArray()[index].closeMenu();
        }
    }

    @ViewChild(MatSort) set matSort(ms: MatSort) {
        this.sort = ms;
        this.setDataSourceAttributes();
    }

    @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
        this.paginator = mp;
        this.setDataSourceAttributes();
    }

    totalElements = 25;
    displayedColumns: string[] = [];
    dataSourcePaginated: MatTableDataSource<any>;
    _configuration: ConfigurazioneTabella;
    tipoColonna = TipoColonnaEnum;
    messaggioDatiAssenti: string;
    isSelectable: boolean = false;
    selectedColumnKeyToShow: string = undefined;
    selectedColumnKeyPrimaryKey: string = 'id';
    chipClickType = TipoClickEnum.CHIP_DETAIL;

    @Output() clickAction: EventEmitter<ClickEvent> = new EventEmitter<ClickEvent>();
    @Output() pageAction: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
    @Output() sortAction: EventEmitter<Sort> = new EventEmitter<Sort>();
    @Output() tableDataEmitter: EventEmitter<TableData> = new EventEmitter<TableData>();

    @Output() outputTableData: EventEmitter<Sort> = new EventEmitter<Sort>();

    @Input() set righeSelezionate(selezione: Array<any>) {
        if (this.dataSourcePaginated.data.length > 0 && selezione.length > 0) {
            this.dataSourcePaginated.data.forEach((value) => {
                const selectedElement = selezione.find(elementoSelezionato => value[this.selectedColumnKeyPrimaryKey] === elementoSelezionato.data[this.selectedColumnKeyPrimaryKey]);
                if (selectedElement) {
                    this._righeSelezionate.push(selectedElement);
                }
            });
            if (this._righeSelezionate.length > 0) {
                this._righeOriginali = cloneDeep(this._righeSelezionate);
            }
        }
    }

    @Input() idRigheSolaLettura: string[] = [];

    get righeSelezionate() {
        return this._righeSelezionate;
    }

    get righeOriginali() {
        return this._righeOriginali;
    }

    get righeAggiunte() {
        if (this.righeOriginali.length === 0) {
            return this._righeSelezionate;
        }
        if (this._righeOriginali.length > 0 && this._righeSelezionate.length === 0) {
            return new Array<any>();
        } else {
            return this._righeSelezionate.filter((rigaSelezionata) => {
                const element = this._righeOriginali.find(rigaOriginale => rigaSelezionata.key === rigaOriginale.key);
                if (!element) {
                    return rigaSelezionata;
                }
            });
        }
    }

    get righeRimosse() {
        if (this.righeOriginali.length === 0) {
            return new Array<any>();
        }
        if (this._righeOriginali.length > 0 && this._righeSelezionate.length === 0) {
            return this._righeOriginali;
        } else {
            return this._righeOriginali.filter((rigaOriginale) => {
                const element = this._righeSelezionate.find(rigaSelezionata => rigaSelezionata.key === rigaOriginale.key);
                if (!element) {
                    return rigaOriginale;
                }
            });
        }
    }

    @Input() set configuration(data: GenericTableConfigurationModel) {
        if (!!data) {
            this._configuration = data.configuration;

            // Gestione della selezione se opportunamente configurata. Se non configurata rimuovo elementi di selezione.
            this.isSelectable = this._configuration.selection !== undefined ? this._configuration.selection.isSelectable : false;
            this._configuration.configurazioneTabella = this._configuration.configurazioneTabella.filter(value => value.tipo !== TipoColonnaEnum.SELECTION);
            if (this.isSelectable) {
                this.selectedColumnKeyToShow = this._configuration.selection.selectedColumnKeyToShow;
                this.selectedColumnKeyPrimaryKey = this._configuration.selection.selectedColumnKeyPrimaryKey;
                this._configuration.configurazioneTabella.splice(0, 0, {
                    tipo: TipoColonnaEnum.SELECTION,
                    nomeColonna: 'common.selection',
                    colonnaKey: 'selezione',
                    flex: 5,
                });
            }
            this.displayedColumns = this._configuration.configurazioneTabella.filter(value => !value.hideColonna).map(value => value.colonnaKey);
            this.dataSourcePaginated = new MatTableDataSource<any>(this._configuration.data);
            this.totalElements = this._configuration.totalElements || this._configuration?.data?.length || this.totalElements;
            if (this._configuration.data.length > 0) {
                this.messaggioDatiAssenti = 'Nessun dato corrispondente al filtro';
            } else {
                this.messaggioDatiAssenti = 'Non ci sono dati da visualizzare nella pagina corrente';
            }
            this.isPaginatedBE = this._configuration.isPaginatedBE;
            this.createFormGroup(data);
        }
    }

    @Input() readOnly: boolean;

    setDataSourceAttributes() {
        if (!this.isPaginatedBE) {
            this.dataSourcePaginated.paginator = this.paginator;
            this.dataSourcePaginated.sort = this.sort;
            this.dataSourcePaginated.sortingDataAccessor = (item, property) => {
                if (this._configuration.configurazioneTabella?.find(v => v?.colonnaKey === property)?.sortByNumber) {
                    const onlyNumber = item[property]?.replace(/\D/g, '');
                    return onlyNumber ? Number(item[property].replace(/\D/g, '')) : this.castSort(item[property]);
                } else {
                    return this.castSort(item[property]);
                }
            };
        }

    }


    setPaginazione(dataSource: any[]): void {
        this.dataSourcePaginated = new MatTableDataSource<any>(dataSource);
        this.setDataSourceAttributes();
    }

    convertiStringa(nomeColonna: string): string {
        return startCase(nomeColonna);
    }


    show(button: ButtonInterface, element, value: string | string []): boolean {
        return !!button.showEvery ?
            this.showEvery(button, element, value) : !!button.show ? !!value ? isArray(value)
                ? value.some(v => button.show(get(element, v, element[v]), element)) : button.show(get(element, value, element[value]), element) : true : true;
    }

    showEvery(button: ButtonInterface, element, value: string | string []): boolean {
        return !!button.showEvery ? !!value ? isArray(value) ? value.every(v => button.showEvery(element[v])) : button.showEvery(element[value]) : true : true;
    }


    getValue(colonna: ConfigurazioneColonna, element): string {
        return !!colonna.convertiValoreBoolean ? colonna.convertiValoreBoolean(element) : '';
    }


    getButton(colonna: ConfigurazioneColonna, elementElement) {
        return !!colonna.button ? this.readOnly ? colonna.button.filter(value => value.click === TipoClickEnum.INFO) : colonna.button : null;
    }

    convertiValoreInBo(colonna: ConfigurazioneColonna, elementElement): IconInterface | null {
        return !!colonna.convertiValoreBooleanInIcon ? colonna.convertiValoreBooleanInIcon(elementElement) : null;
    }

    getValueShow(colonna: ConfigurazioneColonna, element, colonnaKey: string): string {
        if (colonnaKey.includes(' ')) {
            return colonnaKey.split(' ').map(aElem => element[aElem]).join(' ');
        }
        return !!colonna.valueCheck ? !!colonna.getValue(element[colonna.valueCheck]) ? colonna.getValue(element[colonna.valueCheck]) : element[colonnaKey] : element[colonnaKey]?.toString();
    }

    castSort(itemElement: any): any {
        return isString(itemElement) ? itemElement.toLowerCase() : itemElement;
    }

    onPageChange(pageEvent: PageEvent) {
        if (this.isPaginatedBE) {
            this.pageAction.emit(pageEvent);
        }
    }

    onSortChange($event: Sort) {
        if (this.isPaginatedBE) {
            this.sortAction.emit($event);
        }
    }

    goTo(colonna: ConfigurazioneColonna, element, colonnaKey: string) {
        if (colonna.goTo.value && colonna.goTo.path) {
            const baseUrl = window.location.href.replace(this.router.url, '');
            const url = new URL([...colonna.goTo.path, element[colonna.goTo.value]].join('/'), baseUrl).href;
            console.log(url);
            window.open(url, '_blank');
        }
    }

    toggleSelectedRowData(element: any) {
        const elementExtracted = this.righeSelezionate.findIndex(value => value.key === element[this.selectedColumnKeyPrimaryKey]);
        if (elementExtracted !== -1) {
            this.righeSelezionate.splice(elementExtracted, 1);
        } else {
            this.righeSelezionate.push({key: element[this.selectedColumnKeyPrimaryKey], data: element});
        }
        this.tableDataEmitter.emit({
            selectedRows: this.righeSelezionate,
            removedRows: this.righeRimosse,
            addedRows: this.righeAggiunte
        });
    }

    checkIfSelected(key: string) {
        return this.righeSelezionate.find(value => value.key === key);
    }

    //----CHIP
    getValueForChip(colonna: ConfigurazioneColonna, element, colonnaKey: string): Array<UserCodiceFiscaleNomeCognomeView> {
        return !!colonna.valueCheck ?
            !!colonna.getValue(element[colonna.valueCheck]) ? colonna.getValue(element[colonna.valueCheck])
                : element[colonnaKey] : element[colonnaKey];
    }

    getValueForStatus(colonna: ConfigurazioneColonna, element, colonnaKey: string): any {
        return !!colonna.valueCheck ?
            !!colonna.getValue(element[colonna.valueCheck]) ? colonna.getValue(element[colonna.valueCheck])
                : element[colonnaKey] : element[colonnaKey];
    }

    buildNomeCompleto(user: UserCodiceFiscaleNomeCognomeView): string {
        return trim((user?.cognome || '') + ' ' + (user?.nome || ''));
        //return trim((user?.cognome || '') + ' ' + ((user?.nome?.[0] ? (user?.nome?.[0] + '.') : undefined ) || ''));
    }

    checkIfRigaReadOnly(element: any): boolean {
        if (!element?.id || isEmpty(this.idRigheSolaLettura)) {
            return false;
        } else {
            return this.idRigheSolaLettura?.includes(element?.id);
        }
    }

    //-- MARK: PARTE FORM CONTROL --//
    createFormGroup(data: GenericTableConfigurationModel): void {
        this.myFormGroup = this.formBuilder.group({
            input_fields: this.buildFormArray(data)
        });
        this.parentFormGroup.setControl('child', this.myFormGroup);
    }

    buildFormArray(data: GenericTableConfigurationModel): FormArray {
        const myFormArray: FormArray = this.formBuilder.array([]);
        data.configuration.data.forEach(row =>
            myFormArray.push(
                this.formModuleService.createFormGroupWithValue(
                    this.buildFormGroupConfigInterface(data, row),
                    this.formBuilder.group({})
                )
            )
        );
        return myFormArray;
    }

    buildFormGroupConfigInterface(data: GenericTableConfigurationModel, row: any): formGroupConfigInterface[] {
        return data.configuration.configurazioneTabella.filter(item =>
            !!item.formControl
        ).map((configurazioneColonna) => {
            const a: formGroupConfigInterface = {
                ...configurazioneColonna?.formControl,
                value: row[configurazioneColonna?.colonnaKey] ?? configurazioneColonna?.formControl?.value ?? null
            };
            return a;
        });
    }

    getInputFieldsFormArray(): FormArray {
        return this.myFormGroup?.get('input_fields') as FormArray;
    }

    getFormControlByColonnaKey(element, colonnaKey: string): FormControl {
        let index = this.getInputFieldsFormArray().controls.findIndex(
            (group: FormGroup) => group.get('id')?.value === element.id
        );
        return (this.getInputFieldsFormArray()?.at(index) as FormGroup)?.get(colonnaKey) as FormControl;
    }

    checkFormAction(element, param: { tipoClick: TipoClickEnum; index: any; value: any }): void {
        switch (param.tipoClick) {
            case TipoClickEnum.MODIFICA_FORM:
                this.getFormControlByColonnaKey(element, 'readonly').setValue(false);
                break;
            case TipoClickEnum.RESET_FORM:
                this.resetFormArray(element);
                break;
            case TipoClickEnum.SALVA_FORM:
                if (this.myFormGroup.valid) {
                    this.getFormControlByColonnaKey(element, 'readonly').setValue(true);
                } else {
                    this.openSnackBarNotAllFormAreValid()
                }
                break;
            default:
                return null;
        }
    }

    checkReadOnlyValue(element): boolean {
        return (this.getFormControlByColonnaKey(element, 'readonly') as FormControl<boolean>)?.value;
    }

    resetFormArray(element): void {
        let index = this.getInputFieldsFormArray().controls.findIndex(
            (group: FormGroup) => group.get('id')?.value === element?.id
        );
        const currentFormGroup = this.getInputFieldsFormArray()?.at(index) as FormGroup;
        const formGroupKeys = Object.keys(currentFormGroup.controls).filter(
            item => item !== 'readonly' && item !== 'id');
        formGroupKeys.forEach((key) => {
                this.getFormControlByColonnaKey(element, key)?.setValue(element[key]);
            }
        );
    }

    checkForFormButtons(button: ButtonInterface, element): boolean {
        switch (button.click) {
            case TipoClickEnum.MODIFICA_FORM:
                return this.getFormControlByColonnaKey(element, 'readonly').value;
            case TipoClickEnum.RESET_FORM:
            case TipoClickEnum.SALVA_FORM:
                return !this.getFormControlByColonnaKey(element, 'readonly').value;
            default:
                return true;
        }
    }

    openSnackBarNotAllFormAreValid(): void {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        const message = get(translation, 'form.not_valid', null);
        this.fuseConfirmationService.openSnackBar({
            message: message,
            type: SnackbarTypes.Error
        });
    }

    //-- ENDMARK: PARTE FORM CONTROL --//
    protected readonly AuthorityType = AuthorityType;

    dateEvent(event: MatDatepickerInputEvent<Date>, fieldCtrl: AbstractControl, required: boolean) {
        fieldCtrl.setValue(moment(event.value).format(this.outputFormatData));
        fieldCtrl.setErrors(null);
        fieldCtrl.setValidators([]);
        if (required) {
            fieldCtrl.addValidators(Validators.required);
        }
        fieldCtrl.updateValueAndValidity();
    }

    dateValue(fieldCtrl: AbstractControl) {
        return fieldCtrl.value ? moment(fieldCtrl.value, this.outputFormatData).toDate() : null;
    }

    clearDateField(formControl: FormControl<any>) {
        formControl.setValue(undefined);
        formControl.setErrors(null);
        formControl.setValidators([]);
    }
    closeMenus() {
        for (let index = 0; index < this.trigger.toArray().length; index++) {
            this.trigger.toArray()[index].closeMenu();
        }
    }

}
