import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ClickEvent, TipoClickEnum} from '../../../../../shared/components/table/model/generic-table-model';
import {
    AddUpdatePianoDiStudiDTO,
    AggiornamentoPianoDiStudi,
    AggiornamentoPianoDiStudiStatus,
    AnnoRiferimentoValues,
    AttivitaCategoriaAssociationInfoView,
    AttivitaPresuntaAddUpdatePianoDiStudiDTO,
    AttivitaPropostaAddUpdatePianoDiStudiDTO,
    AttivitaTrasversaleAddUpdatePianoDiStudiDTO,
    AuthorityType,
    CategoriaInAggiornamentoPianoDiStudi,
    ConfigurazioneAttivitaPresunteOrProposte,
    CorsoAddUpdatePianoDiStudiDTO,
    CorsoAggiornamentoPianoDiStudi,
    ElementoOffertaFormativaType,
    GuidaLinguaInfoView,
    OffertaFormativaInfoViewImpl,
    OffertaFormativaStatus,
    PianoDiStudiInfoView,
    PianoDiStudiInfoViewImpl,
    StudentiCicloService,
    TipoModificaPianoDiStudi, VincoliCfuPerTipoAttivita
} from '../../../../../../api-clients/generated/services';
import {finalize, takeUntil} from 'rxjs';
import {Translation, TranslocoService} from '@ngneat/transloco';
import {FuseConfirmationDialogListDataI, FuseConfirmationService} from '../../../../../../@fuse/services/confirmation';
import {
    AbstractDefaultComponent
} from '../../../../../shared/abstracts/abstract-default-component/abstract-default-component';
import {get, head, isEmpty, isNil, orderBy, sortBy, trim} from 'lodash';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import * as moment from 'moment';
import {SnackbarTypes} from '../../../../../../@fuse/services/confirmation/snackbar/snackbar.component';
import {
    annoInsegnamentoValueToNumber,
    annoRiferimentoFromRomanNumeral,
    annoRiferimentoToRomanNumeral
} from '../../../../../shared/utils/utils';
import {
    calculateSelectedActivitiesInCategoryConf,
    calculateSelectedCFUInCategoriesByYear,
    calculateSelectedCFUInCategoriesConf,
    calculateSelectedCFUInCategoryConf,
    calculateTotalCFUOfActivitiesInCategories,
    cannotRemoveExtraFromPlan,
    cannotRemoveTrasversalFromPlan
} from '../study-plan-utils';
import {StudyPlanFragments} from '../study-plan.component';
import {LocalStorageService} from '../../../../../shared/service/local-storage.service';
import {cloneDeep, isNull} from 'lodash-es';
import {
    buildConfigurationForCoursesInDraftEditMode,
    buildConfigurationForCoursesInDraftReadMode
} from '../study-plan-configurations/nuova-presentazione/configurazione-corsi-nuova-presentazione';
import {StudentDetailsService} from "../../student-details.service";
import {FuseNavigationService, FuseVerticalNavigationComponent} from "../../../../../../@fuse/components/navigation";
import {
    annoRiferimentoToCheckCategoryConfigurationYearCallback,
    createEditModeCategoriesByActivityTypeConfigurations,
    createPlanCategoriesByActivityTypeConfigurations,
    getDraftCategoriesByYearInEditMode,
    getDraftCategoriesByYearInReadMode,
    UIActivityI
} from "../study-plans-categories-utils";
import {StudyPlanAggiornamentoDataUI} from "../../../../../shared/interface/piano-di-studi-data-u-i";
import {
    buildConfigurationForExtraActivitiesInNewPresentationEditMode,
    buildConfigurationForExtraActivitiesInNewPresentationReadMode
} from "../study-plan-configurations/nuova-presentazione/configurazione-attivita-extra-nuova-presentazione";
import {
    buildConfigurationForTransversalActivitiesForNewPresentationEditMode,
    buildConfigurationForTransversalActivitiesForNewPresentationReadMode,
    buildConfigurationForTransversalTipologiesForNewPresentationEditMode
} from "../study-plan-configurations/nuova-presentazione/configurazione-attivita-trasversale-nuova-presentazione";
import {
    TrainingOfferCategoryDataUI
} from "../../../training-offer-study-plan-shared-module/components/activities-categories/activities-categories.component";
import {
    GenericComponentDialogConfig
} from "../../../../../layout/common/generic-components/generic-components.interface";
import {TypeDialogFormEnum} from "../../../../../layout/common/generic-components/generic-components-enum";
import {
    GenericDialogComponent
} from "../../../../../layout/common/generic-components/generic-dialog/generic-dialog.component";
import {FormGroup} from "@angular/forms";
import {FuseConfirmationDialogComponent} from "../../../../../../@fuse/services/confirmation/dialog/dialog.component";
import {
    getDraftInEditModeCompliance,
    getDraftInReadModeCompliance
} from "./compliancies-utils/draft-compliancies-utils";
import {
    DialogPlanSummaryComponent,
    DialogPlanSummaryI,
    PlanSummaryByYearsI
} from "../dialog-plan-summary/dialog-plan-summary.component";
import {
    DialogDefinePresumedComponent,
    DialogDefinePresumedI
} from "./dialog-define-presumed/dialog-define-presumed.component";
import {LogoutService} from "../../../../../shared/service/logout.service";
import {annoRiferimentoToCheckActivityInCategoryYearCallback} from "../../../cycle/training-offer/training-offer-utils";
import {AppInitService} from "../../../../../shared/service/app-init.service";

export enum StudyPlanDraftCreationMode {
    EDIT= 'EDIT',
    READ_ONLY= 'READ_ONLY'
}

@Component({
    selector: 'app-tab-creation-study-plan',
    templateUrl: './tab-creation-study-plan.component.html',
    styleUrls: ['./tab-creation-study-plan.component.scss']
})
export class TabCreationStudyPlanComponent extends AbstractDefaultComponent implements OnInit, AfterViewInit {

    _isTabActive: boolean;
    loadingFirstDraftSave: boolean;
    errorOnSaveAtNewPresentationTabVisit: boolean;

    @Input() set isTabActive(_isActive: boolean) {
        this._isTabActive = _isActive;
        // if tab is active and there is a bozza and tab is in read mode
        if(_isActive &&
            this.appInitService.cicloCorsoRuoloSelected.ruolo === AuthorityType.STUDENTE &&
            !this.showCreateBozzaButton() &&
            !this.showAlertDaApprovare() &&
            !this.showBoxMessageForPlanCreation &&
            this.offertaFormativa?.stato === OffertaFormativaStatus.PUBBLICA &&
            this.studentDetailsService.studyPlanDraftCreationMode === StudyPlanDraftCreationMode.READ_ONLY){
            // save draft to preselect mandatory activities and remove not available anymore activities
            this.saveDraft(true, true);
        }
    };
    @Input() showButtonModificaCorsi: boolean;
    @Input() myIdStudente: string;
    @Output() tableClickAction = new EventEmitter<ClickEvent>();
    @Output() updatePianoDiStudiConfigurations = new EventEmitter<PianoDiStudiInfoView>();
    @Output() sottomettiPiano = new EventEmitter<AddUpdatePianoDiStudiDTO>();
    @Output() onOpenGuideERegoleTab = new EventEmitter<void>();
    @Output() onCancelDraftEdit = new EventEmitter<void>();
    @Input() guidaCoordinatore: GuidaLinguaInfoView[];
    @Input() guidaAteneo: GuidaLinguaInfoView[];
    @Input() numberOfArchivedRemovableActivities: number;
    @Input() archivedRemovableActivities: CorsoAggiornamentoPianoDiStudi[];
    @Input() offertaFormativa: OffertaFormativaInfoViewImpl;


    currentPianoInfoView: PianoDiStudiInfoView;
    lastUpdateStatus: AggiornamentoPianoDiStudiStatus;
    loading: boolean;
    anniInsegnamentoList = [
        AnnoRiferimentoValues.PRIMO,
        AnnoRiferimentoValues.SECONDO,
        AnnoRiferimentoValues.TERZO
    ];
    draftData: StudyPlanAggiornamentoDataUI;
    cancelReturnValue = 'cancelled';
    showBoxMessageForPlanCreation: boolean;
    currentRuolo: AuthorityType;
    isSupervisore: boolean;
    isDocenteCorso: boolean;
    elementoOffertaFormativaType = ElementoOffertaFormativaType;
    private currentLanguage: string;
    // this is the draft version while edit mode is true (contains the temporary presumed and proposed)
    currentPianoInfoViewWithPropostePresunteEditing: PianoDiStudiInfoView;
    yearTabSelectedIndex: number;
    @Input() set currentPiano(currentPiano: PianoDiStudiInfoViewImpl) {
        console.log(currentPiano, 'currentPiano')
        this.currentPianoInfoView = currentPiano;
        const sottoruolo = this.localStorageService.getCicloCorsoRuolo()?.sottoruolo;
        this.currentRuolo = this.localStorageService.getCicloCorsoRuolo()?.ruolo;
        this.lastUpdateStatus = this.getLastUpdateStatus(currentPiano);
        this.showBoxMessageForPlanCreation = isEmpty(currentPiano?.aggiornamenti);
        this.buildDraftUIData(currentPiano);
    };

    protected readonly AggiornamentoPianoDiStudiStatus = AggiornamentoPianoDiStudiStatus;
    protected readonly ElementoOffertaFormativaType = ElementoOffertaFormativaType;
    protected readonly OffertaFormativaStatus = OffertaFormativaStatus;
    protected readonly Math = Math;
    protected readonly AnnoRiferimentoValues = AnnoRiferimentoValues;
    protected readonly StudyPlanFragments = StudyPlanFragments;
    protected readonly StudyPlanDraftCreationMode = StudyPlanDraftCreationMode;
    trackByIndex = (index: number, item: any) => index;


    // eslint-disable-next-line @typescript-eslint/member-ordering
    constructor(private _translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                private dialog: MatDialog,
                private studentiCicloService: StudentiCicloService,
                private localStorageService: LocalStorageService,
                protected studentDetailsService: StudentDetailsService,
                private fuseNavigationService: FuseNavigationService,
                private translocoService: TranslocoService,
                private logOutService: LogoutService,
                protected appInitService: AppInitService
    ) {
        super();
        this._translocoService.langChanges$.pipe(
            takeUntil(this.destroy$)
        ).subscribe(lang => this.currentLanguage = lang);
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        this.currentRuolo = this.localStorageService.getCicloCorsoRuolo()?.ruolo;
        this.buildDraftUIData(this.currentPianoInfoView);
    }



    tableClickActionMeth(event: ClickEvent): void {
        if (event.tipoClick === TipoClickEnum.EDIT) {
             if(event?.value?.idAttivitaPresunta) {
                 this.openAddEditPresumedActivityDialog(event.category, event?.value);
             } else if(event?.value?.idAttivitaProposta) {
                 this.openAddEditProposedActivityDialog(event.category, event?.value);
             }
        } else if (event.tipoClick === TipoClickEnum.DELETE) {
            if(event?.value?.idAttivitaPresunta) {
                this.openDeletePresumedActivityDialog(event.category, event?.value);
            } else if(event?.value?.idAttivitaProposta) {
                this.openDeleteProposedActivityDialog(event.category, event?.value);
            }
        }else if (event.tipoClick === TipoClickEnum.CONFERMA) {
             this.openDefinePresumedActivityDialog(event.category, event?.value);
         }
    }

    showCreateBozzaButton(): boolean {
        return !(this.lastUpdateStatus === AggiornamentoPianoDiStudiStatus.APPROVATOPARZIALE
            || this.lastUpdateStatus === AggiornamentoPianoDiStudiStatus.DAAPPROVARE ||
            this.lastUpdateStatus === AggiornamentoPianoDiStudiStatus.BOZZA
            || this.lastUpdateStatus === AggiornamentoPianoDiStudiStatus.RIFIUTATO)
            && this.offertaFormativa?.stato === OffertaFormativaStatus.PUBBLICA
            && this.appInitService?.cicloCorsoRuoloSelected?.ruolo === AuthorityType.STUDENTE;
    }

    showAlertDaApprovare(): boolean {
        return (this.lastUpdateStatus === AggiornamentoPianoDiStudiStatus.APPROVATOPARZIALE
            || this.lastUpdateStatus === AggiornamentoPianoDiStudiStatus.DAAPPROVARE);
    }

    // creates the draft from the approved plan
    buildBodyForDraftCreationAndExecuteRequest(){
        const approvedPlan = this.currentPianoInfoView;

        const corsiAndExtra: CorsoAddUpdatePianoDiStudiDTO[] = approvedPlan.categorie
            ?.flatMap(category => category?.corsi
                ?.filter(a => a.tipo !== ElementoOffertaFormativaType.ATTIVITATRASVERSALE && !a.idAttivitaPresunta && !a.idAttivitaProposta)
                ?.map(a => ({
                    idCategoriaOffertaFormativa: category.idCategoriaOffertaFormativa,
                    idCorsoInOffertaFormativa: a.idCorsoInOffertaFormativa,
                    annoInsegnamento: a.annoInsegnamento
                })) ?? []
            ) ?? [];

        const trasversali: AttivitaTrasversaleAddUpdatePianoDiStudiDTO[] = approvedPlan.categorie
            ?.flatMap(category => category?.corsi
                ?.filter(a => a.tipo === ElementoOffertaFormativaType.ATTIVITATRASVERSALE && !a.idAttivitaPresunta && !a.idAttivitaProposta)
                ?.map(a => ({
                    idCategoriaOffertaFormativa: category.idCategoriaOffertaFormativa,
                    idAttivitaTrasversale: a.idAttivitaTrasversale,
                    annoInsegnamento: a.annoInsegnamento,
                    dettaglio: a.dettaglioAttivitaTrasversale,
                    idCorsoInOffertaFormativa: a?.idCorsoInOffertaFormativa,
                    cfuPrevisti: +a?.cfuPrevisti
                })) ?? []
            ) ?? [];

        const proposte: AttivitaPropostaAddUpdatePianoDiStudiDTO[] = approvedPlan.categorie
            ?.flatMap(category => category?.corsi
                ?.filter(a => a.idAttivitaProposta)
                ?.map(a => ({
                    idCategoriaOffertaFormativa: category.idCategoriaOffertaFormativa,
                    idAttivitaProposta: a.idAttivitaProposta,
                    denominazioneAttivitaProposta: a.denominazione,
                    descrizioneAttivitaProposta: a.descrizione,
                    cfuPrevisti: +a.cfuPrevisti,
                    ore: +a.ore,
                    annoInsegnamento: a.annoInsegnamento
                })) ?? []
            ) ?? [];

        const presunte: AttivitaPresuntaAddUpdatePianoDiStudiDTO[] = approvedPlan.categorie
            ?.flatMap(category => category?.corsi
                ?.filter(a => a.idAttivitaPresunta)
                ?.map(a => ({
                    idCategoriaOffertaFormativa: category.idCategoriaOffertaFormativa,
                    idAttivitaPresunta: a.idAttivitaPresunta,
                    denominazioneAttivitaPresunta: a.denominazione,
                    descrizioneAttivitaPresunta: a.descrizione,
                    cfuPrevisti: +a.cfuPrevisti,
                    ore: +a.ore,
                    annoInsegnamento: a.annoInsegnamento
                })) ?? []
            ) ?? [];

        const body = {
            tipo: TipoModificaPianoDiStudi.BOZZA,
            corsi: corsiAndExtra,
            attivitaTrasversaleList: trasversali,
            attivitaPropostaList: proposte,
            attivitaPresuntaList: presunte
        };

        if(!this.currentPianoInfoView.sottomissionePianoNonValidoSbloccata) {
            // removing not available and preselecting mandatory
            this.removeNotAvailableActivitiesAndPreselectMandatory(body, true);
        } else {
            // removing not available
            this.removeNotAvailableActivitiesAndPreselectMandatory(body, true, false);
        }

    }

    // this map the data of the current editing draft to the BE format
    buildBodyDraftUpdateOrSubmissionAndExecuteRequest(draftEditType: TipoModificaPianoDiStudi,
                                                      saveOnNewPresentationTabBecomeActive = false,
                                                      preventExecutionOfRequestIfNothingChanged = false,
                                                      alsoOpenSubmitSuggestion = false) {

        let corsiAndExtra: CorsoAddUpdatePianoDiStudiDTO[] = this.draftData?.draftInEditModeCategoriesByYear
            ?.flatMap(year => year?.categoriesByActivityType
                ?.filter(c => c.activityType !== ElementoOffertaFormativaType.ATTIVITATRASVERSALE)
                ?.flatMap(type => type?.categoriesConfiguration
                    ?.flatMap(category => category?.selectedActivities
                    ?.filter(a => (!a?.data?.idAttivitaProposta && !a?.data?.idAttivitaPresunta))
                    ?.map(a =>
                        ({
                            idCategoriaOffertaFormativa: category.id,
                            idCorsoInOffertaFormativa: a.data?.id ?? a?.data?.idCorsoInOffertaFormativa,
                            annoInsegnamento: annoRiferimentoFromRomanNumeral(year.year),
                        }) as CorsoAddUpdatePianoDiStudiDTO
                    ) ?? [])
                ) ?? []
            ) ?? [];

        let trasversali: AttivitaTrasversaleAddUpdatePianoDiStudiDTO[] = this.draftData?.draftInEditModeCategoriesByYear
            ?.flatMap(year => year?.categoriesByActivityType
                ?.filter(c => c.activityType === ElementoOffertaFormativaType.ATTIVITATRASVERSALE)
                ?.flatMap(type => type?.categoriesConfiguration
                    ?.flatMap(category => category?.attivitaCategoriaAssociations
                        ?.filter(a => !(a?.attivitaOffertaFormativa as any)?.idAttivitaProposta && !(a?.attivitaOffertaFormativa as any)?.idAttivitaPresunta)
                        ?.map(a => a.attivitaOffertaFormativa as any)
                        ?.map(s =>
                        ({
                            idCategoriaOffertaFormativa: category.id,
                            idAttivitaTrasversale: s?.idAttivitaTrasversale,
                            annoInsegnamento: annoRiferimentoFromRomanNumeral(year.year),
                            dettaglio: s?.dettaglioAttivitaTrasversale,
                            idCorsoInOffertaFormativa: s?.idCorsoInOffertaFormativa,
                            cfuPrevisti: s?.cfu ?? s?.cfuPrevisti ?? 0
                        }) as AttivitaTrasversaleAddUpdatePianoDiStudiDTO
                    ) ?? [])
                ) ?? []
            ) ?? [];

        const proposte: AttivitaPropostaAddUpdatePianoDiStudiDTO[] = this.draftData?.draftInEditModeCategoriesByYear
            ?.flatMap(year => year?.categoriesByActivityType
                ?.flatMap(type => type?.categoriesConfiguration
                    ?.flatMap(category => category?.attivitaCategoriaAssociations
                    ?.filter(a => (a?.attivitaOffertaFormativa as any)?.idAttivitaProposta)
                    ?.map(a => a.attivitaOffertaFormativa)
                    ?.map(a =>
                        ({
                            idCategoriaOffertaFormativa: category.id,
                            idAttivitaProposta: (a as any).idAttivitaProposta?.includes('NEW_PROPOSED_') ? undefined : (a as any).idAttivitaProposta,
                            denominazioneAttivitaProposta: a.denominazione,
                            descrizioneAttivitaProposta: a.descrizione,
                            cfuPrevisti: +a.cfu,
                            ore: +a.ore,
                            annoInsegnamento: annoRiferimentoFromRomanNumeral(year.year),
                        }) as AttivitaPropostaAddUpdatePianoDiStudiDTO
                    ) ?? [])
                ) ?? []
            ) ?? [];

        const presunte: AttivitaPresuntaAddUpdatePianoDiStudiDTO[] = this.draftData?.draftInEditModeCategoriesByYear
            ?.flatMap(year => year?.categoriesByActivityType
                ?.flatMap(type => type?.categoriesConfiguration
                    ?.flatMap(category => category?.attivitaCategoriaAssociations
                    ?.filter(a => (a?.attivitaOffertaFormativa as any)?.idAttivitaPresunta)
                    ?.map(a => a.attivitaOffertaFormativa)
                    ?.map(a =>
                        ({
                            idCategoriaOffertaFormativa: category.id,
                            idAttivitaPresunta: (a as any).idAttivitaPresunta?.includes('NEW_PRESUMED_') ? undefined : (a as any).idAttivitaPresunta,
                            denominazioneAttivitaPresunta: a.denominazione,
                            cfuPrevisti: +a.cfu,
                            ore: +a.ore,
                            annoInsegnamento: annoRiferimentoFromRomanNumeral(year.year),
                            descrizioneAttivitaPresunta: a.descrizione,
                        }) as AttivitaPresuntaAddUpdatePianoDiStudiDTO
                    ) ?? [])
                ) ?? []
            ) ?? [];

        const body = {
            tipo: draftEditType,
            corsi: corsiAndExtra,
            attivitaTrasversaleList: trasversali,
            attivitaPropostaList: proposte,
            attivitaPresuntaList: presunte
        }

        if(saveOnNewPresentationTabBecomeActive && !this.draftData?.sottomissionePianoNonValidoSbloccata && draftEditType === TipoModificaPianoDiStudi.BOZZA){
            // preselecting mandatory and removing not available activities
            this.removeNotAvailableActivitiesAndPreselectMandatory(body, false, true, preventExecutionOfRequestIfNothingChanged);
        }else if(saveOnNewPresentationTabBecomeActive && this.draftData?.sottomissionePianoNonValidoSbloccata && draftEditType === TipoModificaPianoDiStudi.BOZZA){
            // removing not available activities
            this.removeNotAvailableActivitiesAndPreselectMandatory(body, false, false, preventExecutionOfRequestIfNothingChanged);
        } else if(draftEditType === TipoModificaPianoDiStudi.BOZZA && !preventExecutionOfRequestIfNothingChanged){
            // only save bozza
            this.saveDraftRequest(body, false, alsoOpenSubmitSuggestion);
        } else if(draftEditType === TipoModificaPianoDiStudi.SOTTOMISSIONE){
            // submit bozza
            this.sottomettiPiano.next(body);
        }

    }

    private checkPresunteProposteConfigYear(config: ConfigurazioneAttivitaPresunteOrProposte, annoInsegnamento: AnnoRiferimentoValues) {
        if(annoInsegnamento === AnnoRiferimentoValues.PRIMO){
            return config.primo_anno;
        } else if(annoInsegnamento === AnnoRiferimentoValues.SECONDO){
            return config.secondo_anno;
        } if(annoInsegnamento === AnnoRiferimentoValues.TERZO){
            return config.terzo_anno;
        }
        return false;
    }

    private removeNotAvailableActivitiesAndPreselectMandatory(body: AddUpdatePianoDiStudiDTO, isCreatingBozza = false, removeNotAvailable = true, preventExecutionOfRequestIfNothingChanged = false) {
        let corsiAndExtra = body.corsi;
        let trasversali = body.attivitaTrasversaleList;
        let presunte = body.attivitaPresuntaList;
        let proposte = body.attivitaPropostaList;

        // foreach corsi extra and trasv if act not more available for the year between not archived archivities from offerta or approved plan, show dialog and remove
        const activitiesToRemove = [];

        if(removeNotAvailable){
            const draft = this.currentPianoInfoView?.aggiornamenti?.find(agg => agg.stato === AggiornamentoPianoDiStudiStatus.BOZZA);
            const activitiesInDraft = draft?.categorie?.flatMap(c => c.corsi ?? []);
            activitiesInDraft?.forEach(aInDraft => {

                const categoryInOffer = this.offertaFormativa.categorie?.find(categoryInOffer => categoryInOffer.id === aInDraft.datiCorsoOffertaFormativa?.idCategoriaOffertaFormativa);

                // if proposta
                if(aInDraft.datiCorsoOffertaFormativa?.idAttivitaProposta){
                    const configForThisYear = categoryInOffer?.configurazione?.configurazioni?.find(annoRiferimentoToCheckCategoryConfigurationYearCallback(aInDraft.datiCorsoOffertaFormativa?.annoInsegnamento));
                    const inLastApprovedPlan = this.currentPianoInfoView.categorie
                        ?.find(c => c.idCategoriaOffertaFormativa === categoryInOffer?.id)
                        ?.corsi
                        ?.find(a => a.idAttivitaProposta === aInDraft.datiCorsoOffertaFormativa?.idAttivitaProposta
                            && a.annoInsegnamento === aInDraft.datiCorsoOffertaFormativa?.annoInsegnamento);
                    const notInInitialState = categoryInOffer?.tipoAttivitaContenute === ElementoOffertaFormativaType.CORSO ? inLastApprovedPlan?.superato : (
                        categoryInOffer?.tipoAttivitaContenute === ElementoOffertaFormativaType.ATTIVITAEXTRA ? cannotRemoveExtraFromPlan(inLastApprovedPlan) :
                            cannotRemoveTrasversalFromPlan(inLastApprovedPlan)
                    )
                    // if not in last approved plan or in initial state and current offerta doesn't allow to add proposte or cfu are different
                    if((!inLastApprovedPlan || !notInInitialState) && (
                        !configForThisYear
                        || !configForThisYear.flag_aggiunta_attivita_proposte
                        || !this.checkPresunteProposteConfigYear(configForThisYear.configurazione_attivita_proposte, aInDraft.datiCorsoOffertaFormativa?.annoInsegnamento)
                        || (configForThisYear.configurazione_attivita_proposte?.cfu && aInDraft.datiCorsoOffertaFormativa?.cfu !== configForThisYear.configurazione_attivita_proposte?.cfu)
                        || aInDraft.datiCorsoOffertaFormativa?.presenzaDeliberaCoordinatore !== configForThisYear.configurazione_attivita_proposte?.presenza_delibera_coordinatore
                        || aInDraft.datiCorsoOffertaFormativa?.tipoDocumentoSupervisore !== configForThisYear.configurazione_attivita_proposte?.tipo_documento_supervisore
                        || aInDraft.datiCorsoOffertaFormativa?.tipoDocumentoDottorando !== configForThisYear.configurazione_attivita_proposte?.tipo_documento_dottorando)){
                        activitiesToRemove.push({
                            idAttivitaProposta: aInDraft.datiCorsoOffertaFormativa?.idAttivitaProposta,
                            idCategoriaOffertaFormativa: aInDraft.datiCorsoOffertaFormativa?.idCategoriaOffertaFormativa,
                            annoInsegnamento: aInDraft.datiCorsoOffertaFormativa?.annoInsegnamento
                        });
                        console.log('Proposed not available anymore or cfu changed', aInDraft.datiCorsoOffertaFormativa?.denominazione)
                    }
                    return;
                }

                // if presunta
                if(aInDraft.datiCorsoOffertaFormativa?.idAttivitaPresunta){
                    const configForThisYear = categoryInOffer?.configurazione?.configurazioni?.find(annoRiferimentoToCheckCategoryConfigurationYearCallback(aInDraft.datiCorsoOffertaFormativa?.annoInsegnamento));
                    const inLastApprovedPlan = this.currentPianoInfoView.categorie
                        ?.find(c => c.idCategoriaOffertaFormativa === categoryInOffer?.id)
                        ?.corsi
                        ?.find(a => a.idAttivitaPresunta === aInDraft.datiCorsoOffertaFormativa?.idAttivitaPresunta
                            && a.annoInsegnamento === aInDraft.datiCorsoOffertaFormativa?.annoInsegnamento);
                    const notInInitialState = categoryInOffer?.tipoAttivitaContenute === ElementoOffertaFormativaType.CORSO ? inLastApprovedPlan?.superato : (
                        categoryInOffer?.tipoAttivitaContenute === ElementoOffertaFormativaType.ATTIVITAEXTRA ? cannotRemoveExtraFromPlan(inLastApprovedPlan) :
                            cannotRemoveTrasversalFromPlan(inLastApprovedPlan)
                    )
                    // if not in last approved plan or in initial state and current offerta doesn't allow to add proposte or cfu are different
                    if((!inLastApprovedPlan || !notInInitialState) && (
                        !configForThisYear
                        || !configForThisYear.flag_aggiunta_attivita_presunte
                        || !this.checkPresunteProposteConfigYear(configForThisYear.configurazione_attivita_presunte, aInDraft.datiCorsoOffertaFormativa?.annoInsegnamento)
                        || (configForThisYear.configurazione_attivita_presunte?.cfu && aInDraft.datiCorsoOffertaFormativa?.cfu !== configForThisYear.configurazione_attivita_presunte?.cfu)
                        || aInDraft.datiCorsoOffertaFormativa?.presenzaDeliberaCoordinatore !== configForThisYear.configurazione_attivita_presunte?.presenza_delibera_coordinatore
                        || aInDraft.datiCorsoOffertaFormativa?.tipoDocumentoSupervisore !== configForThisYear.configurazione_attivita_presunte?.tipo_documento_supervisore
                        || aInDraft.datiCorsoOffertaFormativa?.tipoDocumentoDottorando !== configForThisYear.configurazione_attivita_presunte?.tipo_documento_dottorando)){
                        activitiesToRemove.push({
                            idAttivitaPresunta: aInDraft.datiCorsoOffertaFormativa?.idAttivitaPresunta,
                            idCategoriaOffertaFormativa: aInDraft.datiCorsoOffertaFormativa?.idCategoriaOffertaFormativa,
                            annoInsegnamento: aInDraft.datiCorsoOffertaFormativa?.annoInsegnamento
                        });
                        console.log('Presumed not available anymore or cfu changed', aInDraft.datiCorsoOffertaFormativa?.denominazione)
                    }
                    return;
                }

                const inLastApprovedPlan = this.currentPianoInfoView.categorie
                    ?.find(c => c.idCategoriaOffertaFormativa === categoryInOffer?.id)
                    ?.corsi
                    ?.find(a => a.idCorsoInOffertaFormativa === aInDraft.datiCorsoOffertaFormativa?.idCorsoInOffertaFormativa
                        && a.annoInsegnamento === aInDraft.datiCorsoOffertaFormativa?.annoInsegnamento);
                const activityForYearNotArchivedInOfferOrInLastApprovedPlan = categoryInOffer?.attivitaCategoriaAssociations
                    ?.find(aInOffer => {
                        return aInOffer.attivitaOffertaFormativa?.id === aInDraft.datiCorsoOffertaFormativa?.idCorsoInOffertaFormativa
                            && annoRiferimentoToCheckActivityInCategoryYearCallback(aInDraft.datiCorsoOffertaFormativa?.annoInsegnamento)(aInOffer)
                            && ((!aInOffer.attivitaOffertaFormativa.isArchiviato && !aInOffer.isArchiviato) || inLastApprovedPlan)
                    });

                // if not presunta or proposta
                // if activity not in non archived state or in approved plan
                if(!activityForYearNotArchivedInOfferOrInLastApprovedPlan){
                    activitiesToRemove.push({
                        idCorsoInOffertaFormativa: aInDraft.datiCorsoOffertaFormativa?.idCorsoInOffertaFormativa,
                        idCategoriaOffertaFormativa: aInDraft.datiCorsoOffertaFormativa?.idCategoriaOffertaFormativa,
                        annoInsegnamento: aInDraft.datiCorsoOffertaFormativa?.annoInsegnamento,
                    });
                    console.log('Activity not available anymore', aInDraft.datiCorsoOffertaFormativa?.denominazione)
                }

            });
            body.corsi = corsiAndExtra.filter(a => !activitiesToRemove.find(aToRemove =>
                aToRemove.idCorsoInOffertaFormativa === a.idCorsoInOffertaFormativa
            ));
            body.attivitaTrasversaleList = trasversali.filter(a => !activitiesToRemove.find(aToRemove =>
                aToRemove.idCorsoInOffertaFormativa === a.idCorsoInOffertaFormativa
            ));
            body.attivitaPresuntaList = presunte.filter(a => !activitiesToRemove.find(aToRemove =>
                aToRemove.idAttivitaPresunta === a.idAttivitaPresunta
            ));
            body.attivitaPropostaList = proposte.filter(a => !activitiesToRemove.find(aToRemove =>
                aToRemove.idAttivitaProposta === a.idAttivitaProposta
            ));
        }

        if(activitiesToRemove?.length > 0){
            this.openActivitiesRemovedFromDraftDialog(activitiesToRemove, body, isCreatingBozza);
        } else {
            this.preselectMandatoryActivities(body, isCreatingBozza, true, preventExecutionOfRequestIfNothingChanged);
        }

    }

    private draftHasActivityOrOlderVersion(activitiesInDraft: (CorsoAddUpdatePianoDiStudiDTO | AttivitaTrasversaleAddUpdatePianoDiStudiDTO)[],
                                           activityInOffer: AttivitaCategoriaAssociationInfoView): boolean{
        return !!activitiesInDraft?.find(aInDraft =>
            // same idCorsoInOffertaFormativa
            aInDraft.idCorsoInOffertaFormativa === activityInOffer.attivitaOffertaFormativa?.id
            // activity in draft is older version of activityInOffer
            || activityInOffer.attivitaOffertaFormativa?.idsVersioniPrecedenti?.includes(aInDraft.idCorsoInOffertaFormativa));
    }


    private preselectMandatoryActivities(body: AddUpdatePianoDiStudiDTO, isCreatingBozza = false,
                                         saveOnNewPresentationTabBecomeActive = false,
                                         preventExecutionOfRequestIfNothingChanged = false) {
        const corsiAndExtra = body.corsi;
        const trasversali = body.attivitaTrasversaleList;

        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);

        const mandatoryToAdd = [];

        // preselect mandatory activities in offer
        this.offertaFormativa.categorie?.forEach(category => {
            category.attivitaCategoriaAssociations?.forEach(activityInCategory => {

                // skipping not mandatory or archived
                if (!activityInCategory.attivitaOffertaFormativa?.isObbligatorio
                    || activityInCategory.attivitaOffertaFormativa?.isArchiviato
                    || activityInCategory?.isArchiviato){
                    return;
                }

                // compute the minimum available year for activity
                const minAnnoAttivita = this.getMinCompatibleActivityYear(activityInCategory);

                if(category.tipoAttivitaContenute === ElementoOffertaFormativaType.CORSO || category.tipoAttivitaContenute === ElementoOffertaFormativaType.ATTIVITAEXTRA){
                    // compute if in draft, also in an older version
                    const draftHasActivityOrOlderVersion = this.draftHasActivityOrOlderVersion(corsiAndExtra, activityInCategory);

                    if (
                        // if not already in draft
                        !draftHasActivityOrOlderVersion
                        // if found a compatible year
                        && minAnnoAttivita
                    ) {
                        console.log('Preselecting mandatory cors or ext', activityInCategory)
                        corsiAndExtra.push({
                            idCategoriaOffertaFormativa: category.id,
                            idCorsoInOffertaFormativa: activityInCategory.attivitaOffertaFormativa?.id,
                            annoInsegnamento: activityInCategory?.primoAnno ? AnnoRiferimentoValues.PRIMO : (activityInCategory.secondoAnno ? AnnoRiferimentoValues.SECONDO : AnnoRiferimentoValues.TERZO)
                        });
                        mandatoryToAdd.push({
                            idCorsoInOffertaFormativa: activityInCategory.attivitaOffertaFormativa?.id,
                            idCategoriaOffertaFormativa: category.id,
                            annoInsegnamento: activityInCategory?.primoAnno ? AnnoRiferimentoValues.PRIMO : (activityInCategory.secondoAnno ? AnnoRiferimentoValues.SECONDO : AnnoRiferimentoValues.TERZO)
                        });
                    }
                } else if(category.tipoAttivitaContenute === ElementoOffertaFormativaType.ATTIVITATRASVERSALE) {

                    const draftHasActivityOrOlderVersion = this.draftHasActivityOrOlderVersion(trasversali, activityInCategory);

                    if (
                        !draftHasActivityOrOlderVersion
                        && minAnnoAttivita
                    ) {
                        console.log('Preselecting mandatory trasv', activityInCategory)
                        trasversali.push({
                            idAttivitaTrasversale: undefined, //activity is new
                            idCategoriaOffertaFormativa: category.id,
                            dettaglio: get(translation, 'study_plan_status.mandatory_activity'),
                            idCorsoInOffertaFormativa: activityInCategory.attivitaOffertaFormativa.id,
                            annoInsegnamento: activityInCategory?.primoAnno ? AnnoRiferimentoValues.PRIMO : (activityInCategory.secondoAnno ? AnnoRiferimentoValues.SECONDO : AnnoRiferimentoValues.TERZO),
                            cfuPrevisti: 0
                        });
                        mandatoryToAdd.push({
                            idCorsoInOffertaFormativa: activityInCategory.attivitaOffertaFormativa?.id,
                            idCategoriaOffertaFormativa: category.id,
                            annoInsegnamento: activityInCategory?.primoAnno ? AnnoRiferimentoValues.PRIMO : (activityInCategory.secondoAnno ? AnnoRiferimentoValues.SECONDO : AnnoRiferimentoValues.TERZO)
                        });
                    }
                }
            })
        });

        if(mandatoryToAdd?.length > 0){
            this.openNewMandatoryActivitiesAddedAutomaticallyDialog(mandatoryToAdd, body, isCreatingBozza, saveOnNewPresentationTabBecomeActive);
        } else if(isCreatingBozza) {
            this.createPlaneAsDraftRequest(body);
        } else if(!isCreatingBozza && !preventExecutionOfRequestIfNothingChanged) {
            this.saveDraftRequest(body, saveOnNewPresentationTabBecomeActive);
        } else if(preventExecutionOfRequestIfNothingChanged){
            this.loadingFirstDraftSave = false;
        }
    }

    // returns the minimum available year for the activity according student course year and offer configuration
    getMinCompatibleActivityYear(activityInCategory: AttivitaCategoriaAssociationInfoView): AnnoRiferimentoValues {
        const minAnnoAttivita = activityInCategory?.primoAnno ? AnnoRiferimentoValues.PRIMO : (activityInCategory.secondoAnno ? AnnoRiferimentoValues.SECONDO : AnnoRiferimentoValues.TERZO);
        if (this.offertaFormativa?.configurazione?.is_possibile_inserire_corsi_per_anni_successivi) {
            return minAnnoAttivita;
        } else {
            if (activityInCategory?.primoAnno && annoInsegnamentoValueToNumber(AnnoRiferimentoValues.PRIMO) <= annoInsegnamentoValueToNumber(this.studentDetailsService.studentDetails?.annoDiCorso)){
                return AnnoRiferimentoValues.PRIMO;
            }
            if (activityInCategory?.secondoAnno && annoInsegnamentoValueToNumber(AnnoRiferimentoValues.SECONDO) <= annoInsegnamentoValueToNumber(this.studentDetailsService.studentDetails?.annoDiCorso)){
                return AnnoRiferimentoValues.SECONDO;
            }
            if (activityInCategory?.terzoAnno && annoInsegnamentoValueToNumber(AnnoRiferimentoValues.TERZO) <= annoInsegnamentoValueToNumber(this.studentDetailsService.studentDetails?.annoDiCorso)){
                return AnnoRiferimentoValues.TERZO;
            }
            return undefined;
        }
    }

    createPlaneAsDraft(): void {
        this.buildBodyForDraftCreationAndExecuteRequest();
    }

    createPlaneAsDraftRequest(body: AddUpdatePianoDiStudiDTO): void {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.showLoader();
        this.studentiCicloService.addOrUpdatePianoDiStudiStudente(
            body,
            this.myIdStudente,
        ).pipe(
            takeUntil(this.destroy$),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe(
            {
                next: (value) => {
                    this.updatePianoDiStudiConfigurations.next(value ?? {});
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'common.operation_success', null),
                        type: SnackbarTypes.Success,
                    });
                },
                error: (err) => {
                    this.fuseConfirmationService.openErrorDialog({error: err},
                        this._translocoService,
                        () => {},
                        () => this.createPlaneAsDraftRequest(body),
                        'dialog.close',
                        err?.error?.message);
                }
            }
        );
    }

    buildDraftUIData(pianoDiStudi: PianoDiStudiInfoViewImpl, updateWithoutChangeSelected = false): void {
        const aggiornamento = pianoDiStudi?.aggiornamenti?.find(item => item.stato === AggiornamentoPianoDiStudiStatus.BOZZA);
        if (!!aggiornamento) {
            const activeLang = this._translocoService.getActiveLang();
            const translation = this._translocoService.getTranslation().get(activeLang);
            this.draftData = {
                dataSecondaApprovazione: !!aggiornamento?.dataSecondaApprovazione ? moment(new Date(aggiornamento?.dataSecondaApprovazione)).format('DD/MM/YYYY') : null,
                dataPrimaApprovazione: !!aggiornamento?.dataPrimaApprovazione ?
                    moment(new Date(aggiornamento?.dataPrimaApprovazione)).format('DD/MM/YYYY') : null,
                primoApprovatore: aggiornamento?.primoApprovatore,
                secondoApprovatore: aggiornamento?.secondoApprovatore,
                idPiano: pianoDiStudi?.id,
                idAggiornamento: aggiornamento?.id,
                title: aggiornamento?.stato,
                motivazioneRifiuto: aggiornamento?.motivazioneRifiuto,
                sottoruoloPrimoApprovatore: aggiornamento.sottoruoloPrimoApprovatore,
                data: !!aggiornamento?.updatedAt ? moment(new Date(aggiornamento?.updatedAt)).format('DD/MM/YYYY') : null,
                readModeCategoriesByYear: this.buildDraftInReadMode(aggiornamento, translation),
                draftInEditModeCategoriesByYear: this.buildDraftInEditMode(aggiornamento, translation, updateWithoutChangeSelected),
                presentazioneSbloccata: pianoDiStudi.presentazionePianoSbloccata,
                sottomissionePianoNonValidoSbloccata: pianoDiStudi.sottomissionePianoNonValidoSbloccata,
                modificheCount: pianoDiStudi.modificheCount,
                annoCorso: this.studentDetailsService.studentDetails?.annoDiCorso,
                isNuovaPresentazione: aggiornamento?.isNuovaPresentazione,
            };
            this.setDisabledActivitiesBecauseInAnotherYearOrCategory();
            this.setDisabledActivitiesBecauseAnotherVersionIsSelected();
            this.selectedActivitiesChanged();
            this.updateActivityCompliances();

        } else {
            this.draftData = null;
        }
    }

    private buildDraftInEditMode(aggiornamento: AggiornamentoPianoDiStudi, translation: Translation, updateWithoutChangeSelected: boolean) {
        return this.anniInsegnamentoList.map(anno => {
            const categoriesByYear = getDraftCategoriesByYearInEditMode(this.offertaFormativa, aggiornamento, anno) as TrainingOfferCategoryDataUI[];
            return {
                annoInsegnamento: anno,
                year: annoRiferimentoToRomanNumeral(anno),
                categoriesByActivityType: createEditModeCategoriesByActivityTypeConfigurations(
                    categoriesByYear,
                    translation,
                    anno,
                    (t, category, selectedAtt) => buildConfigurationForCoursesInDraftEditMode(
                        translation,
                        category,
                        aggiornamento,
                        this.currentPianoInfoView,
                        true,
                        selectedAtt
                    ),
                    (category, selectedAtt) => buildConfigurationForExtraActivitiesInNewPresentationEditMode(
                        translation,
                        category,
                        aggiornamento,
                        this.currentPianoInfoView,
                        true,
                        selectedAtt
                    ),
                    (category) => buildConfigurationForTransversalActivitiesForNewPresentationEditMode(
                        translation,
                        category,
                        aggiornamento,
                        this.currentPianoInfoView,
                        true
                    ),
                    (category) => buildConfigurationForTransversalTipologiesForNewPresentationEditMode(
                        this.offertaFormativa,
                        category,
                        this.currentPianoInfoView,
                        aggiornamento
                    ),
                    aggiornamento,
                    this.draftData,
                    updateWithoutChangeSelected
                ),
                canEditActivitiesForYear: this.canEditActivityForYear(anno),
            }
        });
    }

    private buildDraftInReadMode(aggiornamento: AggiornamentoPianoDiStudi, translation: Translation) {
        return this.anniInsegnamentoList.map(anno => {
            const categoriesByYear = getDraftCategoriesByYearInReadMode(aggiornamento, anno, this.offertaFormativa) as CategoriaInAggiornamentoPianoDiStudi[];
            return {
                annoInsegnamento: anno,
                year: annoRiferimentoToRomanNumeral(anno),
                categoriesByActivityType: createPlanCategoriesByActivityTypeConfigurations(
                    categoriesByYear,
                    translation,
                    anno,
                    (t, category) => buildConfigurationForCoursesInDraftReadMode(
                        translation,
                        category,
                        anno,
                        this.currentPianoInfoView,
                    ),
                    (category) => buildConfigurationForExtraActivitiesInNewPresentationReadMode(
                        translation,
                        category,
                        anno,
                        this.currentPianoInfoView,
                    ),
                    (category) => buildConfigurationForTransversalActivitiesForNewPresentationReadMode(
                        translation,
                        category,
                        anno,
                        this.currentPianoInfoView,
                    ),
                    true
                ),
                totaleCfu: calculateTotalCFUOfActivitiesInCategories(categoriesByYear),
                canEditActivitiesForYear: this.canEditActivityForYear(anno),
            }
        });
    }

    updateActivityCompliances(){
        this.draftData.draftInReadModeCompliance = getDraftInReadModeCompliance(this.draftData, this.offertaFormativa);
        this.draftData.draftInEditModeCompliance = getDraftInEditModeCompliance(this.draftData, this.offertaFormativa);
    }

    getLastUpdateStatus(currentPiano: PianoDiStudiInfoView): AggiornamentoPianoDiStudiStatus {
        return head(orderBy(currentPiano?.aggiornamenti,
            element => moment(new Date(element?.updatedAt)).format('YYYYMMDDHHmmss'),
            ['desc']
        ))?.stato;
    }

    openModaleCreazione(): void {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
                title: get(translation, 'study_plan_status.create_study_plan', null),
                message: get(translation, 'study_plan_status.plan_creation_message', null),
                icon: {
                    show: true,
                    name: 'edit',
                    color: 'primary'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    },
                    {
                        color: 'primary',
                        label: get(translation, 'dialog.confirm', null), icon: 'check',
                        function: () =>
                            this.createPlaneAsDraft()
                    }]
            }
        );
    }

    tryToSubmitPlan() {
        this.buildBodyDraftUpdateOrSubmissionAndExecuteRequest(TipoModificaPianoDiStudi.SOTTOMISSIONE);
    }

    private canEditActivityForYear(anno: AnnoRiferimentoValues) {
        return this.offertaFormativa?.configurazione?.is_possibile_inserire_corsi_per_anni_successivi
            || (annoInsegnamentoValueToNumber(this.studentDetailsService.studentDetails?.annoDiCorso) >= annoInsegnamentoValueToNumber(anno));
    }

    getVincoloCFU(annoInsegnamento: AnnoRiferimentoValues, activityType: ElementoOffertaFormativaType): {min: number; max: number} {
        const vincoliCfu = this.offertaFormativa?.configurazione?.vincoli_cfu_per_tipo_attivita as VincoliCfuPerTipoAttivita;
        if(!vincoliCfu){
            return {min: 0, max: 0};
        }
        let vincoloCfuMin;
        let vincoloCfuMax;
        switch (annoInsegnamento) {
            case AnnoRiferimentoValues.PRIMO:
                switch (activityType) {
                    case ElementoOffertaFormativaType.CORSO:
                        vincoloCfuMin = vincoliCfu?.cfu_primo_anno_attivita_didattiche_min;
                        vincoloCfuMax = vincoliCfu?.cfu_primo_anno_attivita_didattiche_max;
                        break;
                    case ElementoOffertaFormativaType.ATTIVITAEXTRA:
                        vincoloCfuMin = vincoliCfu?.cfu_primo_anno_altre_attivita_min;
                        vincoloCfuMax = vincoliCfu?.cfu_primo_anno_altre_attivita_max;
                        break;
                    case ElementoOffertaFormativaType.ATTIVITATRASVERSALE:
                        vincoloCfuMin = vincoliCfu?.cfu_primo_anno_attivita_dottorando_min;
                        vincoloCfuMax = vincoliCfu?.cfu_primo_anno_attivita_dottorando_max;
                        break;
                }
                break;
            case AnnoRiferimentoValues.SECONDO:
                switch (activityType) {
                    case ElementoOffertaFormativaType.CORSO:
                        vincoloCfuMin = vincoliCfu?.cfu_secondo_anno_attivita_didattiche_min;
                        vincoloCfuMax = vincoliCfu?.cfu_secondo_anno_attivita_didattiche_max;
                        break;
                    case ElementoOffertaFormativaType.ATTIVITAEXTRA:
                        vincoloCfuMin = vincoliCfu?.cfu_secondo_anno_altre_attivita_min;
                        vincoloCfuMax = vincoliCfu?.cfu_secondo_anno_altre_attivita_max;
                        break;
                    case ElementoOffertaFormativaType.ATTIVITATRASVERSALE:
                        vincoloCfuMin = vincoliCfu?.cfu_secondo_anno_attivita_dottorando_min;
                        vincoloCfuMax = vincoliCfu?.cfu_secondo_anno_attivita_dottorando_max;
                        break;
                }
                break;
            case AnnoRiferimentoValues.TERZO:
                switch (activityType) {
                    case ElementoOffertaFormativaType.CORSO:
                        vincoloCfuMin = vincoliCfu?.cfu_terzo_anno_attivita_didattiche_min;
                        vincoloCfuMax = vincoliCfu?.cfu_terzo_anno_attivita_didattiche_max;
                        break;
                    case ElementoOffertaFormativaType.ATTIVITAEXTRA:
                        vincoloCfuMin = vincoliCfu?.cfu_terzo_anno_altre_attivita_min;
                        vincoloCfuMax = vincoliCfu?.cfu_terzo_anno_altre_attivita_max;
                        break;
                    case ElementoOffertaFormativaType.ATTIVITATRASVERSALE:
                        vincoloCfuMin = vincoliCfu?.cfu_terzo_anno_attivita_dottorando_min;
                        vincoloCfuMax = vincoliCfu?.cfu_terzo_anno_attivita_dottorando_max;
                        break;
                }
                break;
        }
        return {min: vincoloCfuMin, max: vincoloCfuMax};
    }

    isMenuOpen() {
        return this.fuseNavigationService?.getComponent<FuseVerticalNavigationComponent>('mainNavigation')?.opened;
    }

    editDraft() {
        this.studentDetailsService.studyPlanDraftCreationMode = StudyPlanDraftCreationMode.EDIT;
        // when edit mode is entered, a deep copy of the current piano info are copied
        // this is done since during editing the draft is enriched with proposed and presumed activities
        // if you exit the edit mode without saving you don't want to corrupt the saved draft
        this.currentPianoInfoViewWithPropostePresunteEditing = cloneDeep(this.currentPianoInfoView);
        this.computeSelectedCFUAndActivityNumberForDraftEdit();
        this.updateActivityCompliances();
        this.yearTabSelectedIndex = 0;

        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
                message: get(translation, 'study_plan_status.edit_draft_message', null),
                icon: {
                    name: 'mat_outline:info',
                    color: 'info'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    },
                ]
            }
        );

    }

    cancelDraftEdit() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
            title: get(translation, 'study_plan_status.cancel_edit', null),
            message: get(translation, 'study_plan_status.cancel_edit_message', null),
            icon: {
                name: 'mat_outline:close',
                color: 'error'
            },
            onBackdrop: {
                show: false,
                backdrop: true
            },
            actions: [
                {
                    color: 'accent',
                    label: get(translation, 'common.close', null), icon: 'close',
                },
                {
                    color: 'primary',
                    label: get(translation, 'common.confirm', null), icon: 'check',
                    function: () => {
                        this.onCancelDraftEdit.emit();
                        this.buildDraftUIData(this.currentPianoInfoView);
                        this.studentDetailsService.studyPlanDraftCreationMode = StudyPlanDraftCreationMode.READ_ONLY;
                        this.currentPianoInfoViewWithPropostePresunteEditing = cloneDeep(this.currentPianoInfoView);
                    }
                }]
            }
        );
    }

    openSaveDraftConfirmDialog() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        const planSummary = this.buildDraftSummaryForSave();
        const data: DialogPlanSummaryI = {
            title: 'study_plan_status.save_draft',
            message: get(translation, 'study_plan_status.save_draft_message', null),
            icon: {
                name: 'mat_outline:save',
                color: 'success'
            },
            planSummaryByYears: planSummary,
            onConfirm: () => this.saveDraft(false, false, true)
        };
        this.dialog.open(DialogPlanSummaryComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            disableClose: true,
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    private saveDraft(saveOnNewPresentationTabBecomeActive = false,
                      preventRequestIfNothingChanged = false,
                      alsoOpenSubmitSuggestion = false) {
        if(saveOnNewPresentationTabBecomeActive){
            this.loadingFirstDraftSave = true;
        }
        this.buildBodyDraftUpdateOrSubmissionAndExecuteRequest(
            TipoModificaPianoDiStudi.BOZZA,
            saveOnNewPresentationTabBecomeActive,
            preventRequestIfNothingChanged,
            alsoOpenSubmitSuggestion
        );
    }

    private saveDraftRequest(body: AddUpdatePianoDiStudiDTO,
                             saveOnNewPresentationTabBecomeActive = false,
                             alsoSuggestSubmit = false) {
        this.errorOnSaveAtNewPresentationTabVisit = false;
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.showLoader();
        this.studentiCicloService.addOrUpdatePianoDiStudiStudente(
            body,
            this.myIdStudente,
        ).pipe(
            takeUntil(this.destroy$),
            finalize(() => {
                if(saveOnNewPresentationTabBecomeActive) {
                    this.loadingFirstDraftSave = false;
                }
                this.fuseConfirmationService.hideLoader();
            }),
        ).subscribe(
            {
                next: (updatedStudyPlan) => {
                    this.loadingFirstDraftSave = false;
                    this.updatePianoDiStudiConfigurations.next(updatedStudyPlan);
                    if(!saveOnNewPresentationTabBecomeActive){
                        this.studentDetailsService.studyPlanDraftCreationMode = StudyPlanDraftCreationMode.READ_ONLY;
                    }
                    if(alsoSuggestSubmit) {
                        this.openSuggestSubmissionAfterSaveDraftSuccessDialog();
                    } else if(!saveOnNewPresentationTabBecomeActive){
                        this.fuseConfirmationService.openSnackBar({
                            message: get(translation, 'common.operation_success', null),
                            type: SnackbarTypes.Success,
                        });
                    }
                },
                error: (err) => {
                    if(saveOnNewPresentationTabBecomeActive){
                        this.errorOnSaveAtNewPresentationTabVisit = true;
                        this.fuseConfirmationService.openErrorDialog({error: err},
                            this._translocoService,
                            () => this.logOutService.goToHome(),
                            () => this.saveDraftRequest(body, saveOnNewPresentationTabBecomeActive),
                            (this.currentRuolo !== AuthorityType.STUDENTE) ? 'common.go_to_home' : 'common.go_to_anagrafica',
                            err?.error?.message);
                    } else {
                        this.fuseConfirmationService.openErrorDialog({error: err},
                            this._translocoService,
                            () => {},
                            () => this.saveDraftRequest(body, saveOnNewPresentationTabBecomeActive),
                            'dialog.close',
                            err?.error?.message);
                    }

                }
            }
        );
    }

    private buildDraftSummaryForSave(): PlanSummaryByYearsI[] {

        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);

        const activities = this.draftData?.draftInEditModeCategoriesByYear
            ?.flatMap(year => year?.categoriesByActivityType
                ?.filter(c => c.activityType !== ElementoOffertaFormativaType.ATTIVITATRASVERSALE)
                ?.flatMap(type => type?.categoriesConfiguration
                    ?.flatMap(category => category?.selectedActivities
                        ?.map(s => {
                            const actTypeFormatted = category.tipoAttivitaContenute === ElementoOffertaFormativaType.CORSO ? 'common.didactic_activities' :
                                (category.tipoAttivitaContenute === ElementoOffertaFormativaType.ATTIVITAEXTRA ? 'student.extra_activities' : 'student.transversal_activities');
                            return {
                                activityDenomination:  (s.data?.idAttivitaPresunta && !s.data.denominazione) ? get(translation, 'study_plan_status.presumed_activity') : s.data?.denominazione,
                                categoryDenomination: category.denominazione,
                                categoryType: get(translation, actTypeFormatted),
                                year:  annoRiferimentoFromRomanNumeral(category.year)
                            }
                        }) ?? []
                    ) ?? []
                ) ?? []
            ) ?? [];

        const transversal = this.draftData?.draftInEditModeCategoriesByYear
            ?.flatMap(year => year?.categoriesByActivityType
                ?.filter(c => c.activityType === ElementoOffertaFormativaType.ATTIVITATRASVERSALE)
                ?.flatMap(type => type?.categoriesConfiguration
                    ?.flatMap(category => category?.attivitaCategoriaAssociations
                        ?.filter(a => !(a?.attivitaOffertaFormativa as any)?.idAttivitaProposta && !(a?.attivitaOffertaFormativa as any)?.idAttivitaPresunta)
                        ?.map(a => a.attivitaOffertaFormativa)
                        ?.map((s: any) => {
                            const actTypeFormatted = 'student.transversal_activities';
                            return {
                                activityDenomination:  ((s?.idAttivitaPresunta && !s.denominazione) ?
                                    get(translation, 'study_plan_status.presumed_activity') : s?.denominazione)
                                    + (s.dettaglioAttivitaTrasversale ? ' - ' + s.dettaglioAttivitaTrasversale : ''),
                                categoryDenomination: category.denominazione,
                                categoryType: get(translation, actTypeFormatted),
                                year:  annoRiferimentoFromRomanNumeral(category.year)
                            }
                        }) ?? []
                    ) ?? []
                ) ?? []
            ) ?? [];

        const oldPresumedAndProposed = this.draftData?.draftInEditModeCategoriesByYear
            ?.flatMap(year => year?.categoriesByActivityType
                ?.flatMap(type => type?.categoriesConfiguration
                    ?.flatMap(category => category?.attivitaCategoriaAssociations
                        ?.filter(a => (a?.attivitaOffertaFormativa as any)?.idAttivitaProposta || (a?.attivitaOffertaFormativa as any)?.idAttivitaPresunta)
                        ?.map(a => a.attivitaOffertaFormativa)
                        ?.map(a => {
                            const actTypeFormatted = category.tipoAttivitaContenute === ElementoOffertaFormativaType.CORSO ? 'common.didactic_activities' :
                                (category.tipoAttivitaContenute === ElementoOffertaFormativaType.ATTIVITAEXTRA ? 'student.extra_activities' : 'student.transversal_activities');
                            return {
                                activityDenomination:  (((a as any)?.idAttivitaPresunta && !a.denominazione) ?
                                    get(translation, 'study_plan_status.presumed_activity') : a.denominazione)
                                    + (a.descrizione ? ' - ' + a.descrizione : ''),
                                categoryDenomination: category.denominazione,
                                categoryType: get(translation, actTypeFormatted),
                                year:  annoRiferimentoFromRomanNumeral(category.year)
                            }
                        }) ?? []
                    ) ?? []
                ) ?? []
            ) ?? [];

        let allActivities = [
            ...activities,
            ...transversal,
            ...oldPresumedAndProposed,
        ];

        allActivities = sortBy(allActivities, [
            activity => activity.categoryType,
            activity => activity.activityDenomination
        ]);

        const allActivitiesByYears = [
            {year: AnnoRiferimentoValues.PRIMO, activities: [], canEditActivitiesForYear: this.draftData.draftInEditModeCategoriesByYear[0].canEditActivitiesForYear},
            {year: AnnoRiferimentoValues.SECONDO, activities: [], canEditActivitiesForYear: this.draftData.draftInEditModeCategoriesByYear[1].canEditActivitiesForYear},
            {year: AnnoRiferimentoValues.TERZO, activities: [], canEditActivitiesForYear: this.draftData.draftInEditModeCategoriesByYear[2].canEditActivitiesForYear},
        ];

        allActivities.forEach(a => {
            allActivitiesByYears?.find(aby => aby.year === a.year)?.activities?.push(a);
        })

        return allActivitiesByYears;

    }

    private isActivitySelectedInAnotherYearOrCategory(activityId: string, yearToExclude: string, typeToExclude: ElementoOffertaFormativaType, categoryToExcludeId: string) {
        let isAlreadySelected = false;
        //console.log('checking if to set disabled', activityId);
        this.draftData?.draftInEditModeCategoriesByYear?.forEach(dataByYear =>
            dataByYear.categoriesByActivityType?.forEach(dataByType =>
                dataByType.categoriesConfiguration?.forEach(catData => {
                    // not disable activities from the current category
                    if(yearToExclude === dataByYear.year && typeToExclude === dataByType.activityType && catData?.id === categoryToExcludeId){
                        console.log('SKIP DISABLING ACTIVITIES IN CAT: ', dataByYear.year, dataByType.activityType, catData.denominazione)
                        return;
                    }else if (catData.selectedActivities?.find(s => s.key === activityId)) {
                        console.log('DISABLING: activity found selected also at', dataByYear.year, dataByType.activityType, catData.denominazione)
                        isAlreadySelected = true;
                    }
                })));
        return isAlreadySelected;
    }

    private isActivityInAnotherVersionSelected(selectedActivityToCheck: UIActivityI) {
        let isAlreadySelected = false;
        //console.log('checking if to set disabled', activityId);
        this.draftData?.draftInEditModeCategoriesByYear?.forEach(dataByYear =>
            dataByYear.categoriesByActivityType?.forEach(dataByType => {
                if (dataByType.activityType !== ElementoOffertaFormativaType.ATTIVITATRASVERSALE) {
                    dataByType.categoriesConfiguration?.forEach(catData => {
                        // disable because previous version exist
                        if (catData.selectedActivities?.find(s =>
                            // this is the case before selected by user
                            s.data.corsoInOffertaFormativaIdsVersioniPrecedenti?.includes(selectedActivityToCheck?.id)
                            // this is the case after selected by user
                            ||  s.data.idsVersioniPrecedenti?.includes(selectedActivityToCheck?.id))) {
                            console.log('DISABLING: activity found previous version selected', dataByYear.year, dataByType.activityType)
                            isAlreadySelected = true;
                        }
                        // disable newer versions
                        if (catData.selectedActivities?.find(s =>
                            selectedActivityToCheck.idsVersioniPrecedenti?.includes(s.key))) {
                            console.log('DISABLING: activity found newer version selected', dataByYear.year, dataByType.activityType)
                            isAlreadySelected = true;
                        }
                    })
                }
            }));
        return isAlreadySelected;
    }

    private setDisabledActivitiesBecauseInAnotherYearOrCategory() {
        this.draftData?.draftInEditModeCategoriesByYear?.forEach(categoriesByYear =>
            categoriesByYear.categoriesByActivityType?.forEach(categoriesByType =>
                categoriesByType.categoriesConfiguration.forEach(category => {
                        if(categoriesByType.activityType !== ElementoOffertaFormativaType.ATTIVITATRASVERSALE){
                            category.activitiesToDisableIds = category.activitiesTableConfiguration?.configuration?.data
                                ?.filter(sa => this.isActivitySelectedInAnotherYearOrCategory(sa.id, categoriesByYear.year, categoriesByType.activityType, category.id))
                                ?.map(c => c.id)
                        } else {
                            category.activitiesToDisableIds = [];
                        }
                        console.log(category.denominazione, category.activitiesToDisableIds)
                    }
                )
            )
        )
    }

    // to call after set disabled because in another year
    private setDisabledActivitiesBecauseAnotherVersionIsSelected() {
        this.draftData?.draftInEditModeCategoriesByYear?.forEach(categoriesByYear =>
            categoriesByYear.categoriesByActivityType?.forEach(categoriesByType => {
                if (categoriesByType.activityType !== ElementoOffertaFormativaType.ATTIVITATRASVERSALE) {
                    categoriesByType.categoriesConfiguration.forEach(category => {
                            category.activitiesToDisableIds = [...(category.activitiesToDisableIds ?? []), ...(category.activitiesTableConfiguration?.configuration?.data
                                ?.filter(sa => this.isActivityInAnotherVersionSelected(sa))
                                ?.map(c => c.id)) ?? []]
                        }
                    )
                }
            }));
    }

    selectedActivitiesChanged() {
        this.setDisabledActivitiesBecauseInAnotherYearOrCategory();
        this.setDisabledActivitiesBecauseAnotherVersionIsSelected();
        this.computeSelectedCFUAndActivityNumberForDraftEdit();
        this.updateActivityCompliances();
    }

    private computeSelectedCFUAndActivityNumberForDraftEdit() {
    const editingDraft = this.currentPianoInfoViewWithPropostePresunteEditing?.aggiornamenti?.find(agg => agg.stato === AggiornamentoPianoDiStudiStatus.BOZZA);
        this.draftData?.draftInEditModeCategoriesByYear?.forEach(categoriesByYear => {
            categoriesByYear?.categoriesByActivityType?.forEach(categoriesByType => {
                categoriesByType?.categoriesConfiguration?.forEach(category => {
                    category.totaleCfu = calculateSelectedCFUInCategoryConf(category, editingDraft, categoriesByType.activityType, categoriesByYear.year);
                    category.numeroAttivitaSelezionate = calculateSelectedActivitiesInCategoryConf(category, editingDraft, categoriesByType.activityType, categoriesByYear.year);
                })
                categoriesByType.totaleCfu = calculateSelectedCFUInCategoriesConf(categoriesByType.categoriesConfiguration);
            })
            categoriesByYear.totaleCfu = calculateSelectedCFUInCategoriesByYear(categoriesByYear?.categoriesByActivityType);
        })
    }



    // PRESUMED AND PROPOSED ACTIVITIES

    openAddEditProposedActivityDialog(categoryConfig: TrainingOfferCategoryDataUI, activityToEdit: UIActivityI) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        let confirmLabel = get(translation, 'dialog.confirm', null);
        let cancelLabel = get(translation, 'dialog.cancel', null);
        const configProposed = categoryConfig.configurazioneForThisYear?.configurazione_attivita_proposte;
        const cfuConfig = configProposed?.cfu;
        const descCfuConfig = configProposed?.descrizione_cfu ? (get(translation, 'study_plan_status.CFU_advice') + '' + configProposed?.descrizione_cfu) : undefined;
        const data: GenericComponentDialogConfig = {
            title: activityToEdit ? 'study_plan_status.edit_proposed_activity' : 'study_plan_status.add_proposed_activity',
            subtitle: activityToEdit?.denominazione ?? activityToEdit?.descrizione,
            icon: {
                show: true,
                name: 'add',
                color: 'info'
            },
            actions: {
                confirm: {
                    show: true,
                    label: confirmLabel,
                    color: 'primary',
                    icon: 'check',
                    function: (form, dialogRef) => activityToEdit ?
                        this.editProposedActivity(form, dialogRef, categoryConfig, activityToEdit) :
                        this.addProposedActivity(form, dialogRef, categoryConfig)
                },
                cancel: {
                    show: true,
                    label: cancelLabel,
                }
            },
            dismissible: true,
            formConfig: [
                {
                    show: true,
                    name: 'denominazione',
                    required: true,
                    transloco: 'cycle_doctorate.activity_denomination',
                    type: TypeDialogFormEnum.TEXT,
                },
                {
                    show: true,
                    name: 'descrizione',
                    transloco: 'documents_dashboard.description',
                    required: false,
                    type: TypeDialogFormEnum.TEXTAREA,
                },
                {
                    show: true,
                    name: 'ore',
                    required: true,
                    transloco: 'common.hours',
                    type: TypeDialogFormEnum.NUMBER,
                    min:{ number: 1, text: get(translation, 'message.min_val_hours_error_message')},
                    onlyPositiveIntegers: true,
                },
                {
                    show: true,
                    name: 'cfu',
                    required: true,
                    disable: !!cfuConfig,
                    transloco: 'common.cfu',
                    type: TypeDialogFormEnum.NUMBER,
                    min: {
                        number: 0.1,
                        text: get(translation, 'form.cfu_min', null)
                    },
                    max: {
                        number: 60,
                        text: get(translation, 'form.cfu_max_60', null)
                    },
                    hint: descCfuConfig,
                    onlyPositiveWithOneDecimal: true,
                },
            ],
            valueForm: {
                ore: activityToEdit?.ore,
                cfu: cfuConfig ?? activityToEdit?.cfu,
                descrizione: activityToEdit?.descrizione,
                denominazione: activityToEdit?.denominazione
            }
        };
        this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: data.dismissible,
            disableClose: true,
        });
    }

    openAddEditPresumedActivityDialog(categoryConfig: TrainingOfferCategoryDataUI, activityToEdit?: UIActivityI) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        let confirmLabel = get(translation, 'dialog.confirm', null);
        let cancelLabel = get(translation, 'dialog.cancel', null);
        const configPresumed = categoryConfig.configurazioneForThisYear?.configurazione_attivita_presunte;
        const cfuConfig = configPresumed?.cfu;
        const descCfuConfig = configPresumed?.descrizione_cfu ? (get(translation, 'study_plan_status.CFU_advice') + '' + configPresumed?.descrizione_cfu) : undefined;
        const data: GenericComponentDialogConfig = {
            title: activityToEdit ? 'study_plan_status.edit_presumed_activity' : 'study_plan_status.add_presumed_activity',
            subtitle: activityToEdit?.denominazione ?? activityToEdit?.descrizione,
            icon: {
                show: true,
                name: 'add',
                color: 'info'
            },
            actions: {
                confirm: {
                    show: true,
                    label: confirmLabel,
                    color: 'primary',
                    icon: 'check',
                    function: (form, dialogRef) => activityToEdit ?
                        this.editPresumedActivity(form, dialogRef, categoryConfig, activityToEdit) :
                        this.addPresumedActivity(form, dialogRef, categoryConfig)
                },
                cancel: {
                    show: true,
                    label: cancelLabel,
                }
            },
            dismissible: true,
            formConfig: [
                {
                    show: categoryConfig.tipoAttivitaContenute !== ElementoOffertaFormativaType.CORSO,
                    name: 'defined',
                    type: TypeDialogFormEnum.TWO_OPTIONS_RADIO,
                    radioOptions: {
                        optionTrueText: get(translation, 'study_plan_status.presumed_defined_option'),
                        optionFalseText: get(translation, 'study_plan_status.presumed_not_defined_option'),
                        optionTrueSubtext: get(translation, 'study_plan_status.presumed_defined_option_message'),
                        optionFalseSubtext: get(translation, 'study_plan_status.presumed_not_defined_option_message')
                    }
                },
                {
                    show: categoryConfig.tipoAttivitaContenute !== ElementoOffertaFormativaType.CORSO,
                    name: 'denominazione',
                    requiredIf: {
                        referenceForm: 'defined',
                        referenceFormCheckValueFn: (defined) => categoryConfig.tipoAttivitaContenute !== ElementoOffertaFormativaType.CORSO && defined
                    },
                    transloco: 'cycle_doctorate.activity_denomination',
                    type: TypeDialogFormEnum.TEXT,
                    showIf: {
                        referenceForm: 'defined',
                        referenceFormValue: [true],
                    }
                },
                {
                    show: true,
                    name: 'descrizione',
                    transloco: 'documents_dashboard.description',
                    required: false,
                    type: TypeDialogFormEnum.TEXTAREA,
                },
                {
                    show: true,
                    name: 'ore',
                    required: true,
                    transloco: 'common.hours',
                    type: TypeDialogFormEnum.NUMBER,
                    min:{ number: 1, text: get(translation, 'message.min_val_hours_error_message')},
                    onlyPositiveIntegers: true,
                },
                {
                    show: true,
                    name: 'cfu',
                    disable: !!cfuConfig,
                    required: true,
                    transloco: 'common.cfu',
                    type: TypeDialogFormEnum.NUMBER,
                    min: {
                        number: 0.1,
                        text: get(translation, 'form.cfu_min', null)
                    },
                    max: {
                        number: 60,
                        text: get(translation, 'form.cfu_max_60', null)
                    },
                    hint: descCfuConfig,
                    onlyPositiveWithOneDecimal: true,
                },
            ],
            valueForm: {
                ore: activityToEdit?.ore,
                cfu: cfuConfig ?? activityToEdit?.cfu,
                defined: !!activityToEdit?.denominazione,
                descrizione: activityToEdit?.descrizione,
                denominazione: activityToEdit?.denominazione,
            }
        };
        this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            maxWidth: '150px',
            hasBackdrop: data.dismissible,
            disableClose: true,
        });
    }

    openDefinePresumedActivityDialog(categoryConfig: TrainingOfferCategoryDataUI, activityToEdit?: UIActivityI) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        let confirmLabel = get(translation, 'dialog.confirm', null);
        let cancelLabel = get(translation, 'dialog.cancel', null);
        if(categoryConfig.tipoAttivitaContenute === ElementoOffertaFormativaType.CORSO) {
           this.openDefinePresumedCourseDialog(categoryConfig, activityToEdit);
        } else {
            const data: GenericComponentDialogConfig = {
                title: 'study_plan_status.fill_presumed_activity',
                subtitle: activityToEdit.descrizione,
                icon: {
                    show: true,
                    name: 'done',
                    color: 'info'
                },
                actions: {
                    confirm: {
                        show: true,
                        label: confirmLabel,
                        color: 'primary',
                        icon: 'check',
                        function: (form, dialogRef) => this.fillPresumedActivity(form, dialogRef, categoryConfig, activityToEdit)
                    },
                    cancel: {
                        show: true,
                        label: cancelLabel,
                    }
                },
                alert: {
                    type: 'info',
                    message: get(translation, 'study_plan_status.fill_presumed_other_message', null)
                },
                dismissible: true,
                formConfig: [
                    {
                        show: true,
                        name: 'denominazione',
                        required: true,
                        transloco: 'cycle_doctorate.activity_denomination',
                        type: TypeDialogFormEnum.TEXT,
                    },
                ],
                valueForm: {
                    denominazione: activityToEdit.denominazione
                }
            };
            this.dialog.open(GenericDialogComponent, {
                data: data,
                panelClass: 'dialog-responsive-full-screen',
                maxWidth: '150px',
                hasBackdrop: data.dismissible,
                disableClose: true,
            });
        }
    }

    openDeletePresumedActivityDialog(categoryConfig: TrainingOfferCategoryDataUI, activityToDelete?: UIActivityI) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        const dialogRef = this.fuseConfirmationService.open({
                title: get(translation, 'study_plan_status.delete_presumed_activity', null),
                subtitle: activityToDelete?.denominazione ?? activityToDelete?.descrizione,
                message: get(translation, 'study_plan_status.delete_presumed_activity_message', null),
                icon: {
                    name: 'mat_outline:delete',
                    color: 'warning'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null),
                        icon: 'close',
                    },
                    {
                        color: 'primary',
                        label: get(translation, 'common.delete', null),
                        icon: 'delete',
                        function: () => this.deletePresumedActivity(dialogRef, categoryConfig, activityToDelete)
                    }]
            }
        );
    }

    openDeleteProposedActivityDialog(categoryConfig: TrainingOfferCategoryDataUI, activityToDelete?: UIActivityI) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        const dialogRef = this.fuseConfirmationService.open({
                title: get(translation, 'study_plan_status.delete_proposed_activity', null),
                subtitle: activityToDelete?.denominazione ?? activityToDelete?.descrizione,
                message: get(translation, 'study_plan_status.delete_proposed_activity_message', null),
                icon: {
                    name: 'mat_outline:delete',
                    color: 'warning'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null),
                        icon: 'close',
                    },
                    {
                        color: 'primary',
                        label: get(translation, 'common.delete', null),
                        icon: 'delete',
                        function: () => this.deleteProposedActivity(dialogRef, categoryConfig, activityToDelete)
                    }]
            }
        );
    }

    openPresumedProposedUnselectDialog(activityWithCategorY: {activity: any, category: TrainingOfferCategoryDataUI}) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        const dialogRef = this.fuseConfirmationService.open({
                title: get(translation, activityWithCategorY.activity?.idAttivitaPresunta ?
                    'study_plan_status.delete_presumed_activity' : 'study_plan_status.delete_proposed_activity' , null),
                subtitle: activityWithCategorY.activity?.denominazione ?? activityWithCategorY?.activity?.descrizione,
                message: get(translation, activityWithCategorY.activity?.idAttivitaPresunta ?
                    'study_plan_status.delete_presumed_activity_message' : 'study_plan_status.delete_proposed_activity_message', null),
                icon: {
                    name: 'mat_outline:delete',
                    color: 'warning'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null),
                        icon: 'close',
                    },
                    {
                        color: 'primary',
                        label: get(translation, 'common.delete', null),
                        icon: 'delete',
                        function: () => {
                            if(activityWithCategorY.activity?.idAttivitaPresunta) {
                                this.deletePresumedActivity(dialogRef, activityWithCategorY.category, activityWithCategorY.activity)
                            } else {
                                this.deleteProposedActivity(dialogRef, activityWithCategorY.category, activityWithCategorY.activity)
                            }
                        }
                    }]
            }
        );
    }

    private addProposedActivity(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>, category: TrainingOfferCategoryDataUI) {
        this.fuseConfirmationService.showLoader();
        const formRawValue = form.getRawValue();
        const proposedConfig = this.offertaFormativa.categorie?.find(c => c.id === category.id)
            ?.configurazione?.configurazioni?.find(conf => annoRiferimentoToCheckCategoryConfigurationYearCallback(annoRiferimentoFromRomanNumeral(category.year))(conf))
            ?.configurazione_attivita_proposte;

        const newProposedActivity: CorsoAggiornamentoPianoDiStudi = {
            datiCorsoOffertaFormativa: {
                denominazione: formRawValue.denominazione,
                descrizione: formRawValue.descrizione,
                ore: formRawValue.ore,
                cfu: +formRawValue.cfu,
                idAttivitaProposta: 'NEW_PROPOSED_' + moment().toISOString(),
                annoInsegnamento: annoRiferimentoFromRomanNumeral(category.year),
                presenzaDocumentoDottorando: proposedConfig?.presenza_documento_dottorando,
                tipoDocumentoDottorando: proposedConfig?.tipo_documento_dottorando,
                presenzaDocumentoSupervisore: proposedConfig?.presenza_documento_supervisore,
                tipoDocumentoSupervisore: proposedConfig?.tipo_documento_supervisore,
                presenzaDeliberaCoordinatore: proposedConfig?.presenza_delibera_coordinatore,
            }
        };
        let categoryToEdit = this.currentPianoInfoViewWithPropostePresunteEditing
            ?.aggiornamenti?.find(a => a.stato === AggiornamentoPianoDiStudiStatus.BOZZA)
            ?.categorie?.find(c => c.idCategoriaOffertaFormativa === category.id);

        // if category not exists in draft
        if(!categoryToEdit){
            const newCategory: CategoriaInAggiornamentoPianoDiStudi = {
                idCategoriaOffertaFormativa: category.id,
                denominazione: category.denominazione,
                descrizione: category.descrizione,
                tipoAttivitaContenute: category.tipoAttivitaContenute,
                corsi: []
            }
            this.currentPianoInfoViewWithPropostePresunteEditing
                ?.aggiornamenti?.find(a => a.stato === AggiornamentoPianoDiStudiStatus.BOZZA)
                ?.categorie?.push(newCategory);
            categoryToEdit = newCategory;
        }

        if(categoryToEdit?.corsi?.length > 0){
            categoryToEdit.corsi.push(newProposedActivity);
        } else {
            categoryToEdit.corsi = [newProposedActivity];
        }
        this.buildDraftUIData(this.currentPianoInfoViewWithPropostePresunteEditing, true);
        setTimeout(() => dialogRef.close());

        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.openSnackBar({
            message: get(translation, 'common.operation_success', null),
            type: SnackbarTypes.Success,
        });

        this.fuseConfirmationService.hideLoader();
    }

    private addPresumedActivity(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>, category: TrainingOfferCategoryDataUI) {
        this.fuseConfirmationService.showLoader();

        const presumedConfig = this.offertaFormativa.categorie?.find(c => c.id === category.id)
            ?.configurazione?.configurazioni?.find(conf => annoRiferimentoToCheckCategoryConfigurationYearCallback(annoRiferimentoFromRomanNumeral(category.year))(conf))
            ?.configurazione_attivita_presunte;

        const formRawValue = form.getRawValue();
        const newProposedActivity: CorsoAggiornamentoPianoDiStudi = {
            datiCorsoOffertaFormativa: {
                denominazione: formRawValue.denominazione,
                descrizione: formRawValue.descrizione,
                ore: formRawValue.ore,
                cfu: +formRawValue.cfu,
                idAttivitaPresunta: 'NEW_PRESUMED_' + moment().toISOString(),
                annoInsegnamento: annoRiferimentoFromRomanNumeral(category.year),
                presenzaDocumentoDottorando: presumedConfig?.presenza_documento_dottorando,
                tipoDocumentoDottorando: presumedConfig?.tipo_documento_dottorando,
                presenzaDocumentoSupervisore: presumedConfig?.presenza_documento_supervisore,
                tipoDocumentoSupervisore: presumedConfig?.tipo_documento_supervisore,
                presenzaDeliberaCoordinatore: presumedConfig?.presenza_delibera_coordinatore,
            }
        };
        let categoryToEdit = this.currentPianoInfoViewWithPropostePresunteEditing
            ?.aggiornamenti?.find(a => a.stato === AggiornamentoPianoDiStudiStatus.BOZZA)
            ?.categorie?.find(c => c.idCategoriaOffertaFormativa === category.id);

        // if category not exists in draft
        if(!categoryToEdit){
            const newCategory: CategoriaInAggiornamentoPianoDiStudi = {
                idCategoriaOffertaFormativa: category.id,
                denominazione: category.denominazione,
                descrizione: category.descrizione,
                tipoAttivitaContenute: category.tipoAttivitaContenute,
                corsi: []
            }
            this.currentPianoInfoViewWithPropostePresunteEditing
                ?.aggiornamenti?.find(a => a.stato === AggiornamentoPianoDiStudiStatus.BOZZA)
                ?.categorie?.push(newCategory);
            categoryToEdit = newCategory;
        }

        if(categoryToEdit?.corsi?.length > 0){
            categoryToEdit.corsi.push(newProposedActivity);
        } else {
            categoryToEdit.corsi = [newProposedActivity];
        }
        this.buildDraftUIData(this.currentPianoInfoViewWithPropostePresunteEditing, true);
        setTimeout(() => dialogRef.close());

        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.openSnackBar({
            message: get(translation, 'common.operation_success', null),
            type: SnackbarTypes.Success,
        });

        this.fuseConfirmationService.hideLoader();
    }

    private editPresumedActivity(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>,
                                 category: TrainingOfferCategoryDataUI, activityToEdit: UIActivityI) {
        this.fuseConfirmationService.showLoader();
        const formRawValue = form.getRawValue();
        const categoryToEdit = this.currentPianoInfoViewWithPropostePresunteEditing
            ?.aggiornamenti?.find(a => a.stato === AggiornamentoPianoDiStudiStatus.BOZZA)
            ?.categorie?.find(c => c.idCategoriaOffertaFormativa === category.id);
        const activity = categoryToEdit?.corsi?.find(a => a.datiCorsoOffertaFormativa?.idAttivitaPresunta === activityToEdit.idAttivitaPresunta);

        activity.datiCorsoOffertaFormativa.ore = +formRawValue.ore;
        activity.datiCorsoOffertaFormativa.cfu = +formRawValue.cfu;
        activity.datiCorsoOffertaFormativa.descrizione = formRawValue.descrizione;
        activity.datiCorsoOffertaFormativa.denominazione = formRawValue.denominazione;

        this.buildDraftUIData(this.currentPianoInfoViewWithPropostePresunteEditing, true);
        setTimeout(() => dialogRef.close());

        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.openSnackBar({
            message: get(translation, 'common.operation_success', null),
            type: SnackbarTypes.Success,
        });

        this.fuseConfirmationService.hideLoader();
    }

    private fillPresumedActivity(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>,
                                 category: TrainingOfferCategoryDataUI, activityToEdit: UIActivityI) {
        this.fuseConfirmationService.showLoader();
        const formRawValue = form.getRawValue();
        const categoryToEdit = this.currentPianoInfoViewWithPropostePresunteEditing
            ?.aggiornamenti?.find(a => a.stato === AggiornamentoPianoDiStudiStatus.BOZZA)
            ?.categorie?.find(c => c.idCategoriaOffertaFormativa === category.id);
        const activity = categoryToEdit?.corsi?.find(a => a.datiCorsoOffertaFormativa?.idAttivitaPresunta === activityToEdit.idAttivitaPresunta);
        activity.datiCorsoOffertaFormativa.denominazione = formRawValue.denominazione;
        this.buildDraftUIData(this.currentPianoInfoViewWithPropostePresunteEditing, true);
        setTimeout(() => dialogRef.close());

        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.openSnackBar({
            message: get(translation, 'common.operation_success', null),
            type: SnackbarTypes.Success,
        });

        this.fuseConfirmationService.hideLoader();
    }

    private deletePresumedActivity(dialogRef: MatDialogRef<FuseConfirmationDialogComponent | GenericDialogComponent>, category: TrainingOfferCategoryDataUI, activityToRemove: UIActivityI) {
        this.fuseConfirmationService.showLoader();
        const categoryToEdit = this.currentPianoInfoViewWithPropostePresunteEditing
            ?.aggiornamenti?.find(a => a.stato === AggiornamentoPianoDiStudiStatus.BOZZA)
            ?.categorie?.find(c => c.idCategoriaOffertaFormativa === category.id);
        const categoryToDeleteIndex = categoryToEdit?.corsi?.findIndex(a =>
            a.datiCorsoOffertaFormativa?.idAttivitaPresunta === activityToRemove.idAttivitaPresunta);
        categoryToEdit.corsi?.splice(categoryToDeleteIndex, 1);
        this.buildDraftUIData(this.currentPianoInfoViewWithPropostePresunteEditing, true);
        setTimeout(() => dialogRef.close());

        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.openSnackBar({
            message: get(translation, 'common.operation_success', null),
            type: SnackbarTypes.Success,
        });

        this.fuseConfirmationService.hideLoader();
    }

    private deleteProposedActivity(dialogRef: MatDialogRef<FuseConfirmationDialogComponent>, category: TrainingOfferCategoryDataUI, activityToRemove: UIActivityI) {
        this.fuseConfirmationService.showLoader();
        const categoryToEdit = this.currentPianoInfoViewWithPropostePresunteEditing
            ?.aggiornamenti?.find(a => a.stato === AggiornamentoPianoDiStudiStatus.BOZZA)
            ?.categorie?.find(c => c.idCategoriaOffertaFormativa === category.id);
        const categoryToDeleteIndex = categoryToEdit?.corsi?.findIndex(a =>
            a.datiCorsoOffertaFormativa?.idAttivitaProposta === activityToRemove.idAttivitaProposta);
        categoryToEdit.corsi?.splice(categoryToDeleteIndex, 1);
        this.buildDraftUIData(this.currentPianoInfoViewWithPropostePresunteEditing, true);
        setTimeout(() => dialogRef.close());

        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.openSnackBar({
            message: get(translation, 'common.operation_success', null),
            type: SnackbarTypes.Success,
        });

        this.fuseConfirmationService.hideLoader();
    }


    private editProposedActivity(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>,
                                 category: TrainingOfferCategoryDataUI, activityToEdit: UIActivityI) {
        this.fuseConfirmationService.showLoader();
        const formRawValue = form.getRawValue();
        const categoryToEdit = this.currentPianoInfoViewWithPropostePresunteEditing
            ?.aggiornamenti?.find(a => a.stato === AggiornamentoPianoDiStudiStatus.BOZZA)
            ?.categorie?.find(c => c.idCategoriaOffertaFormativa === category.id);
        const activity = categoryToEdit?.corsi?.find(a =>
            a.datiCorsoOffertaFormativa?.idAttivitaProposta === activityToEdit.idAttivitaProposta);
        activity.datiCorsoOffertaFormativa.ore = +formRawValue.ore;
        activity.datiCorsoOffertaFormativa.cfu = +formRawValue.cfu;
        activity.datiCorsoOffertaFormativa.denominazione = formRawValue.denominazione;
        activity.datiCorsoOffertaFormativa.descrizione = formRawValue.descrizione;
        this.buildDraftUIData(this.currentPianoInfoViewWithPropostePresunteEditing, true);
        setTimeout(() => dialogRef.close());

        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.openSnackBar({
            message: get(translation, 'common.operation_success', null),
            type: SnackbarTypes.Success,
        });

        this.fuseConfirmationService.hideLoader();
    }



    // TRANSVERSAL ACTIVITIES

    addTransversalActivity(activityAndCategory: { activity: UIActivityI; category: TrainingOfferCategoryDataUI }) {
        this.fuseConfirmationService.showLoader();
        const activity = activityAndCategory.activity;
        const category = activityAndCategory.category;
        const activityFromOffer = this.offertaFormativa?.categorie?.find(c => c.id === category.id)
            ?.attivitaCategoriaAssociations?.find(a => a.attivitaOffertaFormativa?.id === activity.idCorsoInOffertaFormativa)
            ?.attivitaOffertaFormativa;
        const newActivity: any = {
            datiCorsoOffertaFormativa: {
                ...activityFromOffer,
                idCorsoInOffertaFormativa: activity.idCorsoInOffertaFormativa,
                idAttivitaTrasversale: activity.idAttivitaTrasversale,
                dettaglioAttivitaTrasversale: activity.dettaglio,
                annoInsegnamento: annoRiferimentoFromRomanNumeral(category.year),
                tipo: ElementoOffertaFormativaType.ATTIVITATRASVERSALE,
                denominazione: activityFromOffer?.denominazione,
                idAttivitaTrasversaleNotSavedYet: activity.idAttivitaTrasversaleNotSavedYet,
                cfu: +activity.cfu
            }
        };
        let categoryToEdit = this.currentPianoInfoViewWithPropostePresunteEditing
            ?.aggiornamenti?.find(a => a.stato === AggiornamentoPianoDiStudiStatus.BOZZA)
            ?.categorie?.find(c => c.idCategoriaOffertaFormativa === category.id);

        // if category not exists in draft
        if(!categoryToEdit){
            const newCategory: CategoriaInAggiornamentoPianoDiStudi = {
                idCategoriaOffertaFormativa: category.id,
                denominazione: category.denominazione,
                descrizione: category.descrizione,
                tipoAttivitaContenute: category.tipoAttivitaContenute,
                corsi: []
            }
            this.currentPianoInfoViewWithPropostePresunteEditing
                ?.aggiornamenti?.find(a => a.stato === AggiornamentoPianoDiStudiStatus.BOZZA)
                ?.categorie?.push(newCategory);
            categoryToEdit = newCategory;
        }

        if(categoryToEdit?.corsi?.length > 0){
            categoryToEdit.corsi.push(newActivity);
        } else {
            categoryToEdit.corsi = [newActivity];
        }
        this.buildDraftUIData(this.currentPianoInfoViewWithPropostePresunteEditing, true);
        this.fuseConfirmationService.hideLoader();
    }

    editTransversalActivity(activityAndCategory: { activity: UIActivityI; category: TrainingOfferCategoryDataUI }) {
        this.fuseConfirmationService.showLoader();
        const editedActivity = activityAndCategory.activity;
        const category = activityAndCategory.category;

        const categoryToEdit = this.currentPianoInfoViewWithPropostePresunteEditing
            ?.aggiornamenti?.find(a => a.stato === AggiornamentoPianoDiStudiStatus.BOZZA)
            ?.categorie?.find(c => c.idCategoriaOffertaFormativa === category.id);
        const activity = categoryToEdit?.corsi?.find(a =>
            (editedActivity.idAttivitaTrasversale && a.datiCorsoOffertaFormativa?.idAttivitaTrasversale === editedActivity.idAttivitaTrasversale)
            || (editedActivity.idAttivitaTrasversaleNotSavedYet && (a.datiCorsoOffertaFormativa as any).idAttivitaTrasversaleNotSavedYet === editedActivity.idAttivitaTrasversaleNotSavedYet));
        const activityFromOffer = this.offertaFormativa?.categorie?.find(c => c.id === category.id)
            ?.attivitaCategoriaAssociations?.find(a => a.attivitaOffertaFormativa?.id === editedActivity.idCorsoInOffertaFormativa)
            ?.attivitaOffertaFormativa;

        activity.datiCorsoOffertaFormativa = {...activity.datiCorsoOffertaFormativa, ...activityFromOffer};
        activity.datiCorsoOffertaFormativa.idCorsoInOffertaFormativa = editedActivity.idCorsoInOffertaFormativa;
        activity.datiCorsoOffertaFormativa.idAttivitaTrasversale = editedActivity.idAttivitaTrasversale;
        activity.datiCorsoOffertaFormativa.dettaglioAttivitaTrasversale = editedActivity.dettaglio;
        activity.datiCorsoOffertaFormativa.annoInsegnamento = annoRiferimentoFromRomanNumeral(category.year);
        activity.datiCorsoOffertaFormativa.tipo = ElementoOffertaFormativaType.ATTIVITATRASVERSALE;
        activity.datiCorsoOffertaFormativa.denominazione = activityFromOffer?.denominazione;
        activity.datiCorsoOffertaFormativa.cfu = +editedActivity?.cfu;

        this.buildDraftUIData(this.currentPianoInfoViewWithPropostePresunteEditing, true);
        this.fuseConfirmationService.hideLoader();

    }

    removeTransversalActivity(activityAndCategory: {activity: UIActivityI; category: TrainingOfferCategoryDataUI}) {
        this.fuseConfirmationService.showLoader();
        const activityToRemove = activityAndCategory.activity;
        const category = activityAndCategory.category;

        const categoryToEdit = this.currentPianoInfoViewWithPropostePresunteEditing
            ?.aggiornamenti?.find(a => a.stato === AggiornamentoPianoDiStudiStatus.BOZZA)
            ?.categorie?.find(c => c.idCategoriaOffertaFormativa === category.id);
        const categoryToDeleteIndex = categoryToEdit?.corsi?.findIndex(a =>
            (activityToRemove.idAttivitaTrasversale && a.datiCorsoOffertaFormativa?.idAttivitaTrasversale === activityToRemove.idAttivitaTrasversale)
            || (activityToRemove.idAttivitaTrasversaleNotSavedYet && (a.datiCorsoOffertaFormativa as any).idAttivitaTrasversaleNotSavedYet === activityToRemove.idAttivitaTrasversaleNotSavedYet));

        categoryToEdit.corsi?.splice(categoryToDeleteIndex, 1);
        this.buildDraftUIData(this.currentPianoInfoViewWithPropostePresunteEditing, true);

        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.openSnackBar({
            message: get(translation, 'common.operation_success', null),
            type: SnackbarTypes.Success,
        });

        this.fuseConfirmationService.hideLoader();
    }

    private openDefinePresumedCourseDialog(categoryConfig: TrainingOfferCategoryDataUI, activityToEdit?: UIActivityI) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        // cloning config, setting single choice
        const tableConfigForChoosing = cloneDeep(categoryConfig.activitiesTableConfiguration);
        tableConfigForChoosing.configuration.singleChoiceSelection = true;
        tableConfigForChoosing.configuration.configurazioneTabella = categoryConfig.activitiesTableConfiguration.configuration.configurazioneTabella; //needed for state
        // taking only not selected not presumed not proposed
        tableConfigForChoosing.configuration.data = categoryConfig.activitiesTableConfiguration.configuration.data
            ?.filter(a =>
                !categoryConfig.selectedActivities.find(selectedActivity => selectedActivity.key === a.id)
                && !a.idAttivitaPresunta
                && !a.idAttivitaProposta
            );
        const data: DialogDefinePresumedI = {
            title: 'study_plan_status.fill_presumed_activity',
            subtitle: activityToEdit.descrizione,
            icon: {
                name: 'done',
                color: 'info'
            },
            onConfirm: (activity) => this.definePresumedCourse(activity, categoryConfig, activityToEdit),
            activitiesConf: tableConfigForChoosing,
            activitiesToDisableIds: categoryConfig.activitiesToDisableIds,
            onTableClickAction: (event) => this.tableClickAction.emit(event)
        };
        this.dialog.open(DialogDefinePresumedComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            minWidth: '95vw',
            hasBackdrop: true,
            disableClose: true,
        });
    }

    private definePresumedCourse(activity: UIActivityI, categoryConfig: TrainingOfferCategoryDataUI, activityToEdit: UIActivityI) {
        categoryConfig.selectedActivities = [
            ...categoryConfig.selectedActivities,
            { key: activity.id, data: activity}
        ];

        const categoryToEdit = this.currentPianoInfoViewWithPropostePresunteEditing
            ?.aggiornamenti?.find(a => a.stato === AggiornamentoPianoDiStudiStatus.BOZZA)
            ?.categorie?.find(c => c.idCategoriaOffertaFormativa === categoryConfig.id);

        const categoryToDeleteIndex = categoryToEdit?.corsi?.findIndex(a =>
            a.datiCorsoOffertaFormativa?.idAttivitaPresunta === activityToEdit.idAttivitaPresunta);
        categoryToEdit.corsi?.splice(categoryToDeleteIndex, 1);

        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.openSnackBar({
            message: get(translation, 'common.operation_success', null),
            type: SnackbarTypes.Success,
        });

        this.buildDraftUIData(this.currentPianoInfoViewWithPropostePresunteEditing, true);
    }

    private openActivitiesRemovedFromDraftDialog(activitiesToRemove: {
                                                     idCorsoInOffertaFormativa?: string,
                                                     idAttivitaPresunta?: string,
                                                     idAttivitaProposta?: string,
                                                     idCategoriaOffertaFormativa: string,
                                                     annoInsegnamento: AnnoRiferimentoValues
                                                 }[],
                                                 body: AddUpdatePianoDiStudiDTO,
                                                 isCreatingBozza = false) {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        const draft = this.currentPianoInfoView?.aggiornamenti?.find(agg => agg.stato === AggiornamentoPianoDiStudiStatus.BOZZA);
        const activitiesToRemoveMapped: FuseConfirmationDialogListDataI[] = activitiesToRemove.map(actToRemove => {
            const category = draft.categorie.find(c => c.idCategoriaOffertaFormativa === actToRemove.idCategoriaOffertaFormativa);
            let mainText = '';
            if(actToRemove.idCorsoInOffertaFormativa){
                mainText = category?.corsi?.find(a => a.datiCorsoOffertaFormativa?.idCorsoInOffertaFormativa === actToRemove.idCorsoInOffertaFormativa)?.datiCorsoOffertaFormativa?.denominazione;
            } else if (actToRemove.idAttivitaPresunta){
                mainText = get(translation, 'study_plan_status.presumed_activity');
            } else if (actToRemove.idAttivitaProposta){
                mainText = get(translation, 'study_plan_status.proposed_activity');
            }
            let type = '';
            if (category.tipoAttivitaContenute === ElementoOffertaFormativaType.CORSO){
                type = get(translation, 'common.didactic_activities');
            } else if (category.tipoAttivitaContenute === ElementoOffertaFormativaType.ATTIVITAEXTRA){
                type = get(translation, 'student.extra_activities');
            }else if (category.tipoAttivitaContenute === ElementoOffertaFormativaType.ATTIVITATRASVERSALE){
                type = get(translation, 'student.transversal_activities');
            }
            return {
                mainText: mainText,
                secondaryText: category?.denominazione + ', ' + annoRiferimentoToRomanNumeral(actToRemove.annoInsegnamento) + ' ' + get(translation, 'common.year'),
                chipText: type
            }
        })
        this.fuseConfirmationService.open({
                message: get(translation, 'study_plan_status.activities_removed_because_not_in_offer', null),
                icon: {
                    show: true,
                    name: 'mat_outline:warning',
                    color: 'warning'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                listData: activitiesToRemoveMapped,
                actions: [
                    {
                        color: 'primary',
                        label: get(translation, 'dialog.confirm', null),
                        icon: 'save',
                        function: () => this.preselectMandatoryActivities(body, isCreatingBozza),
                    },
                ]
            },
        );
    }


    private openNewMandatoryActivitiesAddedAutomaticallyDialog(mandatoryToAdd: {idCorsoInOffertaFormativa: string, idCategoriaOffertaFormativa: string, annoInsegnamento: AnnoRiferimentoValues}[],
                                                               body: AddUpdatePianoDiStudiDTO,
                                                               isCreatingBozza = false,
                                                               saveOnNewPresentationTabBecomeActive = false) {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        const activitiesToAddMapped: FuseConfirmationDialogListDataI[] = mandatoryToAdd.map(actToAdd => {
            const category = this.offertaFormativa.categorie.find(c => c.id === actToAdd.idCategoriaOffertaFormativa);
            let type = '';
            if (category.tipoAttivitaContenute === ElementoOffertaFormativaType.CORSO){
                type = get(translation, 'common.didactic_activities');
            } else if (category.tipoAttivitaContenute === ElementoOffertaFormativaType.ATTIVITAEXTRA){
                type = get(translation, 'student.extra_activities');
            }else if (category.tipoAttivitaContenute === ElementoOffertaFormativaType.ATTIVITATRASVERSALE){
                type = get(translation, 'student.transversal_activities');
            }
            return {
                mainText: category?.attivitaCategoriaAssociations?.find(a => a.attivitaOffertaFormativa?.id === actToAdd.idCorsoInOffertaFormativa)?.attivitaOffertaFormativa?.denominazione,
                secondaryText: category?.denominazione + ', ' + annoRiferimentoToRomanNumeral(actToAdd.annoInsegnamento) + ' ' + get(translation, 'common.year')    ,
                chipText: type
            }
        });
        this.fuseConfirmationService.open({
                message: get(translation, 'study_plan_status.activities_added_because_mandatory', null),
                icon: {
                    show: true,
                    name: 'mat_outline:warning',
                    color: 'warning'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                listData: activitiesToAddMapped,
                actions: [
                    {
                        color: 'primary',
                        label: get(translation, 'dialog.confirm', null),
                        icon: 'save',
                        function: () => {
                            if(isCreatingBozza){
                                this.createPlaneAsDraftRequest(body)
                            } else {
                                this.saveDraftRequest(body, saveOnNewPresentationTabBecomeActive);
                            }
                        },
                    },
                ]
            },
        );
    }


    protected readonly isNull = isNull;
    protected readonly isNil = isNil;

    private openSuggestSubmissionAfterSaveDraftSuccessDialog() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
                title: get(translation, 'common.operation_success', null),
                message: get(translation, 'study_plan_status.save_draft_confirm_message', null),
                icon: {
                    name: 'mat_outline:done',
                    color: 'success'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    }
                ]
            }
        );

    }


    protected readonly AuthorityType = AuthorityType;
}
