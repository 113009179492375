import {Component, Inject, OnInit, Pipe} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogWrapI} from "../../../../layout/common/dialog-wrap/dialog-wrap.component";
import {AuthorityType, DettaglioCalcoloImportoMobilita} from "../../../../../api-clients/generated/services";
import {isEmpty} from "lodash";


export enum TypeInfo {
    TEXTAREA = 'TEXTAREA',
    TEXT = 'TEXT',
    CHIP = 'CHIP',
    SECTION = 'SECTION',
    AMOUNT = 'AMOUNT'
}

export interface DialogMobilitaDashboardInfoI extends DialogWrapI {
    info?: Info[];
    readonlyData?: { key: string; value: any; style?: string ; hide?: boolean; pipe?: Pipe, showVisualizzaButton?: boolean, buttonAction?: () => void}[];
    dettaglioImporto?: DettaglioCalcoloImportoMobilita
}

export interface Info {
    nameGroup: string;
    children: { key: string; value?: any; flex?: string; type?: TypeInfo }[];

}

@Component({
    selector: 'app-dialog-mobilita-dashboard-info',
    templateUrl: './dialog-mobilita-dashboard-info.component.html',
    styleUrls: ['./dialog-mobilita-dashboard-info.component.scss']
})
export class DialogMobilitaDashboardInfoComponent implements OnInit {
    type = TypeInfo;

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogMobilitaDashboardInfoI,
                public dialogRef: MatDialogRef<DialogMobilitaDashboardInfoComponent>) {

    }

    ngOnInit(): void {

    }

    closeDialog() {
        this.dialogRef.close();
    }


    protected readonly AuthorityType = AuthorityType;
    protected readonly isEmpty = isEmpty;
}
