/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Enum Italia o Estero.
 */
export type ItaliaEsteroEnum = 'ITALIA' | 'ESTERO';

export const ItaliaEsteroEnum = {
    ITALIA: 'ITALIA' as ItaliaEsteroEnum,
    ESTERO: 'ESTERO' as ItaliaEsteroEnum
};