/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { EtichettaTicketResponseDTO } from '../model/etichettaTicketResponseDTO';
import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { OpenTicketRequestDTO } from '../model/openTicketRequestDTO';
import { OpenTicketResponseDTO } from '../model/openTicketResponseDTO';
import { PageTicketDTO } from '../model/pageTicketDTO';
import { ReplyTicketRequestDTO } from '../model/replyTicketRequestDTO';
import { TicketDetailsDTO } from '../model/ticketDetailsDTO';
import { TicketPriority } from '../model/ticketPriority';
import { TicketStatus } from '../model/ticketStatus';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class TicketsService {

    protected basePath = 'https://api-dev-vanvitelli.southengineering.it/dottorandi-backend';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Download allegato.
     * 
     * @param ticketId identificivo del ticket
     * @param attachmentId identificivo dell&#x27;allegato
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadThreadAttachment(ticketId: string, attachmentId: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public downloadThreadAttachment(ticketId: string, attachmentId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public downloadThreadAttachment(ticketId: string, attachmentId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public downloadThreadAttachment(ticketId: string, attachmentId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ticketId === null || ticketId === undefined) {
            throw new Error('Required parameter ticketId was null or undefined when calling downloadThreadAttachment.');
        }

        if (attachmentId === null || attachmentId === undefined) {
            throw new Error('Required parameter attachmentId was null or undefined when calling downloadThreadAttachment.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/v1/ticket/${encodeURIComponent(String(ticketId))}/attachment/${encodeURIComponent(String(attachmentId))}`,
            {
             responseType: "blob" as "json",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Download zip di allegati del thread.
     * 
     * @param ticketId identificivo del ticket
     * @param threadId identificivo del thread
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadZipThreadAttachments(ticketId: string, threadId: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public downloadZipThreadAttachments(ticketId: string, threadId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public downloadZipThreadAttachments(ticketId: string, threadId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public downloadZipThreadAttachments(ticketId: string, threadId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ticketId === null || ticketId === undefined) {
            throw new Error('Required parameter ticketId was null or undefined when calling downloadZipThreadAttachments.');
        }

        if (threadId === null || threadId === undefined) {
            throw new Error('Required parameter threadId was null or undefined when calling downloadZipThreadAttachments.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/v1/ticket/${encodeURIComponent(String(ticketId))}/attachments-zip/${encodeURIComponent(String(threadId))}`,
            {
             responseType: "blob" as "json",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce la lista delle etichette.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getListaEtichetteTicket(observe?: 'body', reportProgress?: boolean): Observable<Array<EtichettaTicketResponseDTO>>;
    public getListaEtichetteTicket(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EtichettaTicketResponseDTO>>>;
    public getListaEtichetteTicket(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EtichettaTicketResponseDTO>>>;
    public getListaEtichetteTicket(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<EtichettaTicketResponseDTO>>('get',`${this.basePath}/api/v1/ticket/etichette`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il dettaglio di un ticket.
     * 
     * @param ticketId identificivo del ticket
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTicketDetails(ticketId: string, observe?: 'body', reportProgress?: boolean): Observable<TicketDetailsDTO>;
    public getTicketDetails(ticketId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TicketDetailsDTO>>;
    public getTicketDetails(ticketId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TicketDetailsDTO>>;
    public getTicketDetails(ticketId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ticketId === null || ticketId === undefined) {
            throw new Error('Required parameter ticketId was null or undefined when calling getTicketDetails.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TicketDetailsDTO>('get',`${this.basePath}/api/v1/ticket/${encodeURIComponent(String(ticketId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce la lista paginata di Tickets con i filtri applicati.
     * 
     * @param search 
     * @param type 
     * @param status 
     * @param priority 
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTicketsForm(search?: string, type?: string, status?: TicketStatus, priority?: TicketPriority, page?: number, size?: number, observe?: 'body', reportProgress?: boolean): Observable<PageTicketDTO>;
    public getTicketsForm(search?: string, type?: string, status?: TicketStatus, priority?: TicketPriority, page?: number, size?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageTicketDTO>>;
    public getTicketsForm(search?: string, type?: string, status?: TicketStatus, priority?: TicketPriority, page?: number, size?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageTicketDTO>>;
    public getTicketsForm(search?: string, type?: string, status?: TicketStatus, priority?: TicketPriority, page?: number, size?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (search !== undefined) {
            formParams = formParams.append('search', <any>search) as any || formParams;
        }
        if (type !== undefined) {
            formParams = formParams.append('type', <any>type) as any || formParams;
        }
        if (status !== undefined) {
            formParams = formParams.append('status', <any>status) as any || formParams;
        }
        if (priority !== undefined) {
            formParams = formParams.append('priority', <any>priority) as any || formParams;
        }

        return this.httpClient.request<PageTicketDTO>('post',`${this.basePath}/api/v1/ticket/search`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Apre un nuovo ticket.
     * 
     * @param openTicketRequest 
     * @param multipartFile1 
     * @param multipartFile2 
     * @param multipartFile3 
     * @param multipartFile4 
     * @param multipartFile5 
     * @param logFile 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public openNewTicketForm(openTicketRequest?: OpenTicketRequestDTO, multipartFile1?: Blob, multipartFile2?: Blob, multipartFile3?: Blob, multipartFile4?: Blob, multipartFile5?: Blob, logFile?: Blob, observe?: 'body', reportProgress?: boolean): Observable<OpenTicketResponseDTO>;
    public openNewTicketForm(openTicketRequest?: OpenTicketRequestDTO, multipartFile1?: Blob, multipartFile2?: Blob, multipartFile3?: Blob, multipartFile4?: Blob, multipartFile5?: Blob, logFile?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OpenTicketResponseDTO>>;
    public openNewTicketForm(openTicketRequest?: OpenTicketRequestDTO, multipartFile1?: Blob, multipartFile2?: Blob, multipartFile3?: Blob, multipartFile4?: Blob, multipartFile5?: Blob, logFile?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OpenTicketResponseDTO>>;
    public openNewTicketForm(openTicketRequest?: OpenTicketRequestDTO, multipartFile1?: Blob, multipartFile2?: Blob, multipartFile3?: Blob, multipartFile4?: Blob, multipartFile5?: Blob, logFile?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {








        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (openTicketRequest !== undefined) {
            formParams = formParams.append('openTicketRequest', new Blob([JSON.stringify(openTicketRequest)], { type: 'application/json'})) as any || formParams;
        }
        if (multipartFile1 !== undefined) {
            formParams = formParams.append('multipartFile1', <any>multipartFile1) as any || formParams;
        }
        if (multipartFile2 !== undefined) {
            formParams = formParams.append('multipartFile2', <any>multipartFile2) as any || formParams;
        }
        if (multipartFile3 !== undefined) {
            formParams = formParams.append('multipartFile3', <any>multipartFile3) as any || formParams;
        }
        if (multipartFile4 !== undefined) {
            formParams = formParams.append('multipartFile4', <any>multipartFile4) as any || formParams;
        }
        if (multipartFile5 !== undefined) {
            formParams = formParams.append('multipartFile5', <any>multipartFile5) as any || formParams;
        }
        if (logFile !== undefined) {
            formParams = formParams.append('logFile', <any>logFile) as any || formParams;
        }

        return this.httpClient.request<OpenTicketResponseDTO>('post',`${this.basePath}/api/v1/ticket/open`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Ticket reply. Usata dall&#x27;utente per rispondere ad un ticket esistente NON CHIUSO
     * 
     * @param ticketId identificivo del ticket
     * @param openTicketRequest 
     * @param multipartFile1 
     * @param multipartFile2 
     * @param multipartFile3 
     * @param multipartFile4 
     * @param multipartFile5 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public replyTicketForm(ticketId: string, openTicketRequest?: ReplyTicketRequestDTO, multipartFile1?: Blob, multipartFile2?: Blob, multipartFile3?: Blob, multipartFile4?: Blob, multipartFile5?: Blob, observe?: 'body', reportProgress?: boolean): Observable<TicketDetailsDTO>;
    public replyTicketForm(ticketId: string, openTicketRequest?: ReplyTicketRequestDTO, multipartFile1?: Blob, multipartFile2?: Blob, multipartFile3?: Blob, multipartFile4?: Blob, multipartFile5?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TicketDetailsDTO>>;
    public replyTicketForm(ticketId: string, openTicketRequest?: ReplyTicketRequestDTO, multipartFile1?: Blob, multipartFile2?: Blob, multipartFile3?: Blob, multipartFile4?: Blob, multipartFile5?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TicketDetailsDTO>>;
    public replyTicketForm(ticketId: string, openTicketRequest?: ReplyTicketRequestDTO, multipartFile1?: Blob, multipartFile2?: Blob, multipartFile3?: Blob, multipartFile4?: Blob, multipartFile5?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ticketId === null || ticketId === undefined) {
            throw new Error('Required parameter ticketId was null or undefined when calling replyTicket.');
        }







        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (openTicketRequest !== undefined) {
            formParams = formParams.append('openTicketRequest', new Blob([JSON.stringify(openTicketRequest)], { type: 'application/json'})) as any || formParams;
        }
        if (multipartFile1 !== undefined) {
            formParams = formParams.append('multipartFile1', <any>multipartFile1) as any || formParams;
        }
        if (multipartFile2 !== undefined) {
            formParams = formParams.append('multipartFile2', <any>multipartFile2) as any || formParams;
        }
        if (multipartFile3 !== undefined) {
            formParams = formParams.append('multipartFile3', <any>multipartFile3) as any || formParams;
        }
        if (multipartFile4 !== undefined) {
            formParams = formParams.append('multipartFile4', <any>multipartFile4) as any || formParams;
        }
        if (multipartFile5 !== undefined) {
            formParams = formParams.append('multipartFile5', <any>multipartFile5) as any || formParams;
        }

        return this.httpClient.request<TicketDetailsDTO>('post',`${this.basePath}/api/v1/ticket/${encodeURIComponent(String(ticketId))}/reply`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Mette il ticket in stato RISOLTO.
     * 
     * @param ticketId identificivo del ticket
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setTicketResolved(ticketId: string, observe?: 'body', reportProgress?: boolean): Observable<TicketDetailsDTO>;
    public setTicketResolved(ticketId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TicketDetailsDTO>>;
    public setTicketResolved(ticketId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TicketDetailsDTO>>;
    public setTicketResolved(ticketId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ticketId === null || ticketId === undefined) {
            throw new Error('Required parameter ticketId was null or undefined when calling setTicketResolved.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TicketDetailsDTO>('put',`${this.basePath}/api/v1/ticket/${encodeURIComponent(String(ticketId))}/resolved`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
