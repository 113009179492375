/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Esiti possibili di un operazione. Valori previsti: *SUCCESS*, *ERROR*
 */
export type EsitoOperazione = 'SUCCESS' | 'ERROR';

export const EsitoOperazione = {
    SUCCESS: 'SUCCESS' as EsitoOperazione,
    ERROR: 'ERROR' as EsitoOperazione
};