// @ts-ignore
import {PathEnum} from '../app/app.routing';

export const environment = {
    name: 'vanvitelli-prod',
    production: true,
    servicesUrl: 'https://api-atena-phd.unicampania.it/dottorandi-backend',
    servicesKeycloak: {
        redirectUri: 'https://atena-phd.unicampania.it/'+PathEnum.SIGN_IN,
        idpLogout: 'https://idp.unicampania.it',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        client_secret: 'EHs1hnYP7XYSGIN5d7psrdhDCwE4tBNl',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        client_id: 'univ-vanvitelli-cli',
        basePath: 'https://iam-atena-phd.unicampania.it/',
        realmName: 'univ-vanvitelli-realm'
    },
    errorsQueueCapacity: 5,
    showStatisticsInHome: false,
    recaptcha: {
        siteKey: '6LcHZNopAAAAAKcYs6_3KTByFgJVwzN_qKqTdNh2'
    },
    page: {
        showAnvur: false,
    },
};
