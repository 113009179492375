import {Component, OnInit} from '@angular/core';
import {
    AteneoService,
    GetManualePiattaformaDTO,
    ManualePiattaformaInfoViewImpl
} from "../../../../../../api-clients/generated/services";
import {delay, distinctUntilChanged, finalize, merge, Subscription, switchMap, take, takeUntil} from "rxjs";
import {filter} from "rxjs/operators";
import {get} from "lodash";
import {SnackbarTypes} from "../../../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {openFileInBlankWindow} from "../../../../../shared/utils/utils";
import {PathEnum} from "../../../../../app.routing";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {TranslocoService} from "@ngneat/transloco";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {Router} from "@angular/router";

@Component({
    selector: 'app-support-menu',
    templateUrl: './support-menu.component.html',
    styleUrls: ['./support-menu.component.scss']
})
export class SupportMenuComponent extends AbstractDefaultComponent implements OnInit {

    manuali: ManualePiattaformaInfoViewImpl[];
    loadingManuals: boolean;
    errorLoadingManuals: boolean;
    manualsRequestSubscription: Subscription;

    constructor(private appInitService: AppInitService,
                private translocoService: TranslocoService,
                private ateneoService: AteneoService,
                protected fuseConfirmationService: FuseConfirmationService,
                private router: Router,
    ) {
        super();
    }

    ngOnInit(): void {
        merge(
            this.appInitService.cicloCorsoRuoloSelected$.pipe(
                distinctUntilChanged((c1, c2) =>
                    c1?.ciclo === c2?.ciclo && c1?.ruolo === c2?.ruolo && c1?.codiceCorsoStudi === c2?.codiceCorsoStudi),
                takeUntil(this.destroy$),
            ),
            this.translocoService.langChanges$.pipe(
                distinctUntilChanged(),
                takeUntil(this.destroy$)
            )
        ).subscribe(
            value => {
                this.getManualiRequest();
            }
        );
    }

    getManualiRequest() {
        this.loadingManuals = true;
        this.errorLoadingManuals = false;
        this.manuali = [];
        this.manualsRequestSubscription?.unsubscribe();
        this.manualsRequestSubscription = this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            delay(500),
            switchMap(() => this.ateneoService.getManualiPiattaforma()),
            takeUntil(this.destroy$),
        ).subscribe({
            next: (manuali: ManualePiattaformaInfoViewImpl[]) => {
                console.log('Obtained manuali list:', manuali);
                this.loadingManuals = false
                this.errorLoadingManuals = false;
                this.manuali = manuali;
            },
            error: (err) => {
                this.loadingManuals = false
                this.errorLoadingManuals = true;
                this.manuali = [];
            }
        });
    }

    openManualeRequest(manuale: ManualePiattaformaInfoViewImpl) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        const requestBody: GetManualePiattaformaDTO = {
            nomeFile: manuale.url
        }
        this.fuseConfirmationService.showLoader();
        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.ateneoService.downloadManualePiattaforma(requestBody)),
            takeUntil(this.destroy$),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe(
            {
                next: (file: Blob) => {
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'budget.file_show_success', null),
                        type: SnackbarTypes.Success,
                    });
                    const fileNameToDownload = manuale.url?.split('/')?.pop();
                    openFileInBlankWindow(file, fileNameToDownload);
                },
                error: (err) => {
                    console.error(err);
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'student.file_download_error', null),
                        type: SnackbarTypes.Warning,
                        error: err
                    });
                }
            }
        );
    }

    goToUVDeskPage() {
        if (this.appInitService.isAreaAdministrator) {
            this.router.navigate([PathEnum.ADMINISTRATOR, PathEnum.SUPPORT]);
        } else {
            this.router.navigate([PathEnum.SUPPORT]);
        }
    }

}
