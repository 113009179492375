import {Injectable} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {LocalStorageService} from './local-storage.service';
import {BehaviorSubject, map, Observable} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {
    AuthorityType, MobilitaRequestSelectFormValuesDTO,  PeriodoDiMobilitaStudenteStatus,
    SottoruoloUtenteUserView, StatoTemporaleEnum, TipoPeriodoEnum,
    UserSottoruoloView
} from '../../../api-clients/generated/services';


export interface DelibereFilters {
    nominativoStudente?: string,
    codiceFiscaleStudente?: string,
    matricolaStudente?: string,
    numeroDelibera?: string,
    dataDelibera?: string,
}

@Injectable({
    providedIn: 'root'
})
export class DelibereFiltersManagerService {

    mainFormGroup: FormGroup | undefined;
    checkApplyFilterClick$ = new BehaviorSubject(false);
    currentSottoRuolo: AuthorityType | undefined;
    filterApplied$= new BehaviorSubject(undefined);
    defaultFormPathList = [
        'nominativoStudente',
        'codiceFiscaleStudente',
        'matricolaStudente',
        'numeroDelibera',
        'dataDelibera',
    ];
    fieldsLabelMap = new Map<string, string>([
        ['nominativoStudente', 'mobility.student_nominative'],
        ['codiceFiscaleStudente', 'mobility.cf_student'],
        ['numeroDelibera', 'documents_dashboard.approval_number'],
        ['dataDelibera', 'documents_dashboard.approval_date'],
        ['matricolaStudente', 'mobility.enrollment_number'],
    ]);


    constructor(private localStorageService: LocalStorageService,
                private formBuilder: FormBuilder) {
    }

    init(): void {
        const sottoruolo = this.localStorageService.dipartimentoRuoloCiclo?.sottoruolo;
        if((this.currentSottoRuolo !== sottoruolo) || !this.currentSottoRuolo) {
            this.currentSottoRuolo = sottoruolo;
            if(!!this.mainFormGroup) {
                this.mainFormGroup.reset();
            } else {
                this.mainFormGroup = this.createDefaultFormGroup();
            }
        }
    }

    createDefaultFormGroup(): FormGroup {
        return this.formBuilder.group({
            nominativoStudente: null,
            codiceFiscaleStudente: null,
            numeroDelibera: null,
            dataDelibera: null,
            matricolaStudente: null,
        });
    }

    getCtrlByPath(path: string): FormControl<any> {
        return this.mainFormGroup?.get(path) as FormControl;
    }


    getFieldsByRole(ruolo?: AuthorityType, sottoruolo?: AuthorityType): string[] {
        switch(ruolo) {
            default:
                switch (sottoruolo) {
                    default:
                        return this.defaultFormPathList;
                }
        }


    }

    appliedFiltersObsevable$(): Observable<any> {
        return this.checkApplyFilterClick$.asObservable().pipe(
            filter(Boolean),
            map(() => this.mainFormGroup?.value)
        );
    }

}
