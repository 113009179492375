<ng-container *transloco="let t">


    <app-dialog-wrap [data]="data"
                     [class.readonly-all]="showMiniLoader"
                     (close$)="closeDialog()">

            <div class="flex flex-col py-2 gap-2 w-full">

                <mat-stepper class="w-full sticky-stepper"
                             [linear]="true"
                             #stepper>

                    <mat-step [stepControl]="selectCategoryForm">
                        <form [formGroup]="selectCategoryForm">
                            <ng-template matStepLabel>{{ t('training_offer.select_category') }}
                            </ng-template>
                            <fuse-alert
                                class="w-full"
                                [appearance]="'outline'"
                                [type]="'info'"
                                [showIcon]="true">
                                {{ t('training_offer.move_activity_message') }}
                            </fuse-alert>
                            <app-select-category
                                [form]="selectCategoryForm"
                                [categories]="data.filteredCategories">
                            </app-select-category>
                            <div class="flex flex-row justify-between w-full div-button-sticky">
                                <app-custom-button
                                    [type]="ButtonType.PREVIOUS">
                                </app-custom-button>
                                <app-custom-button
                                    (click)="checkSelectionValid()"
                                    [type]="ButtonType.NEXT">
                                </app-custom-button>
                            </div>
                        </form>
                    </mat-step>
                    <mat-step [stepControl]="formGroupYears">
                        <form [formGroup]="formGroupYears">
                            <ng-template matStepLabel>{{ t('training_offer.year_of_activity') }}</ng-template>
                            <div class="flex flex-col gap-3 items-center mb-2 years-div">

                                <div class="w-full flex flex-col break-words gap-1"
                                     style="word-break: break-word;">
                                    <div class="text-center w-full font-semibold text-lg">{{data.actToMove?.attivitaOffertaFormativa?.denominazione}}</div>
                                </div>

                                <div class="text-center">
                                    {{ t('training_offer.select_activity_years') }}
                                </div>


                                <div class="flex flex-row gap-6 items-center">
                                    <ng-container *ngIf="showPrimoAnno">
                                        <div class="flex flex-row items-center"
                                             *ngIf="formGroupYears.get('primoAnno') as fieldCtrl">
                                            <mat-checkbox [formControlName]="'primoAnno'"
                                                          color="primary">
                                            </mat-checkbox>
                                            <mat-label><strong>{{ t('study_plan_status.first_year') }}</strong>
                                            </mat-label>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="showSecondoAnno">
                                        <div class="flex flex-row items-center"
                                             *ngIf="formGroupYears.get('secondoAnno') as fieldCtrl">
                                            <mat-checkbox [formControlName]="'secondoAnno'"
                                                          color="primary">
                                            </mat-checkbox>
                                            <mat-label><strong>{{ t('study_plan_status.second_year') }}</strong>
                                            </mat-label>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="showTerzoAnno">
                                        <div class="flex flex-row items-center"
                                             *ngIf="formGroupYears.get('terzoAnno') as fieldCtrl">
                                            <mat-checkbox [formControlName]="'terzoAnno'"
                                                          color="primary">
                                            </mat-checkbox>
                                            <mat-label><strong>{{ t('study_plan_status.third_year') }}</strong>
                                            </mat-label>
                                        </div>
                                    </ng-container>
                                </div>

                                <mat-error class="text-sm"
                                    *ngIf="formGroupYears.errors?.yearsRequired && (formGroupYears?.dirty || formGroupYears?.touched)">
                                    {{ t('training_offer.year_required') }}
                                </mat-error>

                            </div>
                            <div class="flex flex-row justify-between w-full div-button-sticky">
                                <app-custom-button
                                    [disabled]="showMiniLoader"
                                    [type]="ButtonType.PREVIOUS"
                                >
                                </app-custom-button>
                                <app-custom-button
                                    [loading]="showMiniLoader"
                                    (clickHandler)="saveBySelect()"
                                    [type]="ButtonType.CONFIRM"
                                >
                                </app-custom-button>
                            </div>
                        </form>
                    </mat-step>

                </mat-stepper>

            </div>

    </app-dialog-wrap>


</ng-container>

