<ng-container *transloco="let t">
    <div class="flex flex-col flex-auto w-full pb-2.5 pl-2.5 pr-2.5" *ngIf="!!getMainFormGroup()"
         #mainContainer>
        <!-- FORMS -->
        <form [formGroup]="mobilitaFilterService.mainFormGroup" (ngSubmit)="applyFilters()" (keydown.tab)="$event.stopPropagation()">


        <!-- TITLE -->
        <div style="background-color: #ffffff; z-index: 1000;" #titleDiv
             class="flex flex-col sm:flex-row gap-3 sm:justify-between pr-2.5"
             [style]="{'width': (mainContainer?.offsetWidth-10)+'px !important'}">
            <div class="mt-4 text-2xl font-medium ml-2">
                <strong>{{t('menu_filter.advanced_search')}}</strong>
            </div>

            <div class="pt-2 pb-2 grid grid-cols-1 sm:grid-cols-2 gap-3">

                <!-- BUTTONS-->
                <button mat-flat-button
                        (click)="reset()"
                        type="button"
                        [color]="'accent'">
                    <mat-icon class="mr-2">delete</mat-icon>
                    <span>{{t('menu_filter.reset')}}</span>
                </button>

                <button mat-flat-button
                        type="submit"
                        [color]="'primary'">
                    <mat-icon class="mr-2">search</mat-icon>
                    <span>{{t('home.apply')}}</span>
                </button>
            </div>
            <mat-divider class="sm:hidden"></mat-divider>
        </div>

        <div style="overflow: auto">
           <div class="grid grid-cols-1 grid-rows-none sm:grid-cols-2 gap-3">
                <ng-container *ngFor="let field of currentFields">

                    <ng-container *ngIf="field !== _dataFineNull
                                    && field !== _stato
                                    && field !== _codiceNazioneStruttura
                                    && field !== _codiceTipologiaMobilita
                                    && field !== _codiceTipologiaBonus
                                    && field !== _periodoDa
                                    && field !== _periodoA
                                    && field !== _statoSvolgimento
                                    && field !== _dataUltimaModifica">
                        <mat-form-field class="w-full" appearance="outline">
                            <mat-label>{{t(fieldsLabelMap.get(field))}}</mat-label>
                            <input matInput
                                   [formControl]="getFormControlByPath(field)"
                                   (blur)="getFormControlByPath(field).setValue(getFormControlByPath(field)?.value?.trim())">
                        </mat-form-field>
                    </ng-container>

                    <mat-form-field class="w-full mat-button-datapicker add-mobilita-mat-form-field" appearance="outline"
                                    *ngIf="field === _periodoDa || field === _periodoA">
                        <mat-label>{{t(fieldsLabelMap.get(field))}}</mat-label>
                        <input matInput
                               [placeholder]="t(fieldsLabelMap.get(field))"
                               [value]="dateValue(getFormControlByPath(field))"
                               readonly
                               [formControlName]="field"
                               (dateInput)="dateEvent($event, getFormControlByPath(field), false)"
                               [matDatepicker]="picker1"
                        >
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-icon matSuffix class="cursor-pointer pr-1"
                                  (click)="clearDateField(getFormControlByPath(field))"
                                  *ngIf="getFormControlByPath(field).value !== '' && !!getFormControlByPath(field).value"
                                  color="warn"
                                  svgIcon="mat_outline:cancel"></mat-icon>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>

                    <ng-container *ngIf="field === _stato">
                        <mat-form-field appearance="outline">
                            <mat-label>{{t(fieldsLabelMap.get(field))}}</mat-label>
                            <mat-select [formControl]="getFormControlByPath(field)">
                                <mat-option [value]="null">{{t('common.all')}}</mat-option>
                                <mat-option *ngFor="let stato of Object.values(PeriodoDiMobilitaStudenteStatus)"
                                            [value]="stato">
                                    {{t(stato | statusEnumToLanguagePath: PeriodoDiMobilitaStudenteStatus) | uppercase}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>

                    <ng-container *ngIf="field === _statoSvolgimento">
                        <mat-form-field appearance="outline">
                            <mat-label>{{t(fieldsLabelMap.get(field))}}</mat-label>
                            <mat-select [formControl]="getFormControlByPath(field)">
                                <mat-option [value]="null">{{t('common.all')}}</mat-option>
                                <mat-option *ngFor="let stato of Object.values(StatoTemporaleEnum)"
                                            [value]="stato">
                                    {{stato | removeUnderscores | uppercase}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>

                    <ng-container *ngIf="field === _codiceNazioneStruttura">
                        <mat-form-field appearance="outline">
                            <mat-label>{{t(fieldsLabelMap.get(field))}}</mat-label>
                            <mat-select [formControl]="getFormControlByPath(field)">
                                <mat-option [value]="null">{{t('common.all')}}</mat-option>
                                <mat-option *ngFor="let nazione of selectValues?.nazioniEstere"
                                            [value]="nazione?.codice">
                                    {{(currentLanguage | async) === 'it' ? nazione?.descrizioneIt : nazione?.descrizione | removeUnderscores | uppercase}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>

                    <ng-container *ngIf="field === _codiceTipologiaMobilita">
                        <mat-form-field appearance="outline">
                            <mat-label>{{t(fieldsLabelMap.get(field))}}</mat-label>
                            <mat-select [formControl]="getFormControlByPath(field)">
                                <mat-option [value]="null">{{t('common.all')}}</mat-option>
                                <mat-option *ngFor="let tipologia of selectValues?.tipologieMobilita"
                                            [value]="tipologia?.codice">
                                    {{tipologia.descrizione}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>

                    <ng-container *ngIf="field === _codiceTipologiaBonus">
                        <mat-form-field appearance="outline">
                            <mat-label>{{t(fieldsLabelMap.get(field))}}</mat-label>
                            <mat-select [formControl]="getFormControlByPath(field)">
                                <mat-option [value]="null">{{t('common.all')}}</mat-option>
                                <mat-option *ngFor="let tipoBonus of selectValues?.tipologieBonus"
                                            [value]="tipoBonus?.numeroTipologiaBonus">
                                    {{tipoBonus?.descrizioneTipologiaBonus}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>

                    <ng-container *ngIf="field === _dataUltimaModifica">
                        <mat-form-field appearance="outline" class="datetime-form-field">
                            <mat-label>{{t(fieldsLabelMap.get(field))}}</mat-label>
                            <input matInput type="datetime-local"
                                   [formControl]="getFormControlByPath(field)">
                        </mat-form-field>
                    </ng-container>


                </ng-container>
            </div>

        </div>

            <mat-divider *ngIf="!!currentFields &&  (currentFields?.includes(_dataFineNull))" ></mat-divider>

            <div style="overflow: hidden">
                <div class="grid grid-cols-1 grid-rows-none sm:grid-cols-2 gap-x-3 gap-y-5 items-center justify-center px-2 py-5">
                    <ng-container *ngFor="let field of currentFields">

                        <ng-container *ngIf="field === _dataFineNull">
                            <div class="flex flex-row items-center pt-4">
                                <mat-slide-toggle class="m-1" color="primary" [formControl]="getFormControlByPath(field)">
                                </mat-slide-toggle>
                                <span>{{ t(fieldsLabelMap.get(field)) }}</span>
                            </div>
                        </ng-container>

                    </ng-container>
                </div>

            </div>

        </form>
    </div>
</ng-container>
