import {isEmpty} from "lodash";
import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {
    AbstractDefaultComponent
} from "../../abstracts/abstract-default-component/abstract-default-component";
import {
    AttivitaOffertaFormativaInfoView, StudenteCicloMotivoStatus,
    StudenteCicloStatus,
    UserSottoruoloView,
    UsersViewInfoView
} from "api-clients/generated/services";
import {FormControl, FormGroup} from "@angular/forms";
import {AppInitService} from "app/shared/service/app-init.service";
import {distinctUntilChanged, take, takeUntil, tap} from "rxjs";
import {LocalStorageService} from "../../service/local-storage.service";
import {capitalizeString, getStudenteMotiviForStatus} from "../../utils/utils";
import {StudentiBarFilterManagerService} from "../../service/studenti-bar-filter-manager.service";

@Component({
    selector: 'app-studenti-bar-filter-container',
    templateUrl: './studenti-bar-filter-container.component.html',
    styleUrls: ['./studenti-bar-filter-container.component.scss']
})
export class StudentiBarFilterContainerComponent extends AbstractDefaultComponent implements OnInit {
    @Output() closeBar: EventEmitter<boolean> = new EventEmitter<boolean>();
    mainForm: FormGroup | undefined;
    currentFields: Array<string> = [];
    fieldsLabelMap: Map<string, string>;
    _codiceFiscaleSupervisore = 'codiceFiscaleSupervisore';
    _codiceFiscaleCosupervisore = 'codiceFiscaleCosupervisore';
    _codiceFiscaleDocenteCorso = 'codiceFiscaleDocenteCorso';
    _statoSpesaStudente = 'statoSpesaStudente';
    _mobilitaDaApprovare = 'mobilitaDaApprovare';
    _codiceTipoBorsaPerCiclo = 'codiceTipoBorsaPerCiclo'
    _statoUltimoAggiornamentoPianoDiStudi = 'statoUltimoAggiornamentoPianoDiStudi';
    _hasNotDeliberaInizioAttivita = 'hasNotDeliberaInizioAttivita';
    _hasNotSupervisore = 'hasNotSupervisore';
    _hasNotCosupervisore = 'hasNotCosupervisore';
    _codiceFiscale = 'codiceFiscale';
    _statoCarriera = 'statoCarriera';
    _statoCarrieraMotivazione = 'statoCarrieraMotivazione';
    _hasNumGiorniMobilitaInferioreAlMinimoPrevisto = 'hasNumGiorniMobilitaInferioreAlMinimoPrevisto';
    _hasNumGiorniMobilitaSuperioreAlPrevisto = 'hasNumGiorniMobilitaSuperioreAlPrevisto';
    _hasMissioniDaApprovare = 'hasMissioniDaApprovare';
    cosupervisoreList: UserSottoruoloView[];
    supervisoreList: UserSottoruoloView[];
    docenteList: UserSottoruoloView[];
    corsiDocenteCorsoList: AttivitaOffertaFormativaInfoView[];
    filterApplied = {
        'nominativo': null,
        'ateneoProvenienza': null,
        'nazionalita': null,
        'codiceFiscaleSupervisore': null, //select
        'ssdSupervisore': null,
        'afferenzaOrganizzativaSupervisore': null,
        'codiceFiscaleCosupervisore': null, //select
        'ssdCosupervisore': null,
        'afferenzaOrganizzativaCosupervisore': null,
        'codiceFiscaleDocenteCorso': null,
        'statoSpesaStudente': null,
        'statoUltimoAggiornamentoPianoDiStudi': null,
        'mobilitaDaApprovare': null,
        'codiceTipoBorsaPerCiclo': null,
        'hasNotDeliberaInizioAttivita': null,
        'hasNotSupervisore': null,
        'hasNotCosupervisore': null,
        'codiceFiscale': null,
        'statoCarriera': null,
        'statoCarrieraMotivazione': null,
        'hasNumGiorniMobilitaInferioreAlMinimoPrevisto': null,
        'hasNumGiorniMobilitaSuperioreAlPrevisto': null,
        'hasMissioniDaApprovare': null,
    };
    filterApp: AttivitaOffertaFormativaInfoView[];

    constructor(public filterManagerService: StudentiBarFilterManagerService,
                private appInitService: AppInitService,
                private localStorageService: LocalStorageService) {
        super();
        this.currentFields = this.filterManagerService.defaultFormPathList;
        this.fieldsLabelMap = this.filterManagerService.fieldsLabelMap;

    }

    ngOnInit(): void {
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            take(1),
            distinctUntilChanged(),
            tap(() => console.log('Aggiorna filtri')),
            tap(value =>
                this.currentFields = this.filterManagerService.getFieldsByRole(value?.ruolo, value?.sottoruolo)),
            takeUntil(this.destroy$)
        ).subscribe();

        this.localStorageService.checkCurrentSottoRuoloSelected$.asObservable().pipe(
            tap(() => this.filterManagerService.init()),
            tap(value =>
                this.currentFields = this.filterManagerService.getFieldsByRole(
                    this.localStorageService?.dipartimentoRuoloCiclo?.ruolo,
                    value)
            ),
            takeUntil(this.destroy$)
        ).subscribe();

        this.filterManagerService.checkAggiornamentoSupervisori$.asObservable().pipe(
            tap(() => this.supervisoreList = this.filterManagerService.codiceFiscaleSupervisoreList),
            takeUntil(this.destroy$)
        ).subscribe();

        this.filterManagerService.checkAggiornamentoCosupervisori$.asObservable().pipe(
            tap(() => this.cosupervisoreList = this.filterManagerService.codiceFiscaleCosupervisoreList),
            takeUntil(this.destroy$)
        ).subscribe();

        this.filterManagerService.checkAggiornamentoDocenti$.asObservable().pipe(
            tap(() => this.docenteList = this.filterManagerService.codiceFiscaleDocenteList),
            takeUntil(this.destroy$)
        ).subscribe();

        this.filterManagerService.filterApplied$.asObservable().pipe(
            tap((value) => this.filterApplied = value),
            takeUntil(this.destroy$)
        ).subscribe();

    }

    getFormControlByPath(path: string): FormControl<any> {
        return this.filterManagerService.getCtrlByPath(path);
    }

    getMainFormGroup(): FormGroup<any> {
        return this.filterManagerService.mainFormGroup;
    }

    applyFilters(): void {
        //this.filterApplied= this.getMainFormGroup().getRawValue();
        // take mainformgroup raw value, and for multiple select fields if the array is empty patch the value of the form with undefined
        const formValue = this.getMainFormGroup().getRawValue();
        for (const key of Object.keys(formValue)) {
            if (Array.isArray(formValue[key]) && formValue[key].length === 0) {
                this.getMainFormGroup().patchValue({[key]: null});
            }
        }
        this.filterManagerService.checkApplyFilterClick$.next(true);
        this.close();
    }

    close(): void {
        this.closeBar.next(true);
    }


    reset(): void {
        this.resetFilterApplied();
        this.getMainFormGroup().reset();
        this.filterManagerService?.checkApplyFilterClick$.next(true);
        this.close();
    }

    setFilterApplied(): void {
        if (!!this.filterApplied) {
            if (!!this.filterApplied?.codiceFiscaleSupervisore && this.filterApplied?.codiceFiscaleSupervisore?.nome && this.filterApplied?.codiceFiscaleSupervisore?.cognome) {
                this.filterApplied.codiceFiscaleSupervisore.nome = capitalizeString(this.filterApplied.codiceFiscaleSupervisore?.nome);
                this.filterApplied.codiceFiscaleSupervisore.cognome = capitalizeString(this.filterApplied.codiceFiscaleSupervisore?.cognome);
            }
            // eslint-disable-next-line max-len
            if (!!this.filterApplied?.codiceFiscaleCosupervisore && this.filterApplied?.codiceFiscaleCosupervisore?.nome && this.filterApplied?.codiceFiscaleCosupervisore?.cognome) {
                this.filterApplied.codiceFiscaleCosupervisore.nome = capitalizeString(this.filterApplied.codiceFiscaleCosupervisore?.nome);
                this.filterApplied.codiceFiscaleCosupervisore.cognome = capitalizeString(this.filterApplied.codiceFiscaleCosupervisore?.cognome);
            }
            if (!!this.filterApplied?.codiceFiscaleDocenteCorso && this.filterApplied?.codiceFiscaleDocenteCorso?.nome && this.filterApplied.codiceFiscaleDocenteCorso?.cognome) {
                this.filterApplied.codiceFiscaleDocenteCorso.nome = capitalizeString(this.filterApplied.codiceFiscaleDocenteCorso?.nome);
                this.filterApplied.codiceFiscaleDocenteCorso.cognome = capitalizeString(this.filterApplied.codiceFiscaleDocenteCorso?.cognome);
            }
        }

        if (!!this.filterApplied && !isEmpty(this.filterApplied)) {
            this.filterManagerService.mainFormGroup.setValue(
                {
                    'nominativo': this.filterApplied.nominativo,
                    'ateneoProvenienza': this.filterApplied.ateneoProvenienza,
                    'nazionalita': this.filterApplied.nazionalita,
                    'codiceFiscaleSupervisore': this.filterApplied.codiceFiscaleSupervisore,  //select
                    'ssdSupervisore': this.filterApplied.ssdSupervisore,
                    'afferenzaOrganizzativaSupervisore': this.filterApplied.afferenzaOrganizzativaSupervisore,
                    'codiceFiscaleCosupervisore': this.filterApplied.codiceFiscaleCosupervisore, //select
                    'ssdCosupervisore': this.filterApplied.ssdCosupervisore,
                    'afferenzaOrganizzativaCosupervisore': this.filterApplied.afferenzaOrganizzativaCosupervisore,
                    'codiceFiscaleDocenteCorso': this.filterApplied.codiceFiscaleDocenteCorso,//select
                    'statoSpesaStudente': this.filterApplied.statoSpesaStudente, //checkbox
                    'statoUltimoAggiornamentoPianoDiStudi': this.filterApplied.statoUltimoAggiornamentoPianoDiStudi, //checkbox
                    'mobilitaDaApprovare': this.filterApplied.mobilitaDaApprovare, //checkbox
                    'codiceTipoBorsaPerCiclo': this.filterApplied.codiceTipoBorsaPerCiclo,
                    'hasNotDeliberaInizioAttivita': this.filterApplied.hasNotDeliberaInizioAttivita,
                    'hasNotSupervisore': this.filterApplied.hasNotSupervisore,
                    'hasNotCosupervisore': this.filterApplied.hasNotCosupervisore,
                    'codiceFiscale': this.filterApplied.codiceFiscale,
                    'statoCarriera': this.filterApplied.statoCarriera,
                    'statoCarrieraMotivazione': this.filterApplied.statoCarrieraMotivazione,
                    'hasNumGiorniMobilitaInferioreAlMinimoPrevisto': this.filterApplied.hasNumGiorniMobilitaInferioreAlMinimoPrevisto,
                    'hasNumGiorniMobilitaSuperioreAlPrevisto': this.filterApplied.hasNumGiorniMobilitaSuperioreAlPrevisto,
                    'hasMissioniDaApprovare': this.filterApplied.hasMissioniDaApprovare,
                }
            );
        }
    }

    resetFilterApplied(): void {
        this.filterApplied = {
            'nominativo': null,
            'ateneoProvenienza': null,
            'nazionalita': null,
            'codiceFiscaleSupervisore': null, //select
            'ssdSupervisore': null,
            'afferenzaOrganizzativaSupervisore': null,
            'codiceFiscaleCosupervisore': null, //select
            'ssdCosupervisore': null,
            'afferenzaOrganizzativaCosupervisore': null,
            'codiceFiscaleDocenteCorso': null, //select
            'statoSpesaStudente': null,
            'statoUltimoAggiornamentoPianoDiStudi': null,
            'mobilitaDaApprovare': null,
            'codiceTipoBorsaPerCiclo': null,
            'hasNotDeliberaInizioAttivita': null,
            'hasNotSupervisore': null,
            'hasNotCosupervisore': null,
            'codiceFiscale': null,
            'statoCarriera': null,
            'statoCarrieraMotivazione': null,
            'hasNumGiorniMobilitaInferioreAlMinimoPrevisto': null,
            'hasNumGiorniMobilitaSuperioreAlPrevisto': null,
            'hasMissioniDaApprovare': null,
        };
        this.filterManagerService.mainFormGroup.reset();
    }

    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    public objectComparisonFunctionByCodiceFiscale = function (option: UsersViewInfoView, value: UsersViewInfoView): boolean {
        return option?.codiceFiscale === value?.codiceFiscale;
    }

    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    public objectComparisonFunctionById = function (option: AttivitaOffertaFormativaInfoView, value: AttivitaOffertaFormativaInfoView): boolean {
        return option?.id === value?.id;
    }
    protected readonly StudenteCicloStatus = StudenteCicloStatus;
    protected readonly Object = Object;
    protected readonly StudenteCicloMotivoStatus = StudenteCicloMotivoStatus;
    protected readonly getStudenteMotiviForStatus = getStudenteMotiviForStatus;

    statoCarrieraFilterChange() {
        this.getFormControlByPath(this._statoCarrieraMotivazione).patchValue(null);
    }

}
