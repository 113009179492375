<ng-container *transloco="let t">

    <!-- Button -->
    <button
        mat-icon-button
        [matMenuTriggerFor]="userActions">
        <span class="relative">
            <img
                alt="Avatar image"
                class="w-7 h-7 rounded-full"
                *ngIf="showAvatar && avatar"
                [src]="ctrlAvatar.value">
                <span class="relative"     *ngIf="(!showAvatar || !avatar)">
                  <div
                       class="w-7 h-7 flex flex-0 items-center justify-center rounded-full overflow-hidden">
                          <ng-container>
                                 <div
                                     class="flex items-center justify-center w-full h-full rounded-full text-md uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                                <span *ngIf="!!profile">  {{(profile?.cognome?.charAt(0) + profile?.nome?.charAt(0)?.toUpperCase() || '' ) || ''}}</span>
                                </div>
                          </ng-container>
                  </div>
        </span>
        </span>
    </button>

    <mat-menu

        [xPosition]="'before'"
        #userActions="matMenu"
        class="menu-background mat-menu-user">
        <span class="flex flex-col px-4 py-2">
            <span style="white-space: nowrap; width: 250px; overflow: hidden; text-overflow: ellipsis;"
                  class="mt-1 text-md font-medium color-white">
                {{profile?.mail}}
            </span>
        </span>
        <ng-container *ngIf="!fuseConfirmationService.errorDuringAppInit">
            <mat-divider class="my-2"></mat-divider>
            <button class="color-white" mat-menu-item (click)="navigateToProfile()">
                <mat-icon class="color-white" [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
                <span>{{t('common.profile')}}</span>
            </button>
            <button class="color-white" mat-menu-item (click)="goToStudentPage()" *ngIf="appInitService.cicloCorsoRuoloSelected?.ruolo === AuthorityType.STUDENTE">
                <mat-icon class="color-white" [svgIcon]="'heroicons_outline:user'" ></mat-icon>
                <span>{{t('common.my_page')}}</span>
            </button>
            <button class="color-white" mat-menu-item (click)="goToGestionale()" *ngIf="showGoToGestionale">
                <mat-icon class="color-white" [svgIcon]="'school'" ></mat-icon>
                <span>{{t('choose_area.go_to_area_management')}}</span>
            </button>
            <button class="color-white" mat-menu-item (click)="goToAmministrazione()" *ngIf="showGoToAmministrazione">
                <mat-icon class="color-white" [svgIcon]="'admin_panel_settings'" ></mat-icon>
                <span>{{t('choose_area.go_to_area_administrator')}}</span>
            </button>
        </ng-container>
        <mat-divider class="mt-2 mb-0"></mat-divider>
        <button
            class="bg-accent py-2"
            mat-menu-item
            (click)="signOut()">
            <mat-icon class="color-black">
                logout
            </mat-icon>
            <div class="color-black font-semibold">{{t('dialog.logout')}}</div>
        </button>
    </mat-menu>

</ng-container>
