/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Ruoli di un utente all'interno del collegio docenti. Valori previsti: *COORDINATORE*, *COMPONENTE*
 */
export type CollegioDocentiRuolo = 'COORDINATORE' | 'COMPONENTE';

export const CollegioDocentiRuolo = {
    COORDINATORE: 'COORDINATORE' as CollegioDocentiRuolo,
    COMPONENTE: 'COMPONENTE' as CollegioDocentiRuolo
};