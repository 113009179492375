/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Stati di un periodo di registro attività. Valori previsti: *INSERITO*, *APPROVATO_PARZIALE*, *APPROVATO*, *RIFIUTATO*
 */
export type PeriodoRegistroAttivitaStatus = 'BOZZA' | 'INSERITO' | 'APPROVATO_PARZIALE' | 'APPROVATO' | 'RIFIUTATO';

export const PeriodoRegistroAttivitaStatus = {
    BOZZA: 'BOZZA' as PeriodoRegistroAttivitaStatus,
    INSERITO: 'INSERITO' as PeriodoRegistroAttivitaStatus,
    APPROVATOPARZIALE: 'APPROVATO_PARZIALE' as PeriodoRegistroAttivitaStatus,
    APPROVATO: 'APPROVATO' as PeriodoRegistroAttivitaStatus,
    RIFIUTATO: 'RIFIUTATO' as PeriodoRegistroAttivitaStatus
};