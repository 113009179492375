import {Component, OnInit} from '@angular/core';
import {AppInitService} from '../../../shared/service/app-init.service';

@Component({
    selector: 'app-terms-and-conditions',
    templateUrl: './terms-and-conditions.component.html',
})
export class TermsAndConditionsComponent implements OnInit {

    /**
     * Constructor
     */
    constructor(private appInitService: AppInitService ) {

    }

    ngOnInit(): void {

    }
}
