import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {tap} from 'rxjs/operators';
import {distinctUntilChanged, take, takeUntil} from 'rxjs';

import {isEmpty} from "lodash";
import {AutorizzazioniFilterManagerService} from "../../service/autorizzazioni-filter-manager.service";
import {LocalStorageService} from 'app/shared/service/local-storage.service';
import {AppInitService} from 'app/shared/service/app-init.service';
import {CorsoDiStudiViewImpl, StudenteCicloMotivoStatus, StudenteCicloStatus} from 'api-clients/generated/services';
import {AbstractDefaultComponent} from "../../abstracts/abstract-default-component/abstract-default-component";
import {getStudenteMotiviForStatus} from "../../utils/utils";

@Component({
    selector: 'app-autorizzazioni-sidebar-filter-container',
    templateUrl: './autorizzazioni-sidebar-filter-container.component.html',
    styleUrls: ['./autorizzazioni-sidebar-filter-container.component.scss']
})
export class AutorizzazioniSidebarFilterContainerComponent extends AbstractDefaultComponent implements OnInit {
    @Output() closeDrawer: EventEmitter<boolean> = new EventEmitter<boolean>();

    mainForm: FormGroup | undefined;
    currentFields: Array<string> = [];
    fieldsLabelMap: Map<string, string>;
    filterApplied = {
        'nominativo': null,
        'ateneoProvenienza': null,
        'nazionalita': null,
        'statoCarriera': null,
        'statoCarrieraMotivazione': null,
    };
    _statoCarriera = "statoCarriera";
    _statoCarrieraMotivazione = "statoCarrieraMotivazione";
    corsi: Array<CorsoDiStudiViewImpl>;

    constructor(public filterManagerService: AutorizzazioniFilterManagerService,
                private appInitService: AppInitService,
                private localStorageService: LocalStorageService,) {
        super();
        this.currentFields = this.filterManagerService.defaultFormPathList;
        this.fieldsLabelMap = this.filterManagerService.fieldsLabelMap;
    }

    ngOnInit(): void {
        const selectedInService$ = this.appInitService.isAreaAdministrator ? this.appInitService.isCicloAmmSelectedInService : this.appInitService.isDipartimentoRuoloCicloSelectedInService;
        selectedInService$.pipe(
            take(1),
            distinctUntilChanged(),
            tap(() => console.log('Aggiorna filtri')),
            tap(value => {
                    this.currentFields = this.filterManagerService.getFieldsByRole(value?.ruolo, value?.sottoruolo, this.appInitService.isAreaAdministrator)
                    if (this.appInitService.isAreaAdministrator) {
                        this.corsi = this.appInitService.getCorsiAmm();
                    }
                }
            ),
            takeUntil(this.destroy$)
        ).subscribe();

        if (!this.appInitService.isAreaAdministrator) {
            this.localStorageService.checkCurrentSottoRuoloSelected$.asObservable().pipe(
                tap(() => this.filterManagerService.init()),
                tap(value =>
                    this.currentFields = this.filterManagerService.getFieldsByRole(
                        this.localStorageService?.dipartimentoRuoloCiclo?.ruolo,
                        value)
                ),
                takeUntil(this.destroy$)
            ).subscribe();
        }

    }

    getFormControlByPath(path: string): FormControl<any> {
        return this.filterManagerService.getCtrlByPath(path);
    }

    getMainFormGroup(): FormGroup<any> {
        return this.filterManagerService.mainFormGroup;
    }

    applyFilters(): void {
        //this.filterApplied= this.getMainFormGroup().getRawValue();
        // take mainformgroup raw value, and for multiple select fields if the array is empty patch the value of the form with undefined
        const formValue = this.getMainFormGroup().getRawValue();
        for (const key of Object.keys(formValue)) {
            if (Array.isArray(formValue[key]) && formValue[key].length === 0) {
                this.getMainFormGroup().patchValue({[key]: null});
            }
        }
        this.filterManagerService.checkApplyFilterClick$.next(true);
        console.log(this.filterManagerService.mainFormGroup?.getRawValue())
        this.close();
    }

    close(): void {
        this.closeDrawer.next(true);
    }


    reset(): void {
        this.resetFilterApplied();
        this.getMainFormGroup().reset();
        this.filterManagerService?.checkApplyFilterClick$.next(true);
        this.close();
    }

    setFilterApplied(): void {
        if (!!this.filterApplied && !isEmpty(this.filterApplied)) {
            this.filterManagerService.mainFormGroup.setValue(
                {
                    'nominativo': this.filterApplied.nominativo,
                    'ateneoProvenienza': this.filterApplied.ateneoProvenienza,
                    'nazionalita': this.filterApplied.nazionalita,
                    'statoCarriera': this.filterApplied.statoCarriera,
                    'statoCarrieraMotivazione': this.filterApplied.statoCarrieraMotivazione,
                }
            );
        }
    }

    resetFilterApplied(): void {
        this.filterApplied = {
            'nominativo': null,
            'ateneoProvenienza': null,
            'nazionalita': null,
            'statoCarriera': null,
            'statoCarrieraMotivazione': null,
        };
        this.filterManagerService.mainFormGroup.reset();
    }


    protected readonly StudenteCicloStatus = StudenteCicloStatus;
    protected readonly Object = Object;
    protected readonly StudenteCicloMotivoStatus = StudenteCicloMotivoStatus;

    statoCarrieraFilterChange() {
        this.getFormControlByPath(this._statoCarrieraMotivazione).patchValue(null);
    }

    protected readonly getStudenteMotiviForStatus = getStudenteMotiviForStatus;
}
