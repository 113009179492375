import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogWrapI} from "../../../layout/common/dialog-wrap/dialog-wrap.component";
import {LogoutService} from "../../service/logout.service";

export enum AreaSeleztione {
    AMMINISTRAZIONE = 'AMMINISTRAZIONE',
    GESTIONALE = 'GESTIONALE'
}

@Component({
    selector: 'app-dialog-area-selection',
    templateUrl: './dialog-area-selection.component.html',
    styleUrls: ['./dialog-area-selection.component.scss']
})
export class DialogAreaSelectionComponent {

    AreaSeleztione = AreaSeleztione;

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogWrapI,
                private logoutService: LogoutService,
                public dialogRef: MatDialogRef<DialogAreaSelectionComponent>) {

    }


    closeDialog(areaSeleztione: AreaSeleztione): void {
        this.dialogRef.close(areaSeleztione);
    }

    logout() {
        this.logoutService.logout();
    }

}
