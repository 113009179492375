<ng-container *transloco="let t ">
    <div class="h-full flex flex-col border-b gap-3"
         [ngClass]="{'lg:flex-row items-center': ngContentFlexRow, 'py-3': compact, 'py-5': !compact}">
        <div class="flex flex-col lg:flex-row gap-2 h-full items-center">
            <div class="text-lg text-center lg:text-start font-medium break-words"
                 [style]="isFlexBasis12_5 ? '' : 'min-width: 25%'"
                 [class]="useLargerLabel ? 'min-w-[70%]' : ''"
                 [ngClass]="isFlexBasis12_5 ? 'basis-1_8' : 'basis-1/4'">{{t(label)}}</div>
            <div *ngIf="!hideValue && ((!showDownloadButton && !showVisualizzaButton) || forceShowValue) && !showChipWithClasses"
                 [class]="labelClasses"
                 class="text-secondary lg:text-start  text-center break-all" style="max-width: 75%">
                <span *ngIf="!dontUseDash">{{((value || '-') | removeUnderscores) | dynamicPipe: pipe}}</span>
                <span *ngIf="dontUseDash">{{(value) | dynamicPipe: pipe}}</span>
            </div>
            <div *ngIf="showDownloadButton" class="text-secondary text-center break-words ml-1" style="max-width: 75%">
                <button
                    mat-mini-fab
                    type="button"
                    (click)="download()"
                    [matTooltip]="t('common.download')"
                    matSuffix>
                    <mat-icon
                        class="icon-size-5"
                        [svgIcon]="'mat_outline:download'"></mat-icon>
                </button>
            </div>
            <div *ngIf="showVisualizzaButton" class="text-secondary text-center break-words ml-2" style="max-width: 75%">
                <button
                    mat-mini-fab
                    type="button"
                    (click)="download()"
                    matSuffix>
                    <mat-icon
                        class="icon-size-5"
                        [svgIcon]="'mat_outline:visibility'"></mat-icon>
                </button>
            </div>
            <div *ngIf="showChipWithClasses">
                <mat-chip-list *ngIf="!!value">
                    <mat-chip [class]="showChipWithClasses"
                              class="chip-width flex flex-row justify-center">
                        {{value}}
                    </mat-chip>
                </mat-chip-list>
                <div *ngIf="!value" class="text-secondary lg:text-start  text-center break-words" style="max-width: 75%">-</div>
            </div>
        </div>
        <div class="flex flex-col lg:flex-row gap-2 h-full items-center" *ngIf="!!subLabel && !!subValue">
            <div class="text-md text-center lg:text-start font-medium break-words"
                 [style]="isFlexBasis12_5 ? '' : 'min-width: 25%'"
                 [ngClass]="isFlexBasis12_5 ? 'basis-1_8' : 'basis-1/4'">{{t(subLabel)}}</div>
            <div *ngIf="!hideValue" class="text-md text-secondary lg:text-start  text-center break-words" style="max-width: 75%">
                {{((subValue || '-') | removeUnderscores) | dynamicPipe: pipe}}
            </div>
        </div>
        <ng-content class="lg:text-start  text-center "></ng-content>
    </div>
</ng-container>
