<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Empty layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<empty-layout *ngIf="layout === 'empty'"></empty-layout>


<!-- Classic -->
<classic-layout *ngIf="layout === 'classic'"
                [isAreaAdministrator]="isAreaAdministrator"
                [mat-spinner]="fuseConfirmationService.loadingSpinner"></classic-layout>

