import { NgModule } from '@angular/core';
import {CommonModule, UpperCasePipe} from '@angular/common';
import {InputValueKeyComponent} from "./input-value-key.component";
import {TranslocoModule} from "@ngneat/transloco";
import {SharedModule} from "../shared.module";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatChipsModule} from "@angular/material/chips";



@NgModule({
    declarations: [InputValueKeyComponent],
    exports: [
        InputValueKeyComponent
    ],
    imports: [
        CommonModule,
        TranslocoModule,
        SharedModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatTooltipModule,
        UpperCasePipe,
        MatChipsModule
    ],
    providers: [UpperCasePipe]
})
export class InputValueKeyModule { }
