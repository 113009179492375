/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Tipologie di modifica. Valori previsti: *UPDATE*, *RIMOZIONE*
 */
export type TipoModificaHeaderOrFooterPdf = 'AGGIUNTA' | 'RIMOZIONE';

export const TipoModificaHeaderOrFooterPdf = {
    AGGIUNTA: 'AGGIUNTA' as TipoModificaHeaderOrFooterPdf,
    RIMOZIONE: 'RIMOZIONE' as TipoModificaHeaderOrFooterPdf
};