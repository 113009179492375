import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

import {get, isArray, isEmpty, orderBy, trim} from "lodash";
import {DialogWrapI} from "../../../../layout/common/dialog-wrap/dialog-wrap.component";
import {
    AddDeliberaDTO,
    AuthorityType,
    CicliCorsiDiStudiService,
    CollegioDocentiInfoView,
    DeliberaDiProgrammazioneMobilitaInfoView,
    DeliberaInfoView,
    DeliberaInfoViewImpl,
    DelibereService,
    PageStudenteCicloUtenteViewImpl,
    PeriodiDiMobilitaService,
    SottoruoloUtenteUserView, StudenteCicloMotivoStatus,
    StudenteCicloStatus,
    StudenteCicloUtenteViewImpl,
    StudentiCicloService,
    UpdateDeliberaDiProgrammazioneMobilitaDTO
} from "../../../../../api-clients/generated/services";
import {italianDateWithFormat} from 'app/shared/utils/utils-date';
import moment from "moment";
import {filter, switchMap, tap} from "rxjs/operators";
import {finalize, forkJoin, Observable, ObservedValueOf, OperatorFunction, take, takeUntil} from "rxjs";
import {SnackbarTypes} from "../../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {AppInitService} from "../../../../shared/service/app-init.service";
import {FuseConfirmationService} from "../../../../../@fuse/services/confirmation";
import {TranslocoService} from "@ngneat/transloco";
import {
    AbstractDefaultComponent
} from "../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {
    GenericDialogComponent
} from "../../../../layout/common/generic-components/generic-dialog/generic-dialog.component";
import {GenericComponentDialogConfig} from "../../../../layout/common/generic-components/generic-components.interface";
import {
    ClickEvent,
    GenericTableConfigurationModel,
    TableData,
    TipoColonnaEnum
} from "../../../../shared/components/table/model/generic-table-model";
import {PageEvent} from "@angular/material/paginator";
import {StudentiBarFilterManagerService} from "../../../../shared/service/studenti-bar-filter-manager.service";
import {
    StudentiBarFilterContainerComponent
} from "../../../../shared/components/studenti-topbar-filter-container/studenti-bar-filter-container.component";
import {LogoutService} from "../../../../shared/service/logout.service";
import {
    getLanguagePathByStudenteCicloMotivoStatus,
    getLanguagePathByStudenteCicloStatus, missioniDaApprovareByRole,
    removeUnderscore
} from "../../../../shared/utils/utils";
import {buildFormConfigForAddNewDelibera} from "../../gestione-documenti/gestione-documenti-utils";
import {LocalStorageService} from "../../../../shared/service/local-storage.service";


export interface DialogEditDeliberaProgrammazioneI extends DialogWrapI {
    deliberaDettaglio: DeliberaDiProgrammazioneMobilitaInfoView;
}

@Component({
    selector: 'app-dialog-edit-delibera-programmazione',
    templateUrl: './dialog-edit-delibera-programmazione.component.html',
    styleUrls: ['./dialog-edit-delibera-programmazione.component.scss']
})
export class DialogEditDeliberaProgrammazioneComponent extends AbstractDefaultComponent implements OnInit, AfterViewInit {

    protected readonly italianDateWithFormat = italianDateWithFormat;
    delibere: DeliberaInfoViewImpl[];
    outputFormatData = 'DD/MM/YYYY';
    showTopbar: boolean;
    showMiniLoader: boolean = false;
    currentFilterListChipLabels: Array<string>;
    studentsListConfiguration: GenericTableConfigurationModel;
    filterContainerComponent: StudentiBarFilterContainerComponent;
    studentiSelected: StudenteCicloUtenteViewImpl[];
    @ViewChild(StudentiBarFilterContainerComponent) set studentiBarFilterContainer(component: StudentiBarFilterContainerComponent) {
        this.filterContainerComponent = component;
    }
    private pageSize: number;
    private fieldsLabelMap: Map<string, string>;

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogEditDeliberaProgrammazioneI,
                public dialogRef: MatDialogRef<DialogEditDeliberaProgrammazioneComponent>,
                private delibereService: DelibereService,
                private appInitService: AppInitService,
                private fuseConfirmationService: FuseConfirmationService,
                private translocoService: TranslocoService,
                private localStorageService: LocalStorageService,
                private fb: FormBuilder,
                private dialog: MatDialog,
                private filterManagerService: StudentiBarFilterManagerService,
                private cicliCorsiDiStudiService: CicliCorsiDiStudiService,
                private logOutService: LogoutService,
                private studentiCicloService: StudentiCicloService,
                private periodiDiMobilitaService: PeriodiDiMobilitaService

    ) {
        super();

    }

    ngOnInit(): void {
        this.fieldsLabelMap = this.filterManagerService.fieldsLabelMap;
        this.getDataAndFilterSubscribe();
        this.createForm();
    }

    ngAfterViewInit() {
        this.studentiSelectedForTable = this.data?.deliberaDettaglio?.studentiCiclo?.map(s => ({
            data: {
                ...s,
                studente: s?.utente?.cognome + ' ' + s?.utente?.nome,
                cognome: s?.utente?.cognome,
                nome: s?.utente?.nome,
                idUtente: s?.utente?.id,
                codiceFiscale: s?.utente?.codiceFiscale
            },
            key: s?.utente?.id
        }));
    }

    closeDialog() {
        this.dialogRef.close();
    }


    buildNomeCompleto(member: CollegioDocentiInfoView): string {
        return trim((member?.cognome || '') + ' ' + (member?.nome || ''));
    }

    protected readonly moment = moment;
    protected readonly AuthorityType = AuthorityType;
    form: FormGroup;
    studentiSelectedForTable: { data: any; key: string }[];

    createForm(): void {
        this.form = this.fb.group({
            deliberaCollegio: [this.data?.deliberaDettaglio.delibera?.id, Validators.required]
        });
    }

    private getDelibereRequest$() {
        return this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.delibereService.searchDelibereForm()),
            takeUntil(this.destroy$),
            tap((delibere => this.delibere = delibere?.content || []))
        );
    }

    private addNewDeliberaRequest(form: FormGroup, rawValue: any, dialogRef: MatDialogRef<GenericDialogComponent>) {
        console.log(rawValue)
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        const requestBody: AddDeliberaDTO = {
            numero: rawValue.numeroDelibera,
            data: rawValue.dataDelibera
        }
        this.fuseConfirmationService.showLoader();
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.delibereService.addDeliberaForm(requestBody, rawValue.fileDelibera, rawValue.fileNotaTrasmissione)),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (addedDelibera: DeliberaInfoView) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation,'common.operation_success_delibera_added', null),
                    type: SnackbarTypes.Success,
                    duration: 5000,
                });
                this.delibere.push(addedDelibera);
                this.delibere = orderBy(this.delibere, [delibera => moment(delibera.data)], ['desc']);
                this.form?.get('deliberaCollegio')?.patchValue(addedDelibera.id);
                dialogRef.close();
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => {},() => this.addNewDeliberaRequest(form, rawValue, dialogRef), 'dialog.cancel',
                    err?.error?.message);
            }
        });

    }

    openAddNewDeliberaDialog() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        let confirmLabel = get(translation, 'dialog.confirm', null);
        let cancelLabel = get(translation, 'dialog.cancel', null);
        const data: GenericComponentDialogConfig = {
            title: 'common.add_delibera',
            //message: get(translation, 'mobility.approve_message', null),
            icon: {
                show: true,
                name: 'mat_outline:add',
                color: 'basic'
            },
            actions: {
                confirm: {
                    show: true,
                    label: confirmLabel,
                    color: 'primary',
                    function: (form, dialogRef) => this.addNewDeliberaRequest(form, form.getRawValue(), dialogRef)
                },
                cancel: {
                    show: true,
                    label: cancelLabel,
                }
            },
            dismissible: true,
            formConfig: buildFormConfigForAddNewDelibera(),
            valueForm: {
                fileDelibera: undefined,
                dataDelibera: undefined,
                numeroDelibera: undefined,
                fileNotaTrasmissione: undefined,
            }
        };
        this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: data.dismissible,
            disableClose: true,
        });
    }

    formatDeliberaDate(dateFromBE: string): string {
        return moment(dateFromBE).format(this.outputFormatData);
    }

    closeBar() {
        this.showTopbar = false;
    }

    getSupervisoriAndCosupervisori$(event?: boolean): void {
        if (!this.filterManagerService?.codiceFiscaleCosupervisoreList
            || !this.filterManagerService?.codiceFiscaleSupervisoreList
            || !this.filterManagerService?.codiceFiscaleDocenteList) {

            this.fuseConfirmationService.showLoader();
            forkJoin([
                this.cicliCorsiDiStudiService.getSupervisoriCicloCorsoDiStudi(),
                this.cicliCorsiDiStudiService.getCosupervisoriCicloCorsoDiStudi(),
                this.cicliCorsiDiStudiService.getDocentiCorsoCicloCorsoDiStudi(),
            ]).pipe(
                tap(([supervisori, cosupervisori, docenti]: ([SottoruoloUtenteUserView[], SottoruoloUtenteUserView[], SottoruoloUtenteUserView[]])) => {
                    this.filterManagerService.setCodiceFiscaleSupervisoreList(supervisori);
                    this.filterManagerService.setCodiceFiscaleCosupervisoreList(cosupervisori);
                    this.filterManagerService.setCodiceFiscaleDocenteList(docenti);
                }),
                finalize(() => this.fuseConfirmationService.hideLoader())
            ).subscribe({
                next: () => {
                    this.toogleFilterContainer(true);
                },
                error: (err) => {
                    this.filterManagerService.setCodiceFiscaleSupervisoreList(undefined);
                    this.filterManagerService.setCodiceFiscaleCosupervisoreList(undefined);
                    this.filterManagerService.setCodiceFiscaleDocenteList(undefined);
                    this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                        () => this.logOutService.goToHome(),
                        () => this.getSupervisoriAndCosupervisori$(),
                        'common.go_to_home',
                        err?.error?.message);
                }
            });
        } else {
            this.toogleFilterContainer(true);
        }
    }

    resetFilters(): void {
        this.getMainFormGroup()?.reset();
        this.filterContainerComponent?.resetFilterApplied();
        this.filterManagerService?.checkApplyFilterClick$.next(true);
    }

    tableClickAction($event: ClickEvent) {

    }


    onStudentDataTableChanged($event: TableData) {
        console.log('SELECTION CHANGED')
        this.studentiSelected = $event.selectedRows.length > 0 ? $event.selectedRows.map(row => row.data) as StudenteCicloUtenteViewImpl[] : undefined;
    }

    pageAction($event: PageEvent): void {
        console.log('PageEvent', $event);
        const page = $event.pageIndex;
        this.pageSize = $event.pageSize;
        this.getListaStudentiAndDelibereRequest$(false, this.getMainFormGroup()?.value, page, this.pageSize);
    }

    toogleFilterContainer(hasToogleInternalMenu: boolean): void {
        this.filterContainerComponent?.setFilterApplied();
        this.showTopbar = true;
    }

    getMainFormGroup(): FormGroup<any> {
        return this.filterManagerService?.mainFormGroup;
    }

    getListaStudentiAndDelibereRequest$(isFirstTime?: boolean, filters?: any, page: number = 0, size: number = 10,
                                        sort?: string ): void {
        this.getStudentiFormObs$(isFirstTime, filters, page, size, ).pipe(
            switchMap((value) => this.getDelibereRequest$())
        ).subscribe({
            next: (value) => {},
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => {
                        this.dialogRef.close();
                        this.logOutService.goToHome()
                    },
                    () => this.getListaStudentiAndDelibereRequest$(isFirstTime, filters, page, size, sort),
                    'common.go_to_home',
                    err?.error?.message);
            }
        });
    }

    getStudentiFormObs$(isFirstTime?: boolean, filters?: any, page: number = 0, size: number = 10,
                        sort?: string): Observable<PageStudenteCicloUtenteViewImpl> {
        this.fuseConfirmationService.showLoader();
        return this.studentiCicloService.getStudentiForm(
            !!filters?.nominativo ? filters?.nominativo : undefined,
            !!filters?.codiceFiscale ? filters?.codiceFiscale : undefined,
            !!filters?.statoCarriera ? filters?.statoCarriera : undefined,
            !!filters?.statoCarrieraMotivazione ? filters?.statoCarrieraMotivazione : undefined,
            !!filters?.ateneoProvenienza ? filters?.ateneoProvenienza : undefined,
            !!filters?.nazionalita ? filters?.nazionalita : undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            !!filters?.codiceFiscaleSupervisore?.codiceFiscale ? filters.codiceFiscaleSupervisore?.codiceFiscale : undefined,
            !!filters?.ssdSupervisore ? filters.ssdSupervisore : undefined,
            !!filters?.afferenzaOrganizzativaSupervisore ? filters.afferenzaOrganizzativaSupervisore : undefined,
            !!filters?.codiceFiscaleCosupervisore?.codiceFiscale ? filters.codiceFiscaleCosupervisore?.codiceFiscale : undefined,
            !!filters?.ssdCosupervisore ? filters.ssdCosupervisore : undefined,
            !!filters?.afferenzaOrganizzativaCosupervisore ? filters.afferenzaOrganizzativaCosupervisore : undefined,
            undefined,
            !!filters?.codiceFiscaleDocenteCorso?.codiceFiscale ? filters.codiceFiscaleDocenteCorso?.codiceFiscale : undefined,
            undefined,
            !!filters?.codiceTipoBorsaPerCiclo ? filters.codiceTipoBorsaPerCiclo : undefined,
            undefined,
            false,
            undefined,
            undefined,
            undefined,
            !!filters?.hasNotSupervisore ? true : undefined,
            !!filters?.hasNotCosupervisore ? true : undefined,
            undefined,
            !!filters?.hasNumGiorniMobilitaInferioreAlMinimoPrevisto ? true : undefined,
            !!filters?.hasNumGiorniMobilitaSuperioreAlPrevisto ? true : undefined,
            !!filters?.hasMissioniDaApprovare ? missioniDaApprovareByRole(this.localStorageService) : undefined,
            page, size, ).pipe(
            tap((pageStudente) => {
                this.studentsListConfiguration = this.buildListaStudentiConfiguration(pageStudente);
            }),
            takeUntil(this.destroy$),
            finalize(() => {
                    this.fuseConfirmationService.hideLoader();
                }
            )
        );



    }

    buildListaStudentiConfiguration(pageStudente: PageStudenteCicloUtenteViewImpl): GenericTableConfigurationModel {
        return {
            configuration: {
                data: [
                    ...this.data?.deliberaDettaglio?.studentiCiclo?.map(studenteContent => ({
                        ...studenteContent,
                        studente: studenteContent?.utente?.cognome + ' ' + studenteContent?.utente?.nome,
                        cognome: studenteContent?.utente?.cognome,
                        nome: studenteContent?.utente?.nome,
                        idUtente: studenteContent?.utente?.id,
                        codiceFiscale: studenteContent?.utente?.codiceFiscale?.toUpperCase(),
                    })),
                    ...pageStudente?.content?.map(studenteContent => ({
                        ...studenteContent,
                        studente: studenteContent.cognome + ' ' + studenteContent.nome,
                        codiceFiscale: studenteContent?.codiceFiscale?.toUpperCase(),
                    }))
                ],
                sticky: true,
                pageSize: 10,
                pageSizeOptions: [10, 20, 30, 40, 50],
                totalElements: pageStudente.totalElements,
                isPaginatedBE: true,
                messaggioDatiAssenti: 'custom_table.no_data',
                hidePaginator: false,
                selection: {
                    isSelectable: true,
                    selectedColumnKeyToShow: 'studente',
                    selectedColumnKeyPrimaryKey: 'idUtente',
                },
                configurazioneTabella: [
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'students_list.student',
                        colonnaKey: 'studente',
                        flex: 30,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'menu_filter.enrollment_number',
                        colonnaKey: 'matricola',
                        flex: 25,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'common.fiscal_code',
                        colonnaKey: 'codiceFiscale',
                        flex: 30,
                    },
                ],
            }
        };
    }

    formatDay(date: string) {
        return moment(new Date(date)).format('DD/MM/YYYY');
    }

    private getDataAndFilterSubscribe() {
        this.getListaStudentiAndDelibereRequest$(true);
        this.filterManagerService.appliedFiltersObsevable$().pipe(
            filter(Boolean),
            switchMap(value => this.getStudentiFormObs$(false, value, 0, this.pageSize).pipe(
                this.catchErrorInStudentList$(this.getStudentiFormObs$(false, value, 0, this.pageSize))
            )),
            takeUntil(this.destroy$)
        ).subscribe(
            {
                next: (value) => {
                    this.filterManagerService.filterApplied$.next( this.getMainFormGroup().getRawValue());
                    this.currentFilterListChipLabels = this.getListChipsLabel();
                }
            }
        );
    }

    getListChipsLabel(): Array<string> {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        if (!isEmpty(this.getMainFormGroup()?.value)) {
            const entries: [string, any][] = Object.entries(this.getMainFormGroup()?.value);
            const filteredEntries = entries?.filter(item => item.every(value => !!value));
            let labels = filteredEntries?.map(filteredItem => filteredItem?.map((element) => {
                    if (typeof element === 'string') {
                        if (!!this.fieldsLabelMap.get(element)) {

                            return get(translation, this.fieldsLabelMap.get(element), element);
                        }else {
                            return removeUnderscore(element);
                        }
                    }
                    if (typeof element === 'boolean') {
                        return '';
                    }
                    else if (!!element?.codiceFiscale) {
                        const completeName = (element?.cognome || '') + ' ' + (element?.nome || '');
                        return (!!element?.cognome ? completeName : '');
                    }
                    else if (!!element?.id && !!element?.denominazione) {
                        return (!!element?.denominazione ? element.denominazione : '');
                    }
                    else if (!!element && isArray(element) && Object.values(StudenteCicloStatus)?.find(e => element.includes(e))) {
                        return element.map(e => get(translation, getLanguagePathByStudenteCicloStatus(e), e))?.join(', ');
                    }
                    else if (!!element && isArray(element) && Object.values(StudenteCicloMotivoStatus)?.find(e => element.includes(e))) {
                        return element.map(e => get(translation, getLanguagePathByStudenteCicloMotivoStatus(e), e))?.join(', ');
                    }
                    else {
                        return element;
                    }
                }
            ).join(': '));
            console.log('Lables chips', labels);
            labels = labels.map(label => label?.endsWith(': ')? label.slice(0, -2) : label);
            return labels;
        } else {
            return [];
        }
    }

    catchErrorInStudentList$(inputObs: Observable<any>): OperatorFunction<unknown, ObservedValueOf<Observable<any>> | unknown> {
        return this.fuseConfirmationService.catchErrorCustom$(inputObs, {openModalError: {goToHome: true}}, true, true, true, false);
    }


    editRequest() {
        const formRawValue = this.form.getRawValue();
        const requestBody: UpdateDeliberaDiProgrammazioneMobilitaDTO = {
            idDelibera: formRawValue.deliberaCollegio,
            dottorandiUtenteIds: this.studentiSelected?.map(s => s.idUtente) || []
        }
        this.fuseConfirmationService.showLoader();
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.periodiDiMobilitaService.updateDeliberaDiProgrammazioneMobilita(requestBody, this.data?.deliberaDettaglio?.id)),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: () => {
                const activeLang = this.translocoService.getActiveLang();
                const translation = this.translocoService.getTranslation().get(activeLang);
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'mobility.op_saved', null),
                    type: SnackbarTypes.Success,
                });
                this.dialogRef.close();
            },
            error: (err) => {
                console.error(err);
                this.fuseConfirmationService.openErrorDialog({error: err},
                    this.translocoService,
                    () => {},
                    () => this.editRequest(),
                    'dialog.close',
                    err?.error?.message);
            }
        });
    }
}
