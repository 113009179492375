<ng-container *transloco="let t">


    <div class="flex flex-col w-full min-h-[20rem] items-center justify-center"
         *ngIf="loading; else stepTemplate">
        <app-loading-screen class="w-full h-full">
        </app-loading-screen>
    </div>


    <ng-template #stepTemplate>

        <div class="mt-4 mb-6">
            <fuse-alert class="w-full"
                        [showIcon]="true"
                        [appearance]="'outline'"
            >{{t('massive_operations.approvazione_spese.hint_choose_spese')}}</fuse-alert>
        </div>

        <div class="w-full flex flex-col gap-4 items-center mt-2">

            <!-- VISUALIZZA FILTRI BUTTON -->
            <div class="flex items-center w-full flex-row justify-end mt-2">
                <button mat-flat-button color="primary"
                        [disabled]="showMiniLoader"
                        (click)="openFilters()">
                    <mat-spinner *ngIf="showMiniLoader" [diameter]="20" color="accent"></mat-spinner>
                    <mat-icon class="mr-2" *ngIf="!showMiniLoader">manage_search</mat-icon>
                    {{t('menu_filter.advanced_search')}}
                </button>
            </div>

            <div class="flex flex-row justify-between pl-2 mr-2 w-full" >

                <div class="mt-0.5 px-2 py-4 flex flex-row gap-4">
                    <!-- TESTO FILTRI APPLICATI -->
                    <div class=" text-2xl font-semibold mt-[0.35rem]">{{t('students_list.applied_filters')}}</div>
                    <!--RESET BUTTON -->
                    <button mat-flat-button
                            *ngIf="currentFilterListChipLabels?.length > 0"
                            (click)="resetFilters()"
                            [color]="'accent'">
                        <mat-icon class="mr-2">delete</mat-icon>
                        <span>{{t('menu_filter.reset')}}</span>
                    </button>
                </div>


            </div>


            <div class="w-full flex flex-row justify-center items-center">
                <!-- CHIPS -->
                <div class="w-full flex items-center">

                    <div class="flex flex-col gap-1.5">

                        <mat-chip-list class="w-full">
                            <div class="w-full flex flex-row gap-1.5 p-2 flex-wrap">
                                <ng-container *ngIf="currentProfile !== AuthorityType.SUPERVISORE && currentProfile !== AuthorityType.COSUPERVISORE; else supervisorChipTmpl">
                                    <mat-chip color="primary" class="justify-between text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600">
                                        {{t('student.approved_by_supervisor') | titlecase}}
                                    </mat-chip>
                                </ng-container>
                                <ng-template #supervisorChipTmpl>
                                    <mat-chip color="primary" class="justify-between text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600">
                                        {{t(SpesaStudenteStatus.DAAPPROVARE | statusEnumToLanguagePath: SpesaStudenteStatus) | titlecase}}
                                    </mat-chip>
                                    <mat-chip *ngIf="currentProfile === AuthorityType.COSUPERVISORE"
                                              color="primary" class="justify-between text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600">
                                        {{t('menu_filter.only_approvable_by_me') | titlecase}}
                                    </mat-chip>
                                </ng-template>
                            </div>
                            <ng-container *ngFor="let chip of currentFilterListChipLabels">
                                <mat-chip color="primary" class="justify-between height-unset">
                                    {{chip | removeUnderscores}}
                                </mat-chip>
                            </ng-container>
                        </mat-chip-list>
                    </div>
                </div>
            </div>

            <!-- TABELLA STUDENTI -->
            <div class="w-full mt-4 mb-6">
                <app-generic-table *ngIf="!!speseListConfiguration"
                                   [configuration]="speseListConfiguration"
                                   [idRigheSolaLettura]="idRigheDaDisabilitare"
                                   (clickAction)="tableClickAction($event)"
                                   (pageAction)="pageAction($event)"
                                   (tableDataEmitter)="onSpeseDataTableChanged($event)">
                </app-generic-table>
            </div>

        </div>



    </ng-template>



</ng-container>
