import {
    getCfuOttentutiFromApprovedPlan,
    getCfuPrevistiFromApprovedPlan,
    getDataSuperamentoValueFromApprovedPlan, getMotivazioneRifiutoValueFromApprovedPlan,
    getNotaSuperamentoValueFromApprovedPlan,
    getStatoAttivitaTrasversaleFromApprovedPlan, getSuperatoValueFromApprovedPlan,
    visualizzaStatoAttivitaTrasversale
} from '../../study-plan-utils';
import {get, sortBy, trim} from 'lodash';
import {
    AttivitaTrasversaleStatus,
    CategoriaInAggiornamentoPianoDiStudi,
    CategoriaInPianoDiStudiInfoView,
    PianoDiStudiInfoViewImpl,
    TipoModificaCorsoPianoDiStudi
} from '../../../../../../../api-clients/generated/services';
import {
    GenericTableConfigurationModel,
    TipoClickEnum,
    TipoColonnaEnum
} from '../../../../../../shared/components/table/model/generic-table-model';
import {getPresumedNumbersForDraft} from "../nuova-presentazione/configurazione-corsi-nuova-presentazione";
import {Translation} from "@ngneat/transloco";


export function buildConfigurationForTransversalActivitiesAggiornamenti(
    translation: Translation,
    category: CategoriaInPianoDiStudiInfoView | CategoriaInAggiornamentoPianoDiStudi,
    approvedPlan?: PianoDiStudiInfoViewImpl,
    ): GenericTableConfigurationModel {

    const coursesPlan = category.corsi;

    //count get presumed numbers
    const presumedNumbers = getPresumedNumbersForDraft(category);

    let tableData = coursesPlan?.map(item => ({
        ...(item?.datiCorsoOffertaFormativa ?? item),
        tipoModifica: item?.tipoModifica
    }));

    tableData = tableData?.map(element => ({
        ...element,
        readonly: true,
        idAttivitaTrasversale: element?.idAttivitaTrasversale,
        statoAttivitaTrasversale: getStatoAttivitaTrasversaleFromApprovedPlan(element, approvedPlan, category.idCategoriaOffertaFormativa),
        superato: getSuperatoValueFromApprovedPlan(element, approvedPlan, category.idCategoriaOffertaFormativa, true),
        notaSuperamento: getNotaSuperamentoValueFromApprovedPlan(element, approvedPlan, category.idCategoriaOffertaFormativa, true),
        dataSuperamento: getDataSuperamentoValueFromApprovedPlan(element, approvedPlan, category.idCategoriaOffertaFormativa, true),
        cfuPrevisti: getCfuPrevistiFromApprovedPlan(element, approvedPlan, category.idCategoriaOffertaFormativa, true),
        cfuOttenuti: getCfuOttentutiFromApprovedPlan(element, approvedPlan, category.idCategoriaOffertaFormativa, true),
        motivazioneRifiuto: getMotivazioneRifiutoValueFromApprovedPlan(element, approvedPlan, category.idCategoriaOffertaFormativa, true),
        tipoDocumentoDottorandoFormatted: element?.tipoDocumentoDottorando || '-',
        tipoDocumentoSupervisoreFormatted: element?.tipoDocumentoSupervisore || '-',
        idCorsoInOffertaFormativa: element.idCorsoInOffertaFormativa,
        denominazione: element.denominazione ?? element.denominazioneAttivitaPresunta,
        denominazioneFormatted: (element.idAttivitaPresunta && !element.denominazione) ? (get(translation, 'study_plan_status.presumed_activity') + ' ' + presumedNumbers.pop()) : element.denominazione,
        idCategoriaOffertaFormativa: category.idCategoriaOffertaFormativa,
        isArchiviato: element.isArchiviato || element?.isAssociazioneArchiviata
    }));

    tableData = sortBy(tableData, [
        attivita => !attivita.idAttivitaProposta,
        attivita => !attivita.idAttivitaPresunta,
        attivita => attivita?.isArchiviato,
        attivita => trim(attivita?.denominazioneFormatted?.toLowerCase()),
    ], ['asc', 'asc', 'asc', 'asc',]);

    const myConfig = {
        configuration: {
            data: tableData,
            totalElements: tableData?.length,
            isPaginatedBE: false,
            sticky: true,
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'cycle_doctorate.activity_typology',
                    colonnaKey: 'denominazioneFormatted',
                    flex: 20,
                    alsoShowChipIf: (activity) => {
                        if(activity.idAttivitaProposta){
                            return {
                                chipText: 'study_plan_status.proposed_activity',
                                iconName: 'school',
                                classes: 'text-blue-600'
                            };
                        } else if (activity.idAttivitaPresunta){
                            return {
                                chipText: activity.denominazione ? 'study_plan_status.presumed_activity_defined' : 'study_plan_status.presumed_activity_not_defined',
                                iconName: 'question_mark',
                                classes: activity.denominazione ? 'text-green-600' : 'text-orange-600'
                            };
                        } else {
                            return undefined;
                        }
                    },
                },
/*                {
                    tipo: TipoColonnaEnum.BOOLEAN,
                    nomeColonna: 'student.passed',
                    colonnaKey: 'superato',
                    flex: 16,
                    convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
                },*/
                {
                    tipo: TipoColonnaEnum.CHIP_STATUS,
                    nomeColonna: 'common.edit_type',
                    colonnaKey: 'tipoModifica',
                    flex: 15,
                    tooltip: tipoModifica => 'study_plan_status.edit_types.' + tipoModifica.toLowerCase(),
                    statusType: TipoModificaCorsoPianoDiStudi
                },
                {
                    tipo: TipoColonnaEnum.CHIP_STATUS,
                    nomeColonna: 'common.status',
                    colonnaKey: 'statoAttivitaTrasversale',
                    flex: 18,
                    show: (value, element) => visualizzaStatoAttivitaTrasversale(element),
                    statusType: AttivitaTrasversaleStatus
                },
                /*                    {
                        tipo: TipoColonnaEnum.BOOLEAN,
                        nomeColonna: 'cycle_doctorate.student_document_required',
                        colonnaKey: 'presenzaDocumentoDottorando',
                        convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no',
                        flex: 8,
                    },*/
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'cycle_doctorate.student_document',
                    colonnaKey: 'tipoDocumentoDottorandoFormatted',
                    flex: 20,
                },
                /*                    {
                                        tipo: TipoColonnaEnum.BOOLEAN,
                                        nomeColonna: 'cycle_doctorate.supervisor_document_required',
                                        colonnaKey: 'presenzaDocumentoSupervisore',
                                        convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no',
                                        flex: 8,
                                    },*/
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'cycle_doctorate.supervisor_document',
                    colonnaKey: 'tipoDocumentoSupervisoreFormatted',
                    flex: 20,
                },
                {
                    tipo: TipoColonnaEnum.BOOLEAN,
                    nomeColonna: 'cycle_doctorate.coordinator_required',
                    colonnaKey: 'presenzaDeliberaCoordinatore',
                    convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no',
                    flex: 8,
                },
                {
                    tipo: TipoColonnaEnum.BOOLEAN,
                    nomeColonna: 'study_plan_status.archived_activity',
                    colonnaKey: 'isArchiviato',
                    show: (_, activity) => !activity.idAttivitaProposta && !activity.idAttivitaPresunta,
                    flex: 8,
                    convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
                },
                {
                    nomeColonna: 'common.detail',
                    colonnaKey: 'descrizione',
                    flex: 10,
                    tipo: TipoColonnaEnum.ACTION,
                    button: [{
                        nameIconButton: 'info',
                        click: TipoClickEnum.SHOW,
                        tooltip: 'common.show_detail',
                        color: 'accent'
                    }]
                },
            ],
            hidePaginator: true,
            css: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'min-width': 'unset'
            },
            messaggioDatiAssenti: 'custom_table.no_data_activities_for_year'
        }
    };
    return myConfig as GenericTableConfigurationModel;
}
