import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {
    AbstractDefaultComponent
} from '../../../../../shared/abstracts/abstract-default-component/abstract-default-component';
import {BehaviorSubject, finalize, takeUntil} from 'rxjs';
import {
    ConfigurazioneColonna,
    GenericTableConfigurationModel,
    TableData,
    TipoColonnaEnum
} from '../../../../../shared/components/table/model/generic-table-model';
import {
    DottorandiCressiWsService,
    PageDottorandoCressiWSInfoView, SpesaStudenteStatus, StudenteCicloStatus,
    UserCodiceFiscaleNomeCognomeView,
    UserFilterForm
} from '../../../../../../api-clients/generated/services';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {GenericTableComponent} from '../../../../../shared/components/table/generic-table/generic-table.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogWrapI} from '../../../../../layout/common/dialog-wrap/dialog-wrap.component';
import {FuseAlertType} from '../../../../../../@fuse/components/alert';
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {TranslocoService} from "@ngneat/transloco";
import {LogoutService} from "../../../../../shared/service/logout.service";
import {PageEvent} from "@angular/material/paginator";
import * as moment from "moment/moment";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {LocalStorageService} from "../../../../../shared/service/local-storage.service";
import RuoloEnum = UserFilterForm.RuoloEnum;
import {sortBy} from "lodash";
import {clone} from "lodash-es";


export interface DialogEditCommissioneDiAutovalutazioneComponentI extends  DialogWrapI{
    title?: string;
    message?: string;
    actions?: {
        confirm?: {
            show?: boolean;
            label?: string;
            color?: 'primary' | 'accent' | 'warn';
            function: (tableData: TableData, dialogRef: MatDialogRef<DialogEditCommissioneDiAutovalutazioneComponent>, loading: BehaviorSubject<boolean>, docentiExtra?: any[]) => void;
        };
        cancel?: {
            show?: boolean;
            label?: string;
        };
    };
    dismissible?: boolean;
    readOnly?: boolean;
    valuesShow?: {key: string; value: any}[];
    alert?: {
        type?: FuseAlertType;
        message?: string;
    };
    table?: {
        configuration?: GenericTableConfigurationModel;
        title?: string;
    };
    righeSelezionate: Array<any>;
    idRigheSolaLettura?: Array<string>;
    showInfoRigheSolaLettura?: {iconName: string; tooltip: string};
    isSingleChoiceSelection?: boolean;
    //showSelectToSetDataTableByRole?: boolean;
    roleDataTable?: RuoloEnum;
    infoMessage?: string;
    fetchDottorandi?: boolean;
    showInvitaEsterni?: boolean;
    righeSelezionateCustom?: Array<any>;
    showAggiungiDocenteManualmente?: boolean;
    listaDaVisualizzare: Array<UserCodiceFiscaleNomeCognomeView>;
}


@Component({
  selector: 'app-dialog-edit-commissione-di-autovalutazione',
  templateUrl: './dialog-edit-commissione-di-autovalutazione.component.html',
  styleUrls: ['./dialog-edit-commissione-di-autovalutazione.component.scss']
})
export class DialogEditCommissioneDiAutovalutazioneComponent extends AbstractDefaultComponent implements OnInit {


    loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    tableData: TableData;

    alert = {type: 'info', message: ''};
    isGetDocentiError: boolean;
    showTable: boolean;
    loading: boolean;
    showSpinner: boolean;
    listaRuoli: RuoloEnum[]= [];
    form: FormGroup;
    docentiExtra = [];
    hasCustomChanged: boolean;
    @ViewChild(GenericTableComponent) private genericTableComponent: GenericTableComponent;
    listaDottorandiPagedInfo: PageDottorandoCressiWSInfoView;
    size: number;
    page: number;

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogEditCommissioneDiAutovalutazioneComponentI,
                public dialogRef: MatDialogRef<DialogEditCommissioneDiAutovalutazioneComponent>,
                private formBuilder: FormBuilder,
                private dottorandiCressiWsService: DottorandiCressiWsService,
                private fuseConfirmationService: FuseConfirmationService,
                private translocoService: TranslocoService,
                private logoutService: LogoutService,
                public appInitService: AppInitService,
                private localStorageService: LocalStorageService
    ) {

        super();

        this.form = this.formBuilder.group({
            filtroNominativoControl: undefined,
            filtroCicloControl: undefined
        });

    }

    ngOnInit(): void {
        if(!this.data.fetchDottorandi) {
            this.getDocentiData();
        } else {
            this.getDottorandiData();
        }
        this.docentiExtra = clone(this.data.righeSelezionateCustom);
    }


    get filtroNomivativoControl(): FormControl{
        return this.form.get('filtroNominativoControl') as FormControl;
    }

    get filtroCicloControl(): FormControl{
        return this.form.get('filtroCicloControl') as FormControl;
    }


    onDataTableChanged(tableData: TableData): void {
        this.tableData = tableData;
    }

    save(): void {
        this.data.actions.confirm.function(this.tableData, this.dialogRef, this.loading$, this.docentiExtra);
    }

    getDocentiData(): void {
        if(this.data && this.data?.table){
            this.data.table.configuration = this.mapDocentiToTableConfig();
            this.showTable= true;
        }
    }

    getDottorandiData(): void {
        if(this.data && this.data?.table){
            this.getDottorandiPagedDataSource(true);
        }
    }

    getDottorandiPagedDataSource(firstTime: boolean, nominativo: string = undefined, ciclo: number = undefined, page: number = 0, size: number = 10): void {
        if (firstTime) {
            this.loading = true;
        } else {
            this.showSpinner= true;
        }
        this.dottorandiCressiWsService.searchDottorandiCressiWSForm(
            undefined,
            this.localStorageService.getCicloCorsoRuolo()?.codiceCorsoStudiCineca,
            !!ciclo ? ciclo : undefined,
            !!nominativo? nominativo : undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            page,
            size).pipe(
            takeUntil(this.destroy$),
            finalize(()=>{
                this.loading= false;
                this.showSpinner= false;
            })
        ).subscribe({
            next: (value: PageDottorandoCressiWSInfoView) => {
                this.listaDottorandiPagedInfo = value;
                if(this.data && this.data?.table){
                    this.data.table.configuration = this.mapDottorandiToTableConfig();
                    this.showTable= true;
                }
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err},
                    this.translocoService,
                    () => this.logoutService.goToHome(),
                    () => this.getDottorandiPagedDataSource(false, this.filtroNomivativoControl?.value?.trim(), this.filtroCicloControl?.value, page, size),
                    undefined,
                    err?.error?.message);
                this.isGetDocentiError = true;
                this.showTable= false;
            }
        });
    }

    mapDottorandiToTableConfig(): GenericTableConfigurationModel{
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        return {
            configuration: {
                data: this.listaDottorandiPagedInfo?.content?.map(studenteContent => ({
                    ...studenteContent,
                    nomeCognome: (studenteContent.cognome + ' ' + studenteContent.nome)?.toUpperCase(),
                    dataIscrizione: this.formatDay(studenteContent?.dataIscrizione),
                    emailAteneo: studenteContent?.emailAteneo?.toLowerCase(),
                    codiceFiscale: studenteContent?.codiceFiscale?.toUpperCase(),
                })),
                sticky: true,
                pageSize: this.size,
                pageBE: this.page,
                pageSizeOptions: [10, 20, 30, 40, 50],
                totalElements: this.listaDottorandiPagedInfo.totalElements,
                isPaginatedBE: true,
                messaggioDatiAssenti: 'custom_table.no_data_student',
                hidePaginator: false,
                singleChoiceSelection: false,
                selection: {
                    isSelectable: true,
                    selectedColumnKeyToShow: 'nomeCognome',
                    selectedColumnKeyPrimaryKey: 'codiceFiscale',
                },
                configurazioneTabella: this.getConfigurazioneTabellaDottorandi()
            }
        };
    }

    formatDay(date: string) {
        return moment(new Date(date)).format('DD/MM/YYYY');
    }

    pageAction($event: PageEvent): void {
        console.log('PageEvent', $event);
        const size = $event.pageSize;
        const page = $event.pageIndex;
        this.page = page;
        this.size = size;
        this.getDottorandiPagedDataSource(false, this.filtroNomivativoControl?.value?.trim(), this.filtroCicloControl?.value, page, size);
    }

    getConfigurazioneTabellaDottorandi(): ConfigurazioneColonna[] {
        return [
            {
                tipo: TipoColonnaEnum.STRING,
                nomeColonna: 'students_list.student',
                colonnaKey: 'nomeCognome',
                flex: 20,
            },
            {
                tipo: TipoColonnaEnum.STRING,
                nomeColonna: 'common.fiscal_code',
                colonnaKey: 'codiceFiscale',
                flex: 14,
            },
            {
                tipo: TipoColonnaEnum.STRING,
                nomeColonna: 'menu_filter.enrollment_number',
                colonnaKey: 'matricola',
                flex: 9,
            },
            {
                tipo: TipoColonnaEnum.STRING,
                nomeColonna: 'students_list.university_email',
                colonnaKey: 'emailAteneo',
                flex: 29,
            },
            {
                tipo: TipoColonnaEnum.CHIP_STATUS,
                nomeColonna: 'menu_filter.career_status',
                colonnaKey: 'stato',
                statusType: StudenteCicloStatus,
                flex: 15
            },
            {
                tipo: TipoColonnaEnum.STRING,
                nomeColonna: 'cycle_doctorate.number_cycle',
                colonnaKey: 'numeroCicloDottorato',
                flex: 8
            },
        ];
    }



    mapDocentiToTableConfig(): GenericTableConfigurationModel{
        let docentiTableData = this.lista().map((docente: UserCodiceFiscaleNomeCognomeView) => ({
            nomeCognome: ((docente?.cognome ? docente?.cognome : '') + ' ' + (docente?.nome ? docente?.nome : ''))?.toUpperCase(),
            codiceFiscale: docente?.codiceFiscale?.toUpperCase(),
            id: docente?.codiceFiscale,
            nome: (docente?.nome ? docente?.nome : ''),
            cognome: (docente?.cognome ? docente?.cognome : '')
        }));
        docentiTableData = sortBy(docentiTableData, ['nomeCognome'], ['asc']);
        return {
            configuration: {
                data: docentiTableData,
                singleChoiceSelection: this.data?.isSingleChoiceSelection,
                selection: {
                    isSelectable: true,
                    selectedColumnKeyToShow: 'nomeCognome',
                    selectedColumnKeyPrimaryKey: 'codiceFiscale',
                },
                sticky: true,
                pageSize: 10,
                pageSizeOptions: [10, 20, 30, 40, 50],
                totalElements: this.data?.listaDaVisualizzare?.length,
                isPaginatedBE: false,
                messaggioDatiAssenti: 'custom_table.no_data_members',
                hidePaginator: false,
                configurazioneTabella: [
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'common.nominative',
                        colonnaKey: 'nomeCognome',
                        flex: 40,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'common.fiscal_code',
                        colonnaKey: 'codiceFiscale',
                        flex: 40,
                    },
                ],
            }
        };

    }

    applyFilters(): void {
        if(!this.data.fetchDottorandi) {
            this.getDocentiData();
        } else {
            this.page = 0;
            this.getDottorandiPagedDataSource(false, this.filtroNomivativoControl?.value?.trim(), this.filtroCicloControl?.value, this.page, this.size);
        }
    }

    svuotaFiltroNominativo(): void{
        this.filtroNomivativoControl.reset();
    }

    svuotaFiltroCiclo(): void{
        this.filtroCicloControl.reset();
    }


    private getHeaderByRole(value: RuoloEnum) {
        switch(value) {
            case RuoloEnum.DOCENTE:
                return 'common.professor';
            case RuoloEnum.PERSONALEPTA:
                return 'cycle_doctorate.pta';
            case RuoloEnum.ESTERNO:
                return 'common.esterno';
            default:
                return 'common.professor';
        }
    }


    customSelectedRowChange(hasChanged: boolean) {
        this.hasCustomChanged = hasChanged;
    }

    lista() {
       return !!this.filtroNomivativoControl?.value?.trim()? this.data?.listaDaVisualizzare.filter((utente: UserCodiceFiscaleNomeCognomeView) =>{

            const nomeCompleto = utente.nome + ' ' + utente.cognome;
            const cognomeNome = utente.cognome + ' ' + utente.nome;

            const ricerca = this.filtroNomivativoControl?.value?.toLowerCase();

            return (
                nomeCompleto.toLowerCase().includes(ricerca) ||
                cognomeNome.toLowerCase().includes(ricerca) ||
                utente.nome.toLowerCase().includes(ricerca) ||
                utente.cognome.toLowerCase().includes(ricerca)
            );
        }) : this.data.listaDaVisualizzare;
    }
}
