import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {OperazioneMassivaTipo} from "../../operazioni-massive/operazioni-massive-config";
import {OperazioniMassiveComponent} from "../../operazioni-massive/operazioni-massive.component";
import {MatDrawer} from "@angular/material/sidenav";
import {takeUntil} from "rxjs";
import {AuthorityType} from "../../../../../api-clients/generated/services";
import {AppInitService} from "../../../../shared/service/app-init.service";
import {SuperamentoCorsiTableComponent} from "./table-superamento-corsi/superamento-corsi-table.component";
import {CorsiFilterService} from "./corsi-sidebar-filter-container/corsi-filter.service";
import {ApplyFiltersQuicklyStudentsI} from "../../students-list/students-list.component";
import {Router} from "@angular/router";
import {
    AbstractDefaultComponent
} from "../../../../shared/abstracts/abstract-default-component/abstract-default-component";

export const DEFAULT_PAGE_SIZE = 10;

@Component({
    selector: 'app-superamento-corsi',
    templateUrl: './superamento-corsi.component.html',
    styleUrls: ['./superamento-corsi.component.scss']
})
export class SuperamentoCorsiComponent extends AbstractDefaultComponent implements OnInit, OnDestroy {
    @ViewChild(OperazioniMassiveComponent) operazioniMassiveComponent: OperazioniMassiveComponent;
    @ViewChild(SuperamentoCorsiTableComponent) superamentoCorsiComponent: SuperamentoCorsiTableComponent;

    @ViewChild('drawer') drawer: MatDrawer;
    operazioneMassivaTipo = OperazioneMassivaTipo.SUPERAMENTO_CORSO;
    canRenderContent = false;
    currentProfile: AuthorityType;

    constructor(private filterService: CorsiFilterService,
                protected router: Router,
                private appInitService: AppInitService) {
        super();
        this.filterService.applyFiltersQuickly = router.getCurrentNavigation()?.extras?.state as ApplyFiltersQuicklyStudentsI;
        this.appInitService.cicloCorsoRuoloSelected$.pipe(takeUntil(this.destroy$)).subscribe(value => this.currentProfile = value?.sottoruolo);
    }

    getCurrentProfileCanAccess() {
        return (profile: AuthorityType) => [
            AuthorityType.DOCENTECORSO,
        ].includes(profile);
    }


    ngOnInit(): void {
        this.filterService.openDrawer
            .asObservable()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.openDrawer();
            });
    }

    protected readonly AuthorityType = AuthorityType;

    checkApplyFilterClick(): void {
        this.superamentoCorsiComponent?.applyFilter();
    }

    ngOnDestroy() {
        this.filterService.reset();
        super.ngOnDestroy();
    }

    approvalMode = false;
    periodsApprovalMode = false;
    validationConclusionApprovalMode = false;
    drawerOpened: boolean = false;

    changeRuolo(): void {
        this.operazioniMassiveComponent?.changeRuolo();
    }

    resetStep(): void {
        this.operazioniMassiveComponent?.resetStep();
    }

    closeDrawer(): void {
        this.drawer?.close();
    }

    openDrawer(): void {
        this.drawer.toggle();
    }


    toogleFilterContainer(resp: { hasToogleInternalMenu: boolean }): void {
        if (resp.hasToogleInternalMenu) {
            this.drawer.toggle();
        }
    }
}
