import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
    AddDeliberaDTO,
    AggiornamentoPianoDiStudiStatus,
    AuthorityType,
    DeliberaInfoView,
    DeliberaInfoViewImpl,
    DelibereService,
    EsitoRichiestaApprovazionePianoDiStudi,
    EsitoRichiestaApprovazionePianoDiStudiByCoordinatore,
    PageDeliberaInfoViewImpl,
    PianiDiStudiService
} from '../../../../api-clients/generated/services';
import {FuseConfirmationService, hideDelibera} from '../../../../@fuse/services/confirmation';
import {TranslocoService} from '@ngneat/transloco';
import {AbstractDefaultComponent} from '../../abstracts/abstract-default-component/abstract-default-component';
import {DialogApprovalConfiguration} from '../../interface/dialog-approval-configuration';
import {GenericTableConfigurationModel} from '../../components/table/model/generic-table-model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {get, isNil, orderBy} from 'lodash';
import {SnackbarTypes} from '../../../../@fuse/services/confirmation/snackbar/snackbar.component';
import {HttpErrorResponse} from '@angular/common/http';
import {finalize, switchMap, take, takeUntil} from 'rxjs';
import {filter} from 'rxjs/operators';
import {AppInitService} from "../../service/app-init.service";
import * as moment from "moment";
import {GenericComponentDialogConfig} from "../../../layout/common/generic-components/generic-components.interface";
import {
    GenericDialogComponent
} from "../../../layout/common/generic-components/generic-dialog/generic-dialog.component";
import {buildFormConfigForAddNewDelibera} from "../../../modules/landing/gestione-documenti/gestione-documenti-utils";
import {isNull} from "lodash-es";

@Component({
    selector: 'app-dialog-approval',
    templateUrl: './dialog-approval.component.html',
    styleUrls: ['./dialog-approval.component.scss']
})
export class DialogApprovalComponent extends AbstractDefaultComponent implements OnInit {

    showMiniLoader: boolean = false;
    extraActivitiesTableConfiguration: GenericTableConfigurationModel ;
    delibere: DeliberaInfoViewImpl[];
    deliberaForm: FormGroup;
    outputFormatData = 'DD/MM/YYYY';

    protected readonly AuthorityType = AuthorityType;
    protected readonly hideDelibera = hideDelibera;
    protected readonly AggiornamentoPianoDiStudiStatus = AggiornamentoPianoDiStudiStatus;
    protected readonly moment = moment;

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogApprovalConfiguration,
                public dialogRef: MatDialogRef<DialogApprovalComponent>,
                private fuseConfirmationService: FuseConfirmationService,
                private _translocoService: TranslocoService,
                private pianiDiStudiService: PianiDiStudiService,
                private dialog: MatDialog,
                private delibereService: DelibereService,
                private appInitService: AppInitService,
                private fb: FormBuilder,
    ) {
        super();
    }

    ngOnInit(): void {
        if(this.data?.sottoruolo === AuthorityType.COORDINATORE && !this.data?.pianoDaApprovare?.deliberaApprovazione){
            this.getDelibereRequest();
            this.createDeliberaForm();
        }
    }

    createDeliberaForm(): void {
        this.deliberaForm = this.fb.group({
            deliberaCollegio: [this.data?.pianoDaApprovare?.deliberaApprovazione?.id, this.data?.pianoDaApprovare?.isNuovaPresentazione ? Validators.required : undefined],
        });
    }

    onConfirm(): void {

        if (this.data.sottoruolo === AuthorityType.COORDINATORE && !this.data?.pianoDaApprovare?.deliberaApprovazione && this.deliberaForm.invalid){
            this.openSnackBarRequiredDelibera();
        } else {
            this.approvaEvento(this.data.sottoruolo);
        }

    }


    openSnackBarRequiredDelibera(): void {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        const message = get(translation, 'study_plan_status.required_delibera', null);
        this.fuseConfirmationService.openSnackBar({
            message: message,
            type: SnackbarTypes.Error
        });
    }

    closeDialog(): void {
        this.dialogRef.close();
    }


    approvaEvento(authority: AuthorityType): void {
        let http$;
        if (authority === AuthorityType.COORDINATORE) {
            const esito: EsitoRichiestaApprovazionePianoDiStudiByCoordinatore = {
                approvato: true,
                id_delibera: isNil(this.deliberaForm?.get('deliberaCollegio')?.value) ? undefined : this.deliberaForm?.get('deliberaCollegio')?.value
            };
            http$ = this.pianiDiStudiService.approvaRifiutaAggiornamentoByCoordinatore(
                esito,
                this.data.pianoDaApprovare.idPiano,
                this.data.pianoDaApprovare.idAggiornamento
            );
        }
        if (authority === AuthorityType.SUPERVISORE || authority === AuthorityType.COSUPERVISORE) {
            const esito: EsitoRichiestaApprovazionePianoDiStudi = {
                approvato: true,
            };
            http$ = this.pianiDiStudiService.approvaRifiutaAggiornamentoBySupervisore(
                esito,
                this.data.pianoDaApprovare.idPiano,
                this.data.pianoDaApprovare.idAggiornamento
            );
        }
        this.showMiniLoader = true;
        http$?.pipe(
            takeUntil(this.destroy$),
            finalize(() => {
                this.showMiniLoader = false;
            })
        ).subscribe({
            next: (value) => {
                this.dialogRef?.close(value);
                const activeLang = this._translocoService.getActiveLang();
                const translation = this._translocoService.getTranslation().get(activeLang);
                const message = get(translation, 'common.operation_success', null);
                this.fuseConfirmationService.openSnackBar({message: message});
            }, error: (err: HttpErrorResponse) => {
                const activeLang = this._translocoService.getActiveLang();
                const translation = this._translocoService.getTranslation().get(activeLang);
                const message = get(translation, 'common.not_loading_data', null);
                this.fuseConfirmationService.openSnackBar({
                    message: err?.error?.message || message,
                    error: err, type: SnackbarTypes.Error
                });
            }
        });
    }


    private getDelibereRequest() {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.showLoader();
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.delibereService.searchDelibereForm()),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (delibere: PageDeliberaInfoViewImpl) => {
                this.delibere = delibere?.content || [];
                this.delibere = orderBy(this.delibere, [delibera => moment(delibera.data)], ['desc']);
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this._translocoService,
                    () => {},() => this.getDelibereRequest(), 'dialog.cancel',
                    err?.error?.message);
            }
        });
    }

    formatDeliberaDate(dateFromBE: string): string {
        return moment(dateFromBE).format(this.outputFormatData);
    }

    openAddNewDeliberaDialog() {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        let confirmLabel = get(translation, 'dialog.confirm', null);
        let cancelLabel = get(translation, 'dialog.cancel', null);
        const data: GenericComponentDialogConfig = {
            title: 'common.add_delibera',
            //message: get(translation, 'mobility.approve_message', null),
            icon: {
                show: true,
                name: 'mat_outline:add',
                color: 'basic'
            },
            actions: {
                confirm: {
                    show: true,
                    label: confirmLabel,
                    color: 'primary',
                    function: (form, dialogRef) => this.addNewDeliberaRequest(form, form.getRawValue(), dialogRef)
                },
                cancel: {
                    show: true,
                    label: cancelLabel,
                }
            },
            dismissible: true,
            formConfig: buildFormConfigForAddNewDelibera(),
            valueForm: {
                fileDelibera: undefined,
                dataDelibera: undefined,
                numeroDelibera: undefined,
                fileNotaTrasmissione: undefined,
            }
        };
        this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: data.dismissible,
            disableClose: true,
        });
    }

    private addNewDeliberaRequest(form: FormGroup, rawValue: any, dialogRef: MatDialogRef<GenericDialogComponent>) {
        console.log(rawValue)
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        const requestBody: AddDeliberaDTO = {
            numero: rawValue.numeroDelibera,
            data: rawValue.dataDelibera
        }
        this.fuseConfirmationService.showLoader();
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.delibereService.addDeliberaForm(requestBody, rawValue.fileDelibera, rawValue.fileNotaTrasmissione)),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (addedDelibera: DeliberaInfoView) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation,'common.operation_success_delibera_added', null),
                    type: SnackbarTypes.Success,
                    duration: 5000,
                });
                this.delibere.push(addedDelibera);
                this.delibere = orderBy(this.delibere, [delibera => moment(delibera.data)], ['desc']);
                this.deliberaForm?.get('deliberaCollegio')?.patchValue(addedDelibera.id);
                dialogRef.close();
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this._translocoService,
                    () => {},() => this.addNewDeliberaRequest(form, rawValue, dialogRef), 'dialog.cancel',
                    err?.error?.message);
            }
        });

    }


}
