import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FuseConfirmationService } from '@fuse/services/confirmation/confirmation.service';
import { FuseConfirmationDialogComponent } from '@fuse/services/confirmation/dialog/dialog.component';
import { CommonModule } from '@angular/common';
import {SnackbarComponent} from './snackbar/snackbar.component';
import {TranslocoModule} from "@ngneat/transloco";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {FuseAlertModule} from "../../components/alert";
import {FlexModule} from "@angular/flex-layout";

@NgModule({
    declarations: [
        FuseConfirmationDialogComponent,
        SnackbarComponent
    ],
    imports: [
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        CommonModule,
        TranslocoModule,
        MatSnackBarModule,
        FuseAlertModule,
        FlexModule
    ],
    providers   : [
        FuseConfirmationService
    ]
})
export class FuseConfirmationModule
{
    /**
     * Constructor
     */
    constructor(private _fuseConfirmationService: FuseConfirmationService)
    {
    }
}
