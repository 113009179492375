import {ErrorHandler, Injectable, Injector} from "@angular/core";
import {LocalStorageService} from "../service/local-storage.service";
import moment from "moment";

@Injectable()
export class CustomErrorHandler implements ErrorHandler {

    constructor(private injector: Injector) {
    }

    handleError(error) {
        console.error(error);
        let localStorageService = this.injector.get(LocalStorageService);
        const errorObject = {
            happened_at: moment().format('DD/MM/YYYY HH:mm:ss.SSS'),
            user_id: localStorageService?.profile?.id,
            stack: error.stack,
            name: error.name,
            message: error.message,
        };
        localStorageService.enqueueError(errorObject);
    }
}
