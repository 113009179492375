/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Tipologie di modifica di un questionario sottomesso. Valori previsti: *BOZZA*, *SOTTOMISSIONE*
 */
export type TipoModificaQuestionarioSottomesso = 'BOZZA' | 'SOTTOMISSIONE';

export const TipoModificaQuestionarioSottomesso = {
    BOZZA: 'BOZZA' as TipoModificaQuestionarioSottomesso,
    SOTTOMISSIONE: 'SOTTOMISSIONE' as TipoModificaQuestionarioSottomesso
};