<ng-container *transloco="let t">
    <div class="w-full h-full flex justify-center items-center" style="min-height: 500px" [mat-spinner]=" showSpinner">

        <app-loading-screen *ngIf="loading; else dialogDocentiTemplate" class="w-full h-full">

        </app-loading-screen>


        <ng-template #dialogDocentiTemplate>
            <div class="dialog-multi-select-container" style="min-height: 250px">
                <div class="pr-2 ">
                    <app-dialog-wrap [data]="data"
                    [showPaddingBottom]="false"
                    >
                        <div class="w-full " style="min-height: 250px">

                            <!-- DELIBERA -->
                            <div class="w-full flex flex-col md:flex-row lg:gap-3 items-center lg:items-start mt-4 mb-0"
                                 *ngIf="data.showDeliberaField">

                                <form [formGroup]="form" class="w-full">

                                    <mat-form-field appearance="outline"
                                                    class="w-full add-mobilita-mat-form-field"
                                                    *ngIf="form?.get('deliberaCollegio') as fieldCtrl">
                                        <mat-label><strong>{{t('mobility.collegio_approval')}}</strong></mat-label>
                                        <mat-select formControlName='deliberaCollegio'>
                                            <mat-option [value]="undefined">-</mat-option>
                                            <mat-option *ngFor="let delibera of delibere" [value]="delibera.id">
                                                {{t('mobility.delibera_name')}} {{delibera.numero}} - {{formatDeliberaDate(delibera?.data)}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="fieldCtrl.hasError('required')">
                                            {{t('form.required')}}
                                        </mat-error>

                                    </mat-form-field>

                                </form>

                                <button [color]="'primary'"
                                        mat-raised-button
                                        type="button"
                                        class="add-delibera-button"
                                        [disabled]="loading || loadingDelibere"
                                        (click)="openAddNewDeliberaDialog()">
                                    <mat-icon class="mr-2">add</mat-icon>
                                    {{t('common.add_delibera')}}
                                </button>

                            </div>

                            <div class="flex flex-col lg:flex-row justify-between items-center gap-2">
                                <form [formGroup]="form" (ngSubmit)="applyFilters()"
                                      (keydown.tab)="$event.stopPropagation()">

                                    <div class="flex flex-col lg:flex-row justify-start items-center "
                                         style="padding: 0 !important; padding-top: 14px !important;"
                                    >
                                        <!--<ng-container *ngIf="data?.showSelectToSetDataTableByRole">
                                            <mat-form-field
                                                class="mat-form-field-select-sottoruolo pb-2 lg:pb-0 max-w-[10rem]"
                                                style="min-width: 10rem;"
                                                appearance="outline">
                                                <mat-label>{{ t('common.role') }}</mat-label>
                                                <mat-select [formControl]="filtroRuoloControl">
                                                    <mat-option *ngFor="let ruolo of listaRuoli" [value]="ruolo">
                                                        {{ ruolo | removeUnderscores }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </ng-container>-->

                                        <ng-container>
                                            <mat-form-field class="mat-form-field-no-padding pb-2 lg:pb-0"
                                                            style="width:43%; min-width: 300px;"
                                                            appearance="outline">
                                                <mat-label>{{ t('menu_filter.nominative') }}</mat-label>
                                                <input matInput
                                                       [placeholder]="t('menu_filter.nominative')"
                                                       [formControl]="filtroNomivativoControl"
                                                       [readonly]="(loading$ | async)">
                                                <button mat-icon-button
                                                        matSuffix
                                                        type="button"
                                                        (click)="svuotaFiltroNominativo()"
                                                        *ngIf="filtroNomivativoControl?.value"
                                                        [disabled]="(loading$ | async)">
                                                    <mat-icon>close</mat-icon>
                                                </button>
                                            </mat-form-field>

                                        </ng-container>

                                        <div class="lg:pl-2">
                                            <button mat-flat-button
                                                    type="submit"
                                                    class=" text-lg md:text-sm  lg:text-lg"
                                                    [color]="'primary'"
                                                    [disabled]="(loading$ | async)">
                                                <mat-icon class="mr-2">search</mat-icon>
                                                <span>{{ t('home.apply') }}</span>
                                            </button>
                                        </div>


                                    </div>
                                </form>

                                <div class="flex flex-col gap-1 items-center"
                                     *ngIf="data.showInvitaEsterni || data.showAggiungiDocenteManualmente">
                                    <span class="text-sm">{{ t('training_offer.add_teacher_manually_label') }}</span>
                                    <div class="flex flex-col xl:flex-row gap-2 items-center justify-center mb-1">
                                        <button mat-flat-button
                                                class="!text-lg md:!text-sm  lg:!text-lg"
                                                *ngIf="data.showInvitaEsterni"
                                                type="button"
                                                (click)="openAddExternDialog()"
                                                [color]="'accent'"
                                                [disabled]="(loading$ | async)">
                                            <mat-icon class="mr-2">add</mat-icon>
                                            <span>{{ t('training_offer.add_extern') }}</span>
                                        </button>
                                        <button mat-flat-button
                                                class=" text-lg md:text-md  lg:text-lg"
                                                *ngIf="data.showAggiungiDocenteManualmente"
                                                type="button"
                                                (click)="openAddTeacherManually()"
                                                [color]="'accent'"
                                                [disabled]="(loading$ | async)">
                                            <mat-icon class="mr-2">add</mat-icon>
                                            <span>{{ t('training_offer.add_teacher_manually') }}</span>
                                        </button>
                                    </div>

                                </div>
                            </div>

                            <div class="w-full mt-2" *ngIf="showTable">
                                <fuse-alert *ngIf="this.data?.isSingleChoiceSelection && this.data?.infoMessage "
                                            class="items-start mt-1"
                                            [appearance]="'outline'"
                                            [type]="alert.type"
                                            [pxPaddingInput]="'6px'"
                                            [pxPaddingRightInput]="'10px'">
                                    {{ t(this.data?.infoMessage) }}
                                </fuse-alert>
                                <div style="border-radius: 6px;  border: 1px solid #e2e8f0;" class="mb-3 mt-2 w-full">
                                    <app-generic-table class="w-full"
                                                       [configuration]="data.table.configuration"
                                                       [righeSelezionate]="data.righeSelezionate"
                                                       [idRigheSolaLettura]="data.idRigheSolaLettura"
                                                       [infoRigheInSolaLettura]="data.showInfoRigheSolaLettura"
                                                       [righeSelezionateCustom]="docentiExtra"
                                                       (tableDataEmitter)="onDataTableChanged($event)"
                                                       [readOnly]="(loading$ | async)"
                                                       (pageAction)="pageAction($event)"
                                                       (onReplaceCustomRow)="openAddExternFromExtraDialog($event)"
                                                       (customRowChanged)="customSelectedRowChange($event)"
                                                       [messageConfirmRemove]="data.messageConfirmRemove"
                                                       [righeConConfermaDiRimozioneParent]="data.righeConConfermaDiRimozioneParent"
                                    ></app-generic-table>
                                </div>
                                <div class="w-full flex flex-col gap-1 ml-2" *ngIf="
                                    data.showRigheSolaLetturaBecauseAlreadyInCollegio
                                    || data.showRigheSolaLetturaBecauseCoordinatore
                                    || data.showRigheSolaLetturaBecauseAlreadyCosupervisore">
                                    <div class="text-secondary text-sm"
                                         *ngIf="data.showRigheSolaLetturaBecauseAlreadyInCollegio">
                                        * {{t('cycle_doctorate.docente_already_in_collegio')}}.
                                    </div>
                                    <div class="text-secondary text-sm"
                                         *ngIf="data.showRigheSolaLetturaBecauseCoordinatore">
                                        ** {{t('cycle_doctorate.docente_is_coordinator')}}.
                                    </div>
                                    <div class="text-secondary text-sm"
                                         *ngIf="data.showRigheSolaLetturaBecauseAlreadyCosupervisore">
                                        * {{t('student.docente_already_cosupervisor')}}.
                                    </div>
                                </div>
                            </div>


                        </div>
                    </app-dialog-wrap>
                </div>

                <!-- Actions -->
                <ng-container *ngIf="data.actions.confirm.show || data.actions.cancel.show">
                    <div
                        class="flex flex-col justify-end lg:flex-row px-4 py-2 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10 mt-2">

                        <div class=" flex flex-col lg:flex-row items-center justify-center gap-2">
                            <fuse-alert
                                *ngIf="(data?.showDeliberaField && form.get('deliberaCollegio')?.invalid)"
                                class="items-start"
                                [appearance]="'outline'"
                                [type]="'error'"
                                [pxPaddingInput]="'6px'"
                                [pxPaddingRightInput]="'10px'">
                                {{ t('dialog.select_docenti_delibera_required') }}
                            </fuse-alert>
                            <fuse-alert
                                *ngIf="(!tableData || (tableData?.removedRows.length === 0 && tableData?.addedRows.length === 0))
                                && (tableData?.selectedRows.length !== 0) && !hasCustomChanged && !haveCheckBoxesChanged"
                                class="items-start"
                                [appearance]="'outline'"
                                [type]="'error'"
                                [pxPaddingInput]="'6px'"
                                [pxPaddingRightInput]="'10px'">
                                {{ t('dialog.not_modified_message') }}
                            </fuse-alert>
                        </div>

                        <div class="flex flex-row justify-center sm:justify-end px-4 py-2 space-x-3">
                            <!-- Cancel -->
                            <ng-container *ngIf="data.actions.cancel.show">
                                <button color="accent" mat-flat-button [disabled]="(loading$ | async)"
                                        [matDialogClose]="'cancelled'">
                                    <mat-icon class="mr-2">close</mat-icon>
                                    {{ t(data.actions.cancel.label) || t('common.close') }}
                                </button>
                            </ng-container>

                            <!-- Confirm -->
                            <ng-container *ngIf="data.actions.confirm.show && !data.readOnly">
                                <button mat-flat-button
                                        (click)="save()"
                                        [disabled]="(loading$ | async)
                                        || (data?.showDeliberaField && form.get('deliberaCollegio')?.invalid)
                                        || (!tableData && !hasCustomChanged && !haveCheckBoxesChanged)
                                        || (tableData?.removedRows.length === 0 && tableData?.addedRows.length === 0 && !hasCustomChanged && !haveCheckBoxesChanged)
                                        || (!data?.allowNoSelectionSingleChoice && data?.isSingleChoiceSelection && tableData?.selectedRows.length === 0 && !hasCustomChanged && !haveCheckBoxesChanged)
                                        || (data?.atLeastOneRequired && tableData?.selectedRows.length === 0)"
                                        [color]="data.actions.confirm.color">
                                    <mat-icon class="mr-2">check</mat-icon>
                            <span>
                                {{ t(data.actions.confirm.label) || t('dialog.confirm') }}
                            </span>
                                    <mat-progress-spinner
                                        style="margin-left: 5px"
                                        *ngIf="loading$ | async"
                                        [diameter]="24"
                                        [mode]="'indeterminate'"></mat-progress-spinner>
                                </button>

                            </ng-container>
                        </div>

                    </div>
                </ng-container>
            </div>
        </ng-template>

    </div>

</ng-container>

