import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DocumentComponent} from "./document.component";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {TranslocoModule} from "@ngneat/transloco";
import {MatTooltipModule} from "@angular/material/tooltip";
import {SharedModule} from "../../shared.module";
import { DocumentNameFromUrlPipe } from './pipes/document-name-from-url.pipe';


@NgModule({
    declarations: [
        DocumentComponent,
        DocumentNameFromUrlPipe
    ],
    imports: [
        CommonModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        TranslocoModule,
        MatTooltipModule,
        SharedModule
    ],
    exports: [
        DocumentComponent,
        DocumentNameFromUrlPipe
    ],
    providers: [DocumentNameFromUrlPipe]
})
export class DocumentModule {
}
