import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DialogWrapComponent} from "./dialog-wrap.component";
import {MatButtonModule} from "@angular/material/button";
import {MatDialogModule} from "@angular/material/dialog";
import {MatIconModule} from "@angular/material/icon";
import {TranslocoModule} from "@ngneat/transloco";
import {ExtendedModule, FlexModule} from "@angular/flex-layout";


@NgModule({
    declarations: [DialogWrapComponent],
    exports: [
        DialogWrapComponent
    ],
    imports: [
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        CommonModule,
        TranslocoModule,
        FlexModule,
        ExtendedModule,
    ]
})
export class DialogWrapModule { }
