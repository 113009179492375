/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Categoria spesa missione.
 */
export type DettaglioSpesaMissioneCategory = 'TRENO' | 'AEREO' | 'NAVE' | 'AUTOBUS_METRO' | 'TAXY' | 'MEZZI_A_NOLEGGIO' | 'VITTO_CO_RISTORANTI_BAR' | 'PERNOTTAMENTO' | 'QUOTA_DI_ISCRIZIONE' | 'ALTRO';

export const DettaglioSpesaMissioneCategory = {
    TRENO: 'TRENO' as DettaglioSpesaMissioneCategory,
    AEREO: 'AEREO' as DettaglioSpesaMissioneCategory,
    NAVE: 'NAVE' as DettaglioSpesaMissioneCategory,
    AUTOBUSMETRO: 'AUTOBUS_METRO' as DettaglioSpesaMissioneCategory,
    TAXY: 'TAXY' as DettaglioSpesaMissioneCategory,
    MEZZIANOLEGGIO: 'MEZZI_A_NOLEGGIO' as DettaglioSpesaMissioneCategory,
    VITTOCORISTORANTIBAR: 'VITTO_CO_RISTORANTI_BAR' as DettaglioSpesaMissioneCategory,
    PERNOTTAMENTO: 'PERNOTTAMENTO' as DettaglioSpesaMissioneCategory,
    QUOTADIISCRIZIONE: 'QUOTA_DI_ISCRIZIONE' as DettaglioSpesaMissioneCategory,
    ALTRO: 'ALTRO' as DettaglioSpesaMissioneCategory
};