import {Component, Inject, OnInit} from '@angular/core';
import {BehaviorSubject} from "rxjs";

import {getOperazioneDescrizione} from "../../operazioni-massive-utils";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { AbstractDefaultComponent } from 'app/shared/abstracts/abstract-default-component/abstract-default-component';
import {OperazioneMassivaDataI} from "../../operazioni-massive-config";
import {CAN_GO_AHEAD$, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {get} from "lodash";
import {TranslocoService} from "@ngneat/transloco";
import {ApprovazioneSpeseMassivaDataI} from "../../approvazione-spesa-steps/approvazione-spese-steps-interface";



export interface ShowWithDeliberaStepI {
    label: string;
    withDelibera: boolean;
}

@Component({
    selector: 'app-show-only-with-delibera-step',
    templateUrl: './show-with-delibera-step.component.html',
    styleUrls: ['./show-with-delibera-step.component.scss']
})
export class ShowWithDeliberaStepComponent extends AbstractDefaultComponent implements OnInit {

    formGroup: FormGroup;
    options: ShowWithDeliberaStepI[] = [
        {label: 'massive_operations.show_with_delibera', withDelibera: true},
        {label: 'massive_operations.show_without_delibera', withDelibera: false},
    ];

    constructor(private fb: FormBuilder,
                private translocoService: TranslocoService,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<OperazioneMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.buildForm();
        this.operazioneMassivaData$.subscribe((inputOutputData: ApprovazioneSpeseMassivaDataI) => {
            if (inputOutputData.refreshData) {
                this.canGoNext$.next(false);
            }
            if(this.formGroup?.get('withDelibera')?.value !== inputOutputData?.showWithDelibera){
                this.formGroup?.get('withDelibera')?.setValue(inputOutputData?.showWithDelibera);
            }
        });
    }



    private buildForm() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.formGroup = this.fb.group({
            withDelibera: [undefined, Validators.required]
        });
        // subscribe to form value changes to update step data
        this.formGroup?.get('withDelibera')?.valueChanges?.subscribe(value => {
            this.operazioneMassivaData$.next({
                showWithDelibera: value,
                showWithDeliberaTranslatedLabel: get(translation, this.options?.find(o => o.withDelibera === value)?.label),
                refreshData: true
            });
            this.canGoNext$.next(this.formGroup?.get('withDelibera')?.valid);
        })
    }
}
