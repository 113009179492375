/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Valori relativi agli anni di riferimento. Valori previsti: *PRIMO*, *SECONDO*, *TERZO*
 */
export type AnnoRiferimentoValues = 'PRIMO' | 'SECONDO' | 'TERZO';

export const AnnoRiferimentoValues = {
    PRIMO: 'PRIMO' as AnnoRiferimentoValues,
    SECONDO: 'SECONDO' as AnnoRiferimentoValues,
    TERZO: 'TERZO' as AnnoRiferimentoValues
};