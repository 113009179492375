/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Stati in fase di onboarding per utente Esterno. Tipologie: *INCOMPLETO*, *INVITATO*, *DA_SINCRONIZZARE*, *SINCRONIZZATO*
 */
export type OnboardingEsternoStatus = 'INVITATO' | 'DA_SINCRONIZZARE' | 'SINCRONIZZATO' | 'LOGGATO' | 'GIA_PRESENTE';

export const OnboardingEsternoStatus = {
    INVITATO: 'INVITATO' as OnboardingEsternoStatus,
    DASINCRONIZZARE: 'DA_SINCRONIZZARE' as OnboardingEsternoStatus,
    SINCRONIZZATO: 'SINCRONIZZATO' as OnboardingEsternoStatus,
    LOGGATO: 'LOGGATO' as OnboardingEsternoStatus,
    GIAPRESENTE: 'GIA_PRESENTE' as OnboardingEsternoStatus
};