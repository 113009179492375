/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Tipi di Personale di un utente all'interno del collegio docenti. Valori previsti: *PERSONALE_ACCADEMICO*, *PERSONALE_NON_ACCADEMICO*, *ALTRO_PERSONALE*
 */
export type CollegioDocentiTipoPersonale = 'PERSONALE_ACCADEMICO' | 'PERSONALE_NON_ACCADEMICO' | 'ALTRO_PERSONALE';

export const CollegioDocentiTipoPersonale = {
    PERSONALEACCADEMICO: 'PERSONALE_ACCADEMICO' as CollegioDocentiTipoPersonale,
    PERSONALENONACCADEMICO: 'PERSONALE_NON_ACCADEMICO' as CollegioDocentiTipoPersonale,
    ALTROPERSONALE: 'ALTRO_PERSONALE' as CollegioDocentiTipoPersonale
};