import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LoadingScreenComponent} from "./loading-screen.component";
import {FlexModule} from "@angular/flex-layout";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatIconModule} from "@angular/material/icon";
import {TranslocoModule} from "@ngneat/transloco";




@NgModule({
    declarations: [LoadingScreenComponent],
    exports: [
        LoadingScreenComponent
    ],
    imports: [
        CommonModule,
        FlexModule,
        MatProgressSpinnerModule,
        MatIconModule,
        TranslocoModule
    ]
})
export class LoadingScreenModule { }
