<div class="input-file-container">
    <label class="mat-orphan-label" [for]="id" [class.active]="files?.length" [innerHtml]="placeholder" *ngIf="placeholder"></label>
    <div class="files-container">

        <ng-container *ngFor="let file of files; let i = index">
            <div class="file-container" [ngClass]="classAnimation">
                <ng-container *ngTemplateOutlet="fileTemplate; context: { $implicit: file, index: i }"></ng-container>
            </div>
        </ng-container>

        <ng-container *ngIf="!readOnly && canAddFile">
            <div class="file-container w-full">
                <ng-container *ngTemplateOutlet="addTemplate"></ng-container>
            </div>
        </ng-container>

    </div>

</div>

<ng-template #addTemplate>
    <button mat-button inputFileDropZone class="file-button w-full rounded-xl bg-gray-200" type="button" (click)="fileInput.click()" (itemDrop)="onSelectFile($event, selectButton)"
        (itemDragOver)="onDragOver(selectButton)" (itemDragLeave)="onDragLeave(selectButton)" [disabled]="disabled" #selectButton>
        <div class="h-full w-full flex flex-row justify-center items-center gap-1 text-center">
            <div class="w-full text-secondary break-words">{{addLabel}}</div>
            <mat-icon class="input-icon">{{ iconAdd }}</mat-icon>
        </div>
    </button>
    <input [id]="id" [accept]="fileAccept" hidden type="file" [attr.multiple]="fileLimit > 1 ? true : null" #fileInput (change)="onSelectFile($event.target.files, selectButton)">
</ng-template>

<ng-template #fileTemplate let-file let-index="index">
    <img class="image-preview" [src]="file.preview" *ngIf="file.preview; else noImagePreview" alt="File image">
    <ng-template #noImagePreview>
        <img class="image-preview bg-slate-100 rounded" [src]="'assets/images/ui/file-input-preview/attachment_icon.svg'" alt="Attachment Logo">
    </ng-template>
    <button mat-button class="secondary-button" type="button" (click)="onDeleteFile(index)" [disabled]="disabled" *ngIf="!readOnly" [matTooltip]="file?.file?.name">
        <div class="flex flex-col justify-center">
            <mat-icon color="warn">{{ iconDelete }}</mat-icon>
        </div>
    </button>
    <button mat-button class="secondary-button" type="button" (click)="onFileClick(index)" [disabled]="disabled" *ngIf="readOnly" [matTooltip]="file?.file?.name">
        <div class="flex flex-col justify-center">
            <mat-icon color="primary" [svgIcon]="iconShow"></mat-icon>
        </div>
    </button>
    <input [accept]="fileAccept" hidden type="file" #fileReplace (change)="onReplaceFile($event.target.files, index, fileReplace)">
</ng-template>

