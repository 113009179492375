import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { italianDateWithFormat } from 'app/shared/utils/utils-date';
import {DialogWrapI} from "../../../../layout/common/dialog-wrap/dialog-wrap.component";
import {AggiornamentoPianoDiStudiStatus, Audit, BodyPart} from "../../../../../api-clients/generated/services";
import TipoElementoBodyEnum = BodyPart.TipoElementoBodyEnum;


export interface DialogAuditDetailsI extends DialogWrapI {
    audit: Audit
}

@Component({
    selector: 'app-dialog-audit-details',
    templateUrl: './dialog-audit-details.component.html',
    styleUrls: ['./dialog-audit-details.component.scss']
})
export class DialogAuditDetailsComponent implements OnInit {

    protected readonly italianDateWithFormat = italianDateWithFormat;
    protected readonly JSON = JSON;
    parsedBody = {};
    parsedBodyParts = [];
    protected readonly Audio = Audio;
    protected readonly Date = Date;
    protected readonly AggiornamentoPianoDiStudiStatus = AggiornamentoPianoDiStudiStatus;

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogAuditDetailsI,
                public dialogRef: MatDialogRef<DialogAuditDetailsComponent>) {
        // try to parse as json
        try {
            this.parsedBody = data.audit?.bodyRisposta ? JSON.parse(data.audit?.bodyRisposta) : undefined;
        } catch {
            this.parsedBody = data.audit?.bodyRisposta;
        }
        this.parsedBodyParts = data.audit?.bodyParts?.map(p => {
            let valore = [p.valore];
            try {
                valore = p.tipoElementoBody === TipoElementoBodyEnum.RAW ? JSON.parse(p.valore) : p.valore
            } catch {}
            return {
                nome: p.nome,
                tipoElementoBody: p.tipoElementoBody,
                valore: valore
            };
        });
        console.log(this.parsedBodyParts)
    }

    ngOnInit(): void {

    }

    closeDialog() {
        this.dialogRef.close();
    }



}
