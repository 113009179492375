/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Ticket Status. Valori previsti: *open*, *pending*, *answered*, *resolved*, *closed*, *spam*
 */
export type TicketStatus = 'open' | 'pending' | 'answered' | 'resolved' | 'closed' | 'spam';

export const TicketStatus = {
    Open: 'open' as TicketStatus,
    Pending: 'pending' as TicketStatus,
    Answered: 'answered' as TicketStatus,
    Resolved: 'resolved' as TicketStatus,
    Closed: 'closed' as TicketStatus,
    Spam: 'spam' as TicketStatus
};