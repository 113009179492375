/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Tipologie di input supportate per glie extra fields. Tipologie: *TESTO*, *FILE*, *INTERO*, *DECIMALE*, *DATA*, *SELECT*, *FILE_CON_RISCONTRO*
 */
export type TipologieFieldsEnum = 'TESTO' | 'FILE' | 'INTERO' | 'DECIMALE' | 'DATA' | 'SELECT' | 'FILE_CON_RISCONTRO';

export const TipologieFieldsEnum = {
    TESTO: 'TESTO' as TipologieFieldsEnum,
    FILE: 'FILE' as TipologieFieldsEnum,
    INTERO: 'INTERO' as TipologieFieldsEnum,
    DECIMALE: 'DECIMALE' as TipologieFieldsEnum,
    DATA: 'DATA' as TipologieFieldsEnum,
    SELECT: 'SELECT' as TipologieFieldsEnum,
    FILECONRISCONTRO: 'FILE_CON_RISCONTRO' as TipologieFieldsEnum
};