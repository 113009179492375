/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Stati di un piano di studi. Valori previsti: *NON_PRESENTATO*, *ATTESA_APPROVAZIONE*, *RIFIUTATO*, *APPROVATO*
 */
export type PianoDiStudiStatus = 'NON_PRESENTATO' | 'ATTESA_APPROVAZIONE' | 'RIFIUTATO' | 'APPROVATO';

export const PianoDiStudiStatus = {
    NONPRESENTATO: 'NON_PRESENTATO' as PianoDiStudiStatus,
    ATTESAAPPROVAZIONE: 'ATTESA_APPROVAZIONE' as PianoDiStudiStatus,
    RIFIUTATO: 'RIFIUTATO' as PianoDiStudiStatus,
    APPROVATO: 'APPROVATO' as PianoDiStudiStatus
};