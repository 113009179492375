import {Pipe, PipeTransform} from '@angular/core';
import {
    AggiornamentoBudgetStudenteStatus,
    AggiornamentoPianoDiStudiStatus,
    AttivitaExtraPianoDiStudiStatus,
    AttivitaTrasversaleStatus, AuthorityType,
    JobStatus, MissioneStatus, OffertaFormativaStatus, OnboardingEsternoStatus,
    PeriodoDiMobilitaStudenteStatus,
    PeriodoRegistroAttivitaStatus,
    PianoDiStudiStatus,
    QuestionarioSottomessoStatus, RichiestaDiRetribuzioneMobilitaStatus,
    SpesaStudenteStatus, StudenteCicloMotivoStatus, StudenteCicloStatus, SuperamentoAnnoStudenteStatus,
    TicketPriority,
    TicketStatus,
    TipoModificaCorsoPianoDiStudi, TipoOperazioneSbloccoPiano, TipoOperazioneStoricoMobilitaStudente, TipoPeriodoEnum
} from '../../../api-clients/generated/services';
import {
    getLanguagePathBooleanNotNullStatus,
    getLanguagePathBooleanStatus,
    getLanguagePathByAggiornamentoBudgetStudenteStatus,
    getLanguagePathByAggiornamentoPianoDiStudiStatus,
    getLanguagePathByAttivitaExtraPianoDiStudiStatus,
    getLanguagePathByAttivitaTrasversale, getLanguagePathByAutorizzazioneStatus,
    getLanguagePathByEsternoStatus,
    getLanguagePathByHttpStatus,
    getLanguagePathByJobStatus, getLanguagePathByMissioneStatus,
    getLanguagePathByMobilitaStatus,
    getLanguagePathByOffertaFormativaStatus,
    getLanguagePathByPeriodiRegistroStatus,
    getLanguagePathByPeriodo,
    getLanguagePathByPianoDiStudiStatus,
    getLanguagePathByQuestionarioSottomessoStatus,
    getLanguagePathByRetribuzioneMobilitaStatus,
    getLanguagePathBySpesaStudenteStatus,
    getLanguagePathByStudenteCicloMotivoStatus,
    getLanguagePathByStudenteCicloStatus, getLanguagePathBySuperamentoAnnoStatus,
    getLanguagePathByTicketPriority,
    getLanguagePathByTicketStatus,
    getLanguagePathByTicketTipologia,
    getLanguagePathByTipoModificaCorsoPianoDiStudi,
    getLanguagePathByTipoOperazioneMobilitaStatus, getLanguagePathTipoOperazioneSbloccoPiano, getYesOrNo
} from '../utils/utils';
import {TicketTipologia} from "../../modules/landing/support/support.component";
import {YesOrNotEnum} from "../costants/app-constants";
import {
    PianoDiStudiStatusExtended
} from "../../modules/landing/cycle/training-offer/dialog-invalid-study-plans/dialog-invalid-study-plans.component";
import {BooleanStatus, BooleanStatusNotNull} from "../components/table/model/generic-table-model";
import {
    StatoAutorizzazione
} from "../../modules/landing/requests/approvazione-periodi-di-mobilita-ricerca/tabs-periodi-mobilita-ricerca/tabs-periodi-mobilita-ricerca.component";

@Pipe({
  name: 'statusEnumToLanguagePath'
})
export class StatusEnumToLanguagePathPipe implements PipeTransform {

    transform(value: AggiornamentoPianoDiStudiStatus | PianoDiStudiStatus | PianoDiStudiStatusExtended | TipoModificaCorsoPianoDiStudi
        | AttivitaExtraPianoDiStudiStatus | TicketStatus | TicketPriority | TicketTipologia
        | QuestionarioSottomessoStatus | SpesaStudenteStatus | JobStatus | AttivitaTrasversaleStatus
        | PeriodoDiMobilitaStudenteStatus | OnboardingEsternoStatus | OffertaFormativaStatus
        | PeriodoRegistroAttivitaStatus | RichiestaDiRetribuzioneMobilitaStatus | AggiornamentoBudgetStudenteStatus
        | TipoOperazioneStoricoMobilitaStudente | StudenteCicloStatus | StudenteCicloMotivoStatus | MissioneStatus
        | BooleanStatusNotNull | TipoOperazioneSbloccoPiano | SuperamentoAnnoStudenteStatus | string, valueEnum: any, sottoruolo?: AuthorityType): string {
        switch (valueEnum){
            case AggiornamentoPianoDiStudiStatus:
                return getLanguagePathByAggiornamentoPianoDiStudiStatus(value as AggiornamentoPianoDiStudiStatus, sottoruolo);
            case PianoDiStudiStatus:
            case PianoDiStudiStatusExtended:
                return getLanguagePathByPianoDiStudiStatus(value as PianoDiStudiStatusExtended);
            case TipoModificaCorsoPianoDiStudi:
                return getLanguagePathByTipoModificaCorsoPianoDiStudi(value as TipoModificaCorsoPianoDiStudi);
            case AttivitaExtraPianoDiStudiStatus:
                return getLanguagePathByAttivitaExtraPianoDiStudiStatus(value as AttivitaExtraPianoDiStudiStatus);
            case TicketStatus:
                return getLanguagePathByTicketStatus(value as TicketStatus);
            case TicketPriority:
                return getLanguagePathByTicketPriority(value as TicketPriority);
            case TicketTipologia:
                return getLanguagePathByTicketTipologia(value as TicketTipologia);
            case QuestionarioSottomessoStatus:
                return getLanguagePathByQuestionarioSottomessoStatus(value as QuestionarioSottomessoStatus);
            case SpesaStudenteStatus:
                return getLanguagePathBySpesaStudenteStatus(value as SpesaStudenteStatus, sottoruolo);
            case JobStatus:
                return getLanguagePathByJobStatus(value as JobStatus);
            case AttivitaTrasversaleStatus:
                return getLanguagePathByAttivitaTrasversale(value as AttivitaTrasversaleStatus);
            case PeriodoDiMobilitaStudenteStatus:
                return getLanguagePathByMobilitaStatus(value as PeriodoDiMobilitaStudenteStatus, sottoruolo);
            case OnboardingEsternoStatus:
                return getLanguagePathByEsternoStatus(value as OnboardingEsternoStatus);
            case PeriodoRegistroAttivitaStatus:
                return getLanguagePathByPeriodiRegistroStatus(value as PeriodoRegistroAttivitaStatus, sottoruolo);
            case OffertaFormativaStatus:
                return getLanguagePathByOffertaFormativaStatus(value as OffertaFormativaStatus);
            case AggiornamentoBudgetStudenteStatus:
                return getLanguagePathByAggiornamentoBudgetStudenteStatus(value as AggiornamentoBudgetStudenteStatus);
            case TipoPeriodoEnum:
                return getLanguagePathByPeriodo(value as TipoPeriodoEnum);
            case RichiestaDiRetribuzioneMobilitaStatus:
                return getLanguagePathByRetribuzioneMobilitaStatus(value as RichiestaDiRetribuzioneMobilitaStatus, sottoruolo);
            case TipoOperazioneStoricoMobilitaStudente:
                return getLanguagePathByTipoOperazioneMobilitaStatus(value as TipoOperazioneStoricoMobilitaStudente);
            case StudenteCicloStatus:
                return getLanguagePathByStudenteCicloStatus(value as StudenteCicloStatus);
            case StudenteCicloMotivoStatus:
                return getLanguagePathByStudenteCicloMotivoStatus(value as StudenteCicloMotivoStatus);
            case MissioneStatus:
                return getLanguagePathByMissioneStatus(value as MissioneStatus);
            case YesOrNotEnum:
                return getYesOrNo(value as YesOrNotEnum);
            case BooleanStatus:
                return getLanguagePathBooleanStatus(value as unknown as BooleanStatus);
            case BooleanStatusNotNull:
                return getLanguagePathBooleanNotNullStatus(value as unknown as BooleanStatusNotNull);
            case TipoOperazioneSbloccoPiano:
                return getLanguagePathTipoOperazioneSbloccoPiano(value as unknown as TipoOperazioneSbloccoPiano);
            case StatoAutorizzazione:
                return getLanguagePathByAutorizzazioneStatus(value as unknown as StatoAutorizzazione);
            case SuperamentoAnnoStudenteStatus:
                return getLanguagePathBySuperamentoAnnoStatus(value as SuperamentoAnnoStudenteStatus);
            case undefined:
                return getLanguagePathByHttpStatus(value as string);
        }
    }

}
