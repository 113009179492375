<div class="w-full flex flex-col gap-3 mt-1 divide-y" *transloco="let t">

    <app-info-maggiorazione class="w-full"
                            *ngIf="richiestaRetribuzione.dettaglioCalcoloImporto"
                            [dettaglioImporto]="richiestaRetribuzione.dettaglioCalcoloImporto">
    </app-info-maggiorazione>

    <div class="w-full flex flex-col gap-2 pt-3"
        *ngIf="richiestaRetribuzione.notePrimoApprovatore">
        <div class="font-medium text-lg">{{t('mobility.first_approver_note')}}</div>
        <div class="text-secondary">{{richiestaRetribuzione.notePrimoApprovatore}}</div>
    </div>

    <div class="w-full flex flex-col gap-2 pt-3"
        *ngIf="richiestaRetribuzione.noteSecondoApprovatore">
        <div class="font-medium text-lg">{{t('mobility.second_approver_note')}}</div>
        <div class="text-secondary">{{richiestaRetribuzione.noteSecondoApprovatore}}</div>
    </div>

    <div class="w-full flex flex-col gap-2 pt-3"
        *ngIf="richiestaRetribuzione.noteValidatorePta">
        <div class="font-medium text-lg">{{t('mobility.pta_approver_note')}}</div>
        <div class="text-secondary">{{richiestaRetribuzione.noteValidatorePta}}</div>
    </div>

</div>


