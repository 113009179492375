/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Stati di un attività trasversale. Valori previsti: *ATTESA_DELIBERA_COORDINATORE*, *ATTESA_DOCUMENTO_DOTTORANDO*, *DOCUMENTO_DOTTORANDO_CARICATO*, *DOCUMENTO_DOTTORANDO_RIFIUTATO*, *ATTESA_SUPERAMENTO*, *ATTESA_DOCUMENTO_SUPERVISORE*, *RICHIESTA_SUPERAMENTO*, *RICHIESTA_SUPERAMENTO_CON_DOCUMENTO_SUPERVISORE*, *COMPLETATA*
 */
export type AttivitaTrasversaleStatus = 'ATTESA_DELIBERA_COORDINATORE' | 'ATTESA_DOCUMENTO_DOTTORANDO' | 'DOCUMENTO_DOTTORANDO_CARICATO' | 'DOCUMENTO_DOTTORANDO_RIFIUTATO' | 'ATTESA_SUPERAMENTO' | 'ATTESA_DOCUMENTO_SUPERVISORE' | 'RICHIESTA_SUPERAMENTO' | 'RICHIESTA_SUPERAMENTO_CON_DOCUMENTO_SUPERVISORE' | 'COMPLETATA';

export const AttivitaTrasversaleStatus = {
    ATTESADELIBERACOORDINATORE: 'ATTESA_DELIBERA_COORDINATORE' as AttivitaTrasversaleStatus,
    ATTESADOCUMENTODOTTORANDO: 'ATTESA_DOCUMENTO_DOTTORANDO' as AttivitaTrasversaleStatus,
    DOCUMENTODOTTORANDOCARICATO: 'DOCUMENTO_DOTTORANDO_CARICATO' as AttivitaTrasversaleStatus,
    DOCUMENTODOTTORANDORIFIUTATO: 'DOCUMENTO_DOTTORANDO_RIFIUTATO' as AttivitaTrasversaleStatus,
    ATTESASUPERAMENTO: 'ATTESA_SUPERAMENTO' as AttivitaTrasversaleStatus,
    ATTESADOCUMENTOSUPERVISORE: 'ATTESA_DOCUMENTO_SUPERVISORE' as AttivitaTrasversaleStatus,
    RICHIESTASUPERAMENTO: 'RICHIESTA_SUPERAMENTO' as AttivitaTrasversaleStatus,
    RICHIESTASUPERAMENTOCONDOCUMENTOSUPERVISORE: 'RICHIESTA_SUPERAMENTO_CON_DOCUMENTO_SUPERVISORE' as AttivitaTrasversaleStatus,
    COMPLETATA: 'COMPLETATA' as AttivitaTrasversaleStatus
};