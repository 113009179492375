import {Pipe, PipeTransform} from '@angular/core';
import {italianDateWithFormat} from "../../../utils/utils-date";
import moment from "moment/moment";

@Pipe({
    name: 'documentNameFromUrl'
})
export class DocumentNameFromUrlPipe implements PipeTransform {

    transform(fileUrl: string): unknown {
        const extension = fileUrl?.substring(fileUrl?.lastIndexOf('.'));
        const fileNameWithouthTimestamp = fileUrl?.substring(0, fileUrl?.lastIndexOf('_'));
        const fileName = fileNameWithouthTimestamp?.substring(fileNameWithouthTimestamp?.lastIndexOf('/') + 1);
        const timestampString = fileUrl?.substring(fileUrl?.lastIndexOf('_') + 1, fileUrl?.lastIndexOf('.'));
        const uploadDate = italianDateWithFormat(moment(Number(timestampString)).toDate(), 'DD_MM_YYYY');
        return fileName + '_' + uploadDate + extension;
    }

}
