<ng-container *transloco="let t">


    <div class="flex flex-col w-full min-h-[20rem] items-center justify-center"
         *ngIf="loading; else stepTemplate">
        <app-loading-screen class="w-full h-full">
        </app-loading-screen>
    </div>


    <ng-template #stepTemplate>

        <div class="w-full flex flex-col items-center">

            <div class="my-4">
                <fuse-alert class="w-full"
                            [showIcon]="true"
                            [appearance]="'outline'"
                >{{t('massive_operations.superamento_corso.hint_compile_info')}}</fuse-alert>
            </div>

            <div class="w-full flex flex-col items-center">
                <!-- INFO SUPERAMENTO FORM-->
                <app-form *ngIf="!!this.corso"
                          class="w-full"
                          [formGroupConfig]="formConfig"
                          [valueForm]="valueForm"
                          (formValueChange)="formValueChanged($event)"
                ></app-form>

                <button mat-flat-button
                        class="max-w-fit mb-8"
                        *ngIf="!studentsListConfiguration"
                        (click)="toggleStudentsList()"
                        [color]="'primary'">
                    <mat-icon class="mr-2">edit</mat-icon>
                    <span>{{t('massive_operations.superamento_corso.add_custom_notes')}}</span>
                </button>
            </div>

            <!-- TABELLA STUDENTI -->
            <div class="lg:w-[95%] my-4 mx-8 flex flex-col items-center gap-5 bg-gray-50 rounded-xl p-6"
                 *ngIf="!!studentsListConfiguration">

                <button mat-flat-button
                        class="max-w-fit "
                        (click)="toggleStudentsList()"
                        [color]="'accent'">
                    <mat-icon class="mr-2">close</mat-icon>
                    <span>{{t('common.close')}}</span>
                </button>

                <app-generic-table-editable
                    class="w-full"
                    [parentFormGroup]="customNotesFormGroup"
                    [configuration]="studentsListConfiguration">
                </app-generic-table-editable>
            </div>

        </div>



    </ng-template>



</ng-container>
