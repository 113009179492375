import {Pipe, PipeTransform} from '@angular/core';
import {EsitoOperazione} from "../../../api-clients/generated/services";

@Pipe({
    name: 'operationSucceedWithAllSuccess'
})
export class OperationSucceedWithAllSuccessPipe implements PipeTransform {

    constructor() { }

    transform(operationEsiti: { esito?: EsitoOperazione }[], args?: any): boolean {
        return operationEsiti?.every(e => e.esito === EsitoOperazione.SUCCESS);
    }
}
